import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { PARCEL_SERVICE_TYPE_PATH } from '../../../../config/paths';
import {
  clearParcelServiceType,
  getParcelServiceType,
} from '../../../../actions/cargo/ParcelServiceType';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import ParcelServiceTypeBasicInformationResource from './ParcelServiceTypeInformationResource';
import ParcelServiceTypeToolbar from './ParcelServiceTypeToolbar';

const ParcelServiceType = ({
  breadcrumbs,
  parcelServiceType,
  loading,
  match: {
    params: { id: parcelServiceTypeId },
  },
  dispatchGetParcelServiceType,
  dispatchClearParcelServiceType,
}) => {
  useLayoutEffect(() => {
    dispatchGetParcelServiceType({ parcelServiceTypeId });
    return () => dispatchClearParcelServiceType();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (parcelServiceType.isEmpty())
    content = <NoDataResource returnPage={PARCEL_SERVICE_TYPE_PATH} />;
  else {
    const parcelServiceTypeJson = parcelServiceType.toJS();
    content = (
      <ParcelServiceTypeBasicInformationResource {...parcelServiceTypeJson} />
    );
    toolbar = (
      <ParcelServiceTypeToolbar
        parcelServiceTypeId={parcelServiceTypeJson.id}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Tipo de Servicio"
      content={content}
    />
  );
};

ParcelServiceType.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  loading: PropTypes.bool,
  dispatchGetParcelServiceType: PropTypes.func.isRequired,
  dispatchClearParcelServiceType: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  parcelServiceType: PropTypes.instanceOf(Immutable.Map).isRequired,
};

ParcelServiceType.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tipos de Servicio',
      href: PARCEL_SERVICE_TYPE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  parcelServiceType: CargoUnit.ParcelServiceType.getIn(['current', 'content']),
  loading: !CargoUnit.ParcelServiceType.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetParcelServiceType: getParcelServiceType,
  dispatchClearParcelServiceType: clearParcelServiceType,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParcelServiceType);
