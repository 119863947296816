import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getDrivers, clearDrivers } from '../../../../actions/';
import { DRIVER_PATH, NEW_DRIVER_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { DRIVER_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Alert from '../../../common/informative/Alert';

export class Drivers extends Component {
  componentWillUnmount() {
    this.props.dispatchClearDrivers();
  }

  render() {
    const { drivers, loading, breadcrumbs, dispatchGetDrivers } = this.props;
    const data = drivers.get('content') || [];
    const pages = drivers.get('totalPages') || null;
    const defaultPageSize = drivers.get('size') || DEFAULT_PAGE_SIZE;

    const description = (
      <Alert
        type="warning"
        message="
    Solo se filtrará los datos según el último criterio ingresado"
      />
    );

    return (
      <ModuleList
        title="Conductores"
        description={description}
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_DRIVER_PATH}
        tableStructure={{
          columns: DRIVER_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetDrivers,
          modelPath: DRIVER_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetDrivers: getDrivers,
  dispatchClearDrivers: clearDrivers,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Conductores',
      href: DRIVER_PATH,
    },
  ],
  drivers: TrafficUnit.Driver.getIn(['all', 'content']),
  loading: TrafficUnit.Driver.getIn(['all', 'loading']),
});

Drivers.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  drivers: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDrivers: PropTypes.func.isRequired,
  dispatchClearDrivers: PropTypes.func.isRequired,
};

Drivers.defaultProps = {
  drivers: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Drivers);
