import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QueryString from 'query-string';
import { USER } from '../../../config/shapes';
import { hasViewAuthorization } from '../../../utils/auth-validator';
import Loader from '../../common/Loader';
import {
  generateCodeChallengeFromVerifier,
  generateCodeVerifier,
} from '../../../utils/pkceGenerator';
import {
  clearRedirectToPath,
  dispatchPush,
  dispatchReplace,
  getAuthServer,
} from '../../../actions';
import { HOST } from '../../../config/endpoints';

// todo: remove eslint disable when parameter is used
// eslint-disable-next-line no-unused-vars
const Authorization = (viewAuthorizations) => (ChildComponent) => {
  class ComposedComponent extends Component {
    static redirectToNewAuthentication = async ({
      dispatchGetAuthServer,
      dispatchPush: push,
      dispatchClearRedirectToPath,
      redirectToPath,
    }) => {
      const codeVerifier = generateCodeVerifier();
      const codeChallenge = await generateCodeChallengeFromVerifier(
        codeVerifier,
      );
      if (redirectToPath) {
        push(redirectToPath);
        dispatchClearRedirectToPath();
        return;
      }

      console.log('Obteniendo codigo');

      localStorage.setItem('code_verifier', codeVerifier); // Save in localStorage

      const params = {
        client_id: 'my-trusted-client2',
        response_type: 'code',
        scope: 'read',
        redirect_uri: `${HOST}/callback`,
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
      };

      console.log('Seteando los parametros: ', params);
      const authorizationServer = await dispatchGetAuthServer();

      const authUrl = `${authorizationServer}/oauth2/authorize?${QueryString.stringify(
        params,
      )}`;
      console.log(`Obteniendo el authorizationServer y el authUrl: ${authUrl}`);

      window.location.href = authUrl;
    };

    static redirectToHome(props) {
      props.dispatchReplace('/');
    }

    constructor(props) {
      super(props);
      const {
        authenticated,
        authenticating,
        user,
        location: { pathname },
      } = this.props;
      if (!authenticated && !authenticating)
        ComposedComponent.redirectToNewAuthentication(this.props);

      if (authenticated) {
        if (
          !hasViewAuthorization({
            path: pathname,
            securityProfileSet: user.securityProfileSet,
          })
        ) {
          ComposedComponent.redirectToHome(this.props);
        }
      }
    }

    componentDidUpdate() {
      const {
        authenticated,
        authenticating,
        location: { pathname },
        user,
      } = this.props;
      if (!authenticated && !authenticating) {
        return ComposedComponent.redirectToNewAuthentication(this.props);
      }
      if (authenticated) {
        if (
          !hasViewAuthorization({
            path: pathname,
            securityProfileSet: user.securityProfileSet,
          })
        ) {
          ComposedComponent.redirectToHome(this.props);
        }
      }
      // todo: check if viewAuthorizations includes current view
      return true;
    }

    render() {
      const { authenticated, authenticating, user } = this.props;
      if ((!authenticated && authenticating) || !user) return <Loader />;

      return <ChildComponent {...this.props} />;
    }
  }

  const mapStateToProps = ({ authentication }) => ({
    user: authentication.get('user'),
    authenticated: authentication.get('authenticated'),
    authenticating: authentication.get('authenticating'),
    redirectToPath: authentication.get('redirectToPath'),
  });

  const mapDispatchToProps = {
    dispatchGetAuthServer: getAuthServer,
    dispatchPush,
    dispatchReplace,
    dispatchClearRedirectToPath: clearRedirectToPath,
  };

  ComposedComponent.propTypes = {
    user: PropTypes.shape(USER),
    authenticated: PropTypes.bool,
    authenticating: PropTypes.bool,
    dispatchPush: PropTypes.func.isRequired,
    dispatchReplace: PropTypes.func.isRequired,
    dispatchClearRedirectToPath: PropTypes.func.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    redirectToPath: PropTypes.string,
    dispatchGetAuthServer: PropTypes.func.isRequired,
  };

  ComposedComponent.defaultProps = {
    user: null,
    authenticated: false,
    authenticating: false,
    redirectToPath: null,
  };

  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);
};

export default Authorization;
