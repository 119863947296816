import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { MANIFEST_ITEM_ITINERARY_ENPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_MANIFEST_ITINERARY,
  CLEAR_MANIFEST_ITINERARY_DETAIL,
  FLAG_GETTING_MANIFEST_ITINERARY,
  FLAG_GETTING_MANIFEST_ITINERARY_DETAIL,
  GET_MANIFEST_ITINERARY,
} from '../types/cargo/ItineraryManifest';

const flagGettingMafistItemItinerary = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MANIFEST_ITINERARY,
    payload: flag,
  });

const flagGettingMafistItemItineraryDetail = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MANIFEST_ITINERARY_DETAIL,
    payload: flag,
  });

const getManifestItemByItinerary =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingMafistItemItinerary(true));
      const url = `${MANIFEST_ITEM_ITINERARY_ENPOINT}/itinerary/${itineraryId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const manifestItemItinerary = await response.json();
      dispatch({
        type: GET_MANIFEST_ITINERARY,
        payload: manifestItemItinerary,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingMafistItemItinerary(false));
    }
  };

const getManifestCargoItemById = async (id) => async (dispatch) => {
  try {
    dispatch(flagGettingMafistItemItinerary(true));
    const url = `${MANIFEST_ITEM_ITINERARY_ENPOINT}/${id}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const manifestItemItinerary = await response.json();
    return manifestItemItinerary;
  } catch (error) {
    toastr.error('Error', error.message);
    return null;
  } finally {
    dispatch(flagGettingMafistItemItinerary(false));
  }
};

const getManifestItemDetail = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingMafistItemItineraryDetail(true));
    const query = tableFilters;
    const url = `${MANIFEST_ITEM_ITINERARY_ENPOINT}/details?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const manifestItemItinerary = await response.json();
    return manifestItemItinerary;
  } catch (error) {
    toastr.error('Error', error.message);
    return null;
  } finally {
    dispatch(flagGettingMafistItemItineraryDetail(false));
  }
};

const clearManifestItinerary = () => (dispatch) =>
  dispatch({
    type: CLEAR_MANIFEST_ITINERARY,
  });

const clearManifestItineraryDetail = () => (dispatch) =>
  dispatch({
    type: CLEAR_MANIFEST_ITINERARY_DETAIL,
  });

export {
  flagGettingMafistItemItinerary,
  getManifestItemByItinerary,
  clearManifestItinerary,
  getManifestItemDetail,
  clearManifestItineraryDetail,
  getManifestCargoItemById,
};
