import React from 'react';
import { Alert } from 'reactstrap';
import { CUSTOMER_PATH } from '../../../../../config/paths';
import {
  COMPANY_STAFF_TYPE,
  DEFAULT_PAGE_SIZE,
  TRIP_REQUEST_STATUS,
} from '../../../../../config/constants';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import {
  ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE,
  TRIP_REQUEST_WITHOUT_CUSTOMERS,
} from '../../../../../config/messages';
import Table from '../../../../common/Table';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import tripRequestBasicInformationPropTypes from '../proptypes/TripRequestPropTypes';
import EnumStatus from '../../../../common/EnumStatus';
import { TRIP_REQUEST_CUSTOMER_COLUMNS } from '../../../../../config/columns';
import TimetableTemplate from '../../itinerary/TimetableTemplate';
import LinkToModuleResource from '../../../../common/resource/LinkToModuleResource';

const TripRequestBasicInformationResource = ({
  contractor: { businessName, businessTaxId },
  customerList,
  status,
  itinerary: {
    companyItineraryAditionalFields,
    departureTime,
    itineraryScheduleName,
    itineraryScheduleSegmentList,
    routeId,
    routeName,
  },
}) => {
  const customerTable =
    customerList.length > 0 ? (
      <Table
        columns={TRIP_REQUEST_CUSTOMER_COLUMNS}
        data={customerList}
        defaultPageSize={
          customerList.length <= 10 ? customerList.length : DEFAULT_PAGE_SIZE
        }
        showPagination={customerList.length > 10}
        manual={false}
        modelPath={CUSTOMER_PATH}
        filterable
        openPathInNewTab
        navigateToModelOnRowClick
        defaultFilterMethod={filterMethodCaseInsensitive}
      />
    ) : (
      <Alert message={TRIP_REQUEST_WITHOUT_CUSTOMERS} type="warning" />
    );

  const customerResource = (
    <div className="mb-3">
      <h3>Personal</h3>
      {customerTable}
    </div>
  );

  let timetableTemplate = (
    <Alert message={ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE} />
  );

  if (itineraryScheduleSegmentList.length) {
    timetableTemplate = (
      <TimetableTemplate
        itineraryScheduleSegmentList={itineraryScheduleSegmentList}
        estimatedDepartureTime={departureTime}
      />
    );
  }

  const itineraryResource = (
    <>
      <h3>Itinerario</h3>
      <ResourceProperty label="Nombre:">
        {itineraryScheduleName || '-'}
      </ResourceProperty>
      <ResourceProperty label="Régimen:">
        {companyItineraryAditionalFields[0].shift || '-'}
      </ResourceProperty>
      <ResourceProperty label="Ruta:">
        <LinkToModuleResource text={`${routeId} - ${routeName}`} />
      </ResourceProperty>
      <ResourceProperty label="Tipo:">
        {companyItineraryAditionalFields[0].staffType
          ? COMPANY_STAFF_TYPE[companyItineraryAditionalFields[0].staffType]
              .label
          : '-'}
      </ResourceProperty>
      {timetableTemplate}
    </>
  );

  return (
    <>
      <ResourceProperty label="Contratista:">
        {businessName}
        {' - RUC '}
        {businessTaxId}
      </ResourceProperty>
      <ResourceProperty label="Estado:">
        <EnumStatus enumObject={TRIP_REQUEST_STATUS} enumValue={status} />
      </ResourceProperty>
      {customerResource}
      {itineraryResource}
    </>
  );
};

TripRequestBasicInformationResource.propTypes =
  tripRequestBasicInformationPropTypes;

export default TripRequestBasicInformationResource;
