import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BASIC_POST_CONFIG } from '../../../config/rest';
import { authenticateUserJwt, getAuthServer } from '../../../actions';
import Loader from '../../common/Loader';
import { HOST } from '../../../config/endpoints';

const CallbackScreen = ({
  location,
  dispatchAuthenticateUserJwt,
  dispatchGetAuthServer,
}) => {
  const encodeFormData = (data) =>
    Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      const codeVerifier = localStorage.getItem('code_verifier');
      const grantType = 'authorization_code';
      const redirectUri = `${HOST}/callback`;

      const payload = {
        code,
        code_verifier: codeVerifier,
        grant_type: grantType,
        redirect_uri: redirectUri,
      };

      const username = 'my-trusted-client2';
      const password = '12312312';
      const basicAuth = `Basic ${btoa(`${username}:${password}`)}`;

      const authorizationServer = await dispatchGetAuthServer();

      try {
        const response = await fetch(`${authorizationServer}/oauth2/token`, {
          ...BASIC_POST_CONFIG,
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: basicAuth,
          },
          body: encodeFormData(payload),
        });
        const access = await response.json();
        const { access_token: accessToken } = access;
        dispatchAuthenticateUserJwt({ accessToken });
      } catch (error) {
        toastr.error('Error', 'Error obteniendo el token');
      }
    };

    handleCallback();
  }, [location.search, dispatchAuthenticateUserJwt]);

  return <Loader />;
};

CallbackScreen.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  dispatchAuthenticateUserJwt: PropTypes.func.isRequired,
  dispatchGetAuthServer: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchAuthenticateUserJwt: authenticateUserJwt,
  dispatchGetAuthServer: getAuthServer,
};

export default connect(null, mapDispatchToProps)(withRouter(CallbackScreen));
