import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';

const ParcelPriceConfigurationForm = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Valor" required>
          <Field
            name="value"
            component={TextInput}
            placeholder="Valor"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

ParcelPriceConfigurationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.ParcelPriceConfiguration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'parcelPriceConfigurationForm',
})(ParcelPriceConfigurationForm);

export default connect(mapStateToProps)(formComponent);
