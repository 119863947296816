import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_TICKETS,
  GET_TICKETS,
  CLEAR_TICKETS,
  GET_TICKET,
  CLEAR_TICKET,
  FLAG_TICKET_ACTIVITY,
  FLAG_CHANGING_PASSENGER,
} from '../types';
import {
  TICKET_ENDPOINT,
  PRINTED_TICKET_ENDPOINT,
  generateSendTicketByEmailEndpoint,
  generateSalesSessionTicketsEnpoint,
  TICKET_ENDPOINT_V2,
  generateTicketRefund,
  generateTicketChangePassengerEndpoint,
  generateTicketPostponeEndpoint,
  generatePostVoidTicket,
  TICKET_CUSTOMER_ENDPOINT,
  TICKET_FOR_MASSIVE_POSTPONE_ENDPOINT,
  TICKET_POSTPONE_PROCESS_ENDPOINT,
  generateTicketTaxableInformationEndpoint,
  generateTicketChangeVoucherTypeEndpoint,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_PATCH_CONFIG,
  DEFAULT_POST_CONFIG,
} from '../../config/rest';
import {
  handleResponseError,
  isErrorResponse,
} from '../../utils/error-handlers';
import { printPrintedTicket } from '../../utils/printers/Ticket';
import {
  ACCOUNTING_UNIT_PATH,
  RESERVATION_UNIT_PATH,
  TICKET_PATH,
} from '../../config/paths';
import { getCompanyTicket } from '../contract';

const flagGettingTickets = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_TICKETS,
    payload: flag,
  });

const getTickets = async (query) => async (dispatch) => {
  try {
    dispatch(flagGettingTickets(true));

    const url = `${TICKET_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await isErrorResponse(response, null, dispatch);

    const tickets = await response.json();

    dispatch({
      type: GET_TICKETS,
      payload: tickets,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingTickets(false));
  }
};

const getTicketsForMassivePostpone = async (query) => async (dispatch) => {
  try {
    dispatch(flagGettingTickets(true));

    const url = `${TICKET_FOR_MASSIVE_POSTPONE_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await isErrorResponse(response, null, dispatch);

    const tickets = await response.json();

    dispatch({
      type: GET_TICKETS,
      payload: tickets,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingTickets(false));
  }
};

const clearTickets = () => (dispatch) =>
  dispatch({
    type: CLEAR_TICKETS,
  });

const flagTicketActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_TICKET_ACTIVITY,
    payload: flag,
  });

const getTicket =
  async ({ ticketId }) =>
  async (dispatch) => {
    try {
      dispatch(flagTicketActivity(true));
      const url = `${TICKET_ENDPOINT_V2}/${ticketId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);

      await isErrorResponse(response, null, dispatch);
      const ticket = await response.json();
      dispatch({
        type: GET_TICKET,
        payload: ticket,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTicketActivity(false));
    }
  };

const clearTicket = () => (dispatch) =>
  dispatch({
    type: CLEAR_TICKET,
  });

const voidTicket =
  async ({ ticketId, justification }) =>
  async (dispatch) => {
    try {
      dispatch(flagTicketActivity(true));
      const url = generatePostVoidTicket(ticketId);
      const payload = { description: justification };
      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);

      await dispatch(getTicket({ ticketId }));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTicketActivity(false));
    }
  };

const getPrintedTicket = async (ticketId) => async (dispatch) => {
  dispatch(flagTicketActivity(true));

  const url = `${PRINTED_TICKET_ENDPOINT}/${ticketId}`;

  try {
    const response = await fetch(url, DEFAULT_GET_CONFIG);

    // handle an error response from the server
    const serverError = await handleResponseError(response);
    if (serverError) {
      toastr.error('Error', serverError);
      return dispatch(flagTicketActivity(false));
    }

    const printedTicket = await response.json();
    printPrintedTicket(printedTicket);
    return dispatch(flagTicketActivity(false));
  } catch (err) {
    toastr.error('Error', 'error obteniendo boleto impreso');
    return dispatch(flagTicketActivity(false));
  }
};

const postTicketVoucherTypeChange =
  async (endpoint, { ticketId, voucherTypeId, businessId }) =>
  async (dispatch) => {
    try {
      dispatch(flagTicketActivity(true));

      const payload = {
        ticketId,
        voucherTypeId,
        businessId,
      };

      const url = generateTicketChangeVoucherTypeEndpoint(ticketId, endpoint);

      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(
        promise,
        (response) => push(`${TICKET_PATH}/${response.ticketId}`),
        dispatch,
      );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTicketActivity(false));
    }
  };

const postPostponeTicket =
  async (endpoint, ticketId, forThirdParty = false) =>
  async (dispatch) => {
    try {
      dispatch(flagTicketActivity(true));

      const url = generateTicketPostponeEndpoint(ticketId, endpoint);

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
      });

      const callTicket = forThirdParty
        ? getCompanyTicket({ companyTicketId: ticketId })
        : getTicket({ ticketId });

      await isErrorResponse(response, () => callTicket, dispatch);
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTicketActivity(false));
    }
  };

const postTicketMassivePostpone =
  async ({ comment, ticketIdList }) =>
  async (dispatch) => {
    try {
      dispatch(flagTicketActivity(true));

      const payload = {
        comment,
        ticketIdList,
      };
      const url = TICKET_POSTPONE_PROCESS_ENDPOINT;

      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();

      dispatch(push(RESERVATION_UNIT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTicketActivity(false));
    }
  };

const flagChangingPassenger = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CHANGING_PASSENGER,
    payload: flag,
  });

const postChangePassenger =
  async (endpoint, ticketId, newCustomerId) => async (dispatch) => {
    try {
      dispatch(flagChangingPassenger(true));

      const payload = { id: newCustomerId };

      const url = generateTicketChangePassengerEndpoint(ticketId, endpoint);

      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(
        promise,
        (response) => push(`${TICKET_PATH}/${response.ticketId}`),
        dispatch,
      );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagChangingPassenger(false));
    }
  };

const postSendTicketByEmail =
  async ({ ticketId, email }) =>
  async (dispatch) => {
    try {
      dispatch(flagTicketActivity(true));
      const url = generateSendTicketByEmailEndpoint(ticketId);
      const payload = [email];
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      // if it is a 200 response then mail has been sent correctly
      return true;
    } catch ({ message }) {
      toastr.error('Error', message);
      return false;
    } finally {
      dispatch(flagTicketActivity(false));
    }
  };

const postTicketRefund =
  async (endpoint, ticketId, forThirdParty) => async (dispatch) => {
    try {
      dispatch(flagTicketActivity(true));

      const url = generateTicketRefund(ticketId, endpoint);

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
      });

      const callTicket = forThirdParty
        ? getCompanyTicket({ companyTicketId: ticketId })
        : getTicket({ ticketId });

      // check if it is an error response
      await isErrorResponse(response, () => callTicket, dispatch);
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTicketActivity(false));
    }
  };

const getSalesSessionTickets =
  async ({ salesSessionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingTickets(true));
      const url = generateSalesSessionTicketsEnpoint(salesSessionId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const tickets = await response.json();
      dispatch({
        type: GET_TICKETS,
        payload: { content: tickets },
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingTickets(false));
    }
  };

const getTicketCustomer = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingTickets(true));
    const query = QueryString.stringify(tableFilters);

    const url = `${TICKET_CUSTOMER_ENDPOINT}?${query}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await isErrorResponse(response, null, dispatch);
    const tickets = await response.json();
    dispatch({
      type: GET_TICKETS,
      payload: tickets,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingTickets(false));
  }
};

const patchTicketTaxableInformation =
  async (
    ticketId,
    {
      customerId,
      firstName,
      lastName,
      identificationTypeId,
      idDocumentNumber,
      idCountryOfOrigin,
      businessId,
      name,
      businessTaxId,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagTicketActivity(true));

      const payload = {
        customerId,
        firstName,
        lastName,
        identificationTypeId,
        idDocumentNumber,
        idCountryOfOrigin,
        businessId,
        name,
        businessTaxId,
        id: ticketId,
      };

      const url = generateTicketTaxableInformationEndpoint(ticketId);
      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(ACCOUNTING_UNIT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTicketActivity(false));
    }
  };

const getTicketActionModal =
  async ({ ticketId, url }) =>
  async () => {
    try {
      const promise = await fetch(`${url}/${ticketId}`, {
        ...DEFAULT_GET_CONFIG,
      });

      await isErrorResponse(promise);

      const response = await promise.json();

      return response;
    } catch (error) {
      toastr.error('Error', error.message);
      return null;
    }
  };

export {
  getTickets,
  clearTickets,
  getTicket,
  clearTicket,
  voidTicket,
  getPrintedTicket,
  postChangePassenger,
  postSendTicketByEmail,
  getSalesSessionTickets,
  postTicketVoucherTypeChange,
  postTicketRefund,
  getTicketCustomer,
  patchTicketTaxableInformation,
  getTicketsForMassivePostpone,
  postTicketMassivePostpone,
  getTicketActionModal,
  postPostponeTicket,
};
