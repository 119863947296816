import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_EXPECTED_FUEL_CONSUMPTIONS,
  GET_EXPECTED_FUEL_CONSUMPTIONS,
  CLEAR_EXPECTED_FUEL_CONSUMPTIONS,
  FLAG_EXPECTED_FUEL_CONSUMPTION_ACTIVITY,
  GET_EXPECTED_FUEL_CONSUMPTION,
  CLEAR_EXPECTED_FUEL_CONSUMPTION,
  POST_EXPECTED_FUEL_CONSUMPTION,
  PUT_EXPECTED_FUEL_CONSUMPTION,
} from '../types';
import {
  EXPECTED_FUEL_CONSUMPTION_ENDPOINT,
  EXPECTED_FUEL_CONSUMPTION_BY_CIRCUIT_ENDPOINT,
  EXPECTED_FUEL_CONSUMPTION_BY_ROUTE_ENDPOINT,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { EXPECTED_FUEL_CONSUMPTION_PATH } from '../../config/paths';

const flagGettingExpectedFuelConsumptions = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_EXPECTED_FUEL_CONSUMPTIONS,
    payload: flag,
  });

const getExpectedFuelConsumptionsAsync = async (tableFilters) => {
  const query = tableFilters;

  const url = `${EXPECTED_FUEL_CONSUMPTION_ENDPOINT}?${QueryString.stringify(
    query,
  )}`;

  const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
  await isErrorResponse(response);
  const expectedFuelConsumptions = await response.json();

  return expectedFuelConsumptions;
};

const getExpectedFuelConsumptions =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingExpectedFuelConsumptions(true));

      const expectedFuelConsumptions = await getExpectedFuelConsumptionsAsync(
        tableFilters,
      );

      dispatch({
        type: GET_EXPECTED_FUEL_CONSUMPTIONS,
        payload: expectedFuelConsumptions,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingExpectedFuelConsumptions(false));
    }
  };

const getExpectedFuelConsumptionsByCircuit =
  async (circuitId) => async (dispatch) => {
    try {
      dispatch(flagGettingExpectedFuelConsumptions(true));
      const url = `${EXPECTED_FUEL_CONSUMPTION_BY_CIRCUIT_ENDPOINT}/${circuitId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const expectedFuelConsumptions = await response.json();
      dispatch({
        type: GET_EXPECTED_FUEL_CONSUMPTIONS,
        payload: { content: expectedFuelConsumptions },
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingExpectedFuelConsumptions(false));
    }
  };

const getExpectedFuelConsumptionsByRoute =
  async (routeId) => async (dispatch) => {
    try {
      dispatch(flagGettingExpectedFuelConsumptions(true));
      const url = `${EXPECTED_FUEL_CONSUMPTION_BY_ROUTE_ENDPOINT}/${routeId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const expectedFuelConsumptions = await response.json();
      dispatch({
        type: GET_EXPECTED_FUEL_CONSUMPTIONS,
        payload: expectedFuelConsumptions,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingExpectedFuelConsumptions(false));
    }
  };

const clearExpectedFuelConsumptions = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPECTED_FUEL_CONSUMPTIONS,
  });

const flagExpectedFuelConsumptionActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_EXPECTED_FUEL_CONSUMPTION_ACTIVITY,
    payload: flag,
  });

const getExpectedFuelConsumption =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpectedFuelConsumptionActivity(true));
      const url = `${EXPECTED_FUEL_CONSUMPTION_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const expectedFuelConsumption = await response.json();
      dispatch({
        type: GET_EXPECTED_FUEL_CONSUMPTION,
        payload: expectedFuelConsumption,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExpectedFuelConsumptionActivity(false));
    }
  };

const clearExpectedFuelConsumption = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPECTED_FUEL_CONSUMPTION,
  });

const postExpectedFuelConsumption =
  async ({ routeId, comment = null, busFuelGroupConsumptionList }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpectedFuelConsumptionActivity(true));
      const payload = {
        routeId,
        comment,
        busFuelGroupConsumptionList,
      };
      const url = EXPECTED_FUEL_CONSUMPTION_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const expected = await response.json();
      dispatch({
        type: POST_EXPECTED_FUEL_CONSUMPTION,
        payload: expected,
      });
      dispatch(push(EXPECTED_FUEL_CONSUMPTION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExpectedFuelConsumptionActivity(false));
    }
  };

const putExpectedFuelConsumption =
  async (
    expectedFuelConsumptionId,
    { routeId, comment = null, busFuelGroupConsumptionList },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagExpectedFuelConsumptionActivity(true));
      const payload = {
        id: expectedFuelConsumptionId,
        routeId,
        comment,
        busFuelGroupConsumptionList,
      };
      const url = `${EXPECTED_FUEL_CONSUMPTION_ENDPOINT}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const expected = await response.json();
      dispatch({
        type: PUT_EXPECTED_FUEL_CONSUMPTION,
        payload: expected,
      });
      dispatch(push(EXPECTED_FUEL_CONSUMPTION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExpectedFuelConsumptionActivity(false));
    }
  };

const deleteExpectedFuelConsumption =
  async ({ expectedFuelConsumptionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpectedFuelConsumptionActivity(true));
      const url = `${EXPECTED_FUEL_CONSUMPTION_ENDPOINT}/${expectedFuelConsumptionId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(EXPECTED_FUEL_CONSUMPTION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExpectedFuelConsumptionActivity(false));
    }
  };

export {
  clearExpectedFuelConsumptions,
  getExpectedFuelConsumptions,
  getExpectedFuelConsumptionsByCircuit,
  getExpectedFuelConsumption,
  clearExpectedFuelConsumption,
  postExpectedFuelConsumption,
  putExpectedFuelConsumption,
  deleteExpectedFuelConsumption,
  getExpectedFuelConsumptionsByRoute,
  getExpectedFuelConsumptionsAsync,
};
