import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { BAGGAGE_PATH } from '../../../../config/paths';
import { getBaggage, clearBaggage } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { Table } from '../../../common/Table';
import BaggageToolbar from './BaggageToolbar';
import {
  BAGGAGE_ITEM_STATUS,
  KILOGRAM_SYMBOL,
  TRANSACTION_TYPE_CREDIT_CARD,
} from '../../../../config/constants';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import BaggageItemMovementResource from './resource/BaggageItemMovementResource';
import { numberFormatter } from '../../../../utils/number';
import { padStart } from '../../../../utils/string';

class Baggage extends Component {
  componentDidMount() {
    const {
      dispatchGetBaggage,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetBaggage({ id });
  }

  componentWillUnmount() {
    const { dispatchClearBaggage } = this.props;
    dispatchClearBaggage();
  }

  render() {
    const { breadcrumbs, baggage, loading } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (baggage.isEmpty()) {
      content = <NoDataResource returnPage={BAGGAGE_PATH} />;
    } else {
      const ticketList = baggage.get('ticketList');
      const tickets = (
        <div className="mb-3">
          <h3>Boletos</h3>
          <Table
            columns={[
              {
                Header: 'Número',
                accessor: 'documentSeries',
                Cell: (row) =>
                  `${row.value}-${padStart(row.original.documentCode, 7)}`,
                width: 150,
                className: 'text-center',
              },
              {
                Header: 'Pasajero',
                accessor: 'customer.fullName',
                width: 400,
                className: 'text-center',
              },
              {
                Header: 'Documento de Identidad',
                accessor: 'customer.identificationType.name',
                Cell: (row) =>
                  `${row.value}: ${row.original.customer.idDocumentNumber}`,
                width: 300,
                className: 'text-center',
              },
              {
                Header: 'Origen',
                accessor: 'sourceLocation.name',
                width: 300,
                className: 'text-center',
              },
              {
                Header: 'Destino',
                accessor: 'destinationLocation.name',
                width: 300,
                className: 'text-center',
              },
              {
                Header: 'Fecha de Viaje',
                accessor: 'departureDate',
                Cell: (row) =>
                  row.value
                    ? tzNormalizeDate({
                        date: row.value,
                        format: DATE_TIME_FORMAT,
                      })
                    : '-',
                width: 200,
                className: 'text-center',
              },
            ]}
            data={ticketList}
            defaultPageSize={ticketList.length}
            showPagination={false}
          />
        </div>
      );

      const itemList = baggage.get('itemList');
      const items = (
        <div className="mb-3">
          <h3>Items</h3>
          <Table
            columns={[
              {
                Header: 'Categoría',
                accessor: 'itemCategory.name',
                className: 'text-center',
              },
              {
                Header: 'Serie',
                accessor: 'itemSeries',
                className: 'text-center',
              },
              {
                Header: 'Correlativo',
                accessor: 'itemCode',
                className: 'text-center',
              },
              {
                Header: 'Correlativo Automático',
                accessor: 'itemCorrelation',
                className: 'text-center',
              },
              {
                Header: 'Descripción',
                accessor: 'description',
                className: 'text-center',
              },
              {
                Header: 'Peso',
                accessor: 'weight',
                Cell: (row) => `${row.value} ${KILOGRAM_SYMBOL}`,
                className: 'text-center',
              },
              {
                Header: 'Estado',
                accessor: 'status',
                Cell: (row) =>
                  BAGGAGE_ITEM_STATUS[row.value]
                    ? BAGGAGE_ITEM_STATUS[row.value].label
                    : null,
                className: 'text-center',
              },
            ]}
            data={itemList}
            defaultPageSize={itemList.length}
            showPagination={false}
            rowComponent={BaggageItemMovementResource}
          />
        </div>
      );

      let excess = null;
      if (baggage.get('excessBaggage')) {
        const excessBaggage = baggage.get('excessBaggage');

        const {
          paymentMethod: { transactionCode },
          voucherCode,
        } = excessBaggage;

        let voucherCodeResource = null;
        if (transactionCode === TRANSACTION_TYPE_CREDIT_CARD)
          voucherCodeResource = (
            <ResourceProperty label="Nro Referencia (POS):">
              {voucherCode}
            </ResourceProperty>
          );

        const businessField = excessBaggage.transaction &&
          excessBaggage.transaction.business && (
            <div>
              <ResourceProperty label="RUC:">
                {excessBaggage.transaction.business.businessTaxId}
              </ResourceProperty>
              <ResourceProperty label="Razón Social:">
                {excessBaggage.transaction.business.name}
              </ResourceProperty>
            </div>
          );

        excess = (
          <div>
            <h3>Exceso Equipaje</h3>
            <ResourceProperty label="Tipo de Documento:">
              {excessBaggage.transaction &&
              excessBaggage.transaction.voucherType
                ? excessBaggage.transaction.voucherType.name
                : '-'}
            </ResourceProperty>
            {businessField}
            <ResourceProperty label="Número de Documento:">
              {excessBaggage.fullDocumentCode}
            </ResourceProperty>
            <ResourceProperty label="Pagador:">
              {excessBaggage.payerCustomer.fullName}
            </ResourceProperty>
            <ResourceProperty label="Peso:">
              {excessBaggage.weight} {KILOGRAM_SYMBOL}
            </ResourceProperty>
            <ResourceProperty label="Precio Total:">
              {numberFormatter({ value: excessBaggage.priceBeforeDiscount })}
            </ResourceProperty>
            <ResourceProperty label="Descuento:">
              {numberFormatter({ value: excessBaggage.discountAmount })}
            </ResourceProperty>
            <ResourceProperty label="Precio Final:">
              {numberFormatter({ value: excessBaggage.price })}
            </ResourceProperty>
            <ResourceProperty label="Método de Pago:">
              {excessBaggage.paymentMethod.name}
            </ResourceProperty>
            {voucherCodeResource}
            <ResourceProperty label="Comentario:">
              {excessBaggage.comment}
            </ResourceProperty>
          </div>
        );
      }

      toolbar = <BaggageToolbar baggageId={baggage.get('id')} />;

      content = (
        <Fragment>
          {tickets}
          {items}
          <ResourceProperty label="Peso:">
            {baggage.get('weight')} {KILOGRAM_SYMBOL}
          </ResourceProperty>
          <ResourceProperty label="Comentario:">
            {baggage.get('comment')}
          </ResourceProperty>
          {excess}
        </Fragment>
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Equipaje"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

Baggage.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetBaggage: PropTypes.func.isRequired,
  dispatchClearBaggage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  baggage: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Baggage.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ BaggageUnit }) => ({
  breadcrumbs: [
    ...BaggageUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Equipajes y Excesos',
      href: BAGGAGE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  baggage: BaggageUnit.Baggage.getIn(['current', 'content']),
  loading: !BaggageUnit.Baggage.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetBaggage: getBaggage,
  dispatchClearBaggage: clearBaggage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Baggage);
