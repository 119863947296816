import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_DRIVER_CONTRACT_TYPES,
  GET_DRIVER_CONTRACT_TYPES,
  CLEAR_DRIVER_CONTRACT_TYPES,
  FLAG_DRIVER_CONTRACT_TYPE_ACTIVITY,
  GET_DRIVER_CONTRACT_TYPE,
  CLEAR_DRIVER_CONTRACT_TYPE,
} from '../types/traffic';
import { DRIVER_CONTRACT_TYPE_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingDriverContractTypes = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DRIVER_CONTRACT_TYPES,
    payload: flag,
  });

const getDriverContractTypes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDriverContractTypes(true));
    const query = tableFilters;
    const url = `${DRIVER_CONTRACT_TYPE_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const types = await response.json();
    dispatch({
      type: GET_DRIVER_CONTRACT_TYPES,
      payload: types,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingDriverContractTypes(false));
  }
};

const clearDriverContractTypes = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVER_CONTRACT_TYPES,
  });

const flagDriverContractTypeActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DRIVER_CONTRACT_TYPE_ACTIVITY,
    payload: flag,
  });

const getDriverContractType =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverContractTypeActivity(true));
      const url = `${DRIVER_CONTRACT_TYPE_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const type = await response.json();
      dispatch({
        type: GET_DRIVER_CONTRACT_TYPE,
        payload: type,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverContractTypeActivity(false));
    }
  };

const clearDriverContractType = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVER_CONTRACT_TYPE,
  });

export {
  flagGettingDriverContractTypes,
  getDriverContractTypes,
  clearDriverContractTypes,
  getDriverContractType,
  clearDriverContractType,
};
