import { shape, string } from 'prop-types';

const contractorBasicInformationPropTypes = {
  customer: shape({
    fullName: string.isRequired,
    idDocumentNumber: string.isRequired,
    identificationType: shape({
      name: string.isRequired,
    }).isRequired,
  }),
  business: shape({
    name: string.isRequired,
    businessTaxId: string.isRequired,
  }).isRequired,
  company: shape({
    name: string.isRequired,
    business: shape({
      businessTaxId: string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default contractorBasicInformationPropTypes;
