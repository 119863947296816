import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../../common/Loader';
import Cookie from '../../../utils/Cookie';
import decodeTokenJWT from '../../../utils/decode-jwt';
import { getNewUser } from '../../../actions';

const Register = ({ dispatchGetNewUser }) => {
  useEffect(() => {
    const accessToken = Cookie.get('access_token');
    const decodedToken = decodeTokenJWT(accessToken);
    dispatchGetNewUser({ email: decodedToken.preferred_username });
  }, []);

  return <Loader />;
};

Register.propTypes = {
  dispatchGetNewUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchGetNewUser: getNewUser,
};

export default connect(null, mapDispatchToProps)(Register);
