import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { POST } from '../../../../config/permissions';
import { postRejectTripRequest } from '../../../../actions';
import { generateRejectTripRequestEndpoint } from '../../../../config/endpoints';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';

const TripRequestRejectButton = ({
  dispatchPostRejectTripRequest,
  tripRequestId,
}) => {
  const onClickReject = () => dispatchPostRejectTripRequest({ tripRequestId });

  return (
    <>
      <Button color="danger" onClick={onClickReject}>
        <i className="fa fa-check-circle-o" /> Rechazar
      </Button>
    </>
  );
};

TripRequestRejectButton.propTypes = {
  tripRequestId: PropTypes.number.isRequired,
  dispatchPostRejectTripRequest: PropTypes.func.isRequired,
};

const mapDispatchToprops = {
  dispatchPostRejectTripRequest: postRejectTripRequest,
};

const connectedComponent = connect(
  null,
  mapDispatchToprops,
)(TripRequestRejectButton);

export default withEndpointAuthorization({
  url: generateRejectTripRequestEndpoint(),
  mapUrlParamsToProps: { tripRequestId: 'tripRequestId' },
  permissionType: POST,
})(connectedComponent);
