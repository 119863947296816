import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  FLAG_GETTING_ADDRESSES,
  GET_ADDRESSES,
  CLEAR_ADDRESSES,
  FLAG_ADDRESS_ACTIVITY,
  GET_ADDRESS,
  CLEAR_ADDRESS,
} from '../types';
import { ADDRESS_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { ADDRESS_PATH } from '../../config/paths';

const flagGettingAddresses = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ADDRESSES,
    payload: flag,
  });

const flagAddressActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_ADDRESS_ACTIVITY,
    payload: flag,
  });

const clearAddresses = () => (dispatch) =>
  dispatch({
    type: CLEAR_ADDRESSES,
  });

const getAddresses =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingAddresses(true));
      const query = tableFilters;
      const url = `${ADDRESS_ENDPOINT}?${QueryString.stringify(query)}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const addresses = await response.json();
      dispatch({ type: GET_ADDRESSES, payload: addresses });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingAddresses(false));
    }
  };

const clearAddress = () => (dispatch) => dispatch({ type: CLEAR_ADDRESS });

const getAddress =
  async ({ addressId }) =>
  async (dispatch) => {
    try {
      dispatch(flagAddressActivity(true));
      const url = `${ADDRESS_ENDPOINT}/${addressId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const address = await promise.json();
      dispatch({ type: GET_ADDRESS, payload: address });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagAddressActivity(false));
    }
  };

const postAddress =
  async ({
    address,
    address2,
    district,
    postalCode,
    phone,
    cityId,
    districtId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagAddressActivity(true));
      const payload = {
        address,
        address2,
        district,
        postalCode,
        phone,
        cityId,
        districtId,
      };
      const url = `${ADDRESS_ENDPOINT}`;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(ADDRESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagAddressActivity(false));
    }
  };

const putAddress =
  async ({
    addressId,
    address,
    address2,
    district,
    postalCode,
    phone,
    cityId,
    districtId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagAddressActivity(true));
      const payload = {
        id: addressId,
        address,
        address2,
        district,
        postalCode,
        phone,
        cityId,
        districtId,
      };
      const url = `${ADDRESS_ENDPOINT}/${addressId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(ADDRESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagAddressActivity(false));
    }
  };

const deleteAddress =
  async ({ addressId }) =>
  async (dispatch) => {
    try {
      dispatch(flagAddressActivity(true));
      const url = `${ADDRESS_ENDPOINT}/${addressId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      }).then((res) => res);
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(ADDRESS_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagAddressActivity(false));
    }
  };

export {
  flagGettingAddresses,
  getAddresses,
  clearAddresses,
  postAddress,
  getAddress,
  clearAddress,
  deleteAddress,
  putAddress,
};
