import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import processBasicInformationPropTypes from '../proptypes/ProcessPropTypes';
import { DATE_FORMAT } from '../../../../../config/locale';
import { tzNormalizeDate } from '../../../../../utils/date';
import {
  COMPANY_STAFF_TYPE,
  DEFAULT_PAGE_SIZE,
} from '../../../../../config/constants';
import Table from '../../../../common/Table';
import { ITINERARY_PATH } from '../../../../../config/paths';
import { ITINERARY_PROCESS_COLUMNS } from '../../../../../config/columns';

const ProcessBasicInformationResource = ({
  id,
  name,
  shift,
  departureTime,
  staffType,
  itineraryList,
}) => (
  <>
    <ResourceProperty label="Código:">{id || '-'}</ResourceProperty>
    <ResourceProperty label="Nombre:">{name || '-'}</ResourceProperty>
    <ResourceProperty label="Régimen:">{shift || '-'}</ResourceProperty>
    <ResourceProperty label="Fecha de Salida:">
      {tzNormalizeDate({
        date: departureTime,
        format: DATE_FORMAT,
      }) || '-'}
    </ResourceProperty>
    <ResourceProperty label="Tipo:">
      {COMPANY_STAFF_TYPE[staffType].label || '-'}
    </ResourceProperty>
    <h3>Itinerarios</h3>
    <Table
      columns={ITINERARY_PROCESS_COLUMNS}
      data={itineraryList}
      defaultPageSize={
        itineraryList.length > 10 ? DEFAULT_PAGE_SIZE : itineraryList.length
      }
      modelPath={ITINERARY_PATH}
      navigateToModelOnRowClick
      openPathInNewTab
    />
  </>
);

ProcessBasicInformationResource.propTypes = processBasicInformationPropTypes;

export default ProcessBasicInformationResource;
