import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_IN_SYSTEM_NOTIFICATIONS,
  GET_IN_SYSTEM_NOTIFICATIONS,
  CLEAR_IN_SYSTEM_NOTIFICATIONS,
  FLAG_IN_SYSTEM_NOTIFICATION_ACTIVITY,
  CLEAR_IN_SYSTEM_NOTIFICATION,
  GET_IN_SYSTEM_NOTIFICATION,
} from '../types';
import { IN_SYSTEM_NOTIFICATION_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { IN_SYSTEM_NOTIFICATION_PATH } from '../../config/paths';

const flagGettingInSystemNotifications = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_IN_SYSTEM_NOTIFICATIONS,
    payload: flag,
  });

const getInSystemNotifications = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingInSystemNotifications(true));

    const query = tableFilters;

    const url = `${IN_SYSTEM_NOTIFICATION_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;

    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await isErrorResponse(response, null, dispatch);

    const notifications = await response.json();

    dispatch({
      type: GET_IN_SYSTEM_NOTIFICATIONS,
      payload: notifications,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingInSystemNotifications(false));
  }
};

const clearInSystemNotifications = () => (dispatch) =>
  dispatch({
    type: CLEAR_IN_SYSTEM_NOTIFICATIONS,
  });

const flagInSystemNotificationActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_IN_SYSTEM_NOTIFICATION_ACTIVITY,
    payload: flag,
  });

const clearInSystemNotification = () => (dispatch) =>
  dispatch({
    type: CLEAR_IN_SYSTEM_NOTIFICATION,
  });

const getInSystemNotification =
  async ({ inSystemNotificationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagInSystemNotificationActivity(true));
      const url = `${IN_SYSTEM_NOTIFICATION_ENDPOINT}/${inSystemNotificationId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const notification = await response.json();
      dispatch({
        type: GET_IN_SYSTEM_NOTIFICATION,
        payload: notification,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagInSystemNotificationActivity(false));
    }
  };

const postInSystemNotification =
  async ({
    message,
    notificationSeverity,
    validFrom,
    validUntil,
    userId,
    active,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagInSystemNotificationActivity(true));
      const payload = {
        message,
        notificationSeverity,
        validFrom,
        validUntil,
        userId,
        active,
      };
      const url = IN_SYSTEM_NOTIFICATION_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const notification = await response.json();
      dispatch(push(`${IN_SYSTEM_NOTIFICATION_PATH}/${notification.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagInSystemNotificationActivity(false));
    }
  };

const putInSystemNotification =
  async (
    inSystemNotificationId,
    { message, notificationSeverity, validFrom, validUntil, userId, active },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagInSystemNotificationActivity(true));
      const payload = {
        message,
        notificationSeverity,
        validFrom,
        validUntil,
        userId,
        active,
        id: inSystemNotificationId,
      };
      const url = `${IN_SYSTEM_NOTIFICATION_ENDPOINT}/${inSystemNotificationId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const notification = await response.json();
      dispatch(push(`${IN_SYSTEM_NOTIFICATION_PATH}/${notification.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagInSystemNotificationActivity(false));
    }
  };

const deleteInSystemNotification =
  async ({ inSystemNotificationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagInSystemNotificationActivity(true));
      const url = `${IN_SYSTEM_NOTIFICATION_ENDPOINT}/${inSystemNotificationId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(IN_SYSTEM_NOTIFICATION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagInSystemNotificationActivity(false));
    }
  };

export {
  flagGettingInSystemNotifications,
  getInSystemNotifications,
  clearInSystemNotifications,
  getInSystemNotification,
  clearInSystemNotification,
  postInSystemNotification,
  putInSystemNotification,
  deleteInSystemNotification,
};
