import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  ITINERARY_PATH,
  ITINERARY_SCHEDULE_PATH,
  NEW_ITINERARY_SCHEDULE_PATH,
} from '../../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import ModuleList from '../../../../layout/ModuleList';
import {
  COMPANY_ITINERARY_SCHEDULE_COLUMNS,
  ITINERARY_SCHEDULE_COLUMNS,
} from '../../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../../common/resource/proptypes/CommonPropTypes';
import {
  clearItinerarySchedules,
  getItinerarySchedules,
} from '../../../../../actions/itinerary/ItinerarySchedule';

export class ItinerarySchedules extends Component {
  componentWillUnmount() {
    this.props.dispatchClearItinerarySchedules();
  }

  render() {
    const {
      itineraries,
      loading,
      dispatchGetItinerarySchedules,
      breadcrumbs,
      isForMine,
    } = this.props;

    const data = itineraries.get('content') || [];
    const pages = itineraries.get('totalPages') || null;
    const defaultPageSize = itineraries.get('size') || DEFAULT_PAGE_SIZE;
    const columns = isForMine
      ? COMPANY_ITINERARY_SCHEDULE_COLUMNS
      : ITINERARY_SCHEDULE_COLUMNS;

    return (
      <ModuleList
        title="Plantillas"
        buttonPath={NEW_ITINERARY_SCHEDULE_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetItinerarySchedules,
          modelPath: ITINERARY_SCHEDULE_PATH,
          loading,
          navigateToModelOnRowClick: !isForMine,
        }}
      />
    );
  }
}

const mapStateToProps = ({ ItineraryUnit, authentication }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Plantillas de itinerario',
      href: ITINERARY_PATH,
    },
  ],
  itineraries: ItineraryUnit.ItinerarySchedule.getIn(['all', 'content']),
  loading: ItineraryUnit.ItinerarySchedule.getIn(['all', 'loading']),
  isForMine: !!authentication.get('user').salesSessionUserId,
});

const mapDispatchToProps = {
  dispatchGetItinerarySchedules: getItinerarySchedules,
  dispatchClearItinerarySchedules: clearItinerarySchedules,
};

ItinerarySchedules.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  itineraries: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetItinerarySchedules: PropTypes.func.isRequired,
  dispatchClearItinerarySchedules: PropTypes.func.isRequired,
  isForMine: PropTypes.bool.isRequired,
};

ItinerarySchedules.defaultProps = {
  itineraries: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItinerarySchedules);
