import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { PARCEL_PRICE_PATH } from '../../../../config/paths';
import { postParcelPrice } from '../../../../actions/cargo/ParcelPrice';
import Content from '../../../layout/Content';
import ParcelPriceForm from './ParcelPriceForm';

const NewParcelPrice = ({ breadcrumbs, dispatchPostParcelPrice }) => {
  const onSubmit = (formValues) => {
    dispatchPostParcelPrice(formValues);
  };

  const content = <ParcelPriceForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Tarifa"
      subtitle="Crea una nueva tarifa"
      content={content}
    />
  );
};

NewParcelPrice.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostParcelPrice: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostParcelPrice: postParcelPrice,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tarifarios',
      href: PARCEL_PRICE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewParcelPrice);
