import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getItineraries,
  clearItineraries,
  getCompanyItineraryDefaultFields,
  clearCompanyItineraryDefaultFields,
} from '../../../../../actions/index';
import {
  ITINERARY_PATH,
  NEW_ITINERARY_PATH,
} from '../../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import ModuleList from '../../../../layout/ModuleList';
import { ITINERARIES_COLUMNS } from '../../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../../common/resource/proptypes/CommonPropTypes';

export const Itineraries = ({
  breadcrumbs,
  itineraries,
  loading,
  isForMine,
  dispatchGetItineraries,
  dispatchClearItineraries,
  companyItineraryDefaultFields,
  dispatchGetCompanyItineraryDefaultFields,
  dispatchClearCompanyItineraryDefaultFields,
}) => {
  const [mounted, setMounted] = useState(false);

  useLayoutEffect(
    () => () => {
      dispatchClearItineraries();
      dispatchClearCompanyItineraryDefaultFields();
    },
    [],
  );

  const onFetchData = (fetchData) => {
    if (mounted) {
      const newFetchData = { ...fetchData };

      newFetchData.query = [
        ...fetchData.query,
        ...[
          'archived:false',
          !companyItineraryDefaultFields.isEmpty()
            ? `companyId:${companyItineraryDefaultFields.get('companyId')}`
            : '',
        ],
      ];

      dispatchGetItineraries({ ...newFetchData });
    }
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      return;
    }

    if (isForMine && companyItineraryDefaultFields.isEmpty()) {
      dispatchGetCompanyItineraryDefaultFields();
      return;
    }

    const query = ['archived:false'];
    if (isForMine && !companyItineraryDefaultFields.isEmpty()) {
      query.push(`companyId:${companyItineraryDefaultFields.get('companyId')}`);
    }

    dispatchGetItineraries({ query });
  }, [mounted, companyItineraryDefaultFields, isForMine]);

  const data = itineraries.get('content') || [];
  const pages = itineraries.get('totalPages') || null;
  const defaultPageSize = itineraries.get('size') || DEFAULT_PAGE_SIZE;
  const columns = ITINERARIES_COLUMNS;

  return (
    <ModuleList
      title="Itinerarios"
      buttonPath={NEW_ITINERARY_PATH}
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: onFetchData,
        modelPath: ITINERARY_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapStateToProps = ({ ItineraryUnit, authentication }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Itinerarios',
      href: ITINERARY_PATH,
    },
  ],
  itineraries: ItineraryUnit.Itinerary.getIn(['all', 'content']),
  companyItineraryDefaultFields: ItineraryUnit.Itinerary.getIn([
    'current',
    'companyItineraryDefaultFields',
  ]),
  loading: ItineraryUnit.Itinerary.getIn(['all', 'loading']),
  isForMine: !!authentication.get('user').salesSessionUserId,
});

const mapDispatchToProps = {
  dispatchGetItineraries: getItineraries,
  dispatchClearItineraries: clearItineraries,
  dispatchGetCompanyItineraryDefaultFields: getCompanyItineraryDefaultFields,
  dispatchClearCompanyItineraryDefaultFields:
    clearCompanyItineraryDefaultFields,
};

Itineraries.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  itineraries: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  isForMine: PropTypes.bool.isRequired,
  dispatchGetItineraries: PropTypes.func.isRequired,
  dispatchClearItineraries: PropTypes.func.isRequired,
  companyItineraryDefaultFields: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetCompanyItineraryDefaultFields: PropTypes.func.isRequired,
  dispatchClearCompanyItineraryDefaultFields: PropTypes.func.isRequired,
};

Itineraries.defaultProps = {
  itineraries: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Itineraries);
