import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_TRIP_REQUEST,
  CLEAR_TRIP_REQUESTS,
  FLAG_GETTING_TRIP_REQUESTS,
  FLAG_TRIP_REQUEST_ACTIVITY,
  GET_TRIP_REQUEST,
  GET_TRIP_REQUESTS,
} from '../types';
import {
  generateApproveTripRequestEndpoint,
  generateRejectTripRequestEndpoint,
  TRIP_REQUEST_ENDPOINT,
} from '../../config/endpoints';
import { TRIP_REQUEST_PATH } from '../../config/paths';

const flagGettingTripRequests = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_TRIP_REQUESTS,
    payload: flag,
  });

const getTripRequests = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingTripRequests(true));
    const query = tableFilters;
    const url = `${TRIP_REQUEST_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_TRIP_REQUESTS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingTripRequests(false));
  }
};

const clearTripRequests = () => (dispatch) =>
  dispatch({
    type: CLEAR_TRIP_REQUESTS,
  });

const flagTripRequestActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_TRIP_REQUEST_ACTIVITY,
    payload: flag,
  });

const getTripRequest =
  async ({ tripRequestId }) =>
  async (dispatch) => {
    try {
      dispatch(flagTripRequestActivity(true));
      const url = `${TRIP_REQUEST_ENDPOINT}/${tripRequestId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_TRIP_REQUEST,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTripRequestActivity(false));
    }
  };

const clearTripRequest = () => (dispatch) =>
  dispatch({
    type: CLEAR_TRIP_REQUEST,
  });

const postTripRequest =
  async ({ contractorId, itineraryId, customerList }) =>
  async (dispatch) => {
    try {
      dispatch(flagTripRequestActivity(true));
      const payload = {
        contractorId,
        itineraryId,
        customerList,
      };
      const url = TRIP_REQUEST_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch(push(`${TRIP_REQUEST_PATH}/${response.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTripRequestActivity(false));
    }
  };

const deleteTripRequest =
  async ({ tripRequestId }) =>
  async (dispatch) => {
    try {
      dispatch(flagTripRequestActivity(true));
      const url = `${TRIP_REQUEST_ENDPOINT}/${tripRequestId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(TRIP_REQUEST_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTripRequestActivity(false));
    }
  };

const putTripRequest =
  async (tripRequestId, { id, contractorId, itineraryId, customerList }) =>
  async (dispatch) => {
    try {
      dispatch(flagTripRequestActivity(true));
      const payload = {
        id,
        contractorId,
        itineraryId,
        customerList,
      };
      const url = `${TRIP_REQUEST_ENDPOINT}/${tripRequestId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch(push(`${TRIP_REQUEST_PATH}/${response.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTripRequestActivity(false));
    }
  };

const postApproveTripRequest =
  async ({ tripRequestId }) =>
  async (dispatch) => {
    try {
      dispatch(flagTripRequestActivity(true));
      const url = generateApproveTripRequestEndpoint(tripRequestId);
      const promise = await fetch(url, { ...DEFAULT_POST_CONFIG });
      await isErrorResponse(promise, null, dispatch);
      const reponse = await promise.json();
      dispatch({
        type: GET_TRIP_REQUEST,
        payload: reponse,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTripRequestActivity(false));
    }
  };

const postRejectTripRequest =
  async ({ tripRequestId }) =>
  async (dispatch) => {
    try {
      dispatch(flagTripRequestActivity(true));
      const url = generateRejectTripRequestEndpoint(tripRequestId);
      const promise = await fetch(url, { ...DEFAULT_POST_CONFIG });
      await isErrorResponse(promise, null, dispatch);
      const reponse = await promise.json();
      dispatch({
        type: GET_TRIP_REQUEST,
        payload: reponse,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagTripRequestActivity(false));
    }
  };

export {
  flagGettingTripRequests,
  getTripRequests,
  clearTripRequests,
  flagTripRequestActivity,
  getTripRequest,
  postTripRequest,
  clearTripRequest,
  deleteTripRequest,
  putTripRequest,
  postApproveTripRequest,
  postRejectTripRequest,
};
