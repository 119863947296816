import React, { useEffect } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Immutable from 'immutable';
import FormItem from '../../../../common/forms/FormItem';
import TextInput from '../../../../common/forms/input/TextInput';
import Table from '../../../../common/Table';
import { generateManifestCargoItemsColumns } from '../../../../../config/columns';

const CargoOperationCorrelativeForm = ({
  manifestItems,
  items,
  setItems,
  handleSubmit,
  reset,
}) => {
  const focusInput = () => {
    const input = document.querySelector('.qr-correlation');
    if (input) {
      input.focus();
    }
  };
  const onSubmit = ({ correlation }) => {
    reset();
    const manifestItemsToAdd = manifestItems.find(
      (manifestItem) =>
        manifestItem.correlation === correlation &&
        !items.find((item) => item.correlation === correlation),
    );
    if (!manifestItemsToAdd) {
      toastr.warning('Advertencia', 'Ya se ha agregado este correlativo', {
        disableCloseButtonFocus: true,
      });
      focusInput();
      return;
    }
    setItems([...items, manifestItemsToAdd]);
  };

  useEffect(() => {
    focusInput();
  }, []);

  const onClickRemoveItem = ({ correlation }) => {
    const removeItem = items.filter((item) => item.correlation !== correlation);
    setItems(removeItem);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <FormItem label="Correlativo" required>
          <Field
            id="qrCorrelation"
            name="correlation"
            className="form-control qr-correlation"
            component={TextInput}
            type="text"
            placeholder="Correlativo"
          />
        </FormItem>
        <button type="submit" hidden />
      </FormGroup>
      <Table
        columns={generateManifestCargoItemsColumns(onClickRemoveItem)}
        data={items}
      />
    </Form>
  );
};

CargoOperationCorrelativeForm.propTypes = {
  manifestItems: PropTypes.instanceOf(Immutable.List).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      correlation: PropTypes.string,
      description: PropTypes.string,
      weight: PropTypes.number,
    }),
  ).isRequired,
  setItems: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'CargoOperationCorrelativeForm',
})(CargoOperationCorrelativeForm);

const mapStateToProps = ({ CargoUnit }) => ({
  manifestItems: CargoUnit.ManifestCargo.getIn(['all', 'content']).map(
    (option) => ({
      correlation: option.correlation,
      description: option.description,
      weight: option.weight,
    }),
  ),
});

export default connect(mapStateToProps, null)(formComponent);
