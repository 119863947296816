// todo: replace with qs
// https://www.npmjs.com/package/qs
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  handleResponseError,
  isErrorResponse,
} from '../../utils/error-handlers';
import { TICKET_REPORT_ENDPOINT } from '../../config/endpoints';
import {
  GET_TICKET_REPORTS,
  FLAG_GETTING_TICKET_REPORTS,
  CLEAR_ERROR_GETTING_TICKET_REPORTS,
  SHOW_ERROR_GETTING_TICKET_REPORTS,
  CLEAR_TICKET_REPORTS,
  CLEAR_ERROR_POSTING_TICKET_REPORT,
  POST_TICKET_REPORT,
  FLAG_POSTING_TICKET_REPORT,
  DOWNLOAD_TICKET_REPORT,
  GET_TICKET_REPORT,
  SHOW_ERROR_GETTING_TICKET_REPORT,
  CLEAR_ERROR_GETTING_TICKET_REPORT,
  FLAG_GETTING_TICKET_REPORT,
  CLEAR_TICKET_REPORT,
} from '../types/index';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from '../../config/constants';
import { getReportsServerEndpoint } from './Report';
import { TICKET_REPORTS_PATH } from '../../config/paths';
import { DEFAULT_POST_CONFIG } from '../../config/rest';

const flagGettingTicketReports = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_TICKET_REPORTS,
  });

const showErrorGettingTicketReports = (error) => (dispatch) =>
  dispatch({
    payload: error,
    type: SHOW_ERROR_GETTING_TICKET_REPORTS,
  });

const clearErrorGettingTicketReports = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_GETTING_TICKET_REPORTS,
  });

const getTicketReports =
  async ({ page = 0, size = DEFAULT_PAGE_SIZE, sort = DEFAULT_SORT } = {}) =>
  async (dispatch, getState) => {
    dispatch(clearErrorGettingTicketReports());
    dispatch(flagGettingTicketReports(true));
    try {
      const reportsServerEndpoint = await getReportsServerEndpoint(
        dispatch,
        getState,
      );
      // make request
      const url = reportsServerEndpoint + TICKET_REPORT_ENDPOINT;
      const query = {
        page,
        size,
        sort,
      };
      const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
        method: 'GET',
        credentials: 'include',
      });
      const serverError = await handleResponseError(response);
      if (serverError) {
        dispatch(flagGettingTicketReports(false));
        return dispatch(showErrorGettingTicketReports(serverError));
      }
      const reports = await response.json();
      dispatch({
        type: GET_TICKET_REPORTS,
        payload: reports,
      });
      return dispatch(flagGettingTicketReports(false));
    } catch (err) {
      console.error(err);
      // lower flag
      dispatch(flagGettingTicketReports(false));
      const errMsg = 'Error obteniendo los reportes de boletos.';
      return dispatch(showErrorGettingTicketReports(errMsg));
    }
  };

const clearTicketReports = () => (dispatch) =>
  dispatch({
    type: CLEAR_TICKET_REPORTS,
  });

const clearErrorPostingTicketReport = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_POSTING_TICKET_REPORT,
  });

const flagPostingTicketReport = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_POSTING_TICKET_REPORT,
    payload: flag,
  });

const postTicketReport =
  async ({ fromDate, toDate, force, agencyIds }) =>
  async (dispatch, getState) => {
    try {
      // raise flag
      dispatch(flagPostingTicketReport(true));
      const reportsServerEndpoint = await getReportsServerEndpoint(
        dispatch,
        getState,
      );
      const query = {
        force,
      };
      const payload = {
        fromDate,
        toDate,
        agencyIds,
      };
      // make request
      const url = reportsServerEndpoint + TICKET_REPORT_ENDPOINT;
      const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      const report = await response.json();
      dispatch({
        type: POST_TICKET_REPORT,
        payload: report,
      });
      dispatch(flagPostingTicketReport(false));
      // redirect to report
      dispatch(push(`${TICKET_REPORTS_PATH}/${report.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagPostingTicketReport(false));
    }
  };

const flagGettingTicketReport = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_TICKET_REPORT,
  });

const clearErrorGettingTicketReport = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_GETTING_TICKET_REPORT,
  });

const showErrorGettingTicketReport = (error) => (dispatch) =>
  dispatch({
    type: SHOW_ERROR_GETTING_TICKET_REPORT,
    payload: error,
  });

const getTicketReport =
  async ({
    id,
    reportFormat = 'json',
    download = false,
    page = 0,
    size = DEFAULT_PAGE_SIZE,
    sort = DEFAULT_SORT,
  }) =>
  async (dispatch, getState) => {
    dispatch(clearErrorGettingTicketReport());
    dispatch(flagGettingTicketReport(true));
    try {
      const reportsServerEndpoint = await getReportsServerEndpoint(
        dispatch,
        getState,
      );
      // make request
      const url = `${reportsServerEndpoint + TICKET_REPORT_ENDPOINT}/${id}`;
      const query = {
        reportFormat,
        page,
        size,
        sort,
      };
      const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
        method: 'GET',
        credentials: 'include',
      });
      const serverError = await handleResponseError(response);
      if (serverError) {
        dispatch(flagGettingTicketReport(false));
        return dispatch(showErrorGettingTicketReport(serverError));
      }
      // parse json or text depending on format
      const text = reportFormat === 'csv';
      const report = text ? await response.text() : await response.json();
      if (download) {
        dispatch({
          type: DOWNLOAD_TICKET_REPORT,
          payload: report,
        });
      } else {
        dispatch({
          type: GET_TICKET_REPORT,
          payload: report,
        });
      }
      return dispatch(flagGettingTicketReport(false));
    } catch (err) {
      console.error(err);
      // lower flag
      dispatch(flagGettingTicketReport(false));
      const errMsg = `Error obteniendo el reporte de boletos #${id}.`;
      return dispatch(showErrorGettingTicketReport(errMsg));
    }
  };

const clearTicketReport = () => (dispatch) =>
  dispatch({
    type: CLEAR_TICKET_REPORT,
  });

export {
  getTicketReports,
  clearErrorGettingTicketReports,
  clearTicketReports,
  clearErrorPostingTicketReport,
  postTicketReport,
  getTicketReport,
  clearTicketReport,
  clearErrorGettingTicketReport,
};
