import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from './Select';
import {
  clearParcelServiceTypes,
  getParcelServiceTypes,
} from '../../../../actions/cargo/ParcelServiceType';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

const ParcelServiceTypeSelect = ({
  options,
  isLoading,
  isMulti,
  input,
  meta,
  dispatchClearParcelServiceTypes,
  dispatchGetParcelServiceTypes,
  ...rest
}) => {
  useLayoutEffect(() => {
    dispatchGetParcelServiceTypes({ ...DEFAULT_QUERY_GET_ALL });
    return () => dispatchClearParcelServiceTypes();
  }, []);

  return (
    <Select
      options={options}
      isLoading={isLoading}
      isMulti={isMulti}
      input={input}
      meta={meta}
      {...rest}
    />
  );
};

ParcelServiceTypeSelect.propTypes = {
  dispatchGetParcelServiceTypes: PropTypes.func.isRequired,
  dispatchClearParcelServiceTypes: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
};

ParcelServiceTypeSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isMulti: false,
  options: [],
};

const mapStateToProps = ({ CargoUnit }) => ({
  options: CargoUnit.ParcelServiceType.getIn(['all', 'content', 'content']).map(
    (parcelServiceType) => ({
      label: parcelServiceType.name,
      value: parcelServiceType.id,
    }),
  ),
  isLoading: CargoUnit.ParcelServiceType.getIn([
    'parcelServiceType',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchClearParcelServiceTypes: clearParcelServiceTypes,
  dispatchGetParcelServiceTypes: getParcelServiceTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParcelServiceTypeSelect);
