import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_ODOMETER_BY_BUS,
  CLEAR_ODOMETER_BY_BUS,
  GET_ODOMETER_BY_BUS,
} from '../types';
import { generateOdometerByBusEndpoint } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingOdometerByBus = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ODOMETER_BY_BUS,
    payload: flag,
  });

const clearOdometerByBus = () => (dispatch) =>
  dispatch({
    type: CLEAR_ODOMETER_BY_BUS,
  });

const getOdometerByBus =
  async ({ registeredBusId, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const params = {
        fromDate,
        toDate,
      };
      dispatch(flagGettingOdometerByBus(true));
      const url = `${generateOdometerByBusEndpoint(
        registeredBusId,
      )}?${QueryString.stringify(params)}`;
      const promise = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({ type: GET_ODOMETER_BY_BUS, payload: response });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingOdometerByBus(false));
    }
  };

export { clearOdometerByBus, getOdometerByBus };
