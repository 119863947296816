import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_COMPANIES,
  GET_COMPANIES,
  CLEAR_COMPANIES,
  FLAG_COMPANY_ACTIVITY,
  GET_COMPANY,
  CLEAR_COMPANY,
} from '../types';
import { COMPANY_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { COMPANY_PATH } from '../../config/paths';

const flagGettingCompanies = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_COMPANIES,
    payload: flag,
  });

const getCompanies = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCompanies(true));

    const query = tableFilters;

    const url = `${COMPANY_ENDPOINT}?${QueryString.stringify(query)}`;

    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response, null, dispatch);

    const companies = await response.json();

    dispatch({
      type: GET_COMPANIES,
      payload: companies,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCompanies(false));
  }
};

const clearCompanies = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANIES,
  });

const flagCompanyActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_COMPANY_ACTIVITY,
    payload: flag,
  });

const postCompany =
  async ({ name, businessId, companyType }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyActivity(true));

      const payload = { name, businessId, companyType };

      const url = COMPANY_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(COMPANY_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCompanyActivity(false));
    }
  };

const getCompany =
  async ({ companyId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyActivity(true));

      const url = `${COMPANY_ENDPOINT}/${companyId}`;

      const response = await fetch(url, DEFAULT_GET_CONFIG);

      await isErrorResponse(response, null, dispatch);

      const company = await response.json();

      dispatch({
        type: GET_COMPANY,
        payload: company,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCompanyActivity(false));
    }
  };

const clearCompany = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY,
  });

const putCompany =
  async (companyId, { name, businessId, companyType }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyActivity(true));
      const payload = {
        name,
        businessId,
        companyType,
        id: companyId,
      };

      const url = `${COMPANY_ENDPOINT}/${companyId}`;

      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      const company = await response.json();

      dispatch(push(`${COMPANY_PATH}/${company.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCompanyActivity(false));
    }
  };

const deleteCompany =
  async ({ companyId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyActivity(true));

      const url = `${COMPANY_ENDPOINT}/${companyId}`;

      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(COMPANY_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCompanyActivity(false));
    }
  };

export {
  getCompanies,
  clearCompanies,
  postCompany,
  getCompany,
  clearCompany,
  putCompany,
  deleteCompany,
};
