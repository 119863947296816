import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../../common/Badge';

const DriverStatusCell = ({ value }) => {
  switch (value) {
    case 1:
      return <Badge color="success" text="OPERATIVO" />;
    case 2:
      return <Badge color="warning" text="VACACIONES" />;
    case 3:
      return <Badge color="danger" text="SUSPENDIDO" />;
    default:
      return <Badge color="danger" text="DESACTIVO" />;
  }
};

DriverStatusCell.propTypes = {
  value: PropTypes.number,
};

DriverStatusCell.defaultProps = {
  value: 0,
};

export default DriverStatusCell;
