import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonToolbar } from 'reactstrap';
import { generateItineraryByProcessStatusEditPath } from '../../../../config/paths';
import ProcessDuplicateButton from './ProcessDuplicateButton';

const ProcessToolbar = ({ processId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <ProcessDuplicateButton processId={processId} />{' '}
      <Link
        className="btn btn-primary"
        to={generateItineraryByProcessStatusEditPath(processId)}
      >
        <i className="fa fa-pencil-square-o" /> Cambiar estados
      </Link>
    </div>
  </ButtonToolbar>
);

ProcessToolbar.propTypes = {
  processId: PropTypes.number.isRequired,
};

export default ProcessToolbar;
