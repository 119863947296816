import { instanceOf, shape, string } from 'prop-types';

const tripRequestBasicInformationPropTypes = {
  contractor: shape({
    businessName: string.isRequired,
    businessTaxId: string.isRequired,
  }).isRequired,
  status: string.isRequired,
  customerList: instanceOf(Array),
};

export default tripRequestBasicInformationPropTypes;
