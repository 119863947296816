import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { PAYMENT_PATH } from '../../../../config/paths';
import PaymentDeleteButton from './PaymentDeleteButton';
import PaymentApproveButton from './PaymentApproveButton';
import PaymentRejectButton from './PaymentRejectButton';

const PaymentToolbar = ({ paymentId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <PaymentApproveButton paymentId={paymentId} />{' '}
      <PaymentRejectButton paymentId={paymentId} />{' '}
      <EditButton path={`${PAYMENT_PATH}/${paymentId}/edit`} />{' '}
      <PaymentDeleteButton paymentId={paymentId} />
    </div>
  </ButtonToolbar>
);

PaymentToolbar.propTypes = {
  paymentId: PropTypes.number.isRequired,
};

export default PaymentToolbar;
