import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import Loader from '../../common/Loader';

// exporting unconnected component for testing
// https://bit.ly/2HtSJlT
export const SignIn = ({ dispatchReplace }) => {
  useEffect(() => {
    dispatchReplace('/');
  }, []);

  return <Loader />;
};

const mapDispatchToProps = {
  dispatchReplace: replace,
};

SignIn.propTypes = {
  dispatchReplace: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(SignIn);
