import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import Loader from '../../../common/Loader';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateEmail } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import { GENDERS } from '../../../../config/constants';
import CountrySelect from '../../../common/forms/select/CountrySelect';
import IdentificationTypeSelect from '../../../common/forms/select/IdentificationTypeSelect';
import validate from './validate';
import DatePicker from '../../../common/forms/input/DatePicker';
import AddressInputGroup from '../../../common/forms/AddressInputGroup';
import FormFooter from '../../../common/forms/FormFooter';
import { tzNormalizeDate } from '../../../../utils/date';

const CustomerForm = ({
  handleSubmit,
  loading,
  onModal,
  mobilePhoneRequired,
  emailRequired,
  initialValues,
}) => {
  const [disabled] = useState(onModal && initialValues !== null);

  if (loading) return <Loader />;

  let onSubmitForForm = handleSubmit;
  let buttonType = 'submit';
  let onClickForButton = null;

  if (onModal) {
    onSubmitForForm = null;
    buttonType = 'button';
    onClickForButton = handleSubmit;
  }

  const validateMobilePhone = [];

  const emailValidations = [validateEmail];

  if (mobilePhoneRequired) validateMobilePhone.push(isRequired);

  if (emailRequired) emailValidations.push(isRequired);

  const addressField = !onModal && (
    <>
      <h3>Dirección</h3>
      <AddressInputGroup
        label="Dirección"
        name="addressId"
        form="CustomerForm"
      />
    </>
  );

  const extraInformationFields = (
    <>
      <FormGroup row>
        <FormItem label="Teléfono Fijo">
          <Field
            name="homePhone"
            component={TextInput}
            type="text"
            placeholder="Teléfono Fijo"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Teléfono de Trabajo">
          <Field
            name="workPhone"
            component={TextInput}
            type="text"
            placeholder="Teléfono de Trabajo"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fax">
          <Field
            name="fax"
            component={TextInput}
            type="text"
            placeholder="Fax"
          />
        </FormItem>
      </FormGroup>
      {addressField}
    </>
  );

  return (
    <Form onSubmit={onSubmitForForm}>
      <h3>Información Básica</h3>
      <FormGroup row>
        <FormItem label="Nombres" required>
          <Field
            name="firstName"
            component={TextInput}
            type="text"
            placeholder="Nombres"
            validate={[isRequired]}
            disabled={disabled}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Apellidos" required>
          <Field
            name="lastName"
            component={TextInput}
            type="text"
            placeholder="Apellidos"
            validate={[isRequired]}
            disabled={disabled}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Documento" required>
          <Field
            name="identificationTypeId"
            component={IdentificationTypeSelect}
            isClearable={false}
            validate={[isRequired]}
            isDisabled={disabled}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Núm. de Documento" required>
          <Field
            name="idDocumentNumber"
            component={TextInput}
            type="text"
            placeholder="Número de Documento"
            validate={[isRequired]}
            disabled={disabled}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="País de Origen" required>
          <Field
            name="idCountryOfOrigin"
            component={CountrySelect}
            validate={[isRequired]}
            placeholder="Ingresa mínimo 4 caracteres"
            isDisabled={disabled}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Género" required>
          <Field
            name="gender"
            component={Select}
            options={GENDERS}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha de Nacimiento">
          <Field
            name="dateOfBirth"
            props={{
              max: tzNormalizeDate(),
            }}
            component={DatePicker}
          />
        </FormItem>
      </FormGroup>
      <h3>Información de Contacto</h3>
      <FormGroup row>
        <FormItem label="Email" required={emailRequired}>
          <Field
            name="email"
            component={TextInput}
            type="text"
            placeholder="ejemplo@gmail.com"
            validate={emailValidations}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Celular" required={mobilePhoneRequired}>
          <Field
            name="mobilePhone"
            component={TextInput}
            type="text"
            placeholder="Celular"
            validate={validateMobilePhone}
          />
        </FormItem>
      </FormGroup>
      {extraInformationFields}
      <FormFooter
        saveButtonType={buttonType}
        saveButtonAction={onClickForButton}
      />
    </Form>
  );
};

CustomerForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onModal: PropTypes.bool,
  mobilePhoneRequired: PropTypes.bool,
  emailRequired: PropTypes.bool,
  initialValues: PropTypes.shape({}),
};

CustomerForm.defaultProps = {
  loading: false,
  onModal: false,
  mobilePhoneRequired: false,
  emailRequired: false,
  initialValues: null,
};

const mapStateToProps = ({ UserUnit }) => ({
  loading: !UserUnit.Customer.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'CustomerForm',
  validate,
})(CustomerForm);

export default connect(mapStateToProps)(formComponent);
