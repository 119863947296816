import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';
import {
  clearManifestItineraryDetail,
  getManifestItemDetail,
} from '../../../../actions/cargo/ItineraryManifest';
import Loader from '../../../common/Loader';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

class ItineraryManifestDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: {},
    };
  }

  componentDidMount() {
    const { dispatchGetManifestItemDetail, manifestId } = this.props;
    const query = [`manifestCargoItemId:${manifestId}`];

    dispatchGetManifestItemDetail({
      query,
      size: DEFAULT_QUERY_GET_ALL.size,
    }).then((details) => {
      this.setState({ details });
    });
  }

  ITINERARY_MANIFEST_DETAILS_COLUMNS = [
    {
      Header: 'Correlativo',
      accessor: 'item.correlation',
      className: 'text-center',
    },
    {
      Header: 'Descripcion',
      accessor: 'item.description',
      className: 'text-center',
    },
    {
      Header: 'Consignado',
      accessor: 'item.consigned',
      className: 'text-center',
    },
    {
      Header: 'Peso',
      accessor: 'item.weight',
      className: 'text-center',
    },
  ];

  render() {
    const { loading } = this.props;
    const { details } = this.state;
    const data = details.content || [];

    let table =
      data.length > 0 ? (
        <Table
          manual={false}
          columns={this.ITINERARY_MANIFEST_DETAILS_COLUMNS}
          data={data}
          defaultPageSize={20}
          filterable
          defaultFilterMethod={filterMethodCaseInsensitive}
          loading={loading}
        />
      ) : null;

    if (loading && !data.length) table = <Loader />;
    return (
      <Fragment>
        <h4>Detalle del Manifiesto</h4>
        {table}
      </Fragment>
    );
  }
}

ItineraryManifestDetail.propTypes = {
  manifestId: PropTypes.number.isRequired,
  dispatchGetManifestItemDetail: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sourceLocation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  destinationLocation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = ({ CargoUnit, authentication }) => ({
  loading: CargoUnit.ItineraryManifest.getIn(['detail', 'loading']),
  user: authentication.get('user') || undefined,
});

const mapDispatchToProps = {
  dispatchGetManifestItemDetail: getManifestItemDetail,
  dispatchClearManifestItineraryDetail: clearManifestItineraryDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItineraryManifestDetail);
