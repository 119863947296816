import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { postContractor } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { CONTRACTOR_PATH } from '../../../../config/paths';
import Content from '../../../layout/Content';
import ContractorForm from './ContractorForm';

const NewContractor = ({ breadcrumbs, dispatchPostContractor }) => {
  const onSubmit = (formValues) =>
    dispatchPostContractor({
      ...formValues,
      customerId: formValues.customer.value,
      businessId: formValues.business.value,
      companyId: formValues.company.value,
    });

  const content = <ContractorForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Contratista"
      subtitle="Crear nuevo contratista"
      content={content}
    />
  );
};

NewContractor.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostContractor: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostContractor: postContractor,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Contratistas',
      href: CONTRACTOR_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewContractor);
