import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddCargoToItineraryCorrelativeForm from './CargoOperationCorrelativeForm';
import AddCargoToItineraryByQrButtonForm from './CargoOperationByQrButtonForm';

export const CargoOperationByQrModalForms = ({
  onClickAddItemToItinerary,
  setShowModal,
  cargoOperation,
}) => {
  const [items, setItems] = useState([]);
  return (
    <>
      <AddCargoToItineraryCorrelativeForm items={items} setItems={setItems} />
      <AddCargoToItineraryByQrButtonForm
        items={items}
        onClickAddItemToItinerary={onClickAddItemToItinerary}
        setShowModal={setShowModal}
        cargoOperation={cargoOperation}
      />
    </>
  );
};

CargoOperationByQrModalForms.propTypes = {
  onClickAddItemToItinerary: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  cargoOperation: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};

export default CargoOperationByQrModalForms;
