import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_DELIVERY_GROUPS,
  GET_DELIVERY_GROUPS,
  CLEAR_DELIVERY_GROUPS,
  FLAG_DELIVERY_GROUP_ACTIVITY,
  GET_DELIVERY_GROUP,
  CLEAR_DELIVERY_GROUP,
} from '../types/cargo';
import { DELIVERY_GROUP_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { DELIVERY_GROUP_PATH } from '../../config/paths';

const flagGettingDeliveryGroups = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DELIVERY_GROUPS,
    payload: flag,
  });

const flagDeliveryGroupActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DELIVERY_GROUP_ACTIVITY,
    payload: flag,
  });

const getDeliveryGroups = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDeliveryGroups(true));
    const query = tableFilters;
    const url = `${DELIVERY_GROUP_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const deliveryGroups = await response.json();
    dispatch({
      type: GET_DELIVERY_GROUPS,
      payload: deliveryGroups,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingDeliveryGroups(false));
  }
};

const getDeliveryGroup =
  async ({ deliveryGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryGroupActivity(true));
      const url = `${DELIVERY_GROUP_ENDPOINT}/${deliveryGroupId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const deliveryGroup = await response.json();
      dispatch({
        type: GET_DELIVERY_GROUP,
        payload: deliveryGroup,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDeliveryGroupActivity(false));
    }
  };

const clearDeliveryGroup = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_GROUP,
  });

const clearDeliveryGroups = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_GROUPS,
  });

const postDeliveryGroup =
  async ({ name, description }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryGroupActivity(true));
      const payload = {
        name,
        description,
      };
      const url = DELIVERY_GROUP_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const deliveryGroup = await response.json();
      dispatch(push(`${DELIVERY_GROUP_PATH}/${deliveryGroup.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDeliveryGroupActivity(false));
    }
  };

const putDeliveryGroup =
  async (deliveryGroupId, { name, description }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryGroupActivity(true));
      const payload = {
        id: deliveryGroupId,
        name,
        description,
      };
      const url = `${DELIVERY_GROUP_ENDPOINT}/${deliveryGroupId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const deliveryGroup = await response.json();
      dispatch(push(`${DELIVERY_GROUP_PATH}/${deliveryGroup.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDeliveryGroupActivity(false));
    }
  };

const deleteDeliveryGroup =
  async ({ deliveryGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryGroupActivity(true));
      const url = `${DELIVERY_GROUP_ENDPOINT}/${deliveryGroupId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(DELIVERY_GROUP_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDeliveryGroupActivity(false));
    }
  };

export {
  flagGettingDeliveryGroups,
  getDeliveryGroups,
  clearDeliveryGroups,
  flagDeliveryGroupActivity,
  postDeliveryGroup,
  getDeliveryGroup,
  clearDeliveryGroup,
  putDeliveryGroup,
  deleteDeliveryGroup,
};
