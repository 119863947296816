import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_FUEL_VOUCHERS,
  GET_FUEL_VOUCHERS,
  CLEAR_FUEL_VOUCHERS,
  FLAG_FUEL_VOUCHER_ACTIVITY,
  GET_FUEL_VOUCHER,
  CLEAR_FUEL_VOUCHER,
  POST_FUEL_VOUCHER,
  PUT_FUEL_VOUCHER,
} from '../types';
import {
  FUEL_VOUCHER_ENDPOINT,
  EXTRAORDINARY_FUEL_VOUCHER_ENDPOINT,
  PRINT_FUEL_VOUCHER_ENDPOINT,
  generateMarkFuelVoucherAsInactiveEndpoint,
  generateGetPreviewFuelVoucherEndpoint,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_PATCH_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { FUEL_VOUCHER_PATH } from '../../config/paths';
import { flagItineraryActivity } from '../itinerary';

const flagGettingFuelVouchers = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_FUEL_VOUCHERS,
    payload: flag,
  });

const getFuelVouchersAsync = async (tableFilters) => {
  const query = tableFilters;

  const url = `${FUEL_VOUCHER_ENDPOINT}?${QueryString.stringify(query)}`;

  const response = await fetch(url, DEFAULT_GET_CONFIG);
  await isErrorResponse(response);
  const fuelVouchers = await response.json();

  return fuelVouchers;
};

const getFuelVouchers = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingFuelVouchers(true));

    const fuelVouchers = await getFuelVouchersAsync(tableFilters);

    dispatch({
      type: GET_FUEL_VOUCHERS,
      payload: fuelVouchers,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingFuelVouchers(false));
  }
};

const clearFuelVouchers = () => (dispatch) =>
  dispatch({
    type: CLEAR_FUEL_VOUCHERS,
  });

const flagFuelVoucherActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_FUEL_VOUCHER_ACTIVITY,
    payload: flag,
  });

const getFuelVoucher =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelVoucherActivity(true));
      const url = `${FUEL_VOUCHER_ENDPOINT}/${id}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const fuelVoucher = await response.json();
      dispatch({
        type: GET_FUEL_VOUCHER,
        payload: fuelVoucher,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagFuelVoucherActivity(false));
    }
  };

const clearFuelVoucher = () => (dispatch) =>
  dispatch({
    type: CLEAR_FUEL_VOUCHER,
  });

const postExtraordinaryFuelVoucher =
  async ({
    validFrom,
    validTo,
    companyBusId,
    fuelReceiverName,
    numberOfGallons,
    itineraryId,
    fuelStationId,
    comments,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelVoucherActivity(true));

      const payload = {
        validFrom,
        validTo,
        companyBusId,
        fuelReceiverName,
        numberOfGallons,
        itineraryId,
        fuelStationId,
        comments,
      };

      const url = EXTRAORDINARY_FUEL_VOUCHER_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const fuelVoucher = await response.json();
      dispatch({
        type: POST_FUEL_VOUCHER,
        payload: fuelVoucher,
      });
      dispatch(push(`${FUEL_VOUCHER_PATH}/${fuelVoucher.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagFuelVoucherActivity(false));
    }
  };

const putFuelVoucher =
  async (
    fuelVoucherId,
    {
      companyBusId,
      fuelReceiverName,
      numberOfGallons,
      realNumberOfGallons,
      itineraryId,
      amount,
      pricePerGallon,
      fuelStationId,
      isValid,
      isSettled,
      redeemed,
      extraordinary,
      fuelVoucherStatus,
      comments,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelVoucherActivity(true));

      const payload = {
        id: fuelVoucherId,
        companyBusId,
        fuelReceiverName,
        numberOfGallons,
        realNumberOfGallons,
        itineraryId,
        amount,
        pricePerGallon,
        fuelStationId,
        isValid,
        isSettled,
        redeemed,
        extraordinary,
        fuelVoucherStatus,
        comments,
      };

      const url = `${FUEL_VOUCHER_ENDPOINT}/${fuelVoucherId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const fuelVoucher = await response.json();
      dispatch({
        type: PUT_FUEL_VOUCHER,
        payload: fuelVoucher,
      });
      dispatch(push(`${FUEL_VOUCHER_PATH}/${fuelVoucher.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagFuelVoucherActivity(false));
    }
  };

const postPrintFuelVoucher =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const payload = { itineraryId };
      const url = `${PRINT_FUEL_VOUCHER_ENDPOINT}/${itineraryId}`;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const fuelVoucher = await response.json();
      return fuelVoucher;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const getPreviewFuelVoucher =
  async ({ itineraryId }) =>
  async () => {
    try {
      const url = generateGetPreviewFuelVoucherEndpoint(itineraryId);
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const fuelVoucher = await response.json();
      return fuelVoucher;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    }
  };

const patchStateFuelVoucher =
  async ({ fuelVoucherId, fuelVoucherStatus }) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelVoucherActivity(true));

      const payload = {
        id: fuelVoucherId,
        fuelVoucherStatus,
      };

      const url = generateMarkFuelVoucherAsInactiveEndpoint(fuelVoucherId);

      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      const fuelVoucher = await response.json();

      dispatch(push(`${FUEL_VOUCHER_PATH}/${fuelVoucher.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagFuelVoucherActivity(false));
    }
  };

export {
  clearFuelVouchers,
  getFuelVouchers,
  getFuelVoucher,
  clearFuelVoucher,
  postExtraordinaryFuelVoucher,
  putFuelVoucher,
  postPrintFuelVoucher,
  getPreviewFuelVoucher,
  patchStateFuelVoucher,
  getFuelVouchersAsync,
  flagGettingFuelVouchers,
};
