import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import { clearExistUser, getUserByCustomerId } from '../../../../actions';
import Loader from '../../../common/Loader';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import CompanySelect from '../../../common/forms/select/CompanySelect';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import './ContractForm.css';

const validatePassword = (confirmPassword, { password }) => {
  if (!confirmPassword) return undefined;

  return confirmPassword !== password
    ? 'Las contraseñas no coinciden'
    : undefined;
};

const ContractorForm = ({
  handleSubmit,
  initialValues,
  loading,
  existUser,
  loadingUser,
  dispatchGetUserByCustomerId,
  dispatchClearExistUser,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearExistUser();
    },
    [],
  );

  const onChangeCustomer = (customer) => {
    dispatchGetUserByCustomerId({ customerId: customer.value });
  };

  let passwordFields;

  if (loadingUser) {
    passwordFields = <Loader />;
  } else if (existUser || initialValues.customer) {
    passwordFields = null;
  } else {
    passwordFields = (
      <>
        <FormGroup row>
          <FormItem label="Nueva contraseña" required>
            <Field
              name="password"
              className="password"
              component={TextInput}
              type="text"
              placeholder="Nueva contraseña"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Confirmar nueva contraseña" required>
            <Field
              name="confirmPassword"
              component={TextInput}
              className="password"
              type="text"
              placeholder="Confirmar nueva contraseña"
              validate={[isRequired, validatePassword]}
            />
          </FormItem>
        </FormGroup>
      </>
    );
  }

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <CustomerInputGroup
        label="Contratista"
        name="customer"
        labelRequired
        validate={[isRequired]}
        emailRequired
        form="ContractorForm"
        onChange={onChangeCustomer}
        showDetails
      />
      <BusinessInputGroup
        label="Empresa del Contratista"
        name="business"
        labelRequired
        form="ContractorForm"
        validate={[isRequired]}
        showDetails
      />
      <FormGroup row>
        <FormItem label="Compañía a la que se le presta el servicio">
          <Field name="company" component={CompanySelect} isClearable />
        </FormItem>
      </FormGroup>
      {passwordFields}
      <FormFooter />
    </Form>
  );
};

ContractorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    customer: PropTypes.instanceOf(Object),
  }),
  loading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  existUser: PropTypes.bool,
  dispatchGetUserByCustomerId: PropTypes.func.isRequired,
  dispatchClearExistUser: PropTypes.func.isRequired,
};

ContractorForm.defaultProps = {
  existUser: false,
  initialValues: {},
};

const mapStateToProps = ({ ContractUnit, UserUnit }) => ({
  loading: !ContractUnit.Contractor.getIn(['current', 'activity']).isEmpty(),
  loadingUser: !UserUnit.User.getIn(['current', 'activity']).isEmpty(),
  existUser: UserUnit.User.getIn(['current', 'exists']),
});

const mapDispatchToProps = {
  dispatchGetUserByCustomerId: getUserByCustomerId,
  dispatchClearExistUser: clearExistUser,
};

const formComponent = reduxForm({
  form: 'ContractorForm',
})(ContractorForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
