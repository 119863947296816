import { toastr } from 'react-redux-toastr';
import { DEFAULT_GET_CONFIG, DEFAULT_POST_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  generateItinerarySearchResultEndpoint,
  SERVICE_MERGE_ENDPOINT,
  generatePrintedMergedTicketsEndpoint,
} from '../../config/endpoints';
import {
  FLAG_GETTING_SOURCE_ITINERARY_SEATMAP,
  CLEAR_SOURCE_ITINERARY_SEATMAP,
  GET_SOURCE_ITINERARY_SEATMAP,
  GET_DESTINATION_ITINERARY_SEATMAP,
  FLAG_GETTING_DESTINATION_ITINERARY_SEATMAP,
  CLEAR_DESTINATION_ITINERARY_SEATMAP,
  GET_SOURCE_ITINERARY_PASSENGER_LIST,
  GET_DESTINATION_ITINERARY_PASSENGER_LIST,
  CLEAR_SOURCE_ITINERARY_PASSENGER_LIST,
  CLEAR_DESTINATION_ITINERARY_PASSENGER_LIST,
  FLAG_SERVICE_MERGE_ACTIVITY,
  GET_SERVICE_MERGE_ID,
  CLEAR_SERVICE_MERGE_ID,
} from '../types/itinerary/ServiceMerge';

const flagServiceMergeActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_SERVICE_MERGE_ACTIVITY,
    payload: flag,
  });

const flagGettingSourceItinerary = (flag, type) => (dispatch) =>
  dispatch({
    type:
      type === 'source'
        ? FLAG_GETTING_SOURCE_ITINERARY_SEATMAP
        : FLAG_GETTING_DESTINATION_ITINERARY_SEATMAP,
    payload: flag,
  });

const getItinerarySeatMap =
  async ({ itineraryId, type }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingSourceItinerary(true, type));
      const url = generateItinerarySearchResultEndpoint(itineraryId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const seatMap = await response.json();
      dispatch({
        type:
          type === 'source'
            ? GET_SOURCE_ITINERARY_SEATMAP
            : GET_DESTINATION_ITINERARY_SEATMAP,
        payload: seatMap.itinerarySearchResult,
      });
      dispatch({
        type:
          type === 'source'
            ? GET_SOURCE_ITINERARY_PASSENGER_LIST
            : GET_DESTINATION_ITINERARY_PASSENGER_LIST,
        payload: seatMap.itineraryPassengerList,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingSourceItinerary(false, type));
    }
  };

const clearItinerarySeatMap =
  ({ type }) =>
  (dispatch) =>
    dispatch({
      type:
        type === 'source'
          ? CLEAR_SOURCE_ITINERARY_SEATMAP
          : CLEAR_DESTINATION_ITINERARY_SEATMAP,
    });

const clearItineraryPassengerList =
  ({ type }) =>
  (dispatch) =>
    dispatch({
      type:
        type === 'source'
          ? CLEAR_SOURCE_ITINERARY_PASSENGER_LIST
          : CLEAR_DESTINATION_ITINERARY_PASSENGER_LIST,
    });

const clearServiceMergeId = () => (dispatch) =>
  dispatch({
    type: CLEAR_SERVICE_MERGE_ID,
  });

const postServiceMerge =
  async ({ sourceItineraryId, destinationItineraryId, comment, forced }) =>
  async (dispatch) => {
    try {
      dispatch(flagServiceMergeActivity(true));
      const payload = {
        sourceItineraryId,
        destinationItineraryId,
        comment,
        forced,
      };

      const url = SERVICE_MERGE_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const merge = await response.json();
      dispatch({
        type: GET_SERVICE_MERGE_ID,
        payload: merge.id,
      });
      dispatch({
        type: GET_SOURCE_ITINERARY_SEATMAP,
        payload: merge.sourceItinerary,
      });
      dispatch({
        type: GET_SOURCE_ITINERARY_PASSENGER_LIST,
        payload: merge.sourceItineraryPassengerList,
      });
      dispatch({
        type: GET_DESTINATION_ITINERARY_SEATMAP,
        payload: merge.destinationItinerary,
      });
      dispatch({
        type: GET_DESTINATION_ITINERARY_PASSENGER_LIST,
        payload: merge.destinationItineraryPassengerList,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagServiceMergeActivity(false));
    }
  };

const getPrintedMergedTickets =
  async ({ serviceMergeId }) =>
  async (dispatch) => {
    try {
      dispatch(flagServiceMergeActivity(true));
      const url = generatePrintedMergedTicketsEndpoint(serviceMergeId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const tickets = await response.json();
      return tickets;
    } catch (error) {
      toastr.error('Error', error.message);
      return null;
    } finally {
      dispatch(flagServiceMergeActivity(false));
    }
  };

export {
  getItinerarySeatMap,
  flagGettingSourceItinerary,
  clearItinerarySeatMap,
  clearItineraryPassengerList,
  postServiceMerge,
  flagServiceMergeActivity,
  clearServiceMergeId,
  getPrintedMergedTickets,
};
