import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../utils/date';
import { FULL_DATE_TIME_FORMAT } from '../../../../config/locale';
import EnumStatus from '../../../common/EnumStatus';
import { PARCEL_PRICE_STATUS } from '../../../../config/constants';
import LinkToModuleResource from '../../../common/resource/LinkToModuleResource';
import Alert from '../../../common/informative/Alert';

const ParcelPriceBasicInformationResource = ({
  id,
  createDate,
  file,
  status,
  user,
  description,
  parcelPriceScheduleItemList,
}) => {
  const missingPricePerKilogram = parcelPriceScheduleItemList.filter(
    (item) => item.pricePerKilogram === null,
  );

  const missingEnvelopePrice = parcelPriceScheduleItemList.filter(
    (item) => item.envelopePrice === null,
  );

  const missingPricePerKilogramComponent = (
    <>
      <h3>Faltan precios por kilogramo en las siguientes rutas:</h3>
      {missingPricePerKilogram.map((item, index) => (
        <p key={+index}>
          De <strong>{item.sourceName}</strong> a{' '}
          <strong>{item.destinationName}</strong>
        </p>
      ))}
    </>
  );

  const missingEnvelopePriceComponent = (
    <>
      <h3>Faltan precios para sobres en las siguientes rutas:</h3>
      {missingEnvelopePrice.map((item, index) => (
        <p key={+index}>
          De <strong>{item.sourceName}</strong> a{' '}
          <strong>{item.destinationName}</strong>
        </p>
      ))}
    </>
  );

  return (
    <>
      <ResourceProperty label="ID:">{id}</ResourceProperty>
      <ResourceProperty label="Archivo:">
        <LinkToModuleResource
          href={file}
          text={file}
          colorIcon="text-primary"
          isExternalPath
        />
      </ResourceProperty>
      <ResourceProperty label="Estado:">
        <EnumStatus enumObject={PARCEL_PRICE_STATUS} enumValue={status} />
      </ResourceProperty>
      <ResourceProperty label="Descripción:">{description}</ResourceProperty>
      <ResourceProperty label="Creado por:">{user.fullName}</ResourceProperty>
      <ResourceProperty label="Fecha de Creación:">
        {tzNormalizeDate({ date: createDate, format: FULL_DATE_TIME_FORMAT })}
      </ResourceProperty>
      <Row>
        {missingPricePerKilogram.length > 0 && (
          <Col lg={6} md={12}>
            <Alert type="warning" message={missingPricePerKilogramComponent} />
          </Col>
        )}
        {missingEnvelopePrice.length > 0 && (
          <Col lg={6} md={12}>
            <Alert type="warning" message={missingEnvelopePriceComponent} />
          </Col>
        )}
      </Row>
    </>
  );
};

ParcelPriceBasicInformationResource.propTypes = {
  id: PropTypes.number.isRequired,
  createDate: PropTypes.number.isRequired,
  file: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string,
  parcelPriceScheduleItemList: PropTypes.arrayOf(
    PropTypes.shape({
      sourceName: PropTypes.string.isRequired,
      sourceLocationId: PropTypes.number.isRequired,
      destinationName: PropTypes.string.isRequired,
      destinationLocationId: PropTypes.number.isRequired,
      envelopePrice: PropTypes.number,
      pricePerKilogram: PropTypes.number,
    }),
  ).isRequired,
};

ParcelPriceBasicInformationResource.defaultProps = {
  description: '',
};

export default ParcelPriceBasicInformationResource;
