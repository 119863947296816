import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, InputGroupText } from 'reactstrap';
import { Field, reduxForm, Form } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import {
  isRequired,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import CompanySelect from '../../../common/forms/select/CompanySelect';
import { CURRENCY } from '../../../../config/locale';
import FormFooter from '../../../common/forms/FormFooter';
import CustomerSelect from '../../../common/forms/select/CustomerSelect';

const hasHigherNumber = (currentValue, allValues) =>
  Number(currentValue) < Number(allValues.actualBalance)
    ? 'No debe ser menor que el saldo actual'
    : undefined;

export const CargoCorporateCreditForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Nombre" required>
        <Field
          name="name"
          component={TextInput}
          placeholder="Nombre"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Descripción">
        <Field
          name="description"
          component={TextInput}
          type="textarea"
          placeholder="Descripción"
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Clientes">
        <Field name="customerList" component={CustomerSelect} isMulti />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Compañia" required>
        <Field
          name="companyId"
          component={CompanySelect}
          isClearable
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Saldo Inicial" required>
        <Field
          name="initialBalance"
          component={TextInput}
          type="text"
          append={<InputGroupText>{CURRENCY}</InputGroupText>}
          placeholder="Saldo Inicial"
          validate={[validateNumber, hasHigherNumber, isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Saldo Actual">
        <Field
          name="actualBalance"
          component={TextInput}
          type="text"
          append={<InputGroupText>{CURRENCY}</InputGroupText>}
          placeholder="Saldo Actual"
          validate={[validateNumber]}
          disabled
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Días de Crédito" required>
        <Field
          name="creditDays"
          component={TextInput}
          type="text"
          placeholder="Días de Crédito"
          validate={[validateIntegerNumber, isRequired]}
        />
      </FormItem>
    </FormGroup>

    <FormGroup row>
      <FormItem label="Porcentaje de Descuento" required>
        <Field
          name="discountPercentage"
          component={TextInput}
          type="text"
          placeholder="Porcentaje de Descuento"
          validate={[validateNumber]}
        />
      </FormItem>
    </FormGroup>

    <FormGroup row>
      <FormItem label="Estado">
        <Label>
          <Field name="active" component="input" type="checkbox" /> Activo
        </Label>
      </FormItem>
    </FormGroup>
    <FormFooter />
  </Form>
);

CargoCorporateCreditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'CargoCorporateCreditForm',
})(CargoCorporateCreditForm);

export default formComponent;
