const filterMethodCaseInsensitive = (filter, row) => {
  const { id } = filter;
  let { value } = filter;

  let rowValue = row[id];

  if (rowValue !== null || !value) {
    if (typeof rowValue === 'number') rowValue = rowValue.toString();

    if (value && (typeof value === 'object' || typeof value === 'number'))
      value = value.toString();

    // eslint-disable-next-line no-nested-ternary
    return value
      ? value.trim()
        ? String(rowValue.toLowerCase()).includes(value.trim().toLowerCase())
        : true
      : true;
  }

  return false;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  filterMethodCaseInsensitive,
};
