import { toastr } from 'react-redux-toastr';
import { FUEL_CONSUMPTION_REPORT_PER_BUS_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_POST_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_FUEL_CONSUMPTION_REPORT_PER_BUS,
  FLAG_FUEL_CONSUMPTION_REPORT_PER_BUS_ACTIVITY,
  POST_FUEL_CONSUMPTION_REPORT_PER_BUS,
} from '../types';

const flagFuelConsumptionReportPerBusActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_FUEL_CONSUMPTION_REPORT_PER_BUS_ACTIVITY,
    payload: flag,
  });

const postFuelConsumptionReportPerBus =
  async ({ fromDate, toDate, registeredBusId }) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelConsumptionReportPerBusActivity(true));
      const payload = { fromDate, toDate, registeredBusId };
      const url = FUEL_CONSUMPTION_REPORT_PER_BUS_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const fuelConsumption = await response.json();
      dispatch({
        type: POST_FUEL_CONSUMPTION_REPORT_PER_BUS,
        payload: fuelConsumption,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagFuelConsumptionReportPerBusActivity(false));
    }
  };

const clearFuelConsumptionReportPerBus = () => (dispatch) =>
  dispatch({
    type: CLEAR_FUEL_CONSUMPTION_REPORT_PER_BUS,
  });

export {
  flagFuelConsumptionReportPerBusActivity,
  postFuelConsumptionReportPerBus,
  clearFuelConsumptionReportPerBus,
};
