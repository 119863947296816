import React from 'react';
import contractorBasicInformationPropTypes from '../proptypes/ContractorPropTypes';
import ResourceProperty from '../../../../common/resource/ResourceProperty';

const ContractorBasicInformationResource = ({
  customer,
  business: { name: businessName, businessTaxId },
  company: {
    name: companyName,
    business: { businessTaxId: companyTaxId },
  },
}) => (
  <>
    <ResourceProperty label="Contratista:">
      {customer ? customer.fullName : ''}
      {' - '}
      {customer ? customer.identificationType.name : ''}{' '}
      {customer ? customer.idDocumentNumber : ''}
    </ResourceProperty>
    <ResourceProperty label="Empresa:">
      {businessName}
      {' - RUC '}
      {businessTaxId}
    </ResourceProperty>
    <ResourceProperty label="Compañía:">
      {companyName}
      {' - RUC '}
      {companyTaxId}
    </ResourceProperty>
  </>
);

ContractorBasicInformationResource.propTypes =
  contractorBasicInformationPropTypes;

ContractorBasicInformationResource.defaultProps = {
  customer: null,
};

export default ContractorBasicInformationResource;
