import React, { useState } from 'react';
import { FormGroup } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { Field } from 'redux-form';
import { isRequired } from '../../../utils/validators';
import { TRANSACTION_TYPE_CREDIT_CARD } from '../../../config/constants';
import FormItem from './FormItem';
import TextInput from './input/TextInput';
import PaymentMethodSelect from './select/PaymentMethodSelect';

const PaymentMethodInputGroup = ({
  onShowVoucherCode,
  transactionCodesNotIncluded,
}) => {
  const [showVoucherCode, setShowVoucherCode] = useState(false);

  const handlePaymentMethodChange = (paymentMethod) => {
    setShowVoucherCode(
      paymentMethod.transactionCode &&
        paymentMethod.transactionCode === TRANSACTION_TYPE_CREDIT_CARD,
    );
    if (showVoucherCode) {
      onShowVoucherCode();
    }
  };

  const voucherCode = showVoucherCode ? (
    <FormGroup row>
      <FormItem label="Nro Referencia (POS)" required>
        <Field
          name="voucherCode"
          component={TextInput}
          type="text"
          placeholder="Código de Voucher"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
  ) : null;

  return (
    <>
      <FormGroup row>
        <FormItem label="Método de Pago" required>
          <Field
            name="paymentMethod"
            component={PaymentMethodSelect}
            transactionCodesNotIncluded={transactionCodesNotIncluded}
            onChange={handlePaymentMethodChange}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      {voucherCode}
    </>
  );
};

PaymentMethodInputGroup.propTypes = {
  onShowVoucherCode: PropTypes.func.isRequired,
  transactionCodesNotIncluded: PropTypes.instanceOf(Array),
};

PaymentMethodInputGroup.defaultProps = {
  transactionCodesNotIncluded: [],
};

export default PaymentMethodInputGroup;
