import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Loader from '../../../common/Loader';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import CommonProductForm from './CommonProductForm';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { COMMON_PRODUCT_PATH } from '../../../../config/paths';
import {
  clearCommonProduct,
  getCommonProduct,
  putCommonProduct,
} from '../../../../actions/cargo';

class EditCommonProduct extends Component {
  componentDidMount() {
    const {
      dispatchGetCommonProduct,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetCommonProduct({ id });
  }

  componentWillUnmount() {
    const { dispatchClearCommonProduct } = this.props;
    dispatchClearCommonProduct();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutCommonProduct,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutCommonProduct(id, formValues);
  };

  createInitialValues = (commonProduct) => {
    const commonProductJson = {
      productName: commonProduct.get('productName'),
      productDescription: commonProduct.get('productDescription'),
      weight: commonProduct.get('weight'),
      height: commonProduct.get('height'),
      width: commonProduct.get('width'),
      length: commonProduct.get('length'),
      internalProduct: commonProduct.get('internalProduct'),
      active: commonProduct.get('active'),
      cargoCategory: {
        value: commonProduct.get('cargoCategory').id,
        label: commonProduct.get('cargoCategory').name,
      },
    };

    return commonProductJson;
  };

  render() {
    const { breadcrumbs, commonProduct, loading } = this.props;

    if (loading || commonProduct.isEmpty()) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Editar Producto Frecuente</h1>
          </Col>
        </Row>
        <CommonProductForm
          onSubmit={this.onSubmit}
          initialValues={this.createInitialValues(commonProduct)}
        />
      </Container>
    );
  }
}

EditCommonProduct.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCommonProduct: PropTypes.func.isRequired,
  dispatchClearCommonProduct: PropTypes.func.isRequired,
  dispatchPutCommonProduct: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  commonProduct: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCommonProduct.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Producto Frecuente',
      href: COMMON_PRODUCT_PATH,
    },
    {
      text: 'Ver',
      href: `${COMMON_PRODUCT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  commonProduct: CargoUnit.CommonProduct.getIn(['current', 'content']),
  loading: CargoUnit.CommonProduct.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCommonProduct: getCommonProduct,
  dispatchClearCommonProduct: clearCommonProduct,
  dispatchPutCommonProduct: putCommonProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCommonProduct);
