import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import TextInput from '../../../common/forms/input/TextInput';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FileDetailResource from '../../../common/resource/FileDetailResource';
import FormFooter from '../../../common/forms/FormFooter';
import { getExcelTemplate } from '../../../../actions/cargo/ParcelPrice';

const ParcelPriceForm = ({
  handleSubmit,
  loading,
  onSubmit,
  dispatchTemplate,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const isValidFileType = (file) => {
    const validExtensions = ['.xlsx'];
    const fileName = file.name.toLowerCase();
    return validExtensions.some((ext) => fileName.endsWith(ext));
  };

  const onChangeVoucher = (e) => {
    const file = e.target.files[0];
    if (file && isValidFileType(file)) {
      setSelectedFile(file);
    } else {
      toastr.error('Error', 'Sólo se permiten archivos con extensión .xlsx');
      e.target.value = null;
      setSelectedFile(null);
    }
  };

  const submitForm = (formValues) => {
    if (!selectedFile) {
      toastr.error('Error', 'Debe seleccionar un archivo .xlsx');
      return;
    }

    if (!isValidFileType(selectedFile)) {
      toastr.error(
        'Error',
        'El archivo seleccionado no es un archivo .xlsx válido',
      );
      return;
    }

    const newFormValues = {
      ...formValues,
      file: selectedFile,
    };

    onSubmit(newFormValues);
  };

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            placeholder="Descripción"
            type="textarea"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="">
          <Button type="button" onClick={dispatchTemplate}>
            Descargar Plantilla
          </Button>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <Col sm={3} xs={12}>
          <Label>Tarifa</Label>
        </Col>
        <Col sm={9} xs={12}>
          <Input
            name="voucher"
            type="file"
            accept=".xlsx"
            onChange={onChangeVoucher}
          />
          {selectedFile && <FileDetailResource file={selectedFile} />}
        </Col>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

ParcelPriceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatchTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.ParcelPrice.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'ParcelPriceForm',
})(ParcelPriceForm);

const mapDispatchToProps = {
  dispatchTemplate: getExcelTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
