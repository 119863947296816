import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_WORKSTATIONS,
  GET_WORKSTATIONS,
  CLEAR_WORKSTATIONS,
  GET_WORKSTATION,
  FLAG_WORKSTATION_ACTIVITY,
  CLEAR_WORKSTATION,
} from '../types';
import { WORKSTATION_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { WORKSTATION_PATH } from '../../config/paths';

const flagGettingWorkstations = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_WORKSTATIONS,
    payload: flag,
  });

const getWorkstations = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingWorkstations(true));

    const query = tableFilters;

    const url = `${WORKSTATION_ENDPOINT}?${QueryString.stringify(query)}`;

    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response, null, dispatch);

    const workstations = await response.json();

    dispatch({
      type: GET_WORKSTATIONS,
      payload: workstations,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingWorkstations(false));
  }
};

const clearWorkstations = () => (dispatch) =>
  dispatch({
    type: CLEAR_WORKSTATIONS,
  });

const clearWorkstation = () => (dispatch) =>
  dispatch({
    type: CLEAR_WORKSTATION,
  });

const flagWorkstationActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_WORKSTATION_ACTIVITY,
    payload: flag,
  });

const postWorkstation =
  async ({ name, description, serialNumber, printStationList }) =>
  async (dispatch) => {
    try {
      dispatch(flagWorkstationActivity(true));
      const payload = {
        name,
        description,
        serialNumber,
        printStationList,
      };

      const url = WORKSTATION_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(WORKSTATION_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagWorkstationActivity(false));
    }
  };

const getWorkstation =
  async ({ workstationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagWorkstationActivity(true));

      const url = `${WORKSTATION_ENDPOINT}/${workstationId}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);

      const workstation = await response.json();

      dispatch({
        type: GET_WORKSTATION,
        payload: workstation,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagWorkstationActivity(false));
    }
  };

const putWorkstation =
  async (
    workstationId,
    {
      agencyId,
      name,
      description,
      serialNumber,
      printStationList,
      virtualPrintStationAssignment,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagWorkstationActivity(true));

      const payload = {
        agencyId,
        name,
        description,
        serialNumber,
        printStationList,
        virtualPrintStationAssignment,
        id: workstationId,
      };

      const url = `${WORKSTATION_ENDPOINT}/${workstationId}`;

      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      const workstation = await response.json();

      dispatch(push(`${WORKSTATION_PATH}/${workstation.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagWorkstationActivity(false));
    }
  };

const deleteWorkstation =
  async ({ workstationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagWorkstationActivity(true));

      const url = `${WORKSTATION_ENDPOINT}/${workstationId}`;

      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(WORKSTATION_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagWorkstationActivity(false));
    }
  };

export {
  getWorkstations,
  clearWorkstations,
  clearWorkstation,
  postWorkstation,
  getWorkstation,
  putWorkstation,
  deleteWorkstation,
};
