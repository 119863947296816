// todo: replace with qs
// https://www.npmjs.com/package/qs
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  handleResponseError,
  isErrorResponse,
} from '../../utils/error-handlers';
import { PRICING_PROFILE_REPORT_ENDPOINT } from '../../config/endpoints';
import {
  GET_PRICING_PROFILE_REPORTS,
  FLAG_GETTING_PRICING_PROFILE_REPORTS,
  CLEAR_ERROR_GETTING_PRICING_PROFILE_REPORTS,
  SHOW_ERROR_GETTING_PRICING_PROFILE_REPORTS,
  CLEAR_PRICING_PROFILE_REPORTS,
  CLEAR_ERROR_POSTING_PRICING_PROFILE_REPORT,
  POST_PRICING_PROFILE_REPORT,
  FLAG_POSTING_PRICING_PROFILE_REPORT,
  DOWNLOAD_PRICING_PROFILE_REPORT,
  GET_PRICING_PROFILE_REPORT,
  SHOW_ERROR_GETTING_PRICING_PROFILE_REPORT,
  CLEAR_ERROR_GETTING_PRICING_PROFILE_REPORT,
  FLAG_GETTING_PRICING_PROFILE_REPORT,
  CLEAR_PRICING_PROFILE_REPORT,
} from '../types/index';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from '../../config/constants';
import { getReportsServerEndpoint } from './Report';
import { PRICING_PROFILE_REPORTS_PATH } from '../../config/paths';
import { DEFAULT_POST_CONFIG } from '../../config/rest';

const flagGettingPricingProfileReports = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_PRICING_PROFILE_REPORTS,
  });

const showErrorGettingPricingProfileReports = (error) => (dispatch) =>
  dispatch({
    payload: error,
    type: SHOW_ERROR_GETTING_PRICING_PROFILE_REPORTS,
  });

const clearErrorGettingPricingProfileReports = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_GETTING_PRICING_PROFILE_REPORTS,
  });

const getPricingProfileReports =
  async ({ page = 0, size = DEFAULT_PAGE_SIZE, sort = DEFAULT_SORT } = {}) =>
  async (dispatch, getState) => {
    dispatch(clearErrorGettingPricingProfileReports());
    dispatch(flagGettingPricingProfileReports(true));
    try {
      const reportsServerEndpoint = await getReportsServerEndpoint(
        dispatch,
        getState,
      );
      // make request
      const url = reportsServerEndpoint + PRICING_PROFILE_REPORT_ENDPOINT;
      const query = {
        page,
        size,
        sort,
      };
      const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
        method: 'GET',
        credentials: 'include',
      });
      const serverError = await handleResponseError(response);
      if (serverError) {
        dispatch(flagGettingPricingProfileReports(false));
        return dispatch(showErrorGettingPricingProfileReports(serverError));
      }
      const reports = await response.json();
      dispatch({
        type: GET_PRICING_PROFILE_REPORTS,
        payload: reports,
      });
      return dispatch(flagGettingPricingProfileReports(false));
    } catch (err) {
      console.error(err);
      // lower flag
      dispatch(flagGettingPricingProfileReports(false));
      const errMsg = 'Error obteniendo los reportes de boletos.';
      return dispatch(showErrorGettingPricingProfileReports(errMsg));
    }
  };

const clearPricingProfileReports = () => (dispatch) =>
  dispatch({
    type: CLEAR_PRICING_PROFILE_REPORTS,
  });

const clearErrorPostingPricingProfileReport = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_POSTING_PRICING_PROFILE_REPORT,
  });

const flagPostingPricingProfileReport = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_POSTING_PRICING_PROFILE_REPORT,
    payload: flag,
  });

const postPricingProfileReport =
  async ({ fromDate, toDate, force }) =>
  async (dispatch, getState) => {
    try {
      // raise flag
      dispatch(flagPostingPricingProfileReport(true));
      const reportsServerEndpoint = await getReportsServerEndpoint(
        dispatch,
        getState,
      );
      const query = {
        force,
      };
      const payload = {
        fromDate,
        toDate,
      };
      // make request
      const url = reportsServerEndpoint + PRICING_PROFILE_REPORT_ENDPOINT;
      const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      const report = await response.json();
      dispatch({
        type: POST_PRICING_PROFILE_REPORT,
        payload: report,
      });
      dispatch(flagPostingPricingProfileReport(false));
      // redirect to report
      dispatch(push(`${PRICING_PROFILE_REPORTS_PATH}/${report.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagPostingPricingProfileReport(false));
    }
  };

const flagGettingPricingProfileReport = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_PRICING_PROFILE_REPORT,
  });

const clearErrorGettingPricingProfileReport = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_GETTING_PRICING_PROFILE_REPORT,
  });

const showErrorGettingPricingProfileReport = (error) => (dispatch) =>
  dispatch({
    type: SHOW_ERROR_GETTING_PRICING_PROFILE_REPORT,
    payload: error,
  });

const getPricingProfileReport =
  async ({
    id,
    reportFormat = 'json',
    download = false,
    page = 0,
    size = DEFAULT_PAGE_SIZE,
    sort = DEFAULT_SORT,
  }) =>
  async (dispatch, getState) => {
    dispatch(clearErrorGettingPricingProfileReport());
    dispatch(flagGettingPricingProfileReport(true));
    try {
      const reportsServerEndpoint = await getReportsServerEndpoint(
        dispatch,
        getState,
      );
      // make request
      const url = `${
        reportsServerEndpoint + PRICING_PROFILE_REPORT_ENDPOINT
      }/${id}`;
      const query = {
        reportFormat,
        page,
        size,
        sort,
      };
      const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
        method: 'GET',
        credentials: 'include',
      });
      const serverError = await handleResponseError(response);
      if (serverError) {
        dispatch(flagGettingPricingProfileReport(false));
        return dispatch(showErrorGettingPricingProfileReport(serverError));
      }
      // parse json or text depending on format
      const text = reportFormat === 'csv';
      const report = text ? await response.text() : await response.json();
      if (download) {
        dispatch({
          type: DOWNLOAD_PRICING_PROFILE_REPORT,
          payload: report,
        });
      } else {
        dispatch({
          type: GET_PRICING_PROFILE_REPORT,
          payload: report,
        });
      }
      return dispatch(flagGettingPricingProfileReport(false));
    } catch (err) {
      console.error(err);
      // lower flag
      dispatch(flagGettingPricingProfileReport(false));
      const errMsg = `Error obteniendo el reporte de boletos #${id}.`;
      return dispatch(showErrorGettingPricingProfileReport(errMsg));
    }
  };

const clearPricingProfileReport = () => (dispatch) =>
  dispatch({
    type: CLEAR_PRICING_PROFILE_REPORT,
  });

export {
  getPricingProfileReports,
  clearErrorGettingPricingProfileReports,
  clearPricingProfileReports,
  clearErrorPostingPricingProfileReport,
  postPricingProfileReport,
  getPricingProfileReport,
  clearPricingProfileReport,
  clearErrorGettingPricingProfileReport,
};
