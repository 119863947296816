import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  CLEAR_DRIVER_COMMISSIONS,
  FLAG_GETTING_DRIVER_COMMISSIONS,
  GET_DRIVER_COMMISSIONS,
} from '../types';
import { DRIVER_COMISSION_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';

const flagGettingDriverCommissions = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DRIVER_COMMISSIONS,
    payload: flag,
  });

const clearDriverCommissions = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVER_COMMISSIONS,
  });

const getDriverCommissions = (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDriverCommissions(true));
    const query = tableFilters;
    const url = `${DRIVER_COMISSION_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const commissions = await response.json();
    dispatch({
      type: GET_DRIVER_COMMISSIONS,
      payload: commissions,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingDriverCommissions(false));
  }
};

export { getDriverCommissions, clearDriverCommissions };
