import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import Loader from '../../../common/Loader';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import FormFooter from '../../../common/forms/FormFooter';

export const ParcelServiceTypeForm = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            placeholder="Descripción"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Aumento porcentual (%)" required>
          <Field
            name="increasePercentage"
            component={TextInput}
            placeholder="Aumento porcentual (%)"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

ParcelServiceTypeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.ParcelServiceType.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'ParcelServiceTypeForm',
})(ParcelServiceTypeForm);

export default connect(mapStateToProps)(formComponent);
