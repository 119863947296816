const decodeTokenJWT = (token) => {
  const base64Url = token.split('.')[1]; // Extraer la parte del payload (claims)
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Convertir URL-safe base64 a base64 estándar
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  ); // Decodificar base64 a JSON

  return JSON.parse(jsonPayload); // Convertir JSON a objeto JavaScript
};

export default decodeTokenJWT;
