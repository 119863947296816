import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_BAGGAGES,
  GET_BAGGAGES,
  CLEAR_BAGGAGES,
  FLAG_BAGGAGE_ACTIVITY,
  GET_BAGGAGE,
  CLEAR_BAGGAGE,
  GET_BAGGAGE_EXCESSES,
  FLAG_GETTING_BAGGAGE_EXCESSES,
  CLEAR_BAGGAGE_EXCESSES,
} from '../types/baggage';
import {
  BAGGAGE_BY_ITEM_CORRELATION_ENDPOINT,
  BAGGAGE_BY_ITEM_STATUS_ENDPOINT,
  BAGGAGE_BY_ITEM_TICKET_ITINERARY_ID_ENDPOINT,
  BAGGAGE_BY_ITINERARY_ENDPOINT,
  BAGGAGE_ENDPOINT,
  BAGGAGE_ITEMS_MOVEMENT_ENDPOINT,
  generateExcessBaggageVoidEndpoint,
  generateSalesSessionBaggageExcessEndpoint,
  PRINT_EXCESS_BAGGAGE_ENDPOINT,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { BAGGAGE_PATH } from '../../config/paths';
import { BAGGAGE_ITEM_STATUS } from '../../config/constants';

const flagGettingBaggages = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_BAGGAGES,
    payload: flag,
  });

const getBaggages = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingBaggages(true));
    const query = tableFilters;
    const url = `${BAGGAGE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const baggages = await response.json();
    dispatch({
      type: GET_BAGGAGES,
      payload: baggages,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingBaggages(false));
  }
};

const clearBaggages = () => (dispatch) =>
  dispatch({
    type: CLEAR_BAGGAGES,
  });

const flagBaggageActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_BAGGAGE_ACTIVITY,
    payload: flag,
  });

const getBaggage =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagBaggageActivity(true));
      const url = `${BAGGAGE_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);
      const baggage = await response.json();
      dispatch({
        type: GET_BAGGAGE,
        payload: baggage,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBaggageActivity(false));
    }
  };

const clearBaggage = () => (dispatch) =>
  dispatch({
    type: CLEAR_BAGGAGE,
  });

const postBaggage =
  async ({
    weight,
    totalWeight,
    excessBaggageRequest,
    tickets,
    items,
    comment,
    businessId,
    voucherTypeId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagBaggageActivity(true));

      const payload = {
        weight,
        totalWeight,
        excessBaggageRequest,
        tickets,
        items,
        comment,
        businessId,
        voucherTypeId,
      };

      const url = BAGGAGE_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const baggage = await response.json();
      dispatch(push(`${BAGGAGE_PATH}/${baggage.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBaggageActivity(false));
    }
  };

const putBaggage =
  async (
    baggageId,
    {
      weight,
      totalWeight,
      excessBaggageRequest,
      tickets,
      items,
      comment,
      businessId,
      voucherTypeId,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagBaggageActivity(true));

      const payload = {
        id: baggageId,
        weight,
        totalWeight,
        excessBaggageRequest,
        tickets,
        items,
        comment,
        businessId,
        voucherTypeId,
      };

      const url = `${BAGGAGE_ENDPOINT}/${baggageId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const baggage = await response.json();
      dispatch(push(`${BAGGAGE_PATH}/${baggage.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBaggageActivity(false));
    }
  };

const deleteBaggage =
  async ({ baggageId }) =>
  async (dispatch) => {
    dispatch(flagBaggageActivity(true));
    try {
      const url = `${BAGGAGE_ENDPOINT}/${baggageId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response;
      dispatch(push(`${BAGGAGE_PATH}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBaggageActivity(false));
    }
  };

const postExcessBaggageVoid =
  async (baggageId, excessBaggageId, { baggageRequest, description }) =>
  async (dispatch) => {
    try {
      dispatch(flagBaggageActivity(true));

      const payload = {
        excessBaggageId,
        excessBaggageVoidRequest: { baggageRequest, description },
      };

      const url = generateExcessBaggageVoidEndpoint(excessBaggageId);
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(`${BAGGAGE_PATH}/${baggageId}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBaggageActivity(false));
    }
  };

const getPrintExcessBaggage =
  async ({ excessBaggageId }) =>
  async (dispatch) => {
    try {
      dispatch(flagBaggageActivity(true));
      const url = `${PRINT_EXCESS_BAGGAGE_ENDPOINT}/${excessBaggageId}`;
      const response = await fetch(url, {
        ...DEFAULT_GET_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      const excessBaggage = await response.json();
      return excessBaggage;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagBaggageActivity(false));
    }
  };

const postBaggageItemMovement =
  async (
    { locationId, itineraryId, status, remarks, itemIdList },
    redirectPath,
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagBaggageActivity(true));

      const payload = {
        locationId,
        itineraryId,
        status,
        remarks,
        itemIdList,
      };

      const url = `${BAGGAGE_ITEMS_MOVEMENT_ENDPOINT}`;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(redirectPath));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBaggageActivity(false));
    }
  };

const getBaggageByItemStatus =
  async ({ itemStatus, locationId, query }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingBaggages(true));

      let url = `${BAGGAGE_BY_ITEM_STATUS_ENDPOINT}/${itemStatus}`;
      if (itemStatus !== BAGGAGE_ITEM_STATUS.ON_ITINERARY.value)
        url += `/location/${locationId}`;

      url += `?${QueryString.stringify(query)}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);
      const baggages = await response.json();
      dispatch({
        type: GET_BAGGAGES,
        payload: baggages,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingBaggages(false));
    }
  };

const getBaggageByItinerary =
  async ({ itineraryId, query }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingBaggages(true));
      const url = `${BAGGAGE_BY_ITINERARY_ENDPOINT}/${itineraryId}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);
      const baggages = await response.json();
      dispatch({
        type: GET_BAGGAGES,
        payload: baggages,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingBaggages(false));
    }
  };

const getBaggageByItemCorrelation =
  async ({ searchTerm, query }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingBaggages(true));
      const url = `${BAGGAGE_BY_ITEM_CORRELATION_ENDPOINT}?searchTerm=${searchTerm}&${QueryString.stringify(
        query,
      )}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);
      const baggages = await response.json();
      dispatch({
        type: GET_BAGGAGES,
        payload: baggages,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingBaggages(false));
    }
  };

const getBaggageByTicketItineraryId =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingBaggages(true));
      const url = `${BAGGAGE_BY_ITEM_TICKET_ITINERARY_ID_ENDPOINT}/${itineraryId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);
      const baggages = await response.json();
      dispatch({
        type: GET_BAGGAGES,
        payload: baggages,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingBaggages(false));
    }
  };

const flagGettingBaggageExcesses = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_BAGGAGE_EXCESSES,
    payload: flag,
  });

const clearBaggageExcesses = () => (dispatch) =>
  dispatch({
    type: CLEAR_BAGGAGE_EXCESSES,
  });

const getSalesSessionBaggageExcesses =
  async ({ salesSessionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingBaggageExcesses(true));
      const url = generateSalesSessionBaggageExcessEndpoint(salesSessionId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);
      const excesses = await response.json();
      await dispatch({
        type: GET_BAGGAGE_EXCESSES,
        payload: excesses,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingBaggageExcesses(false));
    }
  };

export {
  flagGettingBaggages,
  getBaggages,
  clearBaggages,
  getBaggage,
  clearBaggage,
  postBaggage,
  putBaggage,
  deleteBaggage,
  postExcessBaggageVoid,
  getPrintExcessBaggage,
  postBaggageItemMovement,
  getBaggageByItemStatus,
  getBaggageByItinerary,
  getBaggageByItemCorrelation,
  getBaggageByTicketItineraryId,
  getSalesSessionBaggageExcesses,
  clearBaggageExcesses,
};
