import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import Modal from '../../../common/modal/Modal';
import ProcessDuplicateForm from './ProcessDuplicateForm';
import { postDuplicateProcess } from '../../../../actions';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

const ProcessDuplicateButton = ({
  processId,
  dispatchPostDuplicateProcess,
}) => {
  const [modalBody, setModalBody] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = (formValues) => {
    closeModal();
    dispatchPostDuplicateProcess(processId, {
      startDate: tzNormalizeDate({
        date: formValues.startDate,
        format: TIMESTAMP_FORMAT,
      }),
    });
  };

  const openModal = () => {
    setShowModal(true);
    setModalBody(<ProcessDuplicateForm onSubmit={onSubmit} />);
  };

  return (
    <>
      <Button color="info" onClick={openModal}>
        Duplicar proceso
      </Button>
      <Modal
        title="Duplicar proceso"
        body={modalBody}
        show={showModal}
        onClickClose={closeModal}
      />
    </>
  );
};

ProcessDuplicateButton.propTypes = {
  processId: PropTypes.number.isRequired,
  dispatchPostDuplicateProcess: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostDuplicateProcess: postDuplicateProcess,
};

export default connect(null, mapDispatchToProps)(ProcessDuplicateButton);
