import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearParcelPriceConfiguration,
  getParcelPriceConfiguration,
} from '../../../../actions/cargo/ParcelPriceConfiguration';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { PARCEL_PRICE_CONFIGURATION_PATH } from '../../../../config/paths';
import ParcelPriceConfigurationBasicInformationResource from './ParcelPriceConfigurationBasicInformationResource';
import Content from '../../../layout/Content';
import ParcelPriceConfigurationToolbar from './ParcelPriceConfigurationToolbar';

const ParcelPriceConfiguration = ({
  breadcrumbs,
  loading,
  match: {
    params: { id: parcelPriceConfigurationId },
  },
  dispatchGetParcelPriceConfiguration,
  dispatchClearParcelPriceConfigurations,
  parcelPriceConfiguration,
}) => {
  useLayoutEffect(() => {
    dispatchGetParcelPriceConfiguration({ parcelPriceConfigurationId });
    return () => dispatchClearParcelPriceConfigurations();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (parcelPriceConfiguration.isEmpty())
    content = <NoDataResource returnPage={PARCEL_PRICE_CONFIGURATION_PATH} />;
  else {
    const parcelPriceConfigurationJson = parcelPriceConfiguration.toJS();
    toolbar = (
      <ParcelPriceConfigurationToolbar
        parcelPriceConfigurationId={parcelPriceConfigurationJson.id}
      />
    );
    content = (
      <ParcelPriceConfigurationBasicInformationResource
        {...parcelPriceConfigurationJson}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Configuración de Tarifas"
      content={content}
    />
  );
};

ParcelPriceConfiguration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  dispatchGetParcelPriceConfiguration: PropTypes.func.isRequired,
  dispatchClearParcelPriceConfigurations: PropTypes.func.isRequired,
  parcelPriceConfiguration: PropTypes.instanceOf(Immutable.Map).isRequired,
};

ParcelPriceConfiguration.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchGetParcelPriceConfiguration: getParcelPriceConfiguration,
  dispatchClearParcelPriceConfigurations: clearParcelPriceConfiguration,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Configuración de Tarifas',
      href: PARCEL_PRICE_CONFIGURATION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  parcelPriceConfiguration: CargoUnit.ParcelPriceConfiguration.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.ParcelPriceConfiguration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParcelPriceConfiguration);
