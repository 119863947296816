import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  clearPaymentMethods,
  getPaymentMethods,
  getPaymentMethodsPerSecurityProfile,
} from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';

class PaymentMethodSelect extends Component {
  componentDidMount() {
    const {
      dispatchGetAllPaymentMethods,
      dispatchGetPaymentMethods,
      allPaymentMethods,
    } = this.props;
    if (allPaymentMethods) {
      dispatchGetAllPaymentMethods();
    } else {
      dispatchGetPaymentMethods();
    }
  }

  componentWillUnmount() {
    const { dispatchClearPaymentMethods } = this.props;
    dispatchClearPaymentMethods();
  }

  render() {
    const {
      loading,
      options,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      placeholder,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={options}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        placeholder={placeholder}
        {...rest}
      />
    );
  }
}

PaymentMethodSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetPaymentMethods: PropTypes.func.isRequired,
  dispatchClearPaymentMethods: PropTypes.func.isRequired,
  dispatchGetAllPaymentMethods: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  allPaymentMethods: PropTypes.bool,
  transactionCodesNotIncluded: PropTypes.instanceOf(Array),
};

PaymentMethodSelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
  transactionCodesNotIncluded: [],
  allPaymentMethods: false,
};

const mapStateToProps = (
  { AccountingUnit },
  { transactionCodesNotIncluded },
) => ({
  options: AccountingUnit.PaymentMethod.getIn(['all', 'content', 'content'])
    .map((paymentMethod) => ({
      value: paymentMethod.id,
      label: paymentMethod.name,
      transactionCode: paymentMethod.transactionCode,
    }))
    .filter((paymentMethod) => {
      if (transactionCodesNotIncluded.length === 0) {
        return true;
      }
      return !transactionCodesNotIncluded.includes(
        paymentMethod.transactionCode,
      );
    }),
  loading: AccountingUnit.PaymentMethod.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetPaymentMethods: getPaymentMethodsPerSecurityProfile,
  dispatchGetAllPaymentMethods: getPaymentMethods,
  dispatchClearPaymentMethods: clearPaymentMethods,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMethodSelect);
