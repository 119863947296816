export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:8080';
export const FACTURADOR_BASE_URL =
  process.env.REACT_APP_BILL_PROCESS_URL || 'http://localhost:8099';
export const HOST = process.env.REACT_APP_HOST || 'http://localhost:3000';
export const BASE_SECURE_ENDPOINT = '/secure/api/v1';
export const BASE_SECURE_ENDPOINT_V2 = '/secure/api/v2';
export const BASE_PUBLIC_ENDPOINT = '/public/api/v1';
export const BASE_SECURE_URL = BASE_URL + BASE_SECURE_ENDPOINT;
export const BASE_SECURE_URL_V2 = BASE_URL + BASE_SECURE_ENDPOINT_V2;
export const BASE_PUBLIC_URL = BASE_URL + BASE_PUBLIC_ENDPOINT;

export const ENDPOINT_CONFIGURATION_ENDPOINT = '/config/endpoint-configuration';

export const SELF_ENDPOINT = `${BASE_SECURE_URL}/user/self`;

// auth unit
// note auth endpoints are relative because the auth server base url changes
export const SIGN_IN_ENDPOINT = '/oauth/token';
export const SIGN_OUT_ENDPOINT = '/logout';
export const REGISTER_ENDPOINT = `${BASE_SECURE_URL}/chinalco`;

// report unit
// note report endpoints are relative because the report server base url changes
export const OCCUPANCY_REPORT_ENDPOINT = `${BASE_SECURE_ENDPOINT}/occupancy-report`;
export const TICKET_REPORT_ENDPOINT = `${BASE_SECURE_ENDPOINT}/ticket-report`;
export const NOT_BOARDED_TICKET_REPORT_ENDPOINT = `${BASE_SECURE_ENDPOINT}/not-boarded-ticket-report`;
export const ITINERARY_REPORT_ENDPOINT = `${BASE_SECURE_ENDPOINT}/itinerary-report`;
export const ACCOUNTING_REPORT_ENDPOINT = `${BASE_SECURE_ENDPOINT}/accounting-report`;
export const DISCOUNT_CODE_REPORT_ENDPOINT = `${BASE_SECURE_ENDPOINT}/discount-code-report`;
export const CALL_CENTER_REPORT_ENDPOINT = `${BASE_SECURE_ENDPOINT}/call-center-report`;
export const EXPENSE_REPORT_ENDPOINT = `${BASE_SECURE_ENDPOINT}/expense-report`;
export const PRICING_PROFILE_REPORT_ENDPOINT = `${BASE_SECURE_ENDPOINT}/pricing-profile-report`;

// bus unit
export const REGISTERED_BUS_ENDPOINT = `${BASE_SECURE_URL}/registered-bus`;
export const SERVICE_TYPE_ENDPOINT = `${BASE_SECURE_URL}/service-type`;
export const CHASSIS_BODYWORK_ENDPOINT = `${BASE_SECURE_URL}/chassis-bodywork`;
export const PRICING_PROFILE_ENDPOINT = `${BASE_SECURE_URL}/pricing-profile`;
export const SEAT_MAP_ENDPOINT = `${BASE_SECURE_URL}/seat-map`;

// route unit
export const ROUTE_ENDPOINT = `${BASE_SECURE_URL}/route`;
export const SEGMENT_ENDPOINT = `${BASE_SECURE_URL}/segment`;
export const TOLL_ENDPOINT = `${BASE_SECURE_URL}/toll`;
export const CIRCUIT_ENDPOINT = `${BASE_SECURE_URL}/circuit`;
export const FUEL_STATION_ENDPOINT = `${BASE_SECURE_URL}/fuel-station`;
export const ITINERARY_GROUP_ENDPOINT = `${BASE_SECURE_URL}/circuit-group`;
export const generateFuelReportPerItineraryGroupEndpoint = (itineraryGroupId) =>
  `${ITINERARY_GROUP_ENDPOINT}/${itineraryGroupId}/generate-report/`;
export const COMPANY_ROUTE_ENDPOINT = `${BASE_SECURE_URL}/company-route`;

// traffic unit
export const DRIVER_ENDPOINT = `${BASE_SECURE_URL}/driver`;
export const generateDriverTripsEndpoint = (driverId) =>
  `${DRIVER_ENDPOINT}/${driverId}/trips`;
export const DRIVER_TYPE_ENDPOINT = `${BASE_SECURE_URL}/driver-types`;
export const CABIN_CREW_ENDPOINT = `${BASE_SECURE_URL}/cabin-crew`;
export const CREW_STATUS_ENDPOINT = `${BASE_SECURE_URL}/crew-status`;
export const DRIVER_DOCUMENT_TYPE_ENDPOINT = `${BASE_SECURE_URL}/driver-document-type`;
export const DRIVER_CONTRACT_TYPE_ENDPOINT = `${BASE_SECURE_URL}/driver-contract-type`;
export const DRIVER_BUS_ASSIGNMENT_ENDPOINT = `${BASE_SECURE_URL_V2}/driver-bus-assignment`;
export const DRIVER_BUS_UNASSIGN_ENDPOINT = `${BASE_SECURE_URL}/driver-bus-assignment/unassign`;
export const DRIVER_BUS_ASSIGN_ENDPOINT = `${BASE_SECURE_URL}/driver-bus-assignment/assign`;
export const EXPECTED_FUEL_CONSUMPTION_ENDPOINT = `${BASE_SECURE_URL}/expected-fuel-consumption`;
export const EXPECTED_FUEL_CONSUMPTION_BY_CIRCUIT_ENDPOINT = `${BASE_SECURE_URL}/expected-fuel-consumption-by-circuit`;
export const EXPECTED_FUEL_CONSUMPTION_BY_ROUTE_ENDPOINT = `${BASE_SECURE_URL}/expected-fuel-consumption-by-route`;
export const BUS_FUEL_GROUP_ENDPOINT = `${BASE_SECURE_URL}/bus-fuel-group`;
export const FUEL_PROFILE_ENDPOINT = `${BASE_SECURE_URL}/fuel-profile`;
export const generateEnableDisableFuelProfileEndpoint = (fuelProfileId) =>
  `${FUEL_PROFILE_ENDPOINT}/${fuelProfileId}/active`;
export const EXTRAORDINARY_MOVEMENT_CONTROL_ENDPOINT = `${BASE_SECURE_URL}/extraordinary-control-module-record`;
export const FUEL_VOUCHER_ENDPOINT = `${BASE_SECURE_URL}/fuel-voucher`;
export const generateMarkFuelVoucherAsInactiveEndpoint = (fuelVoucherId) =>
  `${FUEL_VOUCHER_ENDPOINT}/${fuelVoucherId}`;
export const EXTRAORDINARY_FUEL_VOUCHER_ENDPOINT = `${BASE_SECURE_URL}/extraordinary-fuel-voucher`;
export const FUEL_VOUCHER_CURRENT_ACTIVE_ENDPOINT = `${FUEL_VOUCHER_ENDPOINT}/current-active`;
export const PURCHASE_ORDER_ENDPOINT = `${BASE_SECURE_URL}/purchase-order`;
export const generatePurchaseOrderEndpoint = (purchaseOrderId) =>
  `${BASE_SECURE_URL}/delete-purchase-order/${purchaseOrderId}`;
export const generateChangeStatusPurchaseOrderEndpoint = (
  purchaseOrderId = ':purchaseOrderId',
) => `${BASE_SECURE_URL}/purchase-order/${purchaseOrderId}/change-status`;
export const BASE_FUEL_REFILL_ENDPOINT = `${BASE_SECURE_URL}/base-fuel-refill`;
export const generateIsCircuitExtraordinaryEndpoint = (registeredBusId) =>
  `${BASE_FUEL_REFILL_ENDPOINT}/${registeredBusId}/extraordinary-checker`;
export const FUEL_CONSUMPTION_REPORT_PER_BUS_ENDPOINT = `${BASE_SECURE_URL}/bus-fuel-consumption-report`;
export const FUEL_CONSUMPTION_REPORT_PER_DRIVER_ENDPOINT = `${BASE_SECURE_URL}/driver-fuel-consumption-report`;
export const EXTRA_FUEL_CONSUMPTION_ENDPOINT = `${BASE_SECURE_URL}/itinerary-extra-fuel-consumption`;

// locations
export const LOCATION_ENDPOINT = `${BASE_SECURE_URL}/location`;
export const COUNTRY_ENDPOINT = `${BASE_SECURE_URL}/country`;
export const ADDRESS_ENDPOINT = `${BASE_SECURE_URL}/address`;
export const CITY_ENDPOINT = `${BASE_SECURE_URL}/city`;
export const DISTRICT_ENDPOINT = `${BASE_SECURE_URL}/district`;
export const GEOGRAPHIC_ZONE_ENDPOINT = `${BASE_SECURE_URL}/geographic-zone`;
export const CARGO_LOCATION_GROUP_ENDPOINT = `${BASE_SECURE_URL}/location-group`;

export const PARCEL_PRICE_ENDPOINT = `${BASE_SECURE_URL}/parcel-price-schedule`;
export const PARCEL_SERVICE_TYPE_ENDPOINT = `${BASE_SECURE_URL}/parcel-service-type`;
export const PARCEL_PRICE_CONFIGURATION_ENDPOINT = `${BASE_SECURE_URL}/parcel-price-configuration`;

// paymentMethod
export const PAYMENT_METHOD_ENDPOINT = `${BASE_SECURE_URL}/payment-method`;
export const PAYMENT_METHOD_SECURITY_PROFILE_ENDPOINT = `${BASE_SECURE_URL}/payment-method/user-profile`;

// voucherType
export const VOUCHER_TYPE_ENDPOINT = `${BASE_SECURE_URL}/voucher-type`;

// USER UNIT
// user
export const USER_ENDPOINT = `${BASE_SECURE_URL}/user`;
export const USER_LOCALE_ENDPOINT = `${BASE_SECURE_URL}/locale`;
export const USER_SECURITY_PROFILE_ENDPOINT = `${BASE_SECURE_URL}/security-profile`;
export const EXPENSE_AUTHORIZER_USER_ENDPOINT = `${BASE_SECURE_URL}/expense-authorizer-user`;
export const generateUserAgenciesEndpoint = (userId) =>
  `${USER_ENDPOINT}/${userId}/agency`;
// customer
export const CUSTOMER_ENDPOINT = `${BASE_SECURE_URL}/customer`;
export const CUSTOMER_ENDPOINT_V2 = `${BASE_SECURE_URL_V2}/customer`;
export const CUSTOMER_EMAILS_ENDPOINT = `${BASE_SECURE_URL}/customer-email-address`;
export const CUSTOMER_DELIVERY_ADDRESSES_ENDPOINT = `${BASE_SECURE_URL}/customer-delivery-address`;
// business
export const BUSINESS_ENDPOINT = `${BASE_SECURE_URL}/business`;

// CONTRACT UNIT
export const COMPANY_ENDPOINT = `${BASE_SECURE_URL}/company`;
export const COMPANY_TICKET_ENDPOINT = `${BASE_SECURE_URL}/company-ticket`;
export const COMPANY_TICKET_ENDPOINT_V2 = `${BASE_SECURE_URL_V2}/company-ticket`;
export const COMPANY_CREDIT_NOTE_ENDPOINT = `${BASE_SECURE_URL}/company-credit-note`;
export const COMPANY_USER_CONFIGURATION_ENDPOINT = `${BASE_SECURE_URL}/company-user-configuration`;
export const PRIVATE_SERVICE_ENDPOINT = `${BASE_SECURE_URL}/contract`;
export const PAYMENT_ENDPOINT = `${BASE_SECURE_URL}/payment`;
export const generateApprovePayment = (paymentId = ':paymentId') =>
  `${PAYMENT_ENDPOINT}/${paymentId}/approve`;
export const generateRejectPayment = (paymentId = ':paymentId') =>
  `${PAYMENT_ENDPOINT}/${paymentId}/reject`;
export const CONTRACTOR_ENDPOINT = `${BASE_SECURE_URL}/contractor`;

// identificationType
export const IDENTIFICATION_TYPE_ENDPOINT = `${BASE_SECURE_URL}/identification-type`;

// reservation
export const RESERVATION_ENDPOINT = `${BASE_SECURE_URL}/reservation`;

// reserve seat
export const RESERVE_SEAT_ENDPOINT = `${BASE_SECURE_URL}/reservation/reserve-seat`;
export const SELF_SERVICE_RESERVE_SEAT_ENDPOINT = `${BASE_SECURE_URL}/reservation/self-service/reserve-seat`;

// customerOrder
export const CUSTOMER_ORDER_ENDPOINT = `${BASE_SECURE_URL}/customer-order`;

// booking unit
export const PRINTED_TICKET_ENDPOINT = `${BASE_SECURE_URL}/printed-ticket`;

// human unit
export const DRIVER_COMISSION_ENDPOINT = `${BASE_SECURE_URL}/driver-commission`;
export const CABIN_CREW_COMISSION_ENDPOINT = `${BASE_SECURE_URL}/cabin-crew-commission`;

// itinerary unit
export const ITINERARY_SCHEDULE_ENDPOINT = `${BASE_SECURE_URL}/itinerary-schedule`;
export const ITINERARY_ENDPOINT = `${BASE_SECURE_URL}/itinerary`;
export const ITINERARY_ENDPOINT_V2 = `${BASE_SECURE_URL_V2}/itinerary`;
export const UPDATE_ACTIVE_STATUS_OF_ITINERARIES_ENDPOINT = `${ITINERARY_ENDPOINT}/update-active-status`;
export const ITINERARY_LIST_ENDPOINT = `${BASE_SECURE_URL}/itinerary-list`;
export const ITINERARY_FOR_COUNTER_ENDPOINT = `${BASE_SECURE_URL}/itinerary-for-counter`;
export const ITINERARY_BETWEEN_DATES_ENDPOINT = `${BASE_SECURE_URL}/itinerary-for-counter-date-between`;
export const ASSIGN_BUSSES_TO_ITINERARY_ENDPOINT = `${ITINERARY_ENDPOINT}/assign-busses`;
export const generateGetItineraryForCounterEndpoint = (
  itineraryId = ':itineraryId',
) => `${ITINERARY_FOR_COUNTER_ENDPOINT}/${itineraryId}`;
export const ITINERARIES_BY_TEMPLATE_ENDPOINT = `${BASE_SECURE_URL}/itinerary-for-counter/itinerary-schedule`;
export const generateItineraryTripStatusEndpoint = (itineraryId) =>
  `${ITINERARY_FOR_COUNTER_ENDPOINT}/${itineraryId}/trip-status`;
export const SEATING_CHART_ENDPOINT = `${BASE_SECURE_URL}/seating-chart`;
export const MANIFEST_ENDPOINT = `${BASE_SECURE_URL}/manifest`;
export const generateGetManifestEndpoint = (itineraryId = ':itineraryId') =>
  `${MANIFEST_ENDPOINT}?itineraryId=${itineraryId}`;
export const BAGGAGE_MANIFEST_ENDPOINT = `${BASE_SECURE_URL}/baggage-manifest`;
export const PRINT_FUEL_VOUCHER_ENDPOINT = `${BASE_SECURE_URL}/print-fuel-voucher`;
export const PREVIEW_FUEL_VOUCHER_ENDPOINT = `${BASE_SECURE_URL}/temporal-fuel-voucher`;
export const generateGetPreviewFuelVoucherEndpoint = (
  itineraryId = ':itineraryId',
) => `${PREVIEW_FUEL_VOUCHER_ENDPOINT}/${itineraryId}`;
export const DELAY_REASON_ENDPOINT = `${BASE_SECURE_URL}/delay-reason`;
export const ITINERARY_QUALITY_ENDPOINT = `${BASE_SECURE_URL}/itinerary-quality`;
export const generateGetItineraryQualityEndpoint = (
  itineraryId = ':itineraryId',
) => `${ITINERARY_QUALITY_ENDPOINT}/${itineraryId}`;
export const ITINERARY_SEARCH_OMISSION_ENDPOINT = `${BASE_SECURE_URL}/itinerary-search-omission`;
export const LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION_ENDPOINT = `${BASE_SECURE_URL}/location-for-itinerary-search-omission`;
export const generateListDriversForItineraryEndpoint = (itineraryId) =>
  `${ITINERARY_ENDPOINT}/${itineraryId}/driver`;
export const generatePassengerFlowForItineraryEndpoint = (itineraryId) =>
  `${ITINERARY_ENDPOINT}/${itineraryId}/passenger-flow`;
export const SERVICE_MERGE_ENDPOINT = `${BASE_SECURE_URL}/service-merge`;
export const generateItinerarySearchResultEndpoint = (itineraryId) =>
  `${SERVICE_MERGE_ENDPOINT}/${itineraryId}/itinerary-search-result`;
export const generatePrintedMergedTicketsEndpoint = (serviceMergeId) =>
  `${SERVICE_MERGE_ENDPOINT}/${serviceMergeId}/print-tickets`;
export const generateCurrentUserSpotInItineraryEndpoint = (itineraryId) =>
  `${ITINERARY_ENDPOINT}/${itineraryId}/current-user-spot-in-itinerary`;
export const ITINERARY_TYPE_ENDPOINT = `${BASE_SECURE_URL}/itinerary-type`;
export const TRIP_STATUS_ENDPOINT = `${BASE_SECURE_URL}/trip-status`;
export const generateFreeDriversEndpoint = (itineraryId) =>
  `${DRIVER_ENDPOINT}/${itineraryId}/free`;
export const generateFreeCabinCrewsEndpoint = (itineraryId) =>
  `${CABIN_CREW_ENDPOINT}/${itineraryId}/free`;
export const generateNewTripEndpoint = (itineraryId) =>
  `${ITINERARY_ENDPOINT}/${itineraryId}/trip`;
export const EXTRAORDINARY_MOVEMENT_ENDPOINT = `${BASE_SECURE_URL}/extraordinary-movement`;
export const RESCUE_ITINERARY_ENDPOINT = `${EXTRAORDINARY_MOVEMENT_ENDPOINT}/rescue-itinerary`;
export const KIDS_ON_ITINERARY_ENDPOINT = `${BASE_SECURE_URL}/kids-on-itinerary`;
export const generateNotFuelReportedItineraries = (registeredBusId) =>
  `${REGISTERED_BUS_ENDPOINT}/${registeredBusId}/not-fuel-reported-itineraries`;
export const ITINERARY_SALES_CONTROL_ENDPOINT = `${ITINERARY_ENDPOINT}/itinerary-sales-control`;
export const generateItinerarySalesControlDetailEndpoint = (itineraryId) =>
  `${ITINERARY_ENDPOINT}/${itineraryId}/tickets-for-sales-control`;
export const LIVE_ITINERARY_SALES_CONTROL_ENDPOINT = `${ITINERARY_ENDPOINT_V2}/itinerary-sales-control`;
export const generateItineraryBoardEndpoint = (itineraryId) =>
  `${ITINERARY_ENDPOINT}/${itineraryId}/actions/board`;
export const COMPANY_ITINERARY_ENDPOINT = `${BASE_SECURE_URL}/company-itinerary`;
export const SHIFT_ENDPOINT = `${BASE_SECURE_URL}/chinalco-shifts`;
export const COMPANY_ITINERARY_DEFAULT_FIELDS = `${BASE_SECURE_URL}/company-itinerary-default-fields`;
export const TRIP_REQUEST_ENDPOINT = `${BASE_SECURE_URL}/trip-request`;
export const generateApproveTripRequestEndpoint = (
  tripRequestId = ':tripRequestId',
) => `${BASE_SECURE_URL}/trip-request/${tripRequestId}/approve`;
export const generateRejectTripRequestEndpoint = (
  tripRequestId = ':tripRequestId',
) => `${BASE_SECURE_URL}/trip-request/${tripRequestId}/reject`;
export const generatePersonnelForItineraryEndpoint = (
  itineraryId = ':itineraryId',
) => `${ITINERARY_ENDPOINT}/${itineraryId}/personnel`;
export const PROCESS_ENDPOINT = `${BASE_SECURE_URL}/process`;
export const ITINERARIES_BY_PROCESS_ENDPOINT = `${BASE_SECURE_URL}/itinerary-for-counter/process`;

// postpaid booking
export const POSTPAID_BOOKING_PRETRANSACTION_ENDPOINT = `${BASE_SECURE_URL}/postpaid-pre-transaction`;
export const POSTPAID_BOOKING_ENDPOINT = `${BASE_SECURE_URL}/postpaid-booking`;
export const generateApprovePostpaidBookingEndpoint = (
  customerOrderId = ':customerOrderId',
) => `${BASE_SECURE_URL}/postpaid-booking/${customerOrderId}/approve`;
export const generateRejectPostpaidBookingEndpoint = (
  customerOrderId = ':customerOrderId',
) => `${BASE_SECURE_URL}/postpaid-booking/${customerOrderId}/reject`;
export const generateAddVoucherToPostpaidBookingEndpoint = (customerOrderId) =>
  `${BASE_SECURE_URL}/postpaid-booking/${customerOrderId}/add-voucher`;
export const PAGOEFECTIVO_CIP_ENDPOINT = `${BASE_SECURE_URL}/pago-efectivo/cip`;

// ticket
export const TICKET_ENDPOINT = `${BASE_SECURE_URL}/ticket`;
export const TICKET_ENDPOINT_V2 = `${BASE_SECURE_URL_V2}/ticket`;
export const TICKET_CUSTOMER_ENDPOINT = `${BASE_SECURE_URL}/customer-order/ticket`;
export const generateTicketPostponeEndpoint = (
  ticketId = ':ticketId',
  url = '/ticket-postpone/',
) => `${BASE_SECURE_URL}${url}${ticketId}`;
export const generateTicketChangePassengerEndpoint = (
  ticketId = ':ticketId',
  url = '/ticket-customer-change/',
) => `${BASE_SECURE_URL}${url}${ticketId}`;
export const generateSendTicketByEmailEndpoint = (ticketId) =>
  `${BASE_SECURE_URL}/pdf-ticket/${ticketId}`;
export const TICKET_FIXED_PRICE_ENDPOINT = `${BASE_SECURE_URL}/ticket-fixed-price`;
export const COMPANY_SEND_TICKET_BY_EMAIL_ENDPOINT = `${BASE_SECURE_URL}/pdf-ticket-company`;
export const generateTicketChangeVoucherTypeEndpoint = (
  ticketId = ':ticketId',
  url = '/actions/change-voucher-type',
) => `${BASE_SECURE_URL}/ticket/${ticketId}${url}`;
export const generateTicketRefund = (
  ticketId = ':ticketId',
  url = '/actions/refund',
) => `${BASE_SECURE_URL}/ticket/${ticketId}${url}`;
export const generatePostVoidTicket = (ticketId = ':ticketId') =>
  `${TICKET_ENDPOINT}/${ticketId}/void`;
export const TICKET_FOR_MASSIVE_POSTPONE_ENDPOINT = `${BASE_SECURE_URL}/ticket-for-postpone-process`;
export const TICKET_POSTPONE_PROCESS_ENDPOINT = `${BASE_SECURE_URL}/ticket-postpone-process`;
export const TICKET_POSTPONE_MODAL_ENDPOINT = `${BASE_SECURE_URL}/ticket-postpone-modal`;
export const TICKET_CUSTOMER_CHANGE_MODAL_ENDPOINT = `${BASE_SECURE_URL}/ticket-customer-change-modal`;
export const TICKET_CHANGE_VOUCHER_TYPE_MODAL_ENDPOINT = `${BASE_SECURE_URL}/ticket-change-voucher-type-modal`;
export const TICKET_REFUND_MODAL_ENDPOINT = `${BASE_SECURE_URL}/ticket-refund-modal`;

// creditNote
export const CREDIT_NOTE_BY_TICKET_ENDPOINT = `${BASE_SECURE_URL}/credit-note-search`;
export const VALIDATE_CREDIT_NOTE_ENDPOINT = `${BASE_SECURE_URL}/credit-note-validate`;

// public endpoints
export const PUBLIC_CITIES_ENDPOINT = `${BASE_SECURE_URL}/city-list`;
export const COMPANY_CITIES_ENDPOINT = `${BASE_SECURE_URL}/company-city`;

// sales session
export const SALES_SESSION_ENDPOINT = `${BASE_SECURE_URL}/sales-session`;
export const generateSalesSessionDownloadEndpoint = (salesSessionId) =>
  `${SALES_SESSION_ENDPOINT}/${salesSessionId}/ticket?download=true`;
export const SALES_SESSION_START_ENDPOINT = `${BASE_SECURE_URL_V2}/sales-session/actions/start`;
export const generateSalesSessionEndpoint = (salesSessionId) =>
  `${BASE_SECURE_URL}/sales-session/${salesSessionId}`;
export const SALES_SESSION_DETAIL_ENDPOINT = `${BASE_SECURE_URL}/sales-session-detail`;
export const generateSalesSessionDetailsEndpoint = (salesSessionId) =>
  `${SALES_SESSION_DETAIL_ENDPOINT}/${salesSessionId}`;
export const generateSalesSessionTicketsEnpoint = (salesSessionId) =>
  `${BASE_SECURE_URL}/sales-session/${salesSessionId}/ticket`;
export const generateSalesSessionIncomeEnpoint = (salesSessionId) =>
  `${BASE_SECURE_URL}/sales-session/${salesSessionId}/general-income`;
export const generateSalesSessionExpenseEnpoint = (salesSessionId) =>
  `${BASE_SECURE_URL}/sales-session/${salesSessionId}/expense`;
export const generateSalesSessionBaggageExcessEndpoint = (salesSessionId) =>
  `${BASE_SECURE_URL}/sales-session/${salesSessionId}/excess-baggage`;
export const generateSalesSessionParcelsEnpoint = (salesSessionId) =>
  `${BASE_SECURE_URL}/sales-session/${salesSessionId}/parcel`;
export const generateSalesSessionOtherIncomesParcelsEnpoint = (
  salesSessionId,
) => `${BASE_SECURE_URL}/sales-session/${salesSessionId}/other-incomes-parcel`;
export const generateSalesSessionEndEndPoint = (salesSessionId) =>
  `${BASE_SECURE_URL_V2}/sales-session/actions/end/${salesSessionId}`;
export const LIQUIDATION_SESSION_ENDPOINT = `${BASE_SECURE_URL}/daily-liquidation-session`;
export const LIQUIDATION_SESSION_DETAIL_ENDPOINT = `${BASE_SECURE_URL}/daily-liquidation-session-detail`;
export const LIQUIDATION_SESSION_ENDPOINT_V2 = `${BASE_SECURE_URL_V2}/daily-liquidation-session`;
export const generateLiquidationSessionByAgencyEndpoint = (agencyId) =>
  `${BASE_SECURE_URL}/agency/${agencyId}/daily-liquidation-session-detail`;
export const UPLOAD_DOCUMENT_WITH_DISCOUNT_ENDPOINT = `${BASE_SECURE_URL}/upload-file/document-with-discount`;
export const DISCOUNT_CODE_DOCUMENT_WITH_DISCOUNT_ENDPOINT = `${BASE_SECURE_URL}/discount-code-document-with-discount`;
export const generateExpenseStatusEndpoint = (expenseStatus) =>
  `${BASE_SECURE_URL}/expense/expense-status/${expenseStatus}`;
export const generateChangeExpenseStatusEndpoint = (expenseId, expenseStatus) =>
  `${BASE_SECURE_URL}/expense/${expenseId}/expense-status/${expenseStatus}`;
export const generateExpenseStatusRemarkEndpoint = (expenseId) =>
  `${BASE_SECURE_URL}/expense/${expenseId}/expense-status-remark`;

// liquidationDifferenceReason
export const LIQUIDATION_DIFFERENCE_REASON_ENDPONT = `${BASE_SECURE_URL}/liquidation-difference-reason`;

// deposit
export const DEPOSIT_ENDPOINT = `${BASE_SECURE_URL}/deposit`;
export const DEPOSIT_SEARCH_ENDPOINT = `${BASE_SECURE_URL}/deposit-search`;
export const DEPOSIT_FILE_UPLOAD_ENDPOINT = `${BASE_SECURE_URL}/upload-file`;
export const GET_DEPOSIT_VOUCHER_ENDPOINT = `${BASE_SECURE_URL}/file-upload`;
export const generateGetDepositEndpoint = (depositId = ':depositId') =>
  `${DEPOSIT_ENDPOINT}/${depositId}`;

// general Income
export const GENERAL_INCOME_ENDPOINT = `${BASE_SECURE_URL}/general-income`;

export const CREDIT_NOTE_ENDPOINT = `${BASE_SECURE_URL}/credit-note`;

// general income type
export const GENERAL_INCOME_TYPE_ENDPOINT = `${BASE_SECURE_URL}/general-income-type`;

// expense
export const EXPENSE_ENDPOINT = `${BASE_SECURE_URL}/expense`;
export const ALL_EXPENSE_ENDPOINT = `${BASE_SECURE_URL}/all-expenses`;
export const TRIP_EXPENSE_ENDPOINT = `${BASE_SECURE_URL}/trip-expense`;
export const TRIP_EXPENSE_ITINERARY_ENDPOINT = `${BASE_SECURE_URL}/trip-expense/itinerary`;
export const TRIP_EXPENSE_CIRCUIT_ENDPOINT = `${BASE_SECURE_URL}/trip-expense/circuit`;
export const PREVIEW_TRIP_EXPENSE_ENDPOINT = `${BASE_SECURE_URL}/temporal-trip-expense`;
export const generateGetPreviewTripEndpoint = (itineraryId = ':itineraryId') =>
  `${PREVIEW_TRIP_EXPENSE_ENDPOINT}/${itineraryId}`;
export const generateGetPreviewTripByCircuitEndpoint = (
  itineraryId = ':itineraryId',
) => `${PREVIEW_TRIP_EXPENSE_ENDPOINT}/${itineraryId}/circuit`;

// expense type
export const COST_CENTER_ENDPOINT = `${BASE_SECURE_URL}/cost-center`;

// human resource
export const AGENCY_ENDPOINT = `${BASE_SECURE_URL}/agency`;
export const AGENCY_GROUP_ENDPOINT = `${BASE_SECURE_URL}/agency-group`;
export const WORKSTATION_ENDPOINT = `${BASE_SECURE_URL}/workstation`;
export const PRINT_STATION_ENDPOINT = `${BASE_SECURE_URL}/print-station`;
export const PRINT_TEMPLATE_ENDPOINT = `${BASE_SECURE_URL}/print-template-type`;
export const generateEndpointToAddCreditLine = (agencyId, amount) =>
  `${BASE_SECURE_URL}/agency/${agencyId}/add-credit-line/${amount}`;

// baggage
export const BAGGAGE_ENDPOINT = `${BASE_SECURE_URL}/baggage`;
export const BAGGAGE_CONFIGURATION_ENDPOINT = `${BASE_SECURE_URL}/baggage-configuration`;
export const ITEM_CATEGORY_ENDPOINT = `${BASE_SECURE_URL}/item-category`;
export const EXCESS_BAGGAGE_ENDPOINT = `${BASE_SECURE_URL}/excess-baggage`;
export const generateExcessBaggageVoidEndpoint = (excessBaggageId) =>
  `${EXCESS_BAGGAGE_ENDPOINT}/${excessBaggageId}/void`;
export const PRINT_EXCESS_BAGGAGE_ENDPOINT = `${BASE_SECURE_URL}/excess-baggage/printed`;
export const BAGGAGE_ITEMS_MOVEMENT_ENDPOINT = `${BAGGAGE_ENDPOINT}/move-items`;
export const BAGGAGE_BY_ITEM_STATUS_ENDPOINT = `${BAGGAGE_ENDPOINT}/find-by-item-status`;
export const BAGGAGE_BY_ITINERARY_ENDPOINT = `${BAGGAGE_ENDPOINT}/find-by-itinerary`;
export const BAGGAGE_BY_ITEM_CORRELATION_ENDPOINT = `${BAGGAGE_ENDPOINT}/find-by-item-correlation`;
export const BAGGAGE_BY_ITEM_TICKET_ITINERARY_ID_ENDPOINT = `${BAGGAGE_ENDPOINT}/find-by-ticket-itineraryId`;
export const BAGGAGE_ITEMS_SERIES_ENDPOINT = `${BAGGAGE_ENDPOINT}/item-series`;

// base price per kilometer
export const BASE_PRICE_PER_KILOMETER_ENDPOINT = `${BASE_SECURE_URL}/base-price-per-kilometer`;
export const GEOGRAPHIC_ZONES_COMBINATION_ENDPOINT = `${BASE_SECURE_URL}/geographic-zone-combination-price`;
export const COMMON_PRODUCT_ENDPOINT = `${BASE_SECURE_URL}/common-product`;
export const COMMON_PRODUCT_PRICE_SCHEDULE_ENDPOINT = `${BASE_SECURE_URL}/common-product-price-schedule`;
export const COMMON_PRODUCT_PRICE_SCHEDULE_ITEM_ENDPOINT = `${BASE_SECURE_URL}/common-product-price-schedule-item`;
export const generateGetCommonProductPrice = (
  sourceLocationId,
  destinationLocationId,
) =>
  `${COMMON_PRODUCT_PRICE_SCHEDULE_ITEM_ENDPOINT}/source-location/${sourceLocationId}/destination-location/${destinationLocationId}`;
export const CARGO_DISTANCE_ENDPOINT = `${BASE_SECURE_URL}/cargo-distance`;
export const DEPRECIATION_RULE_ENDPOINT = `${BASE_SECURE_URL}/depreciation-rule`;
export const DELIVERY_GROUP_ENDPOINT = `${BASE_SECURE_URL}/delivery-group`;
export const CARGO_ENDPOINT = `${BASE_SECURE_URL}/cargo`;
export const CARGO_PRICE_ENDPOINT = `${CARGO_ENDPOINT}/price`;
export const CARGO_CATEGORY_ENDPOINT = `${BASE_SECURE_URL}/cargo-category`;
export const PARCEL_ENDPOINT = `${BASE_SECURE_URL}/parcel`;
export const INTERNAL_PARCEL_ENDPOINT = `${BASE_SECURE_URL}/internal-parcel`;
export const MANIFEST_ITEM_ITINERARY_ENPOINT = `${BASE_SECURE_URL}/cargo-item-manifest`;
export const LEGAL_ABANDONMENT_ENPOINT = `${BASE_SECURE_URL}/legal-abandonment`;
export const generatePostParcelRefund = (parcelId) =>
  `${PARCEL_ENDPOINT}/${parcelId}/actions/refund`;
export const generatePostParcelVoid = (parcelId) =>
  `${PARCEL_ENDPOINT}/${parcelId}/actions/void`;
export const CARGO_MINIMAL_PRICE_ENDPOINT = `${BASE_SECURE_URL}/cargo-minimal-price`;
export const CARGO_ITEM_ENDPOINT = `${BASE_SECURE_URL}/cargo-item`;
export const CARGO_ITEM_ENDPOINT_V2 = `${BASE_SECURE_URL_V2}/cargo-item`;
export const GET_CARGO_ITEM_TO_ITINERARY = `${CARGO_ITEM_ENDPOINT}/find-items-to-add`;
export const GET_CARGO_ITEM_TO_UNLOAD = `${CARGO_ITEM_ENDPOINT}/find-items-to-unload`;
export const GET_CARGO_ITEM_TO_UNLOAD_V2 = `${CARGO_ITEM_ENDPOINT_V2}/find-items-to-unload`;
export const INTERNAL_CARGO_ITEM_ENDPOINT = `${BASE_SECURE_URL}/internal-cargo-item`;
export const GET_CARGO_ITEM_BY_PARCEL_ENDPOINT = `${BASE_SECURE_URL}/cargo-item/find-by-parcel`;
export const GET_CARGO_ITEM_BY_PARCEL_CORRELATION_ENDPOINT = `${BASE_SECURE_URL}/cargo-item/find-by-parcel-correlation`;
export const PUT_ADD_CARGO_ITEM_TO_ITINERARY_ENDPOINT = `${CARGO_ITEM_ENDPOINT}/add-to-itinerary`;
export const PUT_ADD_CARGO_ITEM_TO_ITINERARY_ENDPOINT_V2 = `${CARGO_ITEM_ENDPOINT_V2}/add-to-itinerary`;
export const PUT_REMOVE_CARGO_ITEM_FROM_MANIFEST_ENDPOINT = `${CARGO_ITEM_ENDPOINT}/remove-from-manifest`;
export const PUT_REMOVE_CARGO_ITEM_FROM_ITINERARY_ENDPOINT = `${CARGO_ITEM_ENDPOINT}/remove-from-itinerary`;
export const PUT_REMOVE_CARGO_ITEM_FROM_ITINERARY_ENDPOINT_V2 = `${CARGO_ITEM_ENDPOINT_V2}/remove-from-itinerary`;
export const GET_CARGO_ITEM_BY_ITINERARY_ENDPOINT = `${CARGO_ITEM_ENDPOINT}/find-by-itinerary`;
export const ADD_CARGO_ITEM_TO_LOCATION_ENDPOINT = `${CARGO_ITEM_ENDPOINT}/add-to-location`;
export const GET_CARGO_ITEM_DETAIL_ENDPOINT = `${CARGO_ITEM_ENDPOINT}/find-detail-by-id`;
export const GET_INTERNAL_CARGO_ITEM_DETAIL_ENDPOINT = `${INTERNAL_CARGO_ITEM_ENDPOINT}/find-detail-by-id`;
export const generatePutChangeCargoItemStatus = (
  cargoItemId,
  status = ':status',
) => `${CARGO_ITEM_ENDPOINT}/${cargoItemId}?status=${status}`;
export const generatePutChangeInternalCargoItemStatus = (
  cargoItemId,
  status = ':status',
) => `${INTERNAL_CARGO_ITEM_ENDPOINT}/${cargoItemId}?status=${status}`;
export const CARGO_ITEM_FOUND_LOCATION_ENDPOINT = `${CARGO_ITEM_ENDPOINT}/found`;
export const PARCELS_TO_PICKUP_ENDPOINT = `${BASE_SECURE_URL}/cargo-pickup`;
export const generatePutParcelPickup = (parcelId) =>
  `${PARCEL_ENDPOINT}/${parcelId}/actions/pickup`;
export const generatePutParcelPickupByItem = (parcelId) =>
  `${PARCEL_ENDPOINT}/${parcelId}/actions/pickup/items`;
export const generatePutInternalParcelPickup = (internalParcelId) =>
  `${INTERNAL_PARCEL_ENDPOINT}/${internalParcelId}/actions/pickup`;
export const MANUAL_GRR_ENDPOINT = `${BASE_SECURE_URL}/bill-process`;
export const SENDER_REMISSION_GUIDE_ENDPOINT = `${BASE_SECURE_URL}/sender-remission-guide`;
export const FACTURADOR_PDF_ENDPOINT = `${FACTURADOR_BASE_URL}/api/facturador/grt/pdf/`;
export const CARGO_CORPORATE_CREDIT_ENDPOINT = `${BASE_SECURE_URL}/cargo-corporate-credit`;
export const generateEndpointCargoCorporateCreditToAddCreditLine = (
  cargoCorporateCreditId,
  amount,
) =>
  `${CARGO_CORPORATE_CREDIT_ENDPOINT}/${cargoCorporateCreditId}/add-credit-line/${amount}`;
export const generatePutParcelPay = (parcelId) =>
  `${PARCEL_ENDPOINT}/${parcelId}/actions/pay`;
export const generatePostParcelChangeCode = (parcelId) =>
  `${PARCEL_ENDPOINT}/${parcelId}/actions/change-code`;
export const INTERNAL_PARCEL_BY_CORRELATION_ENDPOINT = `${BASE_SECURE_URL}/internal-parcel/find-by-correlation`;
export const generateEndpointCargoCorporateCreditToUnifyInvoices = (
  cargoCorporateCreditId = ':cargoCorporateCreditId',
) =>
  `${CARGO_CORPORATE_CREDIT_ENDPOINT}/${cargoCorporateCreditId}/actions/unify-invoices`;

export const POST_CARGO_ITEM_COMMENT_ENDPOINT = `${CARGO_ITEM_ENDPOINT}/save-comment`;
export const POST_PARCEL_COMMENT_ENDPOINT = `${PARCEL_ENDPOINT}/save-comment`;
export const generatePostSendParcelByEmailEndpoint = (parcelId) =>
  `${PARCEL_ENDPOINT}/${parcelId}/actions/send-email`;

export const generatePostCreditNoteParcel = (parcelId) =>
  `${PARCEL_ENDPOINT}/${parcelId}/create-partial-credit-note`;

export const generateGetParcelBySeriesAndCorrelative = (series, correlative) =>
  `${PARCEL_ENDPOINT}/document-series/${series}/document-code/${correlative}`;

// security
export const AUTHORIZED_PROFILE_ENDPOINT = `${BASE_SECURE_URL}/profile-authorized-security-profile`;
export const AUTHORIZED_PROFILE_BY_PROFILE_ID_ENDPOINT = `${AUTHORIZED_PROFILE_ENDPOINT}/profileId`;
export const AUTHORIZED_PROFILES_PER_LOGGED_IN_USER_ENDPOINT = `${BASE_SECURE_URL}/authorized-security-profile`;
export const SECURITY_PROFILE_ENDPOINT = `${BASE_SECURE_URL}/security-profile`;
export const PAYMENT_METHOD_FOR_SECURITY_PROFILE_ENDPOINT = `${BASE_SECURE_URL}/payment-method-security-profile`;

// system
export const EVENT_ENDPOINT = `${BASE_SECURE_URL}/event`;
export const IN_SYSTEM_NOTIFICATION_ENDPOINT = `${BASE_SECURE_URL}/in-system-notification`;

// accounting unit
export const EXPENSE_TYPE_ENDPOINT = `${BASE_SECURE_URL}/expense-type`;
export const generateTicketTaxableInformationEndpoint = (ticketId) =>
  `${TICKET_ENDPOINT}/${ticketId}/taxable-information`;
export const MANUAL_VOUCHER_ENDPOINT = `${BASE_SECURE_URL}/manual-voucher`;
export const BILLING_ENDPOINT = `${BASE_SECURE_URL}/bill-process`;
export const generateGrtBillingByItineraryEndpoint = (itineraryId) =>
  `${ITINERARY_ENDPOINT}/${itineraryId}/grt`;
export const DETRACTION_ENDPOINT = `${BASE_SECURE_URL}/detraction`;

export const generateGrrBillingByItineraryEndpoint = (itineraryId) =>
  `${ITINERARY_ENDPOINT}/${itineraryId}/grr`;

export const generateGrtBillingByItineraryPdfEndpointV1 = (itineraryId) =>
  `${ITINERARY_ENDPOINT}/${itineraryId}/grt/pdf`;

export const generateGrtBillingByItineraryPdfEndpointV2 = (itineraryId) =>
  `${ITINERARY_ENDPOINT_V2}/${itineraryId}/grt/pdf`;

export const generateGrtBillingReportByItineraryEndpoint = (itineraryId) =>
  `${ITINERARY_ENDPOINT}/bill/report/${itineraryId}`;

export const generateDisabledBillingsByItinerayIdEndpoint = (itineraryId) =>
  `${BILLING_ENDPOINT}/disabled-by-itinerary/${itineraryId}`;

export const generateContingencyGrtBillingByItineraryEndpoint = () =>
  `${BASE_SECURE_URL}/contingency-itinerary/grt`;

// mechanical maintenance
export const ORGANIZATION_CHART_ENDPOINT = `${BASE_SECURE_URL}/company-area`;
export const HIERARCHY_ENDPOINT = `${BASE_SECURE_URL}/company-area-type`;
export const MECHANIC_ENDPOINT = `${BASE_SECURE_URL}/employee`;
export const ACTIVITY_ENDPOINT = `${BASE_SECURE_URL}/activity`;
export const BUS_ACTIVITY_ASSIGMENT_ENDPOINT = `${BASE_SECURE_URL}/activity-bus`;
export const BUS_ACTIVITY_MASSIVE_ASSIGMENT_ENDPOINT = `${BASE_SECURE_URL}/activity-bus-massive`;
export const PREVENTIVE_MAINTENANCE_REPORT_ENDPOINT = `${BASE_SECURE_URL}/activity-bus-report`;
export const REGISTER_OF_EXECUTION_ENDPOINT = `${BASE_SECURE_URL}/activity-bus-execution`;
export const BUS_ACTIVITY_HISTORY_ENDPOINT = `${BASE_SECURE_URL}/activity-bus-execution-history-report`;
export const generateExecutionRegistrationDeleteEndpoint = (
  activityBusExecutionId = ':activityBusExecutionId',
) => `${REGISTER_OF_EXECUTION_ENDPOINT}/${activityBusExecutionId}`;
export const MATERIAL_ENDPOINT = `${BASE_SECURE_URL}/material`;
export const generateOdometerByBusEndpoint = (registeredBusId) =>
  `${BASE_SECURE_URL}/registered-bus/${registeredBusId}/trips`;
export const ACTIVITY_TASK_ENDPOINT = `${BASE_SECURE_URL}/activity-task`;
export const MAINTENANCE_WARNING_ENDPOINT = `${BASE_SECURE_URL}/maintenance-warning`;
export const MAINTENANCE_WARNING_REPORTER_ENDPOINT = `${MAINTENANCE_WARNING_ENDPOINT}/customer`;
export const MAINTENANCE_FAIL_MODE_ENDPOINT = `${BASE_SECURE_URL}/maintenance-fail-mode`;
export const generateChangeStatusMaintenanceWarningEndpoint = (
  maintenanceWarningId = ':maintenanceWarningId',
) => `${MAINTENANCE_WARNING_ENDPOINT}/${maintenanceWarningId}`;
export const MAINTENANCE_SERVICE_ORDER_ENDPOINT = `${BASE_SECURE_URL}/service-order`;
export const MY_MAINTENANCE_SERVICE_ORDERS_ENDPOINT = `${BASE_SECURE_URL}/user/service-order`;
export const ACTIVITY_BUS_EXECUTION_EMPLOYEE_ENDPOINT = `${BASE_SECURE_URL}/activity-bus-execution-employee`;
export const generateDeleteActivityBusExecutionEmployeeByABEEIdAndEId = (
  activityBusExecutionId = ':activityBusExecutionId',
  employeeId = ':employeeId',
) =>
  `${ACTIVITY_BUS_EXECUTION_EMPLOYEE_ENDPOINT}/activity-bus-execution/${activityBusExecutionId}/employee/${employeeId}`;
export const ACTIVITY_TASK_EXECUTION_ENDPOINT = `${BASE_SECURE_URL}/activity-task-execution`;
export const generateDeleteActivityTaskExecutionByABEEIdAndEId = (
  activityBusExecutionId = ':activityBusExecutionId',
  employeeId = ':employeeId',
) =>
  `${ACTIVITY_TASK_EXECUTION_ENDPOINT}/activity-bus-execution/${activityBusExecutionId}/employee/${employeeId}`;
export const generatePutMaintenanceServiceOrderEndpoint = (
  maintenanceServiceOrderId = ':maintenanceServiceOrderId',
) => `${MAINTENANCE_SERVICE_ORDER_ENDPOINT}/${maintenanceServiceOrderId}`;
export const generatePutAssignActivityToMaintenanceServiceOrderEndpoint = (
  maintenanceServiceOrderId = ':maintenanceServiceOrderId',
) =>
  `${MAINTENANCE_SERVICE_ORDER_ENDPOINT}/${maintenanceServiceOrderId}/assign-activities`;
export const generatePutAssignExecutionToMaintenanceServiceOrderEndpoint = (
  maintenanceServiceOrderId = ':maintenanceServiceOrderId',
) =>
  `${MAINTENANCE_SERVICE_ORDER_ENDPOINT}/${maintenanceServiceOrderId}/assign-executions`;
export const COMPANY_AREA_RESPONSABLE_ENDPOINT = `${BASE_SECURE_URL}/company-area-responsable/my-areas`;
