import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  CLEAR_CABIN_CREW_COMMISSIONS,
  FLAG_GETTING_CABIN_CREW_COMMISSIONS,
  GET_CABIN_CREW_COMMISSIONS,
} from '../types';
import { CABIN_CREW_COMISSION_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';

const flagGettingCabinCrewCommissions = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CABIN_CREW_COMMISSIONS,
    payload: flag,
  });

const clearCabinCrewCommissions = () => (dispatch) =>
  dispatch({
    type: CLEAR_CABIN_CREW_COMMISSIONS,
  });

const getCabinCrewCommissions = (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCabinCrewCommissions(true));
    const query = tableFilters;
    const url = `${CABIN_CREW_COMISSION_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const commissions = await response.json();
    dispatch({
      type: GET_CABIN_CREW_COMMISSIONS,
      payload: commissions,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCabinCrewCommissions(false));
  }
};

export { getCabinCrewCommissions, clearCabinCrewCommissions };
