import React from 'react';
import { Col, Row } from 'reactstrap';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import CargoCorporateCreditInformationPropTypes from '../proptypes/CargoCorporateCreditInformationPropTypes';
import IsActive from '../../../../common/cells/IsActive';
import Table from '../../../../common/Table';
import {
  CONTRACT_TYPE,
  DEFAULT_PAGE_SIZE,
} from '../../../../../config/constants';
import { CUSTOMER_PATH } from '../../../../../config/paths';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { CUSTOMER_COLUMNS } from '../../../../../config/columns';
import Alert from '../../../../common/informative/Alert';
import { CARGO_CORPORATE_CREDIT_CUSTOMER_INFO_MESSAGE } from '../../../../../config/messages';
import InternalResource from '../../../../common/resource/InternalResource';
import { numberFormatter } from '../../../../../utils/number';

const CargoCorporateCreditInformationResource = ({
  id,
  name,
  description,
  active,
  customerList,
  company,
  contractType,
  initialBalance,
  actualBalance,
  creditDays,
  createDate,
  lastUpdate,
  discountPercentage,
}) => {
  const customerTable =
    customerList.length > 0 ? (
      <Table
        columns={CUSTOMER_COLUMNS}
        data={customerList}
        defaultPageSize={
          customerList.length <= 10 ? customerList.length : DEFAULT_PAGE_SIZE
        }
        showPagination={customerList.length > 10}
        manual={false}
        modelPath={CUSTOMER_PATH}
        filterable
        openPathInNewTab
        navigateToModelOnRowClick
        defaultFilterMethod={filterMethodCaseInsensitive}
      />
    ) : (
      <Alert
        message={CARGO_CORPORATE_CREDIT_CUSTOMER_INFO_MESSAGE}
        type="warning"
      />
    );

  const customerResource = (
    <div className="mb-3">
      <h3>Clientes</h3>
      {customerTable}
    </div>
  );

  const contractInformationResource = (
    <>
      <h3>Información de Contrato</h3>
      <ResourceProperty label="Compañia:">
        {company ? company.name : '-'}
      </ResourceProperty>
      <ResourceProperty label="Tipo de Contrato:">
        {contractType ? CONTRACT_TYPE[contractType].label : '-'}
      </ResourceProperty>
      <ResourceProperty label="Saldo Inicial:">
        {numberFormatter({ value: initialBalance })}
      </ResourceProperty>
      <ResourceProperty label="Saldo Actual:">
        {numberFormatter({ value: actualBalance })}
      </ResourceProperty>
      <ResourceProperty label="Porcentaje de Descuento:">
        {numberFormatter({ value: discountPercentage, style: 'percent' })}
      </ResourceProperty>
      <ResourceProperty label="Crédito a (días):">
        {creditDays || '-'}
      </ResourceProperty>
    </>
  );

  const internalResource = (
    <InternalResource id={id} createDate={createDate} lastUpdate={lastUpdate} />
  );

  return (
    <>
      <h3>Información Básica</h3>
      <ResourceProperty label="Nombre:">{name}</ResourceProperty>
      <ResourceProperty label="Descripción:">{description}</ResourceProperty>
      <ResourceProperty label="Estado:">
        <IsActive value={active} />
      </ResourceProperty>
      {customerResource}
      <Row>
        <Col md={6}>{contractInformationResource}</Col>
        <Col md={6}>{internalResource}</Col>
      </Row>
    </>
  );
};

CargoCorporateCreditInformationResource.propTypes =
  CargoCorporateCreditInformationPropTypes;

export default CargoCorporateCreditInformationResource;
