import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { getContingencyBillingsByItinerayIdAndSourceLocation } from '../../../../../actions';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { GET } from '../../../../../config/permissions';
import { generateContingencyGrtBillingByItineraryEndpoint } from '../../../../../config/endpoints';
import Modal from '../../../../common/modal/Modal';
import ContingencySenderRemisionGuideForm from './ContingencySenderRemisionGuideForm';
import printContingencyCarrierRemissionGuide from '../../../../../utils/printers/ContingencyCarrierRemissionGuide';

const PrintContingencyCarrierRemission = ({
  dispatchGetContingencyBillingsByItinerayIdAndSourceLocation,
  itineraryId,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  const onClickConfirm = async (formValues) => {
    const query = {
      itineraryId,
      sourceLocationId: formValues.agency.value,
    };

    const contingencyGuides =
      await dispatchGetContingencyBillingsByItinerayIdAndSourceLocation({
        query,
      });

    if (contingencyGuides) {
      printContingencyCarrierRemissionGuide(contingencyGuides);
    }

    setShowModal(false);
  };

  return (
    <>
      <Button color="warning" onClick={openModal}>
        <i className="fa fa-print" /> Imprimir Contingencia
      </Button>
      <Modal
        show={showModal}
        title="Guía de Remision de Contingencia"
        body={
          <ContingencySenderRemisionGuideForm
            itineraryId={itineraryId}
            handleProcess={onClickConfirm}
            onCancel={closeModal}
          />
        }
        onClickClose={closeModal}
        size="lg"
      />
    </>
  );
};

PrintContingencyCarrierRemission.propTypes = {
  dispatchGetContingencyBillingsByItinerayIdAndSourceLocation:
    PropTypes.func.isRequired,
  itineraryId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchGetContingencyBillingsByItinerayIdAndSourceLocation:
    getContingencyBillingsByItinerayIdAndSourceLocation,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(PrintContingencyCarrierRemission);

export default withEndpointAuthorization({
  url: generateContingencyGrtBillingByItineraryEndpoint(),
  permissionType: GET,
})(connectedComponent);
