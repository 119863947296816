import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CARGO_PATH } from '../../../../config/paths';
import { SALES_SESSION_PARCELS_COLUMNS } from '../../../../config/columns';
import {
  clearIncomesParcels,
  getSalesSessionParcels,
} from '../../../../actions';
import Table from '../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';

const ParcelsTable = ({
  loading,
  salesSessionId,
  salesSessionParcels,
  dispatchClearIncomesParcels,
  dispatchGetSalesSessionParcels,
}) => {
  useEffect(
    () => () => {
      dispatchClearIncomesParcels();
    },
    [],
  );

  const onFetchData = (searchValues) => {
    dispatchGetSalesSessionParcels({ salesSessionId }, searchValues);
  };

  const data = salesSessionParcels.get('content') || [];
  const pages = salesSessionParcels.get('totalPages') || null;
  const defaultPageSize = salesSessionParcels.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <>
      <Table
        columns={SALES_SESSION_PARCELS_COLUMNS}
        data={data}
        filterable
        pages={pages}
        defaultPageSize={defaultPageSize}
        fetchData={onFetchData}
        loading={loading}
        navigateToModelOnRowClick
        modelPath={CARGO_PATH}
        openPathInNewTab
      />
      <br />
    </>
  );
};

ParcelsTable.propTypes = {
  salesSessionId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetSalesSessionParcels: PropTypes.func.isRequired,
  dispatchClearIncomesParcels: PropTypes.func.isRequired,
  salesSessionParcels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
};

ParcelsTable.defaultProps = {
  salesSessionParcels: undefined,
};

const mapStateToProps = ({ CargoUnit }) => ({
  salesSessionParcels: CargoUnit.Cargo.getIn(['salesSession', 'incomes']),
  loading: CargoUnit.Cargo.getIn(['salesSession', 'loadingIncomes']),
});

const mapDispatchToProps = {
  dispatchGetSalesSessionParcels: getSalesSessionParcels,
  dispatchClearIncomesParcels: clearIncomesParcels,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParcelsTable);
