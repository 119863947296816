import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_PARCEL_SERVICE_TYPE,
  CLEAR_PARCEL_SERVICE_TYPES,
  FLAG_GETTING_PARCEL_SERVICE_TYPES,
  FLAG_PARCEL_SERVICE_TYPE_ACTIVITY,
  GET_PARCEL_SERVICE_TYPE,
  GET_PARCEL_SERVICE_TYPES,
} from '../types/cargo/ParcelServiceType';
import { PARCEL_SERVICE_TYPE_ENDPOINT } from '../../config/endpoints';
import { PARCEL_SERVICE_TYPE_PATH } from '../../config/paths';

const flagGettingParcelServiceTypes = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PARCEL_SERVICE_TYPES,
    payload: flag,
  });

const flagParcelServiceTypeActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PARCEL_SERVICE_TYPE_ACTIVITY,
    payload: flag,
  });

const getParcelServiceTypes =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingParcelServiceTypes(true));
      const query = tableFilters;
      const url = `${PARCEL_SERVICE_TYPE_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcelServiceTypes = await response.json();
      dispatch({ type: GET_PARCEL_SERVICE_TYPES, payload: parcelServiceTypes });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingParcelServiceTypes(false));
    }
  };

const clearParcelServiceTypes = () => (dispatch) =>
  dispatch({
    type: CLEAR_PARCEL_SERVICE_TYPES,
  });

const clearParcelServiceType = () => (dispatch) =>
  dispatch({
    type: CLEAR_PARCEL_SERVICE_TYPE,
  });

const flagGettingParcelServiceType = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PARCEL_SERVICE_TYPE_ACTIVITY,
    payload: flag,
  });

const getParcelServiceType =
  ({ parcelServiceTypeId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingParcelServiceType(true));
      const url = `${PARCEL_SERVICE_TYPE_ENDPOINT}/${parcelServiceTypeId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcelServiceType = await response.json();
      dispatch({ type: GET_PARCEL_SERVICE_TYPE, payload: parcelServiceType });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingParcelServiceType(false));
    }
  };

const postParcelServiceType =
  ({ name, description, increasePercentage }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelServiceTypeActivity(true));
      const url = `${PARCEL_SERVICE_TYPE_ENDPOINT}`;
      const payload = {
        name,
        description,
        increasePercentage,
      };
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const parcelServiceType = await response.json();
      dispatch(push(`${PARCEL_SERVICE_TYPE_PATH}/${parcelServiceType.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelServiceTypeActivity(false));
    }
  };

const putParcelServiceType =
  (parcelServiceTypeId, { name, description, increasePercentage }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelServiceTypeActivity(true));
      const url = `${PARCEL_SERVICE_TYPE_ENDPOINT}/${parcelServiceTypeId}`;
      const payload = {
        name,
        description,
        increasePercentage,
      };
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const parcelServiceType = await response.json();
      dispatch(push(`${PARCEL_SERVICE_TYPE_PATH}/${parcelServiceType.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelServiceTypeActivity(false));
    }
  };

const deleteParcelServiceType =
  async ({ parcelServiceTypeId }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelServiceTypeActivity(true));
      const url = `${PARCEL_SERVICE_TYPE_ENDPOINT}/${parcelServiceTypeId}`;
      const response = await fetch(url, { ...DEFAULT_DELETE_CONFIG });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(PARCEL_SERVICE_TYPE_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelServiceTypeActivity(false));
    }
  };

export {
  flagGettingParcelServiceTypes,
  getParcelServiceTypes,
  clearParcelServiceTypes,
  getParcelServiceType,
  clearParcelServiceType,
  postParcelServiceType,
  putParcelServiceType,
  deleteParcelServiceType,
};
