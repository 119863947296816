import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import {
  clearTripRequest,
  getCurrentContractor,
  getTripRequest,
  putTripRequest,
} from '../../../../actions';
import { TRIP_REQUEST_PATH } from '../../../../config/paths';
import TripRequestForm from './TripRequestForm';
import { customersGenerator } from '../../../../utils/app/json-generator-from-reducer';

const EditTripRequest = ({
  breadcrumbs,
  dispatchGetTripRequest,
  dispatchClearTripRequest,
  dispatchPutTripRequest,
  dispatchGetCurrentContractor,
  match: {
    params: { id: tripRequestId },
  },
  loading,
  tripRequest,
}) => {
  useLayoutEffect(() => {
    dispatchGetCurrentContractor();
    dispatchGetTripRequest({ tripRequestId });

    return () => dispatchClearTripRequest();
  }, []);

  const onSubmit = (formValues) => {
    dispatchPutTripRequest(tripRequestId, {
      ...formValues,
      id: tripRequest.get('id'),
      customerList: formValues.customerList.map((customer) => ({
        id: customer.id,
      })),
      contractorId: tripRequest.get('contractor').id,
    });
  };

  const generateInitialValues = (trip) => ({
    itinerary: trip.itinerary,
    customerList: customersGenerator(trip.customerList),
    contractorId: trip.contractor.id,
  });

  let content;

  if (loading) content = <Loader />;
  else if (tripRequest.isEmpty())
    content = <NoDataResource returnPage={TRIP_REQUEST_PATH} />;
  else
    content = (
      <TripRequestForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(tripRequest.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Solicitud de Viaje"
      subtitle="Editar solicitud de viaje"
      content={content}
    />
  );
};

EditTripRequest.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearTripRequest: PropTypes.func.isRequired,
  dispatchGetTripRequest: PropTypes.func.isRequired,
  dispatchPutTripRequest: PropTypes.func.isRequired,
  dispatchGetCurrentContractor: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  tripRequest: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditTripRequest.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearTripRequest: clearTripRequest,
  dispatchGetTripRequest: getTripRequest,
  dispatchPutTripRequest: putTripRequest,
  dispatchGetCurrentContractor: getCurrentContractor,
};

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Solicitudes de Viajes',
      href: TRIP_REQUEST_PATH,
    },
    {
      text: 'Ver',
      href: `${TRIP_REQUEST_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  tripRequest: ItineraryUnit.TripRequest.getIn(['current', 'content']),
  loading: !ItineraryUnit.TripRequest.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTripRequest);
