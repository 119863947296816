import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import Modal from '../../../common/modal/Modal';
import SendEmailCargoForm from './SendEmailCargoForm';
import { postParcelSendEmail } from '../../../../actions';

const SendEmailButton = ({ parcel, dispatchPostParcelSendEmail }) => {
  const [showModal, setShowModal] = useState(false);

  const closeConfirmationModal = () => {
    setShowModal(false);
  };

  const handleSendTicket = async ({ email }) => {
    setShowModal(false);
    dispatchPostParcelSendEmail({ parcelId: parcel.id, email });
  };

  const body = (
    <>
      <p>
        Favor especificar el correo electrónico al que se le va a enviar un PDF
        de este boleto.
      </p>
      <SendEmailCargoForm
        onSubmit={handleSendTicket}
        onCancel={closeConfirmationModal}
      />
    </>
  );

  const modal = <Modal show={showModal} title="Enviar" body={body} size="md" />;

  const showConfirmationModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button color="light" className="border" onClick={showConfirmationModal}>
        <i className="fa fa-envelope" /> Enviar
      </Button>
      {modal}
    </>
  );
};

SendEmailButton.propTypes = {
  parcel: PropTypes.instanceOf(Object).isRequired,
  dispatchPostParcelSendEmail: PropTypes.func.isRequired,
};

const mapDispatchToprops = {
  dispatchPostParcelSendEmail: postParcelSendEmail,
};

export default connect(null, mapDispatchToprops)(SendEmailButton);
