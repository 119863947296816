import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { POST } from '../../../../config/permissions';
import { postApproveTripRequest } from '../../../../actions';
import { generateApproveTripRequestEndpoint } from '../../../../config/endpoints';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';

const TripRequestApproveButton = ({
  dispatchPostApproveTripRequest,
  tripRequestId,
}) => {
  const onClickSuccess = () =>
    dispatchPostApproveTripRequest({ tripRequestId });

  return (
    <>
      <Button color="success" onClick={onClickSuccess}>
        <i className="fa fa-check-circle-o" /> Aprobar
      </Button>
    </>
  );
};

TripRequestApproveButton.propTypes = {
  tripRequestId: PropTypes.number.isRequired,
  dispatchPostApproveTripRequest: PropTypes.func.isRequired,
};

const mapDispatchToprops = {
  dispatchPostApproveTripRequest: postApproveTripRequest,
};

const connectedComponent = connect(
  null,
  mapDispatchToprops,
)(TripRequestApproveButton);

export default withEndpointAuthorization({
  url: generateApproveTripRequestEndpoint(),
  mapUrlParamsToProps: { tripRequestId: 'tripRequestId' },
  permissionType: POST,
})(connectedComponent);
