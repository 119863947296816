import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_GEOGRAPHIC_ZONES,
  GET_GEOGRAPHIC_ZONES,
  CLEAR_GEOGRAPHIC_ZONES,
  FLAG_GEOGRAPHIC_ZONE_ACTIVITY,
  GET_GEOGRAPHIC_ZONE,
  CLEAR_GEOGRAPHIC_ZONE,
} from '../types/location';
import { GEOGRAPHIC_ZONE_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { GEOGRAPHIC_ZONE_PATH } from '../../config/paths';

const flagGettingGeographicZones = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_GEOGRAPHIC_ZONES,
    payload: flag,
  });

const getGeographicZones = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingGeographicZones(true));
    const query = tableFilters;
    const url = `${GEOGRAPHIC_ZONE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const geographicZones = await response.json();
    dispatch({
      type: GET_GEOGRAPHIC_ZONES,
      payload: geographicZones,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingGeographicZones(false));
  }
};

const clearGeographicZones = () => (dispatch) =>
  dispatch({
    type: CLEAR_GEOGRAPHIC_ZONES,
  });

const flagGeographicZoneActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GEOGRAPHIC_ZONE_ACTIVITY,
    payload: flag,
  });

const getGeographicZone =
  async ({ geographicZoneId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGeographicZoneActivity(true));
      const url = `${GEOGRAPHIC_ZONE_ENDPOINT}/${geographicZoneId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const geographicZone = await response.json();
      dispatch({
        type: GET_GEOGRAPHIC_ZONE,
        payload: geographicZone,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGeographicZoneActivity(false));
    }
  };

const clearGeographicZone = () => (dispatch) =>
  dispatch({
    type: CLEAR_GEOGRAPHIC_ZONE,
  });

const postGeographicZone =
  async ({ name, description, cityList }) =>
  async (dispatch) => {
    try {
      dispatch(flagGeographicZoneActivity(true));
      const payload = {
        name,
        description,
        cityList,
      };
      const url = GEOGRAPHIC_ZONE_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const geographicZone = await response.json();
      dispatch(push(`${GEOGRAPHIC_ZONE_PATH}/${geographicZone.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGeographicZoneActivity(false));
    }
  };

const putGeographicZone =
  async (geographicZoneId, { name, description, cityList }) =>
  async (dispatch) => {
    try {
      dispatch(flagGeographicZoneActivity(true));
      const payload = {
        id: geographicZoneId,
        name,
        description,
        cityList,
      };
      const url = `${GEOGRAPHIC_ZONE_ENDPOINT}/${geographicZoneId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const geographicZone = await response.json();
      dispatch(push(`${GEOGRAPHIC_ZONE_PATH}/${geographicZone.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGeographicZoneActivity(false));
    }
  };

const deleteGeographicZone =
  async ({ geographicZoneId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGeographicZoneActivity(true));
      const url = `${GEOGRAPHIC_ZONE_ENDPOINT}/${geographicZoneId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(GEOGRAPHIC_ZONE_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGeographicZoneActivity(false));
    }
  };

export {
  flagGettingGeographicZones,
  getGeographicZones,
  clearGeographicZones,
  getGeographicZone,
  clearGeographicZone,
  postGeographicZone,
  putGeographicZone,
  deleteGeographicZone,
};
