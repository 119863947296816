import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ModuleList from '../../../layout/ModuleList';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { PARCEL_PRICE_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  NEW_PARCEL_PRICE_PATH,
  PARCEL_PRICE_PATH,
} from '../../../../config/paths';
import {
  clearParcelPrices,
  getParcelPrices,
} from '../../../../actions/cargo/ParcelPrice';

const ParcelPrices = ({
  breadcrumbs,
  parcelPrices,
  loading,
  dispatchGetParcelPrices,
  dispatchClearParcelPrices,
}) => {
  useLayoutEffect(() => () => dispatchClearParcelPrices(), []);

  const data = parcelPrices.get('content') || [];
  const pages = parcelPrices.get('totalPages') || null;
  const defaultPageSize = parcelPrices.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Tarifarios"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_PARCEL_PRICE_PATH}
      tableStructure={{
        columns: PARCEL_PRICE_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetParcelPrices,
        modelPath: PARCEL_PRICE_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetParcelPrices: getParcelPrices,
  dispatchClearParcelPrices: clearParcelPrices,
};

ParcelPrices.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  parcelPrices: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetParcelPrices: PropTypes.func.isRequired,
  dispatchClearParcelPrices: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tarifarios',
      href: PARCEL_PRICE_PATH,
    },
  ],
  parcelPrices: CargoUnit.ParcelPrice.getIn(['all', 'content']),
  loading: CargoUnit.ParcelPrice.getIn(['all', 'loading']),
});

ParcelPrices.defaultProps = {
  parcelPrices: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParcelPrices);
