import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Modal from '../../../common/modal/Modal';
import { postCreditNoteParcel } from '../../../../actions';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { generatePostCreditNoteParcel } from '../../../../config/endpoints';
import { POST } from '../../../../config/permissions';
import CreateCreditNoteParcelForm from './CreateCreditNoteParcelForm';

export const CreateCreditNoteParcelButton = ({
  parcel,
  dispatchPostCreditNoteParcel,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  const closeModal = () => setShowModal(false);

  const onModalSubmit = async ({ creditNoteAmount }) => {
    await dispatchPostCreditNoteParcel({
      parcelId: parcel.id,
      creditNoteAmount,
    });
    setShowModal(false);
    setModalBody(null);
  };

  const generateInitialValues = () => {
    const parcelPrice =
      parcel.salePriceWithDiscount !== null
        ? parcel.salePriceWithDiscount
        : parcel.salePrice;

    return {
      creditNoteAmount: parcelPrice,
    };
  };

  const openModal = () => {
    const newModalBody = (
      <CreateCreditNoteParcelForm
        onCancel={closeModal}
        handleProcess={onModalSubmit}
        parcel={parcel}
        initialValues={generateInitialValues()}
      />
    );
    setModalBody(newModalBody);
    setShowModal(true);
  };

  return (
    <>
      <Modal
        show={showModal}
        title="Cambiar Clave Secreta"
        body={modalBody}
        size="lg"
        onClickClose={closeModal}
        onClickCancel={closeModal}
      />
      <Button color="info" onClick={openModal}>
        <i className="fa fa-sticky-note" /> Crear Nota de Crédito
      </Button>
    </>
  );
};

CreateCreditNoteParcelButton.propTypes = {
  parcel: PropTypes.instanceOf(Object).isRequired,
  dispatchPostCreditNoteParcel: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostCreditNoteParcel: postCreditNoteParcel,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(CreateCreditNoteParcelButton);

export default withEndpointAuthorization({
  url: generatePostCreditNoteParcel(0),
  permissionType: POST,
})(connectedComponent);
