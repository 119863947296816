import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../common/modal/Modal';
import FindParcelByQrForm from './FindParcelByQrForm';
import { getParcelBySerieCorrelative } from '../../../../actions';

const FindParcelByQrButton = ({ dispatchGetParcelBySerieCorrelative }) => {
  const [showModal, setShowModal] = useState(false);

  const handleProcess = ({ serieCorrelative }) => {
    dispatchGetParcelBySerieCorrelative({ serieCorrelative });
    setShowModal(false);
  };

  const onCancel = () => setShowModal(false);

  const modalBody = (
    <FindParcelByQrForm handleProcess={handleProcess} onCancel={onCancel} />
  );

  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <Button onClick={handleShowModal} className="mr-2">
        <i className="fa fa-qrcode" /> Buscar por QR
      </Button>
      <Modal
        show={showModal}
        title="Buscar Encomienda por QR"
        body={modalBody}
        size="lg"
      />
    </>
  );
};

FindParcelByQrButton.propTypes = {
  dispatchGetParcelBySerieCorrelative: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchGetParcelBySerieCorrelative: getParcelBySerieCorrelative,
};

export default connect(null, mapDispatchToProps)(FindParcelByQrButton);
