import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteTripRequest } from '../../../../actions';

const TripRequestDeleteButton = ({
  dispatchDeleteTripRequest,
  tripRequestId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () => dispatchDeleteTripRequest({ tripRequestId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

TripRequestDeleteButton.propTypes = {
  dispatchDeleteTripRequest: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tripRequestId: PropTypes.number.isRequired,
};

TripRequestDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteTripRequest: deleteTripRequest,
};

export default connect(null, mapDispatchToProps)(TripRequestDeleteButton);
