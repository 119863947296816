import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { FLAG_GETTING_ROUTES, GET_ROUTES, CLEAR_ROUTES } from '../types';
import { COMPANY_ROUTE_ENDPOINT, ROUTE_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';

const flagGettingRoutes = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_ROUTES,
  });

const getRoutes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingRoutes(true));

    const query = tableFilters;

    const url = `${ROUTE_ENDPOINT}?${QueryString.stringify(query)}`;

    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await isErrorResponse(response, null, dispatch);

    const routes = await response.json();

    dispatch({
      type: GET_ROUTES,
      payload: routes,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingRoutes(false));
  }
};

const getCompanyRoutes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingRoutes(true));

    const query = tableFilters;

    const url = `${COMPANY_ROUTE_ENDPOINT}?${QueryString.stringify(query)}`;

    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await isErrorResponse(response, null, dispatch);

    const routes = await response.json();

    dispatch({
      type: GET_ROUTES,
      payload: routes,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingRoutes(false));
  }
};

const clearRoutes = () => (dispatch) =>
  dispatch({
    type: CLEAR_ROUTES,
  });

export { flagGettingRoutes, getRoutes, clearRoutes, getCompanyRoutes };
