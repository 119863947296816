import { addText, createPdfDocument, setFont } from '../pdf';

let myY = 64;

const printDocumentHeader = (doc, response) => {
  setFont(doc);
  doc.setFontSize(8);

  addText(doc, 'TURISMO CIVA S.A.C', 62, myY, 80, 'right');
  addText(doc, '20102427891', 36, (myY += 5.2), 30);
  doc.setFontSize(5);
  addText(doc, response.sourceAdress, 170, myY, 200, 'center');
  addText(doc, response.destinationAdress, 170, (myY += 5.2), 150, 'center');
  doc.setFontSize(8);
  addText(
    doc,
    `${response.bus.busMark} - ${response.bus.licensePlate}`,
    140,
    (myY += 20),
    150,
    'right',
  );
  addText(doc, '20102427891', 45, (myY += 10), 80, 'right');
  addText(doc, 'TURISMO CIVA S.A.C', 97, myY, 80, 'right');
  doc.setFontSize(5);

  let driverMy = myY;

  if (response.driverList && response.driverList.length > 0) {
    addText(
      doc,
      `${response.driverList[0].name} ${response.driverList[0].lastName}`,
      120,
      (driverMy += -5),
      170,
      'left',
    );

    addText(
      doc,
      `${response.driverList[0].driverAuthorization}`,
      180,
      driverMy,
      90,
      'left',
    );

    if (response.driverList[1]) {
      addText(
        doc,
        `${response.driverList[1].name} ${response.driverList[1].lastName}`,
        125,
        (driverMy += 5.2),
        80,
        'left',
      );
      addText(
        doc,
        `${response.driverList[1].driverAuthorization}`,
        180,
        driverMy,
        90,
        'left',
      );
    }

    if (response.driverList[2]) {
      addText(
        doc,
        `${response.driverList[2].name} ${response.driverList[2].lastName}`,
        125,
        (driverMy += 5.2),
        80,
        'left',
      );
      addText(
        doc,
        `${response.driverList[2].driverAuthorization}`,
        180,
        driverMy,
        90,
        'left',
      );
    }

    addText(
      doc,
      `${response.bus.authorizationDocument}`,
      145,
      (myY += 10),
      90,
      'left',
    );
  }
};

const printDocumentBody = (doc, itemList) => {
  setFont(doc);
  doc.setFontSize(8);

  let startY = 138;
  const lineSpacing = 6;

  itemList.forEach((item, index) => {
    const description = item.descripcionItem || 'N/A';
    const price = item.totalPrice || 0.0;
    const weight = item.totalWeight || 0.0;
    const quantity = item.quantity || 0.0;

    addText(doc, (index + 1).toString(), 20, startY, 80, 'center');
    addText(doc, quantity.toString(), 40, startY, 15, 'center');
    addText(doc, 'KG', 60, startY, 30, 'center');
    addText(doc, description, 110, startY, 150, 'center');
    addText(doc, weight.toString(), 174, startY, 50, 'center');
    addText(doc, `${price.toFixed(2)}`, 190, startY, 50, 'center');

    startY += lineSpacing;
  });
};
const printContingencyCarrierRemissionGuides = (contingencyguides) => {
  const doc = createPdfDocument();

  contingencyguides.forEach((guides, guideIndex) => {
    const rowsPerPage = 23;
    const pages = Math.ceil(guides.itemList.length / rowsPerPage);

    for (let i = 0; i < pages; i += 1) {
      if (i > 0 || guideIndex > 0) doc.addPage();
      myY = 65;
      printDocumentHeader(doc, guides);

      const currentPageItems = guides.itemList.slice(
        i * rowsPerPage,
        (i + 1) * rowsPerPage,
      );

      printDocumentBody(doc, currentPageItems);
    }
  });

  window.open(doc.output('bloburl'), '_blank');
};

export default printContingencyCarrierRemissionGuides;
