import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import { Form, FormGroup, Label } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import CargoCategorySelect from '../../../common/forms/select/CargoCategorySelect';

const selector = formValueSelector('CommonProductForm');

export const CommonProductForm = ({ handleSubmit, dispatchChange }) => {
  const onInternalProductChange = (e) => {
    const actualValueInternalProduct = e.target.checked;
    const value = actualValueInternalProduct ? '0' : '';
    dispatchChange('CommonProductForm', 'shippingPrice', value);
    dispatchChange(
      'CommonProductForm',
      'transshipmentIncreasePercentage',
      value,
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Nombre Producto" required>
          <Field
            name="productName"
            component={TextInput}
            type="text"
            placeholder="Nombre Producto"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción Producto" required>
          <Field
            name="productDescription"
            component={TextInput}
            type="text"
            placeholder="Descripción Producto"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Categoria" required>
          <Field
            name="cargoCategory"
            component={CargoCategorySelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Peso (kg)" required>
          <Field
            name="weight"
            component={TextInput}
            type="text"
            placeholder="Peso (kg)"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Largo (cm)">
          <Field
            name="length"
            component={TextInput}
            type="text"
            placeholder="Largo (cm)"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Ancho (cm)">
          <Field
            name="width"
            component={TextInput}
            type="text"
            placeholder="Ancho (cm)"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Alto (cm)">
          <Field
            name="height"
            component={TextInput}
            type="text"
            placeholder="Alto (cm)"
          />
        </FormItem>
      </FormGroup>

      <FormGroup row>
        <FormItem label="Translado interno">
          <Label>
            <Field
              name="internalProduct"
              component="input"
              type="checkbox"
              onChange={onInternalProductChange}
            />{' '}
            Producto interno
          </Label>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Estado">
          <Label>
            <Field name="active" component="input" type="checkbox" /> Activo
          </Label>
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

CommonProductForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  internalProductValue: selector(state, 'internalProduct'),
});

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({ form: 'CommonProductForm' })(
  CommonProductForm,
);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
