import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';

const SeatSelectionHeader = ({
  itinerarySearchResult: {
    seatMapDisplayName,
    arrivalTime,
    departureTime,
    origin,
    destination,
    itineraryScheduleName,
  },
  isForMine,
}) => {
  // departure
  const formattedDepartureTime = tzNormalizeDate({
    date: departureTime,
    format: DATE_TIME_FORMAT,
  });

  // arrival
  const formattedArrivalTime = tzNormalizeDate({
    date: arrivalTime,
    format: DATE_TIME_FORMAT,
  });

  return (
    <Row className="text-center">
      <Col>
        <h5>{origin.name}</h5>
        <h6>{`${formattedDepartureTime}`}</h6>
      </Col>
      <Col>
        <div>
          <i className="fa fa-arrow-right fa-3x" />
        </div>
        <h5>{isForMine ? itineraryScheduleName : seatMapDisplayName}</h5>
      </Col>
      <Col>
        <h5>{destination.name}</h5>
        <h6>{`${formattedArrivalTime}`}</h6>
      </Col>
    </Row>
  );
};

SeatSelectionHeader.propTypes = {
  itinerarySearchResult: PropTypes.shape({
    seatMapDisplayName: PropTypes.string.isRequired,
    arrivalTime: PropTypes.number.isRequired,
    departureTime: PropTypes.number.isRequired,
    origin: PropTypes.PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    destination: PropTypes.PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    itineraryScheduleName: PropTypes.string.isRequired,
  }).isRequired,
  isForMine: PropTypes.bool.isRequired,
};

export default SeatSelectionHeader;
