import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_GENERAL_INCOMES,
  GET_GENERAL_INCOMES,
  CLEAR_GENERAL_INCOMES,
  FLAG_GENERAL_INCOME_ACTIVITY,
  CLEAR_GENERAL_INCOME,
  GET_GENERAL_INCOME,
} from '../types';
import {
  GENERAL_INCOME_ENDPOINT,
  generateSalesSessionIncomeEnpoint,
} from '../../config/endpoints';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { GENERAL_INCOME_PATH } from '../../config/paths';

const flagGettingGeneralIncomes = (flag) => (dispatch) => {
  dispatch({
    type: FLAG_GETTING_GENERAL_INCOMES,
    payload: flag,
  });
};

const getSalesSessionIncome =
  async ({ salesSessionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingGeneralIncomes(true));

      const url = generateSalesSessionIncomeEnpoint(salesSessionId);

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);

      const income = await response.json();

      dispatch({
        type: GET_GENERAL_INCOMES,
        payload: { content: income },
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingGeneralIncomes(false));
    }
  };

const clearGeneralIncomes = () => (dispatch) => {
  dispatch({
    type: CLEAR_GENERAL_INCOMES,
  });
};

const getGeneralIncomes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingGeneralIncomes(true));

    const query = tableFilters;

    const url = `${GENERAL_INCOME_ENDPOINT}?${QueryString.stringify(query)}`;

    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response, null, dispatch);

    const incomes = await response.json();

    dispatch({ type: GET_GENERAL_INCOMES, payload: incomes });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingGeneralIncomes(false));
  }
};

const flagGeneralIncomeActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GENERAL_INCOME_ACTIVITY,
    payload: flag,
  });

const clearGeneralIncome = () => (dispatch) =>
  dispatch({
    type: CLEAR_GENERAL_INCOME,
  });

const getGeneralIncome =
  async ({ generalIncomeId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGeneralIncomeActivity(true));

      const url = `${GENERAL_INCOME_ENDPOINT}/${generalIncomeId}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);

      const income = await response.json();

      dispatch({ type: GET_GENERAL_INCOME, payload: income });
    } catch ({ message }) {
      toastr.error('Error', { message });
    } finally {
      dispatch(flagGeneralIncomeActivity(false));
    }
  };

const postGeneralIncome =
  async ({
    payerCustomerId,
    authorizerUserId,
    documentSeries,
    documentCode,
    description,
    amount,
    generalIncomeTypeId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagGeneralIncomeActivity(true));

      const payload = {
        payerCustomerId,
        authorizerUserId,
        documentSeries,
        documentCode,
        description,
        amount,
        generalIncomeTypeId,
      };

      const url = GENERAL_INCOME_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(`${GENERAL_INCOME_PATH}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGeneralIncomeActivity(false));
    }
  };

const putGeneralIncome =
  async (
    generalIncomeId,
    {
      payerCustomerId,
      authorizerUserId,
      documentSeries,
      documentCode,
      description,
      amount,
      generalIncomeTypeId,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagGeneralIncomeActivity(true));

      const payload = {
        payerCustomerId,
        authorizerUserId,
        documentSeries,
        documentCode,
        description,
        amount,
        generalIncomeTypeId,
        id: generalIncomeId,
      };

      const url = `${GENERAL_INCOME_ENDPOINT}/${generalIncomeId}`;

      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      const income = await response.json();

      dispatch(push(`${GENERAL_INCOME_PATH}/${income.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGeneralIncomeActivity(false));
    }
  };

const deleteGeneralIncome =
  async ({ generalIncomeId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGeneralIncomeActivity(true));

      const url = `${GENERAL_INCOME_ENDPOINT}/${generalIncomeId}`;

      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(GENERAL_INCOME_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGeneralIncomeActivity(false));
    }
  };

export {
  getSalesSessionIncome,
  clearGeneralIncomes,
  getGeneralIncomes,
  clearGeneralIncome,
  postGeneralIncome,
  getGeneralIncome,
  putGeneralIncome,
  deleteGeneralIncome,
};
