import { push, replace } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  AUTHENTICATE_USER,
  FLAG_AUTHENTICATING_USER,
  CLEAR_ERROR_AUTHENTICATING_USER,
  SHOW_ERROR_AUTHENTICATING_USER,
  SHOW_ERROR_FETCHING_SELF,
  FETCH_SELF,
  DEAUTHENTICATE_USER,
  SHOW_ERROR_DEAUTHENTICATING_USER,
  FLAG_DEAUTHENTICATING_USER,
  CLEAR_ERROR_DEAUTHENTICATING_USER,
  GET_REDIRECT_TO_PATH,
  CLEAR_REDIRECT_TO_PATH,
} from './types';
import { HOME_PATH } from '../config/paths';
import {
  ENDPOINT_CONFIGURATION_ENDPOINT,
  SIGN_IN_ENDPOINT,
  SIGN_OUT_ENDPOINT,
  BASE_PUBLIC_URL,
  SELF_ENDPOINT,
  REGISTER_ENDPOINT,
} from '../config/endpoints';
import { handleResponseError, isErrorResponse } from '../utils/error-handlers';
import Cookie from '../utils/Cookie';
import { DEFAULT_GET_CONFIG } from '../config/rest';

// todo: handle different environments
// requires pointing 127.0.0.1 to local.civa.io
const AUTH_HEADER = 'Basic bXktdHJ1c3RlZC1jbGllbnQ6MTIzNDU=';

const flagAuthenticatingUser = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_AUTHENTICATING_USER,
  });

const flagDeauthenticatingUser = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_DEAUTHENTICATING_USER,
  });

const clearErrorAuthenticatingUser = () => ({
  type: CLEAR_ERROR_AUTHENTICATING_USER,
});

const clearErrorDeauthenticatingUser = () => ({
  type: CLEAR_ERROR_DEAUTHENTICATING_USER,
});

const fetchSelf = async () => async (dispatch) => {
  dispatch(flagAuthenticatingUser(true));
  const url = SELF_ENDPOINT;
  let response;
  try {
    response = await fetch(url, {
      credentials: 'include',
    });
    const error = await handleResponseError(response);
    if (error) {
      dispatch(flagAuthenticatingUser(false));
      dispatch({
        type: SHOW_ERROR_FETCHING_SELF,
        payload: error,
      });
    }

    if (response.headers.get('x-show-registration-form')) {
      throw dispatch(push('/register'));
    }

    const user = await response.json();
    // this dispatch has to come before lowering
    // the flag otherwise the redirects kick in
    dispatch({
      type: FETCH_SELF,
      payload: user,
    });
    dispatch(flagAuthenticatingUser(false));
  } catch (err) {
    if (response.headers.get('X-Redirect-Uri')) {
      dispatch({
        type: GET_REDIRECT_TO_PATH,
        payload: response.headers.get('X-Redirect-Uri'),
      });
    }
    if (err === undefined) throw err;
    dispatch(flagAuthenticatingUser(false));
    dispatch({
      type: SHOW_ERROR_FETCHING_SELF,
    });
  }
};

const authenticateUser =
  async ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch(clearErrorAuthenticatingUser());
      dispatch(flagAuthenticatingUser(true));
      const url = BASE_PUBLIC_URL + ENDPOINT_CONFIGURATION_ENDPOINT;

      const authorizationServerResponse = await fetch(url);

      // check for response errors
      const authorizationServerError = await handleResponseError(
        authorizationServerResponse,
      );
      if (authorizationServerError) {
        dispatch({
          type: SHOW_ERROR_AUTHENTICATING_USER,
          payload: authorizationServerError,
        });
      }

      // prepare form
      const formData = new FormData();
      formData.append('username', email);
      formData.append('password', password);
      formData.append('grant_type', 'password');

      const { authorizationServer } = await authorizationServerResponse.json();
      const loginUrl = authorizationServer + SIGN_IN_ENDPOINT;
      const loginResponse = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          Authorization: AUTH_HEADER,
        },
        body: formData,
      });

      // check for response errors
      const loginError = await handleResponseError(loginResponse);
      if (loginError) {
        dispatch({
          type: SHOW_ERROR_AUTHENTICATING_USER,
          payload: loginError,
        });
      }

      // the response is a response instance
      // parse the data into a usable format using `.json()`
      const accessObj = await loginResponse.json();
      const accessToken = accessObj.access_token;
      if (accessToken) {
        // update state to indicate that the user is authenticated
        dispatch({
          type: AUTHENTICATE_USER,
          payload: accessToken,
        });
        await dispatch(fetchSelf());

        // if there is a redirect handle it here
        const redirect = sessionStorage.getItem('redirect');
        if (redirect) {
          sessionStorage.removeItem('redirect');
          dispatch(push(redirect));
        }

        // todo: remove when all front end has been migrated
        if (HOME_PATH !== '/') {
          window.location.href = HOME_PATH;
        }
        dispatch(push(HOME_PATH));
      }
    } catch (err) {
      dispatch({
        type: SHOW_ERROR_AUTHENTICATING_USER,
        payload: 'Error en el proceso de autenticación.',
      });
    } finally {
      dispatch(flagAuthenticatingUser(false));
    }
  };

const deauthenticateUser = async () => async (dispatch) => {
  dispatch(clearErrorDeauthenticatingUser());
  dispatch(flagDeauthenticatingUser(true));
  const url = BASE_PUBLIC_URL + ENDPOINT_CONFIGURATION_ENDPOINT;
  const accessToken = Cookie.get('access_token');
  if (accessToken) {
    try {
      const authorizationServerResponse = await fetch(url);

      // check for response errors
      const authorizationServerError = await handleResponseError(
        authorizationServerResponse,
      );
      if (authorizationServerError) {
        return dispatch({
          type: SHOW_ERROR_DEAUTHENTICATING_USER,
          payload: authorizationServerError,
        });
      }

      const { authorizationServer } = await authorizationServerResponse.json();
      const signOutUrl = `${authorizationServer + SIGN_OUT_ENDPOINT}`;
      const signOutResponse = await fetch(signOutUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: 'include',
      });
      // check for response errors
      const signOutError = await handleResponseError(signOutResponse);
      if (signOutError) {
        return dispatch({
          type: SHOW_ERROR_DEAUTHENTICATING_USER,
          payload: signOutError,
        });
      }
      dispatch(flagDeauthenticatingUser(false));
      // update state to indicate that the user is deauthenticated
      dispatch({
        type: DEAUTHENTICATE_USER,
      });
      const { headers } = signOutResponse;
      const redirect = headers.get('X-Redirect-Uri');
      return dispatch(push(redirect));
    } catch (err) {
      // lower flag
      dispatch(flagDeauthenticatingUser(false));
      return dispatch({
        type: SHOW_ERROR_DEAUTHENTICATING_USER,
        payload: 'Error en el proceso de salida de la aplicación.',
      });
    }
  }
  return dispatch({
    type: DEAUTHENTICATE_USER,
  });
};

const authenticateUserJwt =
  async ({ accessToken }) =>
  async (dispatch) => {
    try {
      dispatch(flagAuthenticatingUser(true));

      if (accessToken) {
        // update state to indicate that the user is authenticated
        dispatch({
          type: AUTHENTICATE_USER,
          payload: accessToken,
        });
        await dispatch(fetchSelf());

        // if there is a redirect handle it here
        const redirect = sessionStorage.getItem('redirect');
        if (redirect) {
          sessionStorage.removeItem('redirect');
          dispatch(push(redirect));
        }

        // todo: remove when all front end has been migrated
        if (HOME_PATH !== '/') {
          window.location.href = HOME_PATH;
        }
        dispatch(push(HOME_PATH));
      }
    } catch (err) {
      dispatch({
        type: SHOW_ERROR_AUTHENTICATING_USER,
        payload: 'Error en el proceso de autenticación.',
      });
    } finally {
      dispatch(flagAuthenticatingUser(false));
    }
  };

const getNewUser =
  async ({ email }) =>
  async (dispatch) => {
    try {
      dispatch(flagAuthenticatingUser(true));
      const url = `${REGISTER_ENDPOINT}?email=${email}`;
      const response = await fetch(url, {
        ...DEFAULT_GET_CONFIG,
      });
      await isErrorResponse(response);

      await dispatch(fetchSelf());

      const redirect = sessionStorage.getItem('redirect');
      if (redirect) {
        sessionStorage.removeItem('redirect');
        dispatch(push(redirect));
      }

      // todo: remove when all front end has been migrated
      if (HOME_PATH !== '/') {
        window.location.href = HOME_PATH;
      }
      dispatch(push(HOME_PATH));
    } catch (err) {
      dispatch({
        type: SHOW_ERROR_AUTHENTICATING_USER,
        payload: 'Error en el proceso de autenticación.',
      });
      toastr.error('Error', err.message);
    } finally {
      dispatch(flagAuthenticatingUser(false));
    }
  };

const getAuthServer = async () => async (dispatch) => {
  const url = BASE_PUBLIC_URL + ENDPOINT_CONFIGURATION_ENDPOINT;
  const authorizationServerResponse = await fetch(url);

  // check for response errors
  const authorizationServerError = await handleResponseError(
    authorizationServerResponse,
  );
  if (authorizationServerError) {
    return dispatch({
      type: SHOW_ERROR_DEAUTHENTICATING_USER,
      payload: authorizationServerError,
    });
  }

  const { authorizationServer } = await authorizationServerResponse.json();
  return authorizationServer;
};

const dispatchPush = (path) => (dispatch) => {
  dispatch(push(path));
};

const dispatchReplace = (path) => (dispatch) => {
  dispatch(replace(path));
};

const clearRedirectToPath = () => (dispatch) => {
  dispatch({
    type: CLEAR_REDIRECT_TO_PATH,
  });
};

export * from './report';
export * from './bus';
export * from './traffic';
export * from './route';
export * from './booking';
export * from './location';
export * from './accounting';
export * from './user';
export * from './sales';
export * from './itinerary';
export * from './reservation';
export * from './human-resources';
export * from './authentication';
export * from './baggage';
export * from './cargo';
export * from './contract';
export * from './security';
export * from './system';
export * from './mechanical-maintenance';

export {
  authenticateUser,
  clearErrorAuthenticatingUser,
  fetchSelf,
  deauthenticateUser,
  flagAuthenticatingUser,
  authenticateUserJwt,
  getNewUser,
  getAuthServer,
  dispatchPush,
  clearRedirectToPath,
  dispatchReplace,
};
