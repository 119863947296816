import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { PARCEL_SERVICE_TYPE_PATH } from '../../../../config/paths';
import ParcelServiceTypeDeleteButton from './ParcelServiceTypeDeleteButton';

const ParcelServiceTypeToolbar = ({ parcelServiceTypeId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton
        path={`${PARCEL_SERVICE_TYPE_PATH}/${parcelServiceTypeId}/edit`}
      />{' '}
      <ParcelServiceTypeDeleteButton
        parcelServiceTypeId={parcelServiceTypeId}
      />
    </div>
  </ButtonToolbar>
);

ParcelServiceTypeToolbar.propTypes = {
  parcelServiceTypeId: PropTypes.number.isRequired,
};

export default ParcelServiceTypeToolbar;
