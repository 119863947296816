import React from 'react';
import PropTypes from 'prop-types';
import { numberFormatter } from '../../../../utils/number';
import { parcelOtherIncomesSummaryPropTypes } from './SalesSessionPropTypes';
import Table from '../../../common/Table';
import CollapsibleHeader from '../../../layout/CollapsibleHeader';
import OtherIncomesParcelsTable from './OtherIncomesParcelsTable';

const ParcelOtherIncomesSummary = ({
  parcelOtherIncomesSummaryList,
  salesSessionId,
}) => {
  if (!parcelOtherIncomesSummaryList.length) {
    return null;
  }
  return (
    <>
      <hr />
      <CollapsibleHeader
        headerText="Otros Ingresos de Cargo"
        component={OtherIncomesParcelsTable}
        salesSessionId={salesSessionId}
      />
      <Table
        columns={[
          {
            Header: 'Método de Pago',
            accessor: 'moneyFlowConcept',
            className: 'text-center',
          },
          {
            Header: 'Total',
            accessor: 'totalAmount',
            Cell: ({ value }) => numberFormatter({ value }),
            Footer: (row) =>
              `Total: ${numberFormatter({
                value: row.data.reduce(
                  (accumulated, data) => accumulated + data.totalAmount,
                  0,
                ),
              })}`,
            className: 'text-right',
          },
        ]}
        data={parcelOtherIncomesSummaryList}
        defaultPageSize={parcelOtherIncomesSummaryList.length}
        showPagination={false}
      />
    </>
  );
};

ParcelOtherIncomesSummary.propTypes = {
  parcelOtherIncomesSummaryList: parcelOtherIncomesSummaryPropTypes.isRequired,
  salesSessionId: PropTypes.number.isRequired,
};

export default ParcelOtherIncomesSummary;
