import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { NEW_PAYMENT_PATH, PAYMENT_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { PAYMENT_COLUMNS } from '../../../../config/columns';
import {
  clearContractPayments,
  getContractPayments,
} from '../../../../actions';

export const Payments = ({
  breadcrumbs,
  payments,
  loading,
  dispatchGetContractPayments,
  dispatchClearContractPayments,
}) => {
  useLayoutEffect(() => () => dispatchClearContractPayments(), []);

  const data = payments.get('content') || [];
  const pages = payments.get('totalPages') || null;
  const defaultPageSize = payments.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Pagos"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_PAYMENT_PATH}
      tableStructure={{
        columns: PAYMENT_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetContractPayments,
        modelPath: PAYMENT_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetContractPayments: getContractPayments,
  dispatchClearContractPayments: clearContractPayments,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Pagos',
      href: PAYMENT_PATH,
    },
  ],
  payments: ContractUnit.Payment.getIn(['all', 'content']),
  loading: ContractUnit.Payment.getIn(['all', 'loading']),
});

Payments.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  payments: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetContractPayments: PropTypes.func.isRequired,
  dispatchClearContractPayments: PropTypes.func.isRequired,
};

Payments.defaultProps = {
  payments: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
