import PropTypes from 'prop-types';
import React from 'react';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../utils/date';
import { FULL_DATE_TIME_FORMAT } from '../../../../config/locale';

const ParcelPriceConfigurationBasicInformationResource = ({
  id,
  name,
  value,
  createDate,
  lastUpdate,
}) => (
  <>
    <ResourceProperty label="ID:">{id}</ResourceProperty>
    <ResourceProperty label="Nombre:">{name}</ResourceProperty>
    <ResourceProperty label="Valor:">{value}</ResourceProperty>
    <ResourceProperty label="Fecha de Creación:">
      {tzNormalizeDate({ date: createDate, format: FULL_DATE_TIME_FORMAT })}
    </ResourceProperty>
    <ResourceProperty label="Última Actualización:">
      {tzNormalizeDate({ date: lastUpdate, format: FULL_DATE_TIME_FORMAT })}
    </ResourceProperty>
  </>
);

ParcelPriceConfigurationBasicInformationResource.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  createDate: PropTypes.number.isRequired,
  lastUpdate: PropTypes.number.isRequired,
};

export default ParcelPriceConfigurationBasicInformationResource;
