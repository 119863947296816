import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_MAINTENANCE_SERVICE_ORDERS,
  GET_MAINTENANCE_SERVICE_ORDERS,
  CLEAR_MAINTENANCE_SERVICE_ORDERS,
  FLAG_MAINTENANCE_SERVICE_ORDER_ACTIVITY,
  GET_MAINTENANCE_SERVICE_ORDER,
  CLEAR_MAINTENANCE_SERVICE_ORDER,
} from '../types/mechanical-maintenance';
import {
  generatePutMaintenanceServiceOrderEndpoint,
  MAINTENANCE_SERVICE_ORDER_ENDPOINT,
  MY_MAINTENANCE_SERVICE_ORDERS_ENDPOINT,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  MAINTENANCE_SERVICE_ORDER_PATH,
  generateEditMaintenanceServiceOrderStep2,
} from '../../config/paths';
import { tzNormalizeDate } from '../../utils/date';
import { ACTIVITY_STATUS } from '../../config/constants';
import { createExecutionRegistration } from './ExecutionRegistration';

const flagGettingMaintenanceServiceOrders = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MAINTENANCE_SERVICE_ORDERS,
    payload: flag,
  });

const getMaintenanceServiceOrders =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingMaintenanceServiceOrders(true));
      const url = `${MAINTENANCE_SERVICE_ORDER_ENDPOINT}?${QueryString.stringify(
        tableFilters,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_SERVICE_ORDERS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingMaintenanceServiceOrders(false));
    }
  };

const clearMaintenanceServiceOrders = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_SERVICE_ORDERS,
  });

const flagMaintenanceServiceOrderActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_MAINTENANCE_SERVICE_ORDER_ACTIVITY,
    payload: flag,
  });

const getMaintenanceServiceOrder =
  async ({ maintenanceServiceOrderId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceServiceOrderActivity(true));
      const url = `${MAINTENANCE_SERVICE_ORDER_ENDPOINT}/${maintenanceServiceOrderId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_SERVICE_ORDER,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceServiceOrderActivity(false));
    }
  };

const clearMaintenanceServiceOrder = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_SERVICE_ORDER,
  });

const postMaintenanceServiceOrder =
  async ({
    maintenanceWarningList,
    registeredBusId,
    deadline,
    priority,
    description,
    status,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceServiceOrderActivity(true));
      const payload = {
        maintenanceWarningList,
        registeredBusId,
        deadline,
        priority,
        description,
        status,
      };
      const promise = await fetch(MAINTENANCE_SERVICE_ORDER_ENDPOINT, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();

      // Register activityBusExecution
      const activityBusExecutionList = [];
      maintenanceWarningList.forEach((warning) => {
        if (warning.activityBusId)
          activityBusExecutionList.push({
            activityBusId: warning.activityBusId,
            comment:
              'This execution was registered automatically from service order',
            status: ACTIVITY_STATUS.OPEN.value,
            startDate: null,
            endDate: null,
            serviceOrderId: response.id,
          });
      });

      if (activityBusExecutionList.length > 0)
        await createExecutionRegistration(activityBusExecutionList);

      dispatch(push(MAINTENANCE_SERVICE_ORDER_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceServiceOrderActivity(false));
    }
  };

const deleteMaintenanceServiceOrder =
  async ({ maintenanceServiceOrderId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceServiceOrderActivity(true));
      const url = `${MAINTENANCE_SERVICE_ORDER_ENDPOINT}/${maintenanceServiceOrderId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(MAINTENANCE_SERVICE_ORDER_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceServiceOrderActivity(false));
    }
  };

const putMaintenanceServiceOrder =
  async (
    maintenanceServiceOrderId,
    {
      maintenanceWarningList,
      registeredBusId,
      deadline,
      priority,
      description,
      status,
    },
    insideSteps = false,
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceServiceOrderActivity(true));
      const payload = {
        id: maintenanceServiceOrderId,
        maintenanceWarningList,
        registeredBusId,
        deadline,
        priority,
        description,
        status,
        lastUpdate: tzNormalizeDate(),
      };
      const url = generatePutMaintenanceServiceOrderEndpoint(
        maintenanceServiceOrderId,
      );
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      if (!insideSteps) dispatch(push(MAINTENANCE_SERVICE_ORDER_PATH));
      else
        dispatch(
          push(
            generateEditMaintenanceServiceOrderStep2(maintenanceServiceOrderId),
          ),
        );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceServiceOrderActivity(false));
    }
  };

const getMyMaintenanceServiceOrders =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingMaintenanceServiceOrders(true));
      const url = `${MY_MAINTENANCE_SERVICE_ORDERS_ENDPOINT}?${QueryString.stringify(
        tableFilters,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_SERVICE_ORDERS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingMaintenanceServiceOrders(false));
    }
  };

export {
  flagGettingMaintenanceServiceOrders,
  getMaintenanceServiceOrders,
  clearMaintenanceServiceOrders,
  flagMaintenanceServiceOrderActivity,
  getMaintenanceServiceOrder,
  postMaintenanceServiceOrder,
  clearMaintenanceServiceOrder,
  deleteMaintenanceServiceOrder,
  putMaintenanceServiceOrder,
  getMyMaintenanceServiceOrders,
};
