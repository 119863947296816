import React, { useLayoutEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getSalesSession,
  clearSalesSession,
  clearActiveSalesSession,
  getLiquidationDifferenceReasons,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import { SALES_SESSION_PATH } from '../../../../config/paths';
import TicketsSummary from './TicketsSummary';
import {
  salesSessionPropTypes,
  oldSalesSessionPropTypes,
  liquidationDifferenceReasonsPropTypes,
} from './SalesSessionPropTypes';
import Difference from './Difference';
import IncomeSummary from './IncomeSummary';
import ExpenseSummary from './ExpenseSummary';
import CashBalance from './CashBalance';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import CashBalanceForm from './CashBalanceForm';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ParcelIncomeSummary from './ParcelIncomeSummary';
import ExcessBaggageSummary from './ExcessBaggageSummary';
import Content from '../../../layout/Content';
import { numberFormatter } from '../../../../utils/number';
import { tzNormalizeDate } from '../../../../utils/date';
import ParcelCreditSummary from './ParcelCreditSummary';
import ParcelOtherIncomesSummary from './ParcelOtherIncomesSummary';

const SalesSession = ({
  match: {
    params: { id },
  },
  dispatchGetSalesSession,
  dispatchGetLiquidationDifferenceReasons,
  dispatchClearSalesSession,
  dispatchClearActiveSalesSession,
  loading,
  breadcrumbs,
  salesSession,
  oldSalesSession,
  liquidationDifferenceReasons,
}) => {
  useLayoutEffect(() => {
    dispatchGetSalesSession({ salesSessionId: id });
    dispatchGetLiquidationDifferenceReasons(DEFAULT_QUERY_GET_ALL);

    return () => {
      dispatchClearSalesSession();
      dispatchClearActiveSalesSession();
    };
  }, []);

  let content = <div />;

  if (loading) content = <Loader />;
  else if (!salesSession.isEmpty()) {
    const salesSessionId = salesSession.get('id');
    const isCurrentSalesSession = Boolean(!salesSession.get('endDate'));
    let endDateContent;
    if (!isCurrentSalesSession)
      endDateContent = (
        <ResourceProperty label="Fecha de Fin: " boldLabel={false}>
          {tzNormalizeDate({
            date: salesSession.get('endDate'),
            format: DATE_TIME_FORMAT,
          })}
        </ResourceProperty>
      );

    let cashOnHandContent = (
      <CashBalance
        cashOnHand={oldSalesSession.get('liquidationSession').cashOnHand}
      />
    );

    if (isCurrentSalesSession)
      cashOnHandContent = (
        <CashBalanceForm
          liquidationDifferenceReasons={liquidationDifferenceReasons}
          salesSessionId={salesSessionId}
          cashSales={oldSalesSession.get('liquidationSession').netCashIncome}
        />
      );

    const { actualBalance } = oldSalesSession.get('agency');

    const actualBalanceContent = actualBalance ? (
      <ResourceProperty label="Saldo a Favor: ">
        {numberFormatter({ value: actualBalance })}
      </ResourceProperty>
    ) : null;

    content = (
      <Fragment>
        <ResourceProperty label="ID: " boldLabel={false}>
          {salesSession.get('id')}
        </ResourceProperty>
        <ResourceProperty label="Agencia: " boldLabel={false}>
          {salesSession.get('agency')}
        </ResourceProperty>
        <ResourceProperty label="Usuario: " boldLabel={false}>
          {salesSession.get('user')}
        </ResourceProperty>
        <ResourceProperty label="Caja: " boldLabel={false}>
          {salesSession.get('workstation')}
        </ResourceProperty>
        <ResourceProperty label="Fecha de Inicio: " boldLabel={false}>
          {tzNormalizeDate({
            date: salesSession.get('startDate'),
            format: DATE_TIME_FORMAT,
          })}
        </ResourceProperty>
        {endDateContent}
        {actualBalanceContent}
        <TicketsSummary
          salesSessionId={salesSessionId}
          ticketSummaryList={salesSession.get('ticketSummaryList')}
          ticketPaymentMethodSummaryList={salesSession.get(
            'ticketPaymentMethodSummaryList',
          )}
          totalTicketIncome={salesSession.get('totalTicketIncome')}
        />
        <IncomeSummary
          salesSessionId={salesSessionId}
          generalIncomeTypeSummaryList={salesSession.get(
            'generalIncomeTypeSummaryList',
          )}
          totalGeneralIncome={salesSession.get('totalGeneralIncome')}
          numberOfGeneralIncomes={salesSession.get('numberOfGeneralIncomes')}
        />
        <ExcessBaggageSummary
          salesSessionId={salesSessionId}
          excessBaggageSummaryList={salesSession.get(
            'excessBaggageSummaryList',
          )}
          excessBaggagePaymentMethodSummaryList={salesSession.get(
            'excessBaggagePaymentMethodSummaryList',
          )}
        />
        <ExpenseSummary
          salesSessionId={salesSessionId}
          expenseTypeSummaryList={salesSession.get('expenseTypeSummaryList')}
          totalExpense={salesSession.get('totalExpense')}
          numberOfExpenses={salesSession.get('numberOfExpenses')}
        />
        <ParcelIncomeSummary
          salesSessionId={salesSessionId}
          parcelIncomeSummaryList={salesSession.get('parcelIncomeSummaryList')}
          parcelPaymentMethodSummaryList={salesSession.get(
            'parcelPaymentMethodSummaryList',
          )}
        />
        <ParcelCreditSummary
          parcelCreditSummaryList={salesSession.get('parcelCreditSummaryList')}
        />
        <ParcelOtherIncomesSummary
          salesSessionId={salesSessionId}
          parcelOtherIncomesSummaryList={salesSession.get(
            'parcelOtherIncomesSummaryList',
          )}
        />
        <hr />
        {cashOnHandContent}
        <Difference
          liquidationSession={oldSalesSession.get('liquidationSession')}
        />
      </Fragment>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Sesión de Venta"
      content={content}
    />
  );
};

SalesSession.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetSalesSession: PropTypes.func.isRequired,
  dispatchClearSalesSession: PropTypes.func.isRequired,
  dispatchGetLiquidationDifferenceReasons: PropTypes.func.isRequired,
  dispatchClearActiveSalesSession: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  salesSession: salesSessionPropTypes,
  oldSalesSession: oldSalesSessionPropTypes,
  liquidationDifferenceReasons: liquidationDifferenceReasonsPropTypes,
};

SalesSession.defaultProps = {
  salesSession: undefined,
  oldSalesSession: undefined,
  liquidationDifferenceReasons: [],
};

const mapStateToProps = ({ SalesUnit, AccountingUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Sesiones de Venta',
      href: SALES_SESSION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  loading:
    !SalesUnit.SalesSession.getIn(['current', 'activity']).isEmpty() ||
    AccountingUnit.LiquidationDifferenceReason.getIn(['all', 'loading']),
  salesSession: SalesUnit.SalesSession.getIn(['current', 'content']),
  oldSalesSession: SalesUnit.SalesSession.getIn(['current', 'oldSalesSession']),
  liquidationDifferenceReasons:
    AccountingUnit.LiquidationDifferenceReason.getIn([
      'all',
      'content',
      'content',
    ]),
});

const mapDispatchToProps = {
  dispatchGetSalesSession: getSalesSession,
  dispatchClearSalesSession: clearSalesSession,
  dispatchGetLiquidationDifferenceReasons: getLiquidationDifferenceReasons,
  dispatchClearActiveSalesSession: clearActiveSalesSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesSession);
