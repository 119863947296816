import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_COMPANY_TICKETS,
  GET_COMPANY_TICKETS,
  CLEAR_COMPANY_TICKETS,
  FLAG_COMPANY_TICKET_ACTIVITY,
  GET_COMPANY_TICKET,
  CLEAR_COMPANY_TICKET,
} from '../types';
import {
  COMPANY_TICKET_ENDPOINT,
  COMPANY_TICKET_ENDPOINT_V2,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';

const flagGettingCompanyTicket = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_COMPANY_TICKETS,
    payload: flag,
  });

const getCompanyTickets = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCompanyTicket(true));

    const query = tableFilters;

    const url = `${COMPANY_TICKET_ENDPOINT}?${QueryString.stringify(query)}`;

    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response, null, dispatch);

    const tickets = await response.json();

    dispatch({
      type: GET_COMPANY_TICKETS,
      payload: tickets,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingCompanyTicket(false));
  }
};

const clearCompanyTickets = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_TICKETS,
  });

const flagCompanyTicketActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_COMPANY_TICKET_ACTIVITY,
    payload: flag,
  });

const getCompanyTicket =
  async ({ companyTicketId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyTicketActivity(true));

      const url = `${COMPANY_TICKET_ENDPOINT_V2}/${companyTicketId}`;

      const response = await fetch(url, DEFAULT_GET_CONFIG);

      await isErrorResponse(response, null, dispatch);

      const ticket = await response.json();

      dispatch({
        type: GET_COMPANY_TICKET,
        payload: ticket,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCompanyTicketActivity(false));
    }
  };

const clearCompanyTicket = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_TICKET,
  });

export {
  getCompanyTickets,
  clearCompanyTickets,
  getCompanyTicket,
  clearCompanyTicket,
};
