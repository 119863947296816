import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
  AGENCY_PATH,
  USER_PATH,
  WORKSTATION_PATH,
} from '../../../../config/paths';
import { getAgency, clearAgency } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import AddressResource from '../../../common/resource/AddressResource';
import { Table } from '../../../common/Table';
import AgencyToolbar from './AgencyToolbar';
import { CONTRACT_TYPE, DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import IsActive from '../../../common/cells/IsActive';
import { DATE_FORMAT } from '../../../../config/locale';
import DateCell from '../../../common/cells/DateCell';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import { numberFormatter } from '../../../../utils/number';
import Checkbox from '../../../common/forms/Checkbox';
import Alert from '../../../common/informative/Alert';
import {
  AGENCY_WITHOUT_USERS_INFO_MESSAGE,
  AGENCY_WITHOUT_WORKSTATIONS_INFO_MESSAGE,
} from '../../../../config/messages';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';

const Agency = ({
  dispatchGetAgency,
  match: {
    params: { id: agencyId },
  },
  dispatchClearAgency,
  breadcrumbs,
  agency,
  loading,
}) => {
  useLayoutEffect(() => {
    dispatchGetAgency({ agencyId });

    return () => dispatchClearAgency();
  }, []);

  let content = null;
  let toolbar = null;

  if (loading) content = <Loader />;
  else if (agency.isEmpty())
    content = <NoDataResource returnPage={AGENCY_PATH} />;
  else {
    const basicInformationResource = (
      <>
        <h3>Información Básica</h3>
        <ResourceProperty label="Nombre:">
          {agency.get('name')}
        </ResourceProperty>
        <ResourceProperty label="Descripción:">
          {agency.get('description')}
        </ResourceProperty>
        <ResourceProperty label="Código SUNAT:">
          {agency.get('agencySunatCode') || '-'}
        </ResourceProperty>
        <ResourceProperty label="Grupo Agencia:">
          {agency.get('agencyGroup') ? agency.get('agencyGroup').name : '-'}
        </ResourceProperty>
        <ResourceProperty label="Estado:">
          <IsActive value={agency.get('active')} />
        </ResourceProperty>
      </>
    );

    const locationResource = (
      <>
        <h3>Ubicación</h3>
        <ResourceProperty label="Nombre:">
          {agency.get('location').name}
        </ResourceProperty>
        <ResourceProperty label="Dirección:">
          <AddressResource address={agency.get('location').address} />
        </ResourceProperty>
      </>
    );

    const userData = agency.get('userList');

    const userTable =
      userData.length > 0 ? (
        <Table
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
              className: 'text-center',
            },
            {
              Header: 'Nombres',
              accessor: 'customer.firstName',
              className: 'text-center',
            },
            {
              Header: 'Apellidos',
              accessor: 'customer.lastName',
              className: 'text-center',
            },
            {
              Header: 'Usuario',
              accessor: 'username',
              className: 'text-center',
            },
          ]}
          data={userData}
          defaultPageSize={
            userData.length <= 10 ? userData.length : DEFAULT_PAGE_SIZE
          }
          showPagination={userData.length > 10}
          manual={false}
          modelPath={USER_PATH}
          filterable
          openPathInNewTab
          navigateToModelOnRowClick
          defaultFilterMethod={filterMethodCaseInsensitive}
        />
      ) : (
        <Alert message={AGENCY_WITHOUT_USERS_INFO_MESSAGE} type="warning" />
      );

    const userResource = (
      <div className="mb-3">
        <h3>Usuarios</h3>
        {userTable}
      </div>
    );

    const workstationData = agency.get('workstationList');

    const workstationTable =
      workstationData.length > 0 ? (
        <Table
          columns={[
            {
              Header: 'Número de Serie',
              accessor: 'serialNumber',
              className: 'text-center',
            },
            {
              Header: 'Serie de documentos',
              accessor: 'printStationList',
              className: 'text-center',
              Cell: (row) =>
                row.value.map((station) =>
                  station.printTemplateList.map((template) => (
                    <div key={`${station}.id${template.id}`}>
                      {template.printTemplateType}: {template.documentSeries}
                    </div>
                  )),
                ),
            },
          ]}
          data={workstationData}
          defaultPageSize={
            workstationData.length <= 10
              ? workstationData.length
              : DEFAULT_PAGE_SIZE
          }
          showPagination={workstationData.length > 10}
          manual={false}
          modelPath={WORKSTATION_PATH}
          filterable
          openPathInNewTab
          navigateToModelOnRowClick
          defaultFilterMethod={filterMethodCaseInsensitive}
        />
      ) : (
        <Alert
          message={AGENCY_WITHOUT_WORKSTATIONS_INFO_MESSAGE}
          type="warning"
        />
      );

    const workstationResource = (
      <div className="mb-3">
        <h3>Cajas</h3>
        {workstationTable}
      </div>
    );

    let commissionResource = null;

    if (agency.get('contractType') === CONTRACT_TYPE.AE_AGENCY.value) {
      commissionResource = (
        <>
          <ResourceProperty label="Comisión:">
            {agency.get('commission')}%
          </ResourceProperty>
          <ResourceProperty label="Exenta de Impuestos:">
            <Checkbox checked={agency.get('taxExempt')} />
          </ResourceProperty>
        </>
      );
    }

    const contractInformationResource = (
      <>
        <h3>Información de Contrato</h3>
        <ResourceProperty label="Compañia:">
          {agency.get('company') ? agency.get('company').name : '-'}
        </ResourceProperty>
        <ResourceProperty label="Tipo de Contrato:">
          {agency.get('contractType')
            ? CONTRACT_TYPE[agency.get('contractType')].label
            : '-'}
        </ResourceProperty>
        {commissionResource}
        <ResourceProperty label="Saldo Inicial:">
          {numberFormatter({ value: agency.get('initialBalance') })}
        </ResourceProperty>
        <ResourceProperty label="Saldo Actual:">
          {numberFormatter({ value: agency.get('actualBalance') })}
        </ResourceProperty>
        <ResourceProperty label="Día de Corte:">
          {agency.get('cutoffDay') || '-'}
        </ResourceProperty>
        <ResourceProperty label="Fecha de Corte Próxima:">
          {agency.get('nextCutoffDate') ? (
            <DateCell
              value={agency.get('nextCutoffDate')}
              format={DATE_FORMAT}
            />
          ) : (
            '-'
          )}
        </ResourceProperty>
        <ResourceProperty label="Período de Gracia:">
          {agency.get('slack') || '-'}
        </ResourceProperty>
      </>
    );

    const internalResource = (
      <InternalResource
        id={agency.get('id')}
        createDate={agency.get('createDate')}
        lastUpdate={agency.get('lastUpdate')}
      />
    );

    content = (
      <>
        <Row>
          <Col md={6}>{basicInformationResource}</Col>
          <Col md={6}>{locationResource}</Col>
        </Row>
        {userResource}
        {workstationResource}
        <Row>
          <Col md={6}>{contractInformationResource}</Col>
          <Col md={6}>{internalResource}</Col>
        </Row>
      </>
    );

    toolbar = (
      <AgencyToolbar
        agencyId={agency.get('id')}
        initialBalance={agency.get('initialBalance')}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Agencia"
      toolbar={toolbar}
      content={content}
    />
  );
};

Agency.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetAgency: PropTypes.func.isRequired,
  dispatchClearAgency: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  agency: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Agency.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Agencias',
      href: AGENCY_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  agency: HumanResourcesUnit.Agency.getIn(['current', 'content']),
  loading: !HumanResourcesUnit.Agency.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetAgency: getAgency,
  dispatchClearAgency: clearAgency,
};

export default connect(mapStateToProps, mapDispatchToProps)(Agency);
