import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_CARGO_DISTANCES,
  GET_CARGO_DISTANCES,
  CLEAR_CARGO_DISTANCES,
  FLAG_CARGO_DISTANCE_ACTIVITY,
  GET_CARGO_DISTANCE,
  CLEAR_CARGO_DISTANCE,
} from '../types/cargo';
import { CARGO_DISTANCE_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { CARGO_DISTANCE_PATH } from '../../config/paths';

const flagGettingCargoDistances = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CARGO_DISTANCES,
    payload: flag,
  });

const flagCargoDistanceActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CARGO_DISTANCE_ACTIVITY,
    payload: flag,
  });

const getCargoDistances = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCargoDistances(true));
    const query = tableFilters;
    const url = `${CARGO_DISTANCE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const distances = await response.json();
    dispatch({
      type: GET_CARGO_DISTANCES,
      payload: distances,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCargoDistances(false));
  }
};

const getCargoDistance =
  async ({ cargoDistanceId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoDistanceActivity(true));
      const url = `${CARGO_DISTANCE_ENDPOINT}/${cargoDistanceId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const distance = await response.json();
      dispatch({
        type: GET_CARGO_DISTANCE,
        payload: distance,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoDistanceActivity(false));
    }
  };

const clearCargoDistance = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_DISTANCE,
  });

const clearCargoDistances = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_DISTANCES,
  });

const postCargoDistance =
  async ({ sourceCityId, destinationCityId, kilometers, transships }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoDistanceActivity(true));
      const payload = {
        sourceCityId,
        destinationCityId,
        kilometers,
        transships,
      };
      const url = CARGO_DISTANCE_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const distance = await response.json();
      dispatch(push(`${CARGO_DISTANCE_PATH}/${distance.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoDistanceActivity(false));
    }
  };

const putCargoDistance =
  async (
    cargoDistanceId,
    { sourceCityId, destinationCityId, kilometers, transships },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoDistanceActivity(true));
      const payload = {
        id: cargoDistanceId,
        sourceCityId,
        destinationCityId,
        kilometers,
        transships,
      };
      const url = `${CARGO_DISTANCE_ENDPOINT}/${cargoDistanceId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const distance = await response.json();
      dispatch(push(`${CARGO_DISTANCE_PATH}/${distance.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoDistanceActivity(false));
    }
  };

const deleteCargoDistance =
  async ({ cargoDistanceId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoDistanceActivity(true));
      const url = `${CARGO_DISTANCE_ENDPOINT}/${cargoDistanceId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(CARGO_DISTANCE_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoDistanceActivity(false));
    }
  };

export {
  flagGettingCargoDistances,
  getCargoDistances,
  clearCargoDistances,
  flagCargoDistanceActivity,
  postCargoDistance,
  getCargoDistance,
  clearCargoDistance,
  putCargoDistance,
  deleteCargoDistance,
};
