import React from 'react';
import { Col, Row } from 'reactstrap';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import paymentBasicInformationPropTypes from '../proptypes/PaymentPropTypes';
import { tzNormalizeDate } from '../../../../../utils/date';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../../../config/locale';
import EnumStatus from '../../../../common/EnumStatus';
import {
  DEFAULT_PAGE_SIZE,
  MANUAL_VOUCHER_SERVICE_TYPE,
  PAYMENT_STATUS,
} from '../../../../../config/constants';
import { numberFormatter } from '../../../../../utils/number';
import Table from '../../../../common/Table';
import {
  CARGO_CORPORATE_CREDIT_UNIFY_INVOICES_COLUMNS,
  generateManualVoucherItemsColumns,
} from '../../../../../config/columns';
import { CARGO_PATH, MANUAL_VOUCHER_PATH } from '../../../../../config/paths';
import { padStart } from '../../../../../utils/string';
import LinkToModuleResource from '../../../../common/resource/LinkToModuleResource';

const PaymentBasicInformationResource = ({
  createDate,
  lastUpdate,
  companyName,
  operatorUserFullName,
  bankName,
  accountNumber,
  operationNumber,
  paymentDate,
  parcelList,
  amount,
  status,
  manualVoucher,
}) => {
  const {
    id: manualvoucherId,
    currencyCode,
    voucherTypeName,
    documentSeries,
    documentCode,
    serviceType,
    issueDate,
    description,
    manualVoucherItems: items,
  } = manualVoucher || {};

  const paymentInformationResource = (
    <>
      <h3>Información del Pago</h3>
      <ResourceProperty label="Compañía:">{companyName}</ResourceProperty>
      <ResourceProperty label="Nombre del banco:">{bankName}</ResourceProperty>
      <ResourceProperty label="Número de cuenta:">
        {accountNumber}
      </ResourceProperty>
      <ResourceProperty label="Número de operación:">
        {operationNumber}
      </ResourceProperty>
      <ResourceProperty label="Fecha de pago:">
        {tzNormalizeDate({
          date: paymentDate,
          format: DATE_TIME_FORMAT,
        })}
      </ResourceProperty>
      <ResourceProperty label="Monto:">
        {numberFormatter({
          value: amount,
        })}
      </ResourceProperty>
      <ResourceProperty label="Estado:">
        <EnumStatus enumObject={PAYMENT_STATUS} enumValue={status} />
      </ResourceProperty>
    </>
  );

  const parcelInformationResource =
    parcelList && parcelList.length ? (
      <>
        <h3>Información del Carga</h3>
        <Table
          columns={CARGO_CORPORATE_CREDIT_UNIFY_INVOICES_COLUMNS}
          data={parcelList}
          defaultPageSize={
            parcelList.length < 10 ? parcelList.length : DEFAULT_PAGE_SIZE
          }
          modelPath={CARGO_PATH}
          navigateToModelOnRowClick
          openPathInNewTab
        />
      </>
    ) : null;

  const manualVoucherInformationResource = manualVoucher ? (
    <>
      <h3>Información del Comprobante Manual</h3>
      <ResourceProperty label="Comprobante Manual:">
        <LinkToModuleResource
          text={manualvoucherId}
          href={`${MANUAL_VOUCHER_PATH}/${manualvoucherId}`}
          isExternalPath
        />
      </ResourceProperty>
      <ResourceProperty label="Tipo de Comprobante:">
        {voucherTypeName}
      </ResourceProperty>
      <ResourceProperty label="Número de Comprobante:">
        {documentSeries}-{padStart(documentCode, 7)}
      </ResourceProperty>
      <ResourceProperty label="Tipo de Servicio:">
        {MANUAL_VOUCHER_SERVICE_TYPE[serviceType].label}
      </ResourceProperty>
      <ResourceProperty label="Fecha de Expedición:">
        {issueDate
          ? tzNormalizeDate({ date: issueDate, format: DATE_FORMAT })
          : '-'}
      </ResourceProperty>
      <ResourceProperty label="Motivo o Sustento:">
        {description || '-'}
      </ResourceProperty>
      <h3>Items facturados</h3>
      <div className="mb-3">
        <Table
          columns={generateManualVoucherItemsColumns({ currencyCode })}
          data={items}
          defaultPageSize={items.length}
          showPagination={false}
        />
      </div>
    </>
  ) : null;

  const internalInformationResource = (
    <>
      <h3>Información Interna</h3>
      <ResourceProperty label="Fecha de creación:">
        {tzNormalizeDate({
          date: createDate,
          format: DATE_TIME_FORMAT,
        })}
      </ResourceProperty>
      <ResourceProperty label="Fecha de actualización:">
        {tzNormalizeDate({
          date: lastUpdate,
          format: DATE_TIME_FORMAT,
        })}
      </ResourceProperty>
      <ResourceProperty label="Usuario:">
        {operatorUserFullName}
      </ResourceProperty>
    </>
  );
  return (
    <>
      <Row>
        <Col>{paymentInformationResource}</Col>
        <Col>{internalInformationResource}</Col>
      </Row>
      {manualVoucherInformationResource}
      {parcelInformationResource}
    </>
  );
};

PaymentBasicInformationResource.propTypes = paymentBasicInformationPropTypes;

export default PaymentBasicInformationResource;
