import Immutable from 'immutable';
import {
  CLEAR_ERROR_GETTING_SHIFTS,
  CLEAR_SHIFTS,
  FLAG_GETTING_SHIFTS,
  GET_SHIFTS,
  SHOW_ERROR_GETTING_SHIFTS,
} from '../../../actions/types';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: [],
    error: null,
    loading: false,
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_SHIFTS:
      return state.setIn(['all', 'loading'], action.payload);
    case SHOW_ERROR_GETTING_SHIFTS:
      return state.setIn(['all', 'error'], action.payload);
    case CLEAR_ERROR_GETTING_SHIFTS:
      return state.deleteIn(['all', 'error']);
    case GET_SHIFTS:
      return state.setIn(['all', 'content'], action.payload);
    case CLEAR_SHIFTS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    default:
      return state;
  }
};
