import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { POST } from '../../../../config/permissions';
import { generateApprovePayment } from '../../../../config/endpoints';
import { postApproveContractPayment } from '../../../../actions';

const PaymentApproveButton = ({
  paymentId,
  dispatchPostApproveContractPayment,
}) => (
  <Button
    color="success"
    onClick={() => dispatchPostApproveContractPayment({ paymentId })}
  >
    <i className="fa fa-check-square-o" /> Aprobar
  </Button>
);

PaymentApproveButton.propTypes = {
  paymentId: PropTypes.number,
  dispatchPostApproveContractPayment: PropTypes.func.isRequired,
};

PaymentApproveButton.defaultProps = {
  paymentId: null,
};

const mapDispatchToProps = {
  dispatchPostApproveContractPayment: postApproveContractPayment,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(PaymentApproveButton);

export default withEndpointAuthorization({
  url: generateApprovePayment(),
  mapUrlParamsToProps: { paymentId: 'paymentId' },
  permissionType: POST,
})(connectedComponent);
