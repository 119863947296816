import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteContractPayment } from '../../../../actions';

const PaymentDeleteButton = ({
  dispatchDeleteContractPayment,
  paymentId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () => dispatchDeleteContractPayment({ paymentId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

PaymentDeleteButton.propTypes = {
  dispatchDeleteContractPayment: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  paymentId: PropTypes.number.isRequired,
};

PaymentDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteContractPayment: deleteContractPayment,
};

export default connect(null, mapDispatchToProps)(PaymentDeleteButton);
