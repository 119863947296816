import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormFooter from '../../../common/forms/FormFooter';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';

const FindParcelByQrForm = ({ handleProcess, handleSubmit, onCancel }) => {
  useEffect(() => {
    const input = document.querySelector('.qr-series-correlative');
    if (input) {
      input.focus();
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit(handleProcess)}>
      <FormGroup row>
        <FormItem label="Serie Correlativo" required>
          <Field
            name="serieCorrelative"
            component={TextInput}
            className="form-control qr-series-correlative"
            type="text"
            placeholder="Serie Correlativo"
          />
        </FormItem>
      </FormGroup>

      <FormFooter
        saveButtonColor="secondary"
        saveButtonIcon="fa fa-search"
        saveButtonText="Buscar"
      >
        <Button color="primary" onClick={onCancel}>
          Cancelar
        </Button>
      </FormFooter>
    </Form>
  );
};

FindParcelByQrForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'CargoPickupModelForm',
})(FindParcelByQrForm);

export default connect(null, null)(formComponent);
