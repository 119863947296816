import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { clearContractor, getContractor } from '../../../../actions';
import { CONTRACTOR_PATH } from '../../../../config/paths';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import ContractorBasicInformationResource from './resource/ContractorBasicInformationResource';
import Content from '../../../layout/Content';
import ContractorToolbar from './ContractorToolbar';

export const Contractor = ({
  breadcrumbs,
  dispatchGetContractor,
  dispatchClearContractor,
  match: {
    params: { id: contractorId },
  },
  loading,
  contractor,
}) => {
  useLayoutEffect(() => {
    dispatchGetContractor({ contractorId });

    return () => dispatchClearContractor();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (contractor.isEmpty())
    content = <NoDataResource returnPage={CONTRACTOR_PATH} />;
  else {
    const contractorJson = contractor.toJS();

    content = <ContractorBasicInformationResource {...contractorJson} />;

    toolbar = <ContractorToolbar contractorId={contractorJson.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Contratista"
      content={content}
    />
  );
};

Contractor.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearContractor: PropTypes.func.isRequired,
  dispatchGetContractor: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  contractor: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Contractor.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearContractor: clearContractor,
  dispatchGetContractor: getContractor,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Contratistas',
      href: CONTRACTOR_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  contractor: ContractUnit.Contractor.getIn(['current', 'content']),
  loading: !ContractUnit.Contractor.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contractor);
