import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_DRIVER_TYPES,
  GET_DRIVER_TYPES,
  CLEAR_DRIVER_TYPES,
  FLAG_DRIVER_TYPE_ACTIVITY,
  GET_DRIVER_TYPE,
  CLEAR_DRIVER_TYPE,
} from '../types/traffic';
import { DRIVER_TYPE_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingDriverTypes = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DRIVER_TYPES,
    payload: flag,
  });

const getDriverTypes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDriverTypes(true));
    const query = tableFilters;
    const url = `${DRIVER_TYPE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const types = await response.json();
    dispatch({
      type: GET_DRIVER_TYPES,
      payload: types,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingDriverTypes(false));
  }
};

const clearDriverTypes = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVER_TYPES,
  });

const flagDriverTypeActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DRIVER_TYPE_ACTIVITY,
    payload: flag,
  });

const getDriverType =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverTypeActivity(true));
      const url = `${DRIVER_TYPE_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const types = await response.json();
      dispatch({
        type: GET_DRIVER_TYPE,
        payload: types,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverTypeActivity(false));
    }
  };

const clearDriverType = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVER_TYPE,
  });

export {
  flagGettingDriverTypes,
  getDriverTypes,
  clearDriverTypes,
  getDriverType,
  clearDriverType,
};
