import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_SEAT_MAPS,
  GET_SEAT_MAPS,
  CLEAR_SEAT_MAPS,
} from '../types';
import { SEAT_MAP_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingSeatMaps = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_SEAT_MAPS,
    payload: flag,
  });

const getSeatMaps = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingSeatMaps(true));
    const query = tableFilters;
    const url = `${SEAT_MAP_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const seatMaps = await response.json();
    dispatch({
      type: GET_SEAT_MAPS,
      payload: seatMaps,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingSeatMaps(false));
  }
};

const clearSeatMaps = () => (dispatch) =>
  dispatch({
    type: CLEAR_SEAT_MAPS,
  });

export { flagGettingSeatMaps, getSeatMaps, clearSeatMaps };
