import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import {
  GET_ITEMS_TO_ITINERARY,
  CLEAR_ITEMS_TO_ITINERARY,
  FLAG_ITEM_TO_ITINERARY_ACTIVITY,
  UPDATE_ITEM_TO_ITINERARY,
  DELETE_ITEM_FROM_ITINERARY,
  GET_ITEMS_TO_ITINERARY_BY_PARCEL,
  CLEAR_ITEMS_TO_ITINERARY_BY_PARCEL,
  UPDATE_ITEMS_TO_ITINERARY_BY_PARCEL,
} from '../types/cargo';
import {
  CARGO_ITEM_ENDPOINT,
  GET_CARGO_ITEM_BY_ITINERARY_ENDPOINT,
  GET_CARGO_ITEM_BY_PARCEL_CORRELATION_ENDPOINT,
  PUT_ADD_CARGO_ITEM_TO_ITINERARY_ENDPOINT,
  PUT_ADD_CARGO_ITEM_TO_ITINERARY_ENDPOINT_V2,
  PUT_REMOVE_CARGO_ITEM_FROM_ITINERARY_ENDPOINT,
  PUT_REMOVE_CARGO_ITEM_FROM_ITINERARY_ENDPOINT_V2,
  PUT_REMOVE_CARGO_ITEM_FROM_MANIFEST_ENDPOINT,
} from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_PUT_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const clearItemToItinerary = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITEMS_TO_ITINERARY,
  });

const flagItemToItinerary = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_ITEM_TO_ITINERARY_ACTIVITY,
    payload: flag,
  });

const getCargoItemByItinerary =
  ({ itineraryId, ...tableFilters }) =>
  async (dispatch) => {
    try {
      dispatch(flagItemToItinerary(true));
      const query = tableFilters;
      const url = `${GET_CARGO_ITEM_BY_ITINERARY_ENDPOINT}/${itineraryId}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const items = await response.json();
      dispatch({
        type: GET_ITEMS_TO_ITINERARY,
        payload: items,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagItemToItinerary(false));
    }
  };

const getCargoItemToItinerary = (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagItemToItinerary(true));
    const query = tableFilters;
    const url = `${CARGO_ITEM_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const items = await response.json();
    dispatch({
      type: GET_ITEMS_TO_ITINERARY,
      payload: items,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagItemToItinerary(false));
  }
};
const getCargoItemsFromParcelCorrelation =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      const query = tableFilters;
      dispatch(flagItemToItinerary(true));
      const url = `${GET_CARGO_ITEM_BY_PARCEL_CORRELATION_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;

      const response = await fetch(url, DEFAULT_GET_CONFIG);

      await isErrorResponse(response, null, dispatch);
      const cargoItems = await response.json();
      dispatch({
        type: GET_ITEMS_TO_ITINERARY_BY_PARCEL,
        payload: cargoItems,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagItemToItinerary(false));
    }
  };

const clearCargoItemsFromParcelCorrelation = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITEMS_TO_ITINERARY_BY_PARCEL,
  });

const putAddItemToItinerary =
  ({
    cargoItemId,
    itineraryId,
    locationId,
    destinationLocationId,
    createDate,
    exitDate,
    internalCargoItem,
    userId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagItemToItinerary(true));
      const payload = {
        cargoItemId,
        itineraryId,
        locationId,
        destinationLocationId,
        createDate,
        exitDate,
        internalCargoItem,
        userId,
      };
      const url = PUT_ADD_CARGO_ITEM_TO_ITINERARY_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      return await response.json();
    } catch (error) {
      toastr.error('Error', error.message);
      return null;
    } finally {
      dispatch(flagItemToItinerary(false));
    }
  };

const putAddItemToItineraryV2 =
  ({
    cargoItemList,
    itineraryId,
    sourceLocationId,
    destinationLocationId,
    exitDate,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagItemToItinerary(true));
      const payload = {
        cargoItemList,
        itineraryId,
        sourceLocationId,
        destinationLocationId,
        exitDate,
      };
      const url = PUT_ADD_CARGO_ITEM_TO_ITINERARY_ENDPOINT_V2;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      toastr.success('Confirmación', 'Se subieron las encomiendas');
      return await response.json();
    } catch (error) {
      toastr.error('Error', error.message);
      return null;
    } finally {
      dispatch(flagItemToItinerary(false));
    }
  };

const putRemoveItemFromManifest =
  ({
    cargoItemId,
    itineraryId,
    locationId,
    internalCargoItem,
    manifestCargoItemId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagItemToItinerary(true));
      const payload = {
        cargoItemId,
        itineraryId,
        locationId,
        internalCargoItem,
        manifestCargoItemId,
      };
      const url = PUT_REMOVE_CARGO_ITEM_FROM_MANIFEST_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      return await response.json();
    } catch (error) {
      toastr.error('Error', error.message);
      return null;
    } finally {
      dispatch(flagItemToItinerary(false));
    }
  };

const putRemoveItemFromItinerary =
  ({ cargoItemId, itineraryId, locationId, internalCargoItem }) =>
  async (dispatch) => {
    try {
      dispatch(flagItemToItinerary(true));
      const payload = {
        cargoItemId,
        itineraryId,
        locationId,
        internalCargoItem,
      };
      const url = PUT_REMOVE_CARGO_ITEM_FROM_ITINERARY_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      return await response.json();
    } catch (error) {
      toastr.error('Error', error.message);
      return null;
    } finally {
      dispatch(flagItemToItinerary(false));
    }
  };

const putRemoveItemFromItineraryV2 =
  ({ cargoItemList, itineraryId, destinationLocationId, registeredBusId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItemToItinerary(true));
      const payload = {
        cargoItemList,
        itineraryId,
        destinationLocationId,
        registeredBusId,
      };
      const url = PUT_REMOVE_CARGO_ITEM_FROM_ITINERARY_ENDPOINT_V2;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      toastr.success('Confirmación', 'Se bajaron las encomiendas');
      return await response.json();
    } catch (error) {
      toastr.error('Error', error.message);
      return null;
    } finally {
      dispatch(flagItemToItinerary(false));
    }
  };

const updateItemToItinerary = (item) => ({
  type: UPDATE_ITEM_TO_ITINERARY,
  payload: item,
});

const updateItemToItineraryByParcel = (items) => ({
  type: UPDATE_ITEMS_TO_ITINERARY_BY_PARCEL,
  payload: items,
});

const deleteItemFromItinerary = (correlation) => ({
  type: DELETE_ITEM_FROM_ITINERARY,
  payload: correlation,
});

export {
  flagItemToItinerary,
  getCargoItemByItinerary,
  getCargoItemToItinerary,
  clearItemToItinerary,
  putAddItemToItinerary,
  putRemoveItemFromManifest,
  putRemoveItemFromItinerary,
  updateItemToItinerary,
  deleteItemFromItinerary,
  putAddItemToItineraryV2,
  getCargoItemsFromParcelCorrelation,
  clearCargoItemsFromParcelCorrelation,
  updateItemToItineraryByParcel,
  putRemoveItemFromItineraryV2,
};
