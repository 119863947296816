import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import {
  clearParcelServiceTypes,
  getParcelServiceTypes,
} from '../../../../actions/cargo/ParcelServiceType';
import {
  NEW_PARCEL_SERVICE_TYPE_PATH,
  PARCEL_SERVICE_TYPE_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { PARCEL_SERVICE_TYPE_COLUMNS } from '../../../../config/columns';

const ParcelServiceTypes = ({
  breadcrumbs,
  parcelServiceTypes,
  loading,
  dispatchGetParcelServiceTypes,
  dispatchClearParcelServiceTypes,
}) => {
  useLayoutEffect(() => () => dispatchClearParcelServiceTypes(), []);

  const data = parcelServiceTypes.get('content') || [];
  const pages = parcelServiceTypes.get('totalPages') || null;
  const defaultPageSize = parcelServiceTypes.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Tipos de Servicio"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_PARCEL_SERVICE_TYPE_PATH}
      tableStructure={{
        columns: PARCEL_SERVICE_TYPE_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetParcelServiceTypes,
        modelPath: PARCEL_SERVICE_TYPE_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

ParcelServiceTypes.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  parcelServiceTypes: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetParcelServiceTypes: PropTypes.func.isRequired,
  dispatchClearParcelServiceTypes: PropTypes.func.isRequired,
};

ParcelServiceTypes.defaultProps = {
  parcelServiceTypes: null,
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    { text: 'Tipos de Servicio', href: PARCEL_SERVICE_TYPE_PATH },
  ],
  parcelServiceTypes: CargoUnit.ParcelServiceType.getIn(['all', 'content']),
  loading: CargoUnit.ParcelServiceType.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetParcelServiceTypes: getParcelServiceTypes,
  dispatchClearParcelServiceTypes: clearParcelServiceTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParcelServiceTypes);
