import PropTypes from 'prop-types';
import Immutable from 'immutable';

const expenseSummaryListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    documentSeries: PropTypes.string.isRequired,
    numberOfRecords: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
);

const expenseTypeSummaryListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    moneyFlowConcept: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
);

const generalIncomeSummaryListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    documentSeries: PropTypes.string.isRequired,
    numberOfRecords: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
);

const generalIncomeTypeSummaryListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    moneyFlowConcept: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
);

const ticketPaymentMethodSummaryListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    moneyFlowConcept: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
);

const ticketSummaryListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    documentSeries: PropTypes.string.isRequired,
    numberOfSoldTickets: PropTypes.number,
  }),
);

const salesSessionPropTypes = PropTypes.instanceOf(Immutable.Map);

const liquidationSessionPropTypes = PropTypes.shape({
  liquidationDifferenceReasonId: PropTypes.number,
  liquidationDifferenceReason: PropTypes.shape({
    name: PropTypes.string,
  }),
  liquidationDifferenceAmount: PropTypes.number,
  liquidationDifferenceReasonDescription: PropTypes.string,
});

const oldSalesSessionPropTypes = PropTypes.shape({
  liquidationSession: liquidationSessionPropTypes,
});

const liquidationDifferenceReasonsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
);

const parcelIncomeSummaryPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    documentSeries: PropTypes.string,
    numberOfParcels: PropTypes.number,
    totalAmount: PropTypes.number,
  }),
);

const parcelPaymentMethodSummaryListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    moneyFlowConcept: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
);

const parcelCreditSummaryPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    moneyFlowConcept: PropTypes.string,
    totalAmount: PropTypes.number,
  }),
);

const parcelOtherIncomesSummaryPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    moneyFlowConcept: PropTypes.string,
    totalAmount: PropTypes.number,
  }),
);

const excessBaggageSummaryPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    activeExcessBaggageAmount: PropTypes.number.isRequired,
    activeExcessBaggageCashAmount: PropTypes.number.isRequired,
    activeExcessBaggageNumber: PropTypes.number.isRequired,
    voidedExcessBaggageAmount: PropTypes.number.isRequired,
    voidedExcessBaggageNumber: PropTypes.number.isRequired,
  }),
);

export {
  salesSessionPropTypes,
  ticketPaymentMethodSummaryListPropTypes,
  ticketSummaryListPropTypes,
  oldSalesSessionPropTypes,
  liquidationSessionPropTypes,
  generalIncomeSummaryListPropTypes,
  generalIncomeTypeSummaryListPropTypes,
  expenseSummaryListPropTypes,
  expenseTypeSummaryListPropTypes,
  liquidationDifferenceReasonsPropTypes,
  parcelIncomeSummaryPropTypes,
  excessBaggageSummaryPropTypes,
  parcelCreditSummaryPropTypes,
  parcelOtherIncomesSummaryPropTypes,
  parcelPaymentMethodSummaryListPropTypes,
};
