import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { CARGO_OPERATIONS } from '../../../../../config/constants';

const AddCargoToItineraryByQrButtonForm = ({
  handleSubmit,
  items,
  onClickAddItemToItinerary,
  setShowModal,
  cargoOperation,
}) => {
  const onSubmit = () => {
    if (cargoOperation.value === CARGO_OPERATIONS.ADD_ITEM.value) {
      const itemsToAddCorrelations = items.map((item) => item.correlation);
      onClickAddItemToItinerary({
        itemsToAddCorrelations,
      });
    }
    if (cargoOperation.value === CARGO_OPERATIONS.UNLOAD_ITEM.value) {
      const itemsToUnloadCorrelations = items.map((item) => item.correlation);
      onClickAddItemToItinerary({
        itemsToUnloadCorrelations,
      });
    }
    setShowModal(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
      <Button color="success" type="submit">
        {cargoOperation.label}
      </Button>
    </Form>
  );
};

AddCargoToItineraryByQrButtonForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      correlation: PropTypes.string,
      description: PropTypes.string,
      weight: PropTypes.number,
    }),
  ).isRequired,
  onClickAddItemToItinerary: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  cargoOperation: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};

const formComponent = reduxForm({
  form: 'addCargoToItineraryByQrButtonForm',
})(AddCargoToItineraryByQrButtonForm);

export default connect(null, null)(formComponent);
