import Immutable from 'immutable';
import {
  AUTHENTICATE_USER,
  DEAUTHENTICATE_USER,
  CLEAR_ERROR_AUTHENTICATING_USER,
  SHOW_ERROR_AUTHENTICATING_USER,
  FLAG_AUTHENTICATING_USER,
  FETCH_SELF,
  GET_REDIRECT_TO_PATH,
  CLEAR_REDIRECT_TO_PATH,
} from '../actions/types';
import Cookie from '../utils/Cookie';

const INITIAL_STATE = new Immutable.Map({
  authenticated: false,
  authenticating: false,
  user: null,
  error: '',
  redirectToPath: null,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      // access token is returned in the payload
      Cookie.set('access_token', action.payload);
      return state.set('authenticated', true).set('error', '');
    case DEAUTHENTICATE_USER:
      // remove access token from cookies
      Cookie.remove('access_token');
      return state.set('authenticated', false).delete('user').set('error', '');
    case FETCH_SELF:
      return state.set('user', action.payload).set('authenticated', true);
    case GET_REDIRECT_TO_PATH:
      return state.set('redirectToPath', action.payload);
    case CLEAR_REDIRECT_TO_PATH:
      return state.set('redirectToPath', null);
    case SHOW_ERROR_AUTHENTICATING_USER:
      return state.set('error', action.payload);
    case CLEAR_ERROR_AUTHENTICATING_USER:
      return state.set('error', '');
    case FLAG_AUTHENTICATING_USER:
      return state.set('authenticating', action.payload);
    default:
      return state;
  }
};
