import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { CONTRACTOR_PATH } from '../../../../config/paths';
import {
  clearContractor,
  getContractor,
  putContractor,
} from '../../../../actions';
import ContractorForm from './ContractorForm';

const EditContractor = ({
  breadcrumbs,
  dispatchGetContractor,
  dispatchClearContractor,
  dispatchPutContractor,
  match: {
    params: { id: contractorId },
  },
  loading,
  contractor,
}) => {
  useLayoutEffect(() => {
    dispatchGetContractor({ contractorId });

    return () => dispatchClearContractor();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPutContractor(contractorId, {
      ...formValues,
      measurementUnit: formValues.measurementUnit.value,
    });

  const generateInitialValues = ({ customer, business, company }) => ({
    customer: customer
      ? {
          value: customer.id,
          label: `${customer.fullName} | ${customer.identificationType.name}: ${customer.idDocumentNumber} (${customer.idCountryOfOrigin})`,
        }
      : null,
    business: {
      value: business.id,
      label: `${business.businessTaxId} - ${business.name}`,
    },
    company: {
      value: company.id,
      label: company.name,
    },
  });

  let content;

  if (loading) content = <Loader />;
  else if (contractor.isEmpty())
    content = <NoDataResource returnPage={CONTRACTOR_PATH} />;
  else
    content = (
      <ContractorForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(contractor.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Contratista"
      subtitle="Editar Contratista"
      content={content}
    />
  );
};

EditContractor.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearContractor: PropTypes.func.isRequired,
  dispatchGetContractor: PropTypes.func.isRequired,
  dispatchPutContractor: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  contractor: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditContractor.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearContractor: clearContractor,
  dispatchGetContractor: getContractor,
  dispatchPutContractor: putContractor,
};

const mapStateToProps = (
  { ContractUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Contratistas',
      href: CONTRACTOR_PATH,
    },
    {
      text: 'Ver',
      href: `${CONTRACTOR_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  contractor: ContractUnit.Contractor.getIn(['current', 'content']),
  loading: !ContractUnit.Contractor.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditContractor);
