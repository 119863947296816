import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_COMPANY_AREA_RESPONSABLE,
  GET_COMPANY_AREA_RESPONSABLE,
  CLEAR_COMPANY_AREA_RESPONSABLE,
} from '../types/mechanical-maintenance';
import { COMPANY_AREA_RESPONSABLE_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingCompanyAreaResponsable = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_COMPANY_AREA_RESPONSABLE,
    payload: flag,
  });

const getCompanyAreaResponsable = async () => async (dispatch) => {
  try {
    dispatch(flagGettingCompanyAreaResponsable(true));
    const url = COMPANY_AREA_RESPONSABLE_ENDPOINT;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_COMPANY_AREA_RESPONSABLE,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingCompanyAreaResponsable(false));
  }
};

const clearCompanyAreaResponsable = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_AREA_RESPONSABLE,
  });

export {
  flagGettingCompanyAreaResponsable,
  getCompanyAreaResponsable,
  clearCompanyAreaResponsable,
};
