import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { EXPENSE_TYPE_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import {
  FLAG_GETTING_EXPENSE_TYPES,
  GET_EXPENSE_TYPES,
  CLEAR_EXPENSE_TYPES,
} from '../types/accounting/ExpenseType';

const flagGettingExpenseTypes = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_EXPENSE_TYPES,
    payload: flag,
  });

const getExpenseTypes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingExpenseTypes(true));
    const query = tableFilters;
    const url = `${EXPENSE_TYPE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const expenses = await response.json();
    dispatch({ type: GET_EXPENSE_TYPES, payload: expenses });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingExpenseTypes(false));
  }
};

const clearExpenseTypes = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPENSE_TYPES,
  });

export { flagGettingExpenseTypes, getExpenseTypes, clearExpenseTypes };
