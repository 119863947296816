import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deauthenticateUser } from '../../../actions';
import Loader from '../../common/Loader';

// exporting unconnected component for testing
// https://bit.ly/2HtSJlT
export const SignOut = ({ dispatchDeauthenticateUser }) => {
  useEffect(() => {
    dispatchDeauthenticateUser();
  }, []);

  return <Loader />;
};

SignOut.propTypes = {
  dispatchDeauthenticateUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchDeauthenticateUser: deauthenticateUser,
};

export default connect(null, mapDispatchToProps)(SignOut);
