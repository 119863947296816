import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import AddCargoToItineraryByParcelButton from './AddCargoToItineraryByParcelButton';
import { CARGO_OPERATIONS } from '../../../../../config/constants';
import CargoOperationByQrButton from '../cargo-operation-qr/CargoOperationByQrButton';

const AddCargoToItineraryToolbar = ({
  onClickAddItemToItinerary,
  disabled,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      <Button color="success" type="submit" disabled={disabled}>
        Subir
      </Button>{' '}
      <CargoOperationByQrButton
        onClickAddItemToItinerary={onClickAddItemToItinerary}
        cargoOperation={CARGO_OPERATIONS.ADD_ITEM}
        disabled={disabled}
      />{' '}
      <AddCargoToItineraryByParcelButton
        onClickAddItemToItinerary={onClickAddItemToItinerary}
        disabled={disabled}
      />{' '}
    </div>
  </ButtonToolbar>
);

AddCargoToItineraryToolbar.propTypes = {
  onClickAddItemToItinerary: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AddCargoToItineraryToolbar.defaultProps = {
  disabled: false,
};
export default AddCargoToItineraryToolbar;
