import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { PAYMENT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import PaymentForm from './PaymentForm';
import {
  clearContractPayment,
  getContractPayment,
  putContractPayment,
} from '../../../../actions';

const EditPayment = ({
  breadcrumbs,
  dispatchGetContractPayment,
  dispatchClearContractPayment,
  dispatchPutContractPayment,
  match: {
    params: { id: paymentId },
  },
  loading,
  payment,
}) => {
  useLayoutEffect(() => {
    dispatchGetContractPayment({ paymentId });

    return () => dispatchClearContractPayment();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPutContractPayment(paymentId, {
      ...formValues,
    });

  const generateInitialValues = ({
    companyName,
    bankName,
    accountNumber,
    operationNumber,
    paymentDate,
    amount,
    companyId,
    parcelList,
    manualVoucher,
  }) => ({
    company: {
      value: companyName,
      label: companyName,
      id: companyId,
    },
    bankName,
    accountNumber,
    operationNumber,
    paymentDate,
    amount,
    parcelList,
    manualVoucher,
  });

  let content;

  if (loading) content = <Loader />;
  else if (payment.isEmpty())
    content = <NoDataResource returnPage={PAYMENT_PATH} />;
  else
    content = (
      <PaymentForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(payment.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Pago"
      subtitle="Editar pago"
      content={content}
    />
  );
};

EditPayment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearContractPayment: PropTypes.func.isRequired,
  dispatchGetContractPayment: PropTypes.func.isRequired,
  dispatchPutContractPayment: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  payment: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditPayment.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearContractPayment: clearContractPayment,
  dispatchGetContractPayment: getContractPayment,
  dispatchPutContractPayment: putContractPayment,
};

const mapStateToProps = (
  { ContractUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Pagos',
      href: PAYMENT_PATH,
    },
    {
      text: 'Ver',
      href: `${PAYMENT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  payment: ContractUnit.Payment.getIn(['current', 'content']),
  loading: !ContractUnit.Payment.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPayment);
