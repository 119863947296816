import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { PROCESS_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  generateItineraryByProcessStatusEditPath,
  PROCESS_PATH,
} from '../../config/paths';
import {
  CLEAR_PROCESS,
  CLEAR_PROCESSES,
  FLAG_GETTING_PROCESSES,
  FLAG_PROCESS_ACTIVITY,
  GET_PROCESS,
  GET_PROCESSES,
} from '../types';

const flagGettingProcesses = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PROCESSES,
    payload: flag,
  });

const getProcesses = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingProcesses(true));
    const query = tableFilters;
    const url = `${PROCESS_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_PROCESSES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingProcesses(false));
  }
};

const clearProcesses = () => (dispatch) =>
  dispatch({
    type: CLEAR_PROCESSES,
  });

const flagProcessActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PROCESS_ACTIVITY,
    payload: flag,
  });

const getProcess =
  async ({ processId }) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const url = `${PROCESS_ENDPOINT}/${processId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_PROCESS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

const clearProcess = () => (dispatch) =>
  dispatch({
    type: CLEAR_PROCESS,
  });

const postProcess =
  async ({ companyItineraryRequestList }) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const payload = companyItineraryRequestList;
      const url = PROCESS_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const process = await promise.json();

      dispatch(push(generateItineraryByProcessStatusEditPath(process.id)));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

const deleteProcess =
  async ({ materialId }) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const url = `${PROCESS_ENDPOINT}/${materialId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(PROCESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

const putProcess =
  async (materialId, { name, externalId, measurementUnit }) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const payload = {
        id: materialId,
        name,
        externalId,
        measurementUnit,
      };
      const url = `${PROCESS_ENDPOINT}/${materialId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(PROCESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

const postDuplicateProcess =
  async (processId, { startDate }) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const payload = startDate;
      const url = `${PROCESS_ENDPOINT}/${processId}/duplicate`;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const process = await promise.json();

      dispatch(push(generateItineraryByProcessStatusEditPath(process.id)));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

export {
  flagGettingProcesses,
  getProcesses,
  clearProcesses,
  flagProcessActivity,
  getProcess,
  postProcess,
  clearProcess,
  deleteProcess,
  putProcess,
  postDuplicateProcess,
};
