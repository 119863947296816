import React, { useEffect, useLayoutEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, InputGroupText } from 'reactstrap';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { isRequired, validateNumber } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';
import DatePicker from '../../../common/forms/input/DatePicker';
import { CURRENCY } from '../../../../config/locale';
import CompanySelect from '../../../common/forms/select/CompanySelect';
import ManualVoucherSearchInput from '../../../common/forms/input/ManualVoucherSearchInput';
import Alert from '../../../common/informative/Alert';
import { PAYMENT_FORM_NO_COMPANY_WARNING } from '../../../../config/messages';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import {
  CARGO_CORPORATE_CREDIT_UNIFY_INVOICES_COLUMNS,
  generateManualVoucherItemsColumns,
} from '../../../../config/columns';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import {
  clearCargoCorporateInvoices,
  getCargoCorporateInvoicesByCompanyId,
} from '../../../../actions/cargo/CargoCorporateCredit';
import Table from '../../../common/Table';

const selector = formValueSelector('PaymentForm');

export const PaymentForm = ({
  handleSubmit,
  onSubmit,
  parcels,
  company,
  loading,
  loadingParcels,
  dispatchChange,
  parcelList,
  initialValues,
  manualVoucher,
  dispatchClearCargoCorporateInvoices,
  dispatchGetCargoCorporateInvoicesByCompanyId,
}) => {
  const [editingMode, setEditingMode] = useState(false);

  useLayoutEffect(() => {
    if (initialValues) {
      setEditingMode(true);
    }

    return () => {
      dispatchClearCargoCorporateInvoices();
    };
  }, []);

  const changeAmountByParcel = (selectedParcelList) => {
    if (!initialValues) {
      if (selectedParcelList && selectedParcelList.length) {
        const filteredParcels = parcels
          .toArray()
          .filter((parcel) => selectedParcelList.includes(parcel.id));
        const amount = filteredParcels.reduce(
          (total, parcel) => total + parcel.salePrice,
          0,
        );
        dispatchChange('PaymentForm', 'amount', amount);
      } else {
        dispatchChange('PaymentForm', 'amount', '0');
      }
    }
  };

  useEffect(() => {
    changeAmountByParcel(parcelList);
  }, [parcelList]);

  useEffect(() => {
    if (company) {
      dispatchGetCargoCorporateInvoicesByCompanyId({ companyId: company.id });
    }
  }, [company]);

  const [manualVoucherId, setManualVoucherId] = useState(null);

  const onHandleSubmit = (formValues) => {
    const formattedParcelList = parcelList
      ? parcelList.map((id) => ({ id }))
      : [];
    onSubmit({
      ...formValues,
      manualVoucherId,
      parcelList: formattedParcelList,
    });
  };

  const onSelectManualVoucher = ({ totalAmount, id }) => {
    dispatchChange('PaymentForm', 'amount', totalAmount);
    setManualVoucherId(id);
  };

  const onClearManualVoucher = () => {
    dispatchChange('PaymentForm', 'amount', '0');
    setManualVoucherId(null);
  };

  let invoicesToPay;

  if (!editingMode) {
    if (company !== null) {
      invoicesToPay = (
        <>
          {!parcelList || !parcelList.length ? (
            <FormGroup row>
              <FormItem label="Comprobante Manual">
                <Field
                  name="manualVoucher"
                  component={ManualVoucherSearchInput}
                  placeholder="Comprobante Manual"
                  props={{
                    form: 'PaymentForm',
                  }}
                  onSelectRow={onSelectManualVoucher}
                  onClear={onClearManualVoucher}
                  byBusiness={company.business.businessTaxId}
                />
              </FormItem>
            </FormGroup>
          ) : null}

          {!manualVoucherId ? (
            <FormGroup>
              <h5>Facturas de carga</h5>
              <SelectionableTable
                name="parcelList"
                columns={CARGO_CORPORATE_CREDIT_UNIFY_INVOICES_COLUMNS}
                data={parcels.toJS()}
                defaultPageSize={DEFAULT_PAGE_SIZE}
                returnOnlySelectedItems
                keyField="id"
                loading={loadingParcels}
                form="PaymentForm"
              />
            </FormGroup>
          ) : null}
        </>
      );
    } else {
      invoicesToPay = (
        <Alert message={PAYMENT_FORM_NO_COMPANY_WARNING} type="warning" />
      );
    }
  } else {
    invoicesToPay = (
      <>
        {!parcelList || !parcelList.length ? (
          <FormGroup>
            <h3>Items facturados del Comprobante Manual</h3>
            <Table
              columns={generateManualVoucherItemsColumns({
                currencyCode: manualVoucher.currencyCode,
              })}
              data={manualVoucher.manualVoucherItems}
              defaultPageSize={manualVoucher.manualVoucherItems.length}
              showPagination={false}
            />
          </FormGroup>
        ) : null}

        {!manualVoucher ? (
          <FormGroup>
            <h5>Facturas de carga</h5>
            <Table
              columns={CARGO_CORPORATE_CREDIT_UNIFY_INVOICES_COLUMNS}
              data={parcelList}
              defaultPageSize={DEFAULT_PAGE_SIZE}
            />
          </FormGroup>
        ) : null}
      </>
    );
  }

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <FormGroup row>
        <FormItem label="Compañía" required>
          <Field
            name="company"
            component={CompanySelect}
            placeholder="Compañía"
            validate={[isRequired]}
            isDisabled={editingMode}
            retrieveCompanyObject
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Nombre del banco" required>
          <Field
            name="bankName"
            component={TextInput}
            placeholder="Nombre del banco"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Número de cuenta" required>
          <Field
            name="accountNumber"
            component={TextInput}
            placeholder="Número de cuenta"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Número de operación" required>
          <Field
            name="operationNumber"
            component={TextInput}
            placeholder="Número de operación"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha de Pago" required>
          <Field
            name="paymentDate"
            props={{
              time: true,
            }}
            component={DatePicker}
            placeholder="Fecha de Pago"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Monto" required>
          <Field
            name="amount"
            disabled
            component={TextInput}
            type="text"
            placeholder="0.00"
            validate={[isRequired, validateNumber]}
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
      {invoicesToPay}
      <FormFooter />
    </Form>
  );
};

PaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  company: PropTypes.instanceOf(Object),
  manualVoucher: PropTypes.instanceOf(Object),
  dispatchChange: PropTypes.func.isRequired,
  parcels: PropTypes.instanceOf(Immutable.Map),
  loadingParcels: PropTypes.bool,
  dispatchGetCargoCorporateInvoicesByCompanyId: PropTypes.func.isRequired,
  dispatchClearCargoCorporateInvoices: PropTypes.func.isRequired,
  parcelList: PropTypes.instanceOf(Array),
  initialValues: PropTypes.instanceOf(Object),
};
PaymentForm.defaultProps = {
  company: null,
  parcels: Immutable.Map(),
  loadingParcels: false,
  manualVoucher: null,
  parcelList: null,
  initialValues: null,
};

const mapStateToProps = (state) => ({
  loading: !state.ContractUnit.Payment.getIn(['current', 'activity']).isEmpty(),
  parcels: state.CargoUnit.CargoCorporateCredit.getIn(['current', 'invoices']),
  loadingParcels: state.CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'loadingInvoices',
  ]),
  company: selector(state, 'company'),
  manualVoucher: selector(state, 'manualVoucher'),
  parcelList: selector(state, 'parcelList'),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetCargoCorporateInvoicesByCompanyId:
    getCargoCorporateInvoicesByCompanyId,
  dispatchClearCargoCorporateInvoices: clearCargoCorporateInvoices,
};

const formComponent = reduxForm({
  form: 'PaymentForm',
})(PaymentForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
