export const FLAG_GETTING_PARCEL_PRICE_CONFIGURATIONS =
  'FLAG_GETTING_PARCEL_PRICE_CONFIGURATIONS';
export const GET_PARCEL_PRICE_CONFIGURATIONS =
  'GET_PARCEL_PRICE_CONFIGURATIONS';
export const CLEAR_PARCEL_PRICE_CONFIGURATIONS =
  'CLEAR_PARCEL_PRICE_CONFIGURATIONS';
export const FLAG_PARCEL_PRICE_CONFIGURATION_ACTIVITY =
  'FLAG_PARCEL_PRICE_CONFIGURATION_ACTIVITY';
export const GET_PARCEL_PRICE_CONFIGURATION = 'GET_PARCEL_PRICE_CONFIGURATION';
export const CLEAR_PARCEL_PRICE_CONFIGURATION =
  'CLEAR_PARCEL_PRICE_CONFIGURATION';
