import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import {
  clearParcelPriceConfigurations,
  getParcelPriceConfigurations,
} from '../../../../actions/cargo/ParcelPriceConfiguration';
import ModuleList from '../../../layout/ModuleList';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { PARCEL_PRICE_CONFIGURATION_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { PARCEL_PRICE_CONFIGURATION_COLUMNS } from '../../../../config/columns';

const ParcelPriceConfigurations = ({
  breadcrumbs,
  parcelPriceConfigurations,
  loading,
  dispatchGetParcelPriceConfiguration,
  dispatchClearParcelPriceConfigurations,
}) => {
  useLayoutEffect(() => () => dispatchClearParcelPriceConfigurations(), []);

  const data = parcelPriceConfigurations.get('content') || [];
  const pages = parcelPriceConfigurations.get('totalPages') || null;
  const defaultPageSize =
    parcelPriceConfigurations.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Configuración de Tarifas"
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns: PARCEL_PRICE_CONFIGURATION_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetParcelPriceConfiguration,
        modelPath: PARCEL_PRICE_CONFIGURATION_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetParcelPriceConfiguration: getParcelPriceConfigurations,
  dispatchClearParcelPriceConfigurations: clearParcelPriceConfigurations,
};

ParcelPriceConfigurations.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  parcelPriceConfigurations: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetParcelPriceConfiguration: PropTypes.func.isRequired,
  dispatchClearParcelPriceConfigurations: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Configuración de Tarifas',
      href: PARCEL_PRICE_CONFIGURATION_PATH,
    },
  ],
  parcelPriceConfigurations: CargoUnit.ParcelPriceConfiguration.getIn([
    'all',
    'content',
  ]),
  loading: CargoUnit.ParcelPriceConfiguration.getIn(['all', 'loading']),
});

ParcelPriceConfigurations.defaultProps = {
  parcelPriceConfigurations: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParcelPriceConfigurations);
