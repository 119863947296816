import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import {
  FLAG_BILLING_ACTIVITY,
  GET_BILLING,
  CLEAR_BILLING,
  FLAG_GETTING_BILLINGS,
  CLEAR_BILLINGS,
  GET_BILLINGS,
} from '../types/accounting';
import {
  BILLING_ENDPOINT,
  generateContingencyGrtBillingByItineraryEndpoint,
  generateDisabledBillingsByItinerayIdEndpoint,
  generateGrtBillingByItineraryEndpoint,
  generateGrtBillingReportByItineraryEndpoint,
} from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_PUT_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { BILLING_PATH } from '../../config/paths';
import { BILLING_PROCESS_TYPE } from '../../config/constants';

const flagGettingBillings = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_BILLINGS,
    payload: flag,
  });

const clearBillings = () => (dispatch) =>
  dispatch({
    type: CLEAR_BILLINGS,
  });

const flagBillingActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_BILLING_ACTIVITY,
    payload: flag,
  });

const clearBilling = () => (dispatch) =>
  dispatch({
    type: CLEAR_BILLING,
  });

const getBillings =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingBillings(true));

      let uri;
      if (
        tableFilters.searchBy ===
        BILLING_PROCESS_TYPE.GUIA_REMISION_TRANSPORTISTA.value
      )
        uri = generateGrtBillingByItineraryEndpoint(tableFilters.itineraryId);

      const url = `${uri}?${QueryString.stringify(tableFilters)}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();

      dispatch({ type: GET_BILLINGS, payload: response });
      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingBillings(false));
    }
    return null;
  };

const obtainBillingReport =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagBillingActivity(true));

      const url = generateGrtBillingReportByItineraryEndpoint(itineraryId);
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBillingActivity(false));
    }
    return null;
  };

const obtainBilling = async ({ billingId }) => {
  const url = `${BILLING_ENDPOINT}/${billingId}`;
  const promise = await fetch(url, DEFAULT_GET_CONFIG);
  await isErrorResponse(promise);
  const response = await promise.json();
  return response;
};

const getBilling =
  async ({ billingId }) =>
  async (dispatch) => {
    try {
      dispatch(flagBillingActivity(true));

      const response = await obtainBilling({ billingId });
      dispatch({
        type: GET_BILLING,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBillingActivity(false));
    }
  };

const putBilling =
  async (
    billingId,
    {
      processType,
      documentSeries,
      refDocumentSeries,
      documentCode,
      refDocumentCode,
      itineraryId,
      json,
      extraFields,
      jsonRequest,
      response,
      status,
      createDate,
      retry,
      active,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagBillingActivity(true));

      const payload = {
        processType,
        documentSeries,
        refDocumentSeries,
        documentCode,
        refDocumentCode,
        itineraryId,
        json,
        extraFields,
        jsonRequest,
        response,
        status,
        createDate,
        retry,
        active,
        id: billingId,
      };
      const url = `${BILLING_ENDPOINT}/${billingId}`;

      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const billing = await promise.json();

      dispatch(push(`${BILLING_PATH}/${billing.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBillingActivity(false));
    }
  };

const putDisabledBillingsByItinerayId =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagBillingActivity(true));

      const url = generateDisabledBillingsByItinerayIdEndpoint(itineraryId);

      const promise = await fetch(url, DEFAULT_PUT_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const billing = await promise.json();

      return toastr.info('Información', billing.message);
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBillingActivity(false));
    }
    return null;
  };

const getContingencyBillingsByItinerayIdAndSourceLocation =
  async ({ query }) =>
  async (dispatch) => {
    try {
      dispatch(flagBillingActivity(true));

      const url = `${generateContingencyGrtBillingByItineraryEndpoint()}?${QueryString.stringify(
        query,
      )}`;

      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const billing = await promise.json();

      return billing;
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBillingActivity(false));
    }
    return null;
  };

export {
  flagGettingBillings,
  clearBillings,
  getBillings,
  flagBillingActivity,
  getBilling,
  clearBilling,
  putBilling,
  obtainBillingReport,
  putDisabledBillingsByItinerayId,
  getContingencyBillingsByItinerayIdAndSourceLocation,
};
