import React from 'react';
import { ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import EditButton from '../../../common/button/EditButton';
import { PARCEL_PRICE_CONFIGURATION_PATH } from '../../../../config/paths';

const ParcelPriceConfigurationToolbar = ({ parcelPriceConfigurationId }) => (
  <ButtonToolbar className="pull-right">
    <EditButton
      path={`${PARCEL_PRICE_CONFIGURATION_PATH}/${parcelPriceConfigurationId}/edit`}
    />{' '}
  </ButtonToolbar>
);

ParcelPriceConfigurationToolbar.propTypes = {
  parcelPriceConfigurationId: PropTypes.number.isRequired,
};

export default ParcelPriceConfigurationToolbar;
