import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_SHIFTS,
  SHOW_ERROR_GETTING_SHIFTS,
  CLEAR_ERROR_GETTING_SHIFTS,
  GET_SHIFTS,
  CLEAR_SHIFTS,
} from '../types';
import { SHIFT_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingShifts = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_SHIFTS,
    payload: flag,
  });

const showErrorGettingShifts = (error) => (dispatch) =>
  dispatch({
    type: SHOW_ERROR_GETTING_SHIFTS,
    payload: error,
  });

const clearErrorGettingShifts = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_GETTING_SHIFTS,
  });

const getShifts = async () => async (dispatch) => {
  try {
    dispatch(clearErrorGettingShifts());
    dispatch(flagGettingShifts(true));
    const url = SHIFT_ENDPOINT;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const shifts = await response.json();
    dispatch({
      type: GET_SHIFTS,
      payload: shifts,
    });
  } catch (error) {
    toastr.error('Error', error.message);
    dispatch(showErrorGettingShifts(error));
  } finally {
    dispatch(flagGettingShifts(false));
  }
};

const clearShifts = () => (dispatch) =>
  dispatch({
    type: CLEAR_SHIFTS,
  });

export {
  flagGettingShifts,
  showErrorGettingShifts,
  clearErrorGettingShifts,
  getShifts,
  clearShifts,
};
