import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { TRIP_REQUEST_PATH } from '../../../../config/paths';
import TripRequestDeleteButton from './TripRequestDeleteButton';
import TripRequestApproveButton from './TripRequestApproveButton';
import TripRequestRejectButton from './TripRequestRejectButton';

const TripRequestToolbar = ({ tripRequestId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <TripRequestApproveButton tripRequestId={tripRequestId} />{' '}
      <TripRequestRejectButton tripRequestId={tripRequestId} />{' '}
      <EditButton path={`${TRIP_REQUEST_PATH}/${tripRequestId}/edit`} />{' '}
      <TripRequestDeleteButton tripRequestId={tripRequestId} />
    </div>
  </ButtonToolbar>
);

TripRequestToolbar.propTypes = {
  tripRequestId: PropTypes.number.isRequired,
};

export default TripRequestToolbar;
