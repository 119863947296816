import React, { useEffect } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import Table from '../../../common/Table';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { generateManifestCargoItemsColumns } from '../../../../config/columns';

const CargoPickupQrCorrelativeForm = ({
  items,
  handleSubmit,
  cargoItemList,
  setItems,
  reset,
}) => {
  const focusInput = () => {
    const input = document.querySelector('.qr-correlation');
    if (input) {
      input.focus();
    }
  };

  useEffect(() => {
    focusInput();
  }, []);

  const onSubmit = ({ correlation }) => {
    reset();
    const manifestItemsToAdd = cargoItemList.find(
      (manifestItem) =>
        manifestItem.correlation === correlation &&
        !items.find((item) => item.correlation === correlation),
    );
    if (!manifestItemsToAdd) {
      toastr.warning('Advertencia', 'Esta encomienda ya ha sido agregada.', {
        disableCloseButtonFocus: true,
      });
      focusInput();
      return;
    }
    setItems([...items, manifestItemsToAdd]);
  };

  const onClickRemoveItem = ({ correlation }) => {
    const removeItem = items.filter((item) => item.correlation !== correlation);
    setItems(removeItem);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <FormItem label="Correlativo" required>
          <Field
            id="qrCorrelation"
            name="correlation"
            className="form-control qr-correlation"
            component={TextInput}
            type="text"
            placeholder="Correlativo"
          />
          <button type="submit" hidden />
        </FormItem>
      </FormGroup>
      <Table
        columns={generateManifestCargoItemsColumns(onClickRemoveItem)}
        data={items}
      />
    </Form>
  );
};

CargoPickupQrCorrelativeForm.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      correlation: PropTypes.string,
      description: PropTypes.string,
      weight: PropTypes.number,
    }),
  ),
  handleSubmit: PropTypes.func.isRequired,
  cargoItemList: PropTypes.arrayOf(
    PropTypes.shape({
      correlation: PropTypes.string,
      description: PropTypes.string,
      weight: PropTypes.number,
    }),
  ).isRequired,
  reset: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
};

CargoPickupQrCorrelativeForm.defaultProps = {
  items: [],
};

const formComponent = reduxForm({
  form: 'CargoPickupQrCorrelativeForm',
})(CargoPickupQrCorrelativeForm);

export default connect(null, null)(formComponent);
