import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import Company from './Company';
import CompanyTicket from './CompanyTicket';
import CompanyCreditNote from './CompanyCreditNote';
import CompanyUserConfiguration from './CompanyUserConfiguration';
import PrivateService from './PrivateService';
import Payment from './Payment';
import Contractor from './Contractor';

export default combineReducers({
  UnitHome,
  Company,
  CompanyTicket,
  CompanyCreditNote,
  CompanyUserConfiguration,
  PrivateService,
  Payment,
  Contractor,
});
