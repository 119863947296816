import React, { useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { CARGO_ITEM_STATUS } from '../../../../config/constants';
import Modal from '../../../common/modal/Modal';
import FoundCargoItemForm from './FoundCargoItemForm';
import { patchItemFound } from '../../../../actions';

const FoundCargoItemButton = ({
  cargoItemDetail,
  internal,
  dispatchPatchItemFound,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const cargoItem = cargoItemDetail.toJS();

  const onSubmit = ({ location }) => {
    dispatchPatchItemFound({
      locationId: location.value,
      id: cargoItem.cargoItemId,
      internal,
    });
  };

  const onClickFound = () => {
    openModal();
    setModalBody(<FoundCargoItemForm onSubmit={onSubmit} />);
  };

  if (
    cargoItem &&
    cargoItem.cargoItemDetail.status === CARGO_ITEM_STATUS.LOST.value
  )
    return (
      <>
        <Button color="warning" onClick={onClickFound}>
          <i className="fa fa-search" /> Encontrado
        </Button>
        <Modal
          show={showModal}
          title="Encomienda encontrada"
          body={modalBody}
          onClickClose={closeModal}
          size="lg"
        />
      </>
    );

  return null;
};

FoundCargoItemButton.propTypes = {
  cargoItemDetail: PropTypes.instanceOf(Immutable.Map).isRequired,
  internal: PropTypes.bool.isRequired,
  dispatchPatchItemFound: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPatchItemFound: patchItemFound,
};

export default connect(null, mapDispatchToProps)(FoundCargoItemButton);
