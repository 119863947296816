import { toastr } from 'react-redux-toastr';
import {
  ACTIVITY_BUS_EXECUTION_EMPLOYEE_ENDPOINT,
  generateDeleteActivityBusExecutionEmployeeByABEEIdAndEId,
} from '../../config/endpoints';
import { DEFAULT_POST_CONFIG, DEFAULT_DELETE_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { flagGettingMechanics } from './Mechanic';

const postActivityBusExecutionEmployee =
  async ({ activityBusExecutionId, employeeId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingMechanics(true));

      const payload = {
        activityBusExecutionId,
        employeeId,
      };

      const promise = await fetch(ACTIVITY_BUS_EXECUTION_EMPLOYEE_ENDPOINT, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(promise, null, dispatch);

      const response = promise.json();

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagGettingMechanics(false));
    }
  };

const deleteActivityBusExecutionEmployee =
  async ({ activityBusExecutionId, employeeId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingMechanics(true));

      const payload = {
        activityBusExecutionId,
        employeeId,
      };

      const promise = await fetch(
        generateDeleteActivityBusExecutionEmployeeByABEEIdAndEId(
          activityBusExecutionId,
          employeeId,
        ),
        {
          ...DEFAULT_DELETE_CONFIG,
          body: JSON.stringify(payload),
        },
      );

      await isErrorResponse(promise, null, dispatch);

      const response = promise.json();

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagGettingMechanics(false));
    }
  };

export { postActivityBusExecutionEmployee, deleteActivityBusExecutionEmployee };
