import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { clearManualVouchers, getManualVouchers } from '../../../../actions';
import {
  MANUAL_VOUCHER_PATH,
  NEW_MANUAL_VOUCHER_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { generateManualVoucherColumns } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Table from '../../../common/Table';

export class ManualVouchers extends Component {
  componentWillUnmount() {
    this.props.dispatchClearManualVouchers();
  }

  onFetchData = (data) => {
    const newData = { ...data };

    // TODO remove this hack when ModuleList implements multiple filter on a same column
    const businessTaxIdFound =
      newData.query &&
      newData.query.length &&
      newData.query.filter(
        (column) =>
          column.substring(0, column.indexOf(':')) === 'business.businessTaxId',
      );

    if (businessTaxIdFound && businessTaxIdFound[0]) {
      const businessTaxIdValue = businessTaxIdFound[0].substring(
        businessTaxIdFound[0].indexOf(':') + 1,
      );
      const businessTaxIdIndex = newData.query.indexOf(businessTaxIdFound[0]);
      const column =
        businessTaxIdValue.length === 8
          ? 'customer.idDocumentNumber'
          : 'business.businessTaxId';
      newData.query[businessTaxIdIndex] = `${column}:${businessTaxIdValue}`;
    }

    if (this.props.byBusiness)
      newData.query = [
        ...newData.query,
        `business.businessTaxId:${this.props.byBusiness}`,
        `isPaid:false`,
      ];

    this.props.dispatchGetManualVouchers(newData);
  };

  render() {
    const { vouchers, loading, breadcrumbs, showOnModal, getRowData } =
      this.props;

    const data = vouchers.get('content') || [];
    const pages = vouchers.get('totalPages') || null;
    const defaultPageSize = vouchers.get('size') || DEFAULT_PAGE_SIZE;

    const tableProps = {
      data,
      pages,
      defaultPageSize,
      filterable: true,
      fetchData: this.onFetchData,
      loading,
      navigateToModelOnRowClick: true,
    };

    if (showOnModal)
      return (
        <Table
          {...tableProps}
          columns={generateManualVoucherColumns(false)}
          getRowData={getRowData}
          highlightSelectedRow
        />
      );

    return (
      <ModuleList
        title="Comprobantes Manuales"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_MANUAL_VOUCHER_PATH}
        tableStructure={{
          ...tableProps,
          modelPath: MANUAL_VOUCHER_PATH,
          columns: generateManualVoucherColumns(true),
        }}
      />
    );
  }
}

ManualVouchers.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  vouchers: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetManualVouchers: PropTypes.func.isRequired,
  dispatchClearManualVouchers: PropTypes.func.isRequired,
  showOnModal: PropTypes.bool,
  getRowData: PropTypes.func,
  showCreditNotes: PropTypes.bool,
  byBusiness: PropTypes.string,
};

ManualVouchers.defaultProps = {
  vouchers: null,
  loading: false,
  showOnModal: false,
  getRowData: null,
  showCreditNotes: true,
  byBusiness: null,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Comprobantes Manuales',
      href: MANUAL_VOUCHER_PATH,
    },
  ],
  vouchers: AccountingUnit.ManualVoucher.getIn(['all', 'content']),
  loading: AccountingUnit.ManualVoucher.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetManualVouchers: getManualVouchers,
  dispatchClearManualVouchers: clearManualVouchers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualVouchers);
