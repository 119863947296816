import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { clearContractors, getContractors } from '../../../../actions';
import { CONTRACTOR_PATH, NEW_CONTRACTOR_PATH } from '../../../../config/paths';
import ModuleList from '../../../layout/ModuleList';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { CONTRACTOR_COLUMNS } from '../../../../config/columns';

const Contractors = ({
  breadcrumbs,
  contractors,
  loading,
  dispatchGetContractors,
  dispatchClearContractors,
}) => {
  useLayoutEffect(() => () => dispatchClearContractors(), []);

  const data = contractors.get('content') || [];
  const pages = contractors.get('totalPages') || null;
  const defaultPageSize = contractors.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Contratistas"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_CONTRACTOR_PATH}
      tableStructure={{
        columns: CONTRACTOR_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetContractors,
        modelPath: CONTRACTOR_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetContractors: getContractors,
  dispatchClearContractors: clearContractors,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Contratistas',
      href: CONTRACTOR_PATH,
    },
  ],
  contractors: ContractUnit.Contractor.getIn(['all', 'content']),
  loading: ContractUnit.Contractor.getIn(['all', 'loading']),
});

Contractors.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  contractors: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetContractors: PropTypes.func.isRequired,
  dispatchClearContractors: PropTypes.func.isRequired,
};

Contractors.defaultProps = {
  contractors: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contractors);
