import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PROCESS_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import ProcessForm from './ProcessForm';
import { postProcess } from '../../../../actions';

const NewProcess = ({ breadcrumbs, dispatchPostProcess }) => {
  const onSubmit = (formValues) =>
    dispatchPostProcess({
      ...formValues,
    });

  const content = <ProcessForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Proceso"
      subtitle="Crear nuevo proceso"
      content={content}
    />
  );
};

NewProcess.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostProcess: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostProcess: postProcess,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Procesos',
      href: PROCESS_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProcess);
