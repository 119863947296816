import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_FUEL_STATIONS,
  GET_FUEL_STATIONS,
  CLEAR_FUEL_STATIONS,
  GET_FUEL_STATION,
  CLEAR_FUEL_STATION,
  FLAG_FUEL_STATION_ACTIVITY,
  PUT_FUEL_STATION,
  POST_FUEL_STATION,
} from '../types/route';
import { FUEL_STATION_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_POST_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { FUEL_STATION_PATH } from '../../config/paths';

const flagGettingFuelStations = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_FUEL_STATIONS,
    payload: flag,
  });

const flagFuelStationActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_FUEL_STATION_ACTIVITY,
    payload: flag,
  });

const getFuelStations = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingFuelStations(true));
    const query = tableFilters;
    const url = `${FUEL_STATION_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const fuelStations = await response.json();
    dispatch({
      type: GET_FUEL_STATIONS,
      payload: fuelStations,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingFuelStations(false));
  }
};

const clearFuelStations = () => (dispatch) =>
  dispatch({
    type: CLEAR_FUEL_STATIONS,
  });

const getFuelStation =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelStationActivity(true));
      const url = `${FUEL_STATION_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const fuelStation = await response.json();
      dispatch({
        type: GET_FUEL_STATION,
        payload: fuelStation,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagFuelStationActivity(false));
    }
  };

const clearFuelStation = () => (dispatch) =>
  dispatch({
    type: CLEAR_FUEL_STATION,
  });

const putFuelStation =
  async (fuelStationId, { locationId, businessId, agencyId }) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelStationActivity(true));
      const payload = {
        id: fuelStationId,
        locationId,
        businessId,
        agencyId,
      };
      const url = `${FUEL_STATION_ENDPOINT}/${fuelStationId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const fuelStation = await response.json();
      dispatch({
        type: PUT_FUEL_STATION,
        payload: fuelStation,
      });
      dispatch(push(FUEL_STATION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagFuelStationActivity(false));
    }
  };

const deleteFuelStation =
  async ({ fuelStationId }) =>
  async (dispatch) => {
    dispatch(flagFuelStationActivity(true));
    try {
      const url = `${FUEL_STATION_ENDPOINT}/${fuelStationId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(FUEL_STATION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagFuelStationActivity(false));
    }
  };

const postFuelStation =
  async ({ locationId, businessId, agencyId }) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelStationActivity(true));
      const payload = {
        locationId,
        businessId,
        agencyId,
      };
      const url = FUEL_STATION_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const fuelStation = await response.json();
      dispatch({
        type: POST_FUEL_STATION,
        payload: fuelStation,
      });
      dispatch(push(FUEL_STATION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagFuelStationActivity(false));
    }
  };

export {
  flagGettingFuelStations,
  flagFuelStationActivity,
  getFuelStations,
  clearFuelStations,
  getFuelStation,
  clearFuelStation,
  putFuelStation,
  deleteFuelStation,
  postFuelStation,
};
