import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { CARGO_PATH } from '../../../../config/paths';
import {
  getParcel,
  clearParcel,
  flagPrintCargoVoucher,
  getCurrentActiveSalesSessionWithoutShowingError,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';
import ParcelToolbar from './ParcelToolbar';
import NoDataResource from '../../../common/resource/NoDataResource';
import ParcelBasicInformationResource from './resource/ParcelBasicInformationResource';
import printVoucherCargo from '../../../../utils/printers/CargoVoucher';

const Parcel = ({
  breadcrumbs,
  parcel,
  loading,
  dispatchGetParcel,
  match: {
    params: { id },
  },
  currentSalesSession,
  dispatchClearParcel,
  printOnMount,
  dispatchFlagPrintCargoVoucher,
  dispatchGetCurrentActiveSalesSessionWithoutShowingError,
}) => {
  useLayoutEffect(() => {
    dispatchGetParcel({ parcelId: id });
    dispatchGetCurrentActiveSalesSessionWithoutShowingError();

    return () => dispatchClearParcel();
  }, []);

  useEffect(() => {
    if (printOnMount && !parcel.isEmpty()) {
      const parcelJson = parcel.toJS();
      printVoucherCargo(parcelJson);

      dispatchFlagPrintCargoVoucher(false);
    }
  }, [printOnMount, parcel]);

  let content = null;
  let toolbar = null;
  if (loading) content = <Loader />;
  else if (parcel.isEmpty())
    content = <NoDataResource returnPage={CARGO_PATH} />;
  else {
    const parcelJson = parcel.toJS();
    content = <ParcelBasicInformationResource {...parcelJson} />;
    toolbar = (
      <ParcelToolbar
        currentSalesSession={currentSalesSession}
        parcel={parcelJson}
      />
    );
  }
  return (
    <Content
      toolbar={toolbar}
      breadcrumbs={breadcrumbs}
      title="Carga"
      content={content}
    />
  );
};
Parcel.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetParcel: PropTypes.func.isRequired,
  dispatchClearParcel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  parcel: PropTypes.instanceOf(Immutable.Map).isRequired,
  currentSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetCurrentActiveSalesSessionWithoutShowingError:
    PropTypes.func.isRequired,
  printOnMount: PropTypes.bool,
  dispatchFlagPrintCargoVoucher: PropTypes.func.isRequired,
};

Parcel.defaultProps = {
  loading: false,
  printOnMount: false,
};

const mapStateToProps = ({ CargoUnit, SalesUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Carga',
      href: CARGO_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  parcel: CargoUnit.Cargo.getIn(['current', 'content']),
  currentSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
  loading: !CargoUnit.Cargo.getIn(['current', 'activity']).isEmpty(),
  printOnMount: CargoUnit.Cargo.getIn(['current', 'printOnMount']),
});

const mapDispatchToProps = {
  dispatchGetParcel: getParcel,
  dispatchClearParcel: clearParcel,
  dispatchGetCurrentActiveSalesSessionWithoutShowingError:
    getCurrentActiveSalesSessionWithoutShowingError,
  dispatchFlagPrintCargoVoucher: flagPrintCargoVoucher,
};

export default connect(mapStateToProps, mapDispatchToProps)(Parcel);
