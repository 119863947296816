import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { DETRACTION_PATH } from '../../config/paths';
import { isErrorResponse } from '../../utils/error-handlers';
import { DETRACTION_ENDPOINT } from '../../config/endpoints';
import {
  CLEAR_DETRACTION,
  CLEAR_DETRACTIONS,
  FLAG_DETRACTION_ACTIVITY,
  FLAG_GETTING_DETRACTIONS,
  GET_DETRACTION,
  GET_DETRACTIONS,
} from '../types';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';

const flagGettingDetractions = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DETRACTIONS,
    payload: flag,
  });

const getDetractions = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDetractions(true));
    const query = tableFilters;
    const url = `${DETRACTION_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const detractions = await response.json();
    dispatch({
      type: GET_DETRACTIONS,
      payload: detractions,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingDetractions(false));
  }
};

const clearDetractions = () => (dispatch) =>
  dispatch({
    type: CLEAR_DETRACTIONS,
  });

const flagDetractionActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DETRACTION_ACTIVITY,
    payload: flag,
  });

const getDetraction =
  async ({ detractionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDetractionActivity(true));
      const url = `${DETRACTION_ENDPOINT}/${detractionId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const detraction = await response.json();
      dispatch({
        type: GET_DETRACTION,
        payload: detraction,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDetractionActivity(false));
    }
  };

const clearDetraction = () => (dispatch) =>
  dispatch({
    type: CLEAR_DETRACTION,
  });

const postDetraction =
  async ({
    countryCode,
    detractionRate,
    minDetractionAmount,
    detractionCode,
    bankAccountNumber,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagDetractionActivity(true));
      const payload = {
        countryCode,
        detractionRate,
        minDetractionAmount,
        detractionCode,
        bankAccountNumber,
      };
      const url = DETRACTION_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const detraction = await response.json();
      dispatch(push(`${DETRACTION_PATH}/${detraction.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDetractionActivity(false));
    }
  };

const putDetraction =
  async (
    detractionId,
    {
      countryCode,
      detractionRate,
      minDetractionAmount,
      detractionCode,
      bankAccountNumber,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagDetractionActivity(true));
      const payload = {
        id: detractionId,
        countryCode,
        detractionRate,
        minDetractionAmount,
        detractionCode,
        bankAccountNumber,
      };
      const url = `${DETRACTION_ENDPOINT}/${detractionId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const detraction = await response.json();
      dispatch(push(`${DETRACTION_PATH}/${detraction.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDetractionActivity(false));
    }
  };

const deleteDetraction =
  async ({ detractionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDetractionActivity(true));
      const url = `${DETRACTION_ENDPOINT}/${detractionId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response;
      dispatch(push(DETRACTION_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDetractionActivity(false));
    }
  };

const getDetractionAmountBySalePrice =
  async ({ salePrice }) =>
  async (dispatch) => {
    try {
      dispatch(flagDetractionActivity(true));
      const url = `${DETRACTION_ENDPOINT}/actions/get-amount-by-sale-price`;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(salePrice),
      });
      await isErrorResponse(response, null, dispatch);
      const detraction = await response.json();
      return detraction;
    } catch (error) {
      toastr.error('Error', error.message);
      return 0;
    } finally {
      dispatch(flagDetractionActivity(false));
    }
  };

export {
  flagGettingDetractions,
  getDetractions,
  clearDetractions,
  getDetraction,
  clearDetraction,
  postDetraction,
  putDetraction,
  deleteDetraction,
  getDetractionAmountBySalePrice,
};
