import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { PROCESS_PATH } from '../../../../config/paths';
import { clearProcess, getProcess } from '../../../../actions';
import ProcessToolbar from './ProcessToolbar';
import ProcessBasicInformationResource from './resource/ProcessBasicInformationResource';

export const Process = ({
  breadcrumbs,
  dispatchGetProcess,
  dispatchClearProcess,
  match: {
    params: { id: processId },
  },
  loading,
  process,
}) => {
  useLayoutEffect(() => {
    dispatchGetProcess({ processId });

    return () => dispatchClearProcess();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (process.isEmpty())
    content = <NoDataResource returnPage={PROCESS_PATH} />;
  else {
    const processJson = process.toJS();

    content = <ProcessBasicInformationResource {...processJson} />;

    toolbar = <ProcessToolbar processId={processJson.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Proceso"
      content={content}
    />
  );
};

Process.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearProcess: PropTypes.func.isRequired,
  dispatchGetProcess: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  process: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Process.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearProcess: clearProcess,
  dispatchGetProcess: getProcess,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Procesos',
      href: PROCESS_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  process: ItineraryUnit.Process.getIn(['current', 'content']),
  loading: !ItineraryUnit.Process.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Process);
