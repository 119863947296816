import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { clearTripRequest, getTripRequest } from '../../../../actions';
import { TRIP_REQUEST_PATH } from '../../../../config/paths';
import TripRequestBasicInformationResource from './resource/TripRequestBasicInformationResource';
import TripRequestToolbar from './TripRequestToolbar';
import { TRIP_REQUEST_STATUS } from '../../../../config/constants';

export const TripRequest = ({
  breadcrumbs,
  dispatchGetTripRequest,
  dispatchClearTripRequest,
  match: {
    params: { id: tripRequestId },
  },
  loading,
  tripRequest,
}) => {
  useLayoutEffect(() => {
    dispatchGetTripRequest({ tripRequestId });

    return () => dispatchClearTripRequest();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (tripRequest.isEmpty())
    content = <NoDataResource returnPage={TRIP_REQUEST_PATH} />;
  else {
    const tripRequestJson = tripRequest.toJS();

    content = <TripRequestBasicInformationResource {...tripRequestJson} />;

    toolbar =
      tripRequestJson.status === TRIP_REQUEST_STATUS.PENDING.value ? (
        <TripRequestToolbar tripRequestId={tripRequestJson.id} />
      ) : null;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Solicitud de Viaje"
      content={content}
    />
  );
};

TripRequest.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearTripRequest: PropTypes.func.isRequired,
  dispatchGetTripRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  tripRequest: PropTypes.instanceOf(Immutable.Map).isRequired,
};

TripRequest.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearTripRequest: clearTripRequest,
  dispatchGetTripRequest: getTripRequest,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Solicitudes de Viajes',
      href: TRIP_REQUEST_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  tripRequest: ItineraryUnit.TripRequest.getIn(['current', 'content']),
  loading: !ItineraryUnit.TripRequest.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(TripRequest);
