import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../common/modal/Modal';
import {
  getActiveSalesSession,
  getParcel,
  putParcelsToPickupByItem,
} from '../../../../actions';
import { CARGO_PATH } from '../../../../config/paths';
import CargoPickupQrModal from '../cargo-pickup/CargoPickupQrModal';

const DeliverParcelQrButton = ({
  dispatchGetActiveSalesSession,
  parcel,
  dispatchGetParcel,
  dispatchPutParcelsToPickup,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState([]);

  const getConsigneeData = () => {
    const { consigneeCustomer, secondaryConsigneeCustomer } = parcel;

    const consigneeCustomerData = {
      value: consigneeCustomer.id || '',
      label:
        `${consigneeCustomer.fullName} | ${consigneeCustomer.identificationType.name}: ${consigneeCustomer.idDocumentNumber}` ||
        '',
    };

    if (!secondaryConsigneeCustomer) {
      return [consigneeCustomerData];
    }

    const secondaryConsigneeCustomerData = {
      value: secondaryConsigneeCustomer.id || '',
      label:
        `${secondaryConsigneeCustomer.fullName} | ${secondaryConsigneeCustomer.identificationType.name}: ${secondaryConsigneeCustomer.idDocumentNumber}` ||
        '',
    };

    return [consigneeCustomerData, secondaryConsigneeCustomerData];
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const onModalSubmit = async ({ secretCode, deliveredCustomer }) => {
    const itemsFormat = items.map((item) => item.id);
    await dispatchPutParcelsToPickup({
      parcelId: parcel.id,
      items: itemsFormat,
      secretCode,
      deliveredCustomerId: deliveredCustomer.value,
    });
    dispatchGetParcel({ parcelId: parcel.id });
    setShowModal(false);
  };

  const body = (
    <CargoPickupQrModal
      parcel={parcel}
      onCancel={onCancel}
      handleProcess={onModalSubmit}
      options={getConsigneeData()}
      items={items}
      setItems={setItems}
    />
  );

  const onShowModal = async () => {
    await dispatchGetActiveSalesSession({
      source: `${CARGO_PATH}/${parcel.id}`,
    });
    setShowModal(true);
  };

  return (
    <>
      <Button color="success" onClick={onShowModal}>
        <i className="fa fa-qrcode" /> Entregar por QR
      </Button>
      <Modal
        show={showModal}
        title="Entregar encomienda por QR"
        body={body}
        size="lg"
      />
    </>
  );
};

DeliverParcelQrButton.propTypes = {
  parcel: PropTypes.instanceOf(Object).isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  dispatchGetParcel: PropTypes.func.isRequired,
  dispatchPutParcelsToPickup: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchGetActiveSalesSession: getActiveSalesSession,
  dispatchGetParcel: getParcel,
  dispatchPutParcelsToPickup: putParcelsToPickupByItem,
};

export default connect(null, mapDispatchToProps)(DeliverParcelQrButton);
