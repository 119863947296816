import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { FLAG_GETTING_CITIES, GET_CITIES, CLEAR_CITIES } from '../types';
import { CITY_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingCities = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CITIES,
    payload: flag,
  });

const getCities = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCities(true));
    const query = tableFilters;
    const url = `${CITY_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const cities = await response.json();
    dispatch({
      type: GET_CITIES,
      payload: cities,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCities(false));
  }
};

const clearCities = () => (dispatch) =>
  dispatch({
    type: CLEAR_CITIES,
  });

export { getCities, clearCities };
