import React from 'react';
import PropTypes from 'prop-types';
import { numberFormatter } from '../../../../utils/number';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import {
  parcelIncomeSummaryPropTypes,
  parcelPaymentMethodSummaryListPropTypes,
} from './SalesSessionPropTypes';
import CollapsibleHeader from '../../../layout/CollapsibleHeader';
import ParcelsTable from './ParcelsTable';
import { PARCEL_FINANCIAL_STATUS } from '../../../../config/constants';

const ParcelIncomeSummary = ({
  parcelIncomeSummaryList,
  salesSessionId,
  parcelPaymentMethodSummaryList,
}) => {
  if (!parcelIncomeSummaryList.length) {
    return null;
  }

  let totalAmount = 0;
  parcelIncomeSummaryList.forEach((parcel) => {
    totalAmount += parcel.totalAmount;
  });

  return (
    <>
      <hr />
      <CollapsibleHeader
        headerText="Carga"
        component={ParcelsTable}
        salesSessionId={salesSessionId}
      />
      {parcelIncomeSummaryList.map((parcelSummary, index) => (
        <div key={+index}>
          <p>{`Serie ${parcelSummary.documentSeries}: `}</p>
          <div className="summaryContainer">
            <p>
              {`${parcelSummary.numberOfActiveParcels} ${PARCEL_FINANCIAL_STATUS.ACTIVE.label}(s)`}
            </p>
            <p>
              {`${parcelSummary.numberOfRefundedParcels} ${PARCEL_FINANCIAL_STATUS.REFUNDED.label}(s)`}
            </p>
            <p>
              {`${parcelSummary.numberOfVoidedParcels} ${PARCEL_FINANCIAL_STATUS.VOIDED.label}(s)`}
            </p>
          </div>
        </div>
      ))}
      {parcelPaymentMethodSummaryList.map((paymentMethodSales) => (
        <ResourceProperty
          key={paymentMethodSales.moneyFlowConcept}
          label={`${paymentMethodSales.moneyFlowConcept}: `}
        >
          {numberFormatter({ value: paymentMethodSales.totalAmount })}
        </ResourceProperty>
      ))}
      <ResourceProperty label="Total de Ventas de Carga: ">
        {numberFormatter({ value: totalAmount })}
      </ResourceProperty>
    </>
  );
};

ParcelIncomeSummary.propTypes = {
  parcelIncomeSummaryList: parcelIncomeSummaryPropTypes.isRequired,
  parcelPaymentMethodSummaryList:
    parcelPaymentMethodSummaryListPropTypes.isRequired,
  salesSessionId: PropTypes.number.isRequired,
};

export default ParcelIncomeSummary;
