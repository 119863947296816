import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getShifts, clearShifts } from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';

const ShiftSelect = ({
  dispatchGetShifts,
  dispatchClearShifts,
  loading,
  options,
  isGridVariant,
  value,
  isMulti,
  isClearable,
  onValueChange,
  placeholder,
  ...rest
}) => {
  useLayoutEffect(() => {
    dispatchGetShifts();

    return () => {
      dispatchClearShifts();
    };
  }, []);

  return (
    <Select
      isLoading={loading}
      options={options}
      isMulti={isMulti}
      input={{ value }}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      placeholder={placeholder}
      {...rest}
    />
  );
};

ShiftSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetShifts: PropTypes.func.isRequired,
  dispatchClearShifts: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  displayInPaymentPage: PropTypes.bool,
};

ShiftSelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
  displayInPaymentPage: false,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  options: ItineraryUnit.Shift.getIn(['all', 'content']).map((shift) => ({
    value: shift,
    label: shift,
  })),
  loading: ItineraryUnit.Shift.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetShifts: getShifts,
  dispatchClearShifts: clearShifts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftSelect);
