import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PAYMENT_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import PaymentForm from './PaymentForm';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import { postContractPayment } from '../../../../actions/contract';

const NewPayment = ({ breadcrumbs, dispatchPostContractPayment }) => {
  const onSubmit = (formValues) =>
    dispatchPostContractPayment({
      ...formValues,
      paymentDate: tzNormalizeDate({
        date: formValues.paymentDate,
        format: TIMESTAMP_FORMAT,
      }),
      companyId: formValues.company.value,
    });

  const content = <PaymentForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Pago"
      subtitle="Crear nuevo pago"
      content={content}
    />
  );
};

NewPayment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostContractPayment: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostContractPayment: postContractPayment,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Pagos',
      href: PAYMENT_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPayment);
