import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { changeStep } from '../../../actions';
import {
  CIVA_BOOKING_STEPS,
  THIRD_PARTY_BOOKING_STEPS,
} from '../../../config/constants';
import Badge from '../../common/Badge';

const CONFIRMATION_STEP_INDEX = 3;

class BookingNavbar extends Component {
  constructor(props) {
    super(props);
    this.renderSteps = this.renderSteps.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(step) {
    this.props.dispatchChangeStep(step);
  }

  renderSteps() {
    const { isForMine, currentStep } = this.props;

    const steps = isForMine ? THIRD_PARTY_BOOKING_STEPS : CIVA_BOOKING_STEPS;

    return steps.map((step, index) => {
      // current step is highlighted
      if (index === currentStep)
        return (
          <Col key={step.path}>
            <Badge color="info" text={index + 1} />
            &nbsp;{step.title}
          </Col>
        );

      // future steps are disabled and not clickable
      if (index > currentStep || currentStep === CONFIRMATION_STEP_INDEX)
        return (
          <Col key={step.path}>
            <Badge color="light" text={index + 1} />
            &nbsp;{step.title}
          </Col>
        );

      // past steps are clickable
      return (
        <Col
          key={step.path}
          onClick={() => {
            this.handleClick(step);
          }}
          className="clickable"
        >
          <Badge color="success" text={index + 1} />
          &nbsp;{step.title}
        </Col>
      );
    });
  }

  render() {
    return (
      <div className="p-2 text-center">
        <Row>{this.renderSteps()}</Row>
      </div>
    );
  }
}

BookingNavbar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  dispatchChangeStep: PropTypes.func.isRequired,
  isForMine: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ authentication }) => ({
  isForMine: !!authentication.get('user').salesSessionUserId,
});

const mapDispatchToProps = {
  dispatchChangeStep: changeStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingNavbar);
