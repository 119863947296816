import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  ACTIVITY_TASK_EXECUTION_ENDPOINT,
  generateDeleteActivityTaskExecutionByABEEIdAndEId,
} from '../../config/endpoints';
import {
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_GET_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { flagGettingMechanics } from './Mechanic';
import {
  CLEAR_ACTIVITY_TASK_EXECUTION,
  FLAG_ACTIVITY_TASK_EXECUTION_ACTIVITY,
  GET_ACTIVITY_TASK_EXECUTION,
} from '../types';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../config/paths';

const postActivityTaskExecution =
  async ({ activityBusExecutionId, employeeId, activityTaskId, status }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingMechanics(true));

      const payload = {
        activityBusExecutionId,
        employeeId,
        activityTaskId,
        status,
      };

      const promise = await fetch(ACTIVITY_TASK_EXECUTION_ENDPOINT, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(promise, null, dispatch);

      const response = promise.json();

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagGettingMechanics(false));
    }
  };

const deleteActivityTaskExecution =
  async ({ activityBusExecutionId, employeeId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingMechanics(true));

      const payload = {
        activityBusExecutionId,
        employeeId,
      };

      const promise = await fetch(
        generateDeleteActivityTaskExecutionByABEEIdAndEId(
          activityBusExecutionId,
          employeeId,
        ),
        {
          ...DEFAULT_DELETE_CONFIG,
          body: JSON.stringify(payload),
        },
      );

      await isErrorResponse(promise, null, dispatch);

      const response = promise.json();

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagGettingMechanics(false));
    }
  };

const flagActivityBusExecutionActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_ACTIVITY_TASK_EXECUTION_ACTIVITY,
    payload: flag,
  });

const clearActivityTaskExecution = () => (dispatch) =>
  dispatch({
    type: CLEAR_ACTIVITY_TASK_EXECUTION,
  });

const putActivityTaskExecution =
  async (
    activityTaskExecutionId,
    {
      startDate,
      endDate,
      activityBusExecutionId,
      employeeId,
      activityTaskId,
      status,
      workingHour,
      comment,
      createDate,
      userId,
      serviceOrderId,
    },
    isDispatch,
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagActivityBusExecutionActivity(true));

      const payload = {
        startDate,
        endDate,
        activityBusExecutionId,
        employeeId,
        activityTaskId,
        status,
        workingHour,
        comment,
        createDate,
        userId,
        id: activityTaskExecutionId,
      };

      const url = `${ACTIVITY_TASK_EXECUTION_ENDPOINT}/${activityTaskExecutionId}`;

      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(promise, null, dispatch);

      const response = await promise.json();

      if (isDispatch)
        dispatch(
          push(`${MAINTENANCE_SERVICE_ORDER_PATH}/${serviceOrderId}/execution`),
        );

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      dispatch(flagActivityBusExecutionActivity(false));
      return null;
    }
  };

const getActivityTaskExecution =
  async ({ activityTaskExecutionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagActivityBusExecutionActivity(true));
      const url = `${ACTIVITY_TASK_EXECUTION_ENDPOINT}/${activityTaskExecutionId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_ACTIVITY_TASK_EXECUTION,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagActivityBusExecutionActivity(false));
    }
  };

export {
  postActivityTaskExecution,
  deleteActivityTaskExecution,
  putActivityTaskExecution,
  getActivityTaskExecution,
  clearActivityTaskExecution,
};
