import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_CARGO_CATEGORIES,
  GET_CARGO_CATEGORIES,
  CLEAR_CARGO_CATEGORIES,
  GET_CARGO_CATEGORY,
  CLEAR_CARGO_CATEGORY,
  FLAG_CARGO_CATEGORY_ACTIVITY,
} from '../types/cargo';
import { CARGO_CATEGORY_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { CARGO_CATEGORY_PATH } from '../../config/paths';

const flagGettingCargoCategories = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CARGO_CATEGORIES,
    payload: flag,
  });

const getCargoCategories = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCargoCategories(true));
    const query = tableFilters;
    const url = `${CARGO_CATEGORY_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const cargoCategories = await response.json();
    dispatch({
      type: GET_CARGO_CATEGORIES,
      payload: cargoCategories,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCargoCategories(false));
  }
};

const clearCargoCategories = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_CATEGORIES,
  });

const flagCargoCategoryActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CARGO_CATEGORY_ACTIVITY,
    payload: flag,
  });

const getCargoCategory =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCategoryActivity(true));
      const url = `${CARGO_CATEGORY_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const cargoCategory = await response.json();
      dispatch({
        type: GET_CARGO_CATEGORY,
        payload: cargoCategory,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCategoryActivity(false));
    }
  };

const clearCargoCategory = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_CATEGORY,
  });

const postCargoCategory =
  async ({ name, description }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCategoryActivity(true));
      const payload = {
        name,
        description,
      };
      const url = CARGO_CATEGORY_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const cargoCategory = await response.json();
      dispatch(push(`${CARGO_CATEGORY_PATH}/${cargoCategory.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCategoryActivity(false));
    }
  };

const putCargoCategory =
  async (cargoCategoryId, { name, description }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCategoryActivity(true));
      const payload = {
        id: cargoCategoryId,
        name,
        description,
      };
      const url = `${CARGO_CATEGORY_ENDPOINT}/${cargoCategoryId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const cargoCategory = await response.json();
      dispatch(push(`${CARGO_CATEGORY_PATH}/${cargoCategory.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCategoryActivity(false));
    }
  };

const deleteCargoCategory =
  async ({ cargoCategoryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCategoryActivity(true));
      const url = `${CARGO_CATEGORY_ENDPOINT}/${cargoCategoryId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response;
      dispatch(push(CARGO_CATEGORY_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCategoryActivity(false));
    }
  };

export {
  flagGettingCargoCategories,
  getCargoCategories,
  clearCargoCategories,
  getCargoCategory,
  clearCargoCategory,
  postCargoCategory,
  putCargoCategory,
  deleteCargoCategory,
};
