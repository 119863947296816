import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Content from '../../../layout/Content';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearParcelPrice,
  getParcelPrice,
} from '../../../../actions/cargo/ParcelPrice';
import { PARCEL_PRICE_PATH } from '../../../../config/paths';
import Loader from '../../../common/Loader';

import NoDataResource from '../../../common/resource/NoDataResource';
import ParcelPriceBasicInformationResource from './ParcelPriceBasicInformationResource';

const ParcelPrice = ({
  breadcrumbs,
  loading,
  match: {
    params: { id: parcelPriceId },
  },
  dispatchGetParcelPrice,
  dispatchClearParcelPrice,
  parcelPrice,
}) => {
  useLayoutEffect(() => {
    dispatchGetParcelPrice({ parcelPriceId });
    return () => dispatchClearParcelPrice();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (parcelPrice.isEmpty())
    content = <NoDataResource returnPage={PARCEL_PRICE_PATH} />;
  else {
    const parcelPriceJson = parcelPrice.toJS();
    content = <ParcelPriceBasicInformationResource {...parcelPriceJson} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Tarifario"
      content={content}
    />
  );
};

ParcelPrice.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  dispatchGetParcelPrice: PropTypes.func.isRequired,
  dispatchClearParcelPrice: PropTypes.func.isRequired,
  parcelPrice: PropTypes.instanceOf(Immutable.Map).isRequired,
};

ParcelPrice.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearParcelPrice: clearParcelPrice,
  dispatchGetParcelPrice: getParcelPrice,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tarifarios',
      href: PARCEL_PRICE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  parcelPrice: CargoUnit.ParcelPrice.getIn(['current', 'content']),
  loading: !CargoUnit.ParcelPrice.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParcelPrice);
