import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import { isRequired } from '../../../../utils/validators';
import FormFooter from '../../../common/forms/FormFooter';

const FoundCargoItemForm = ({ handleSubmit, onSubmit }) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <span>Seleccione en donde se encontró la encomienda</span>
    <FormGroup row className="mt-4">
      <FormItem label="Ubicación" required>
        <Field
          name="location"
          component={LocationSelect}
          placeholder="Digite mínimo 4 caracteres"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormFooter />
  </Form>
);

FoundCargoItemForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'FoundCargoItemForm',
})(FoundCargoItemForm);

export default connect()(formComponent);
