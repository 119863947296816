import React from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateEmail } from '../../../../utils/validators';
import FormFooter from '../../../common/forms/FormFooter';

const SendEmailCargoForm = ({ handleSubmit, onCancel }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Email" required>
        <Field
          name="email"
          component={TextInput}
          type="text"
          placeholder="ejemplo@gmail.com"
          validate={[isRequired, validateEmail]}
        />
      </FormItem>
    </FormGroup>
    <FormFooter
      saveButtonColor="secondary"
      saveButtonIcon={null}
      saveButtonText="Enviar"
    >
      <Button color="primary" onClick={onCancel}>
        Cancelar
      </Button>
    </FormFooter>
  </Form>
);

SendEmailCargoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'SendEmailCargoForm',
})(SendEmailCargoForm);
