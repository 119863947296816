// todo: replace with qs
// https://www.npmjs.com/package/qs
import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { isErrorResponse } from '../../utils/error-handlers';
import { DISCOUNT_CODE_REPORT_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_POST_CONFIG } from '../../config/rest';
import {
  GET_DISCOUNT_CODE_REPORTS,
  FLAG_GETTING_DISCOUNT_CODE_REPORTS,
  CLEAR_ERROR_GETTING_DISCOUNT_CODE_REPORTS,
  CLEAR_DISCOUNT_CODE_REPORTS,
  CLEAR_ERROR_POSTING_DISCOUNT_CODE_REPORT,
  POST_DISCOUNT_CODE_REPORT,
  FLAG_POSTING_DISCOUNT_CODE_REPORT,
  DOWNLOAD_DISCOUNT_CODE_REPORT,
  GET_DISCOUNT_CODE_REPORT,
  CLEAR_ERROR_GETTING_DISCOUNT_CODE_REPORT,
  FLAG_GETTING_DISCOUNT_CODE_REPORT,
  CLEAR_DISCOUNT_CODE_REPORT,
} from '../types/index';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from '../../config/constants';
import { getReportsServerEndpoint } from './Report';
import { DISCOUNT_CODE_REPORTS_PATH } from '../../config/paths';
import { validateReportDateRange } from '../../utils/validators';

const flagGettingDiscountCodeReports = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_DISCOUNT_CODE_REPORTS,
  });

const clearErrorGettingDiscountCodeReports = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_GETTING_DISCOUNT_CODE_REPORTS,
  });

const getDiscountCodeReports =
  async ({ page = 0, size = DEFAULT_PAGE_SIZE, sort = DEFAULT_SORT } = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch(flagGettingDiscountCodeReports(true));

      const reportsServerEndpoint = await getReportsServerEndpoint(
        dispatch,
        getState,
      );
      // make request
      const url = reportsServerEndpoint + DISCOUNT_CODE_REPORT_ENDPOINT;
      const query = {
        page,
        size,
        sort,
      };
      const response = await fetch(
        `${url}?${QueryString.stringify(query)}`,
        DEFAULT_GET_CONFIG,
      );

      await isErrorResponse(response, null, dispatch);
      const reports = await response.json();
      dispatch({
        type: GET_DISCOUNT_CODE_REPORTS,
        payload: reports,
      });
    } catch (err) {
      console.error(err);
      // lower flag
      toastr.error('Error', err);
    } finally {
      dispatch(flagGettingDiscountCodeReports(false));
    }
  };

const clearDiscountCodeReports = () => (dispatch) =>
  dispatch({
    type: CLEAR_DISCOUNT_CODE_REPORTS,
  });

const clearErrorPostingDiscountCodeReport = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_POSTING_DISCOUNT_CODE_REPORT,
  });

const flagPostingDiscountCodeReport = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_POSTING_DISCOUNT_CODE_REPORT,
    payload: flag,
  });

const postDiscountCodeReport =
  async ({ fromDate, toDate, force }) =>
  async (dispatch, getState) => {
    dispatch(clearErrorPostingDiscountCodeReport());

    // validate before raising the flag and outside the
    // try/catch so that the error is caught by redux form
    validateReportDateRange(fromDate, toDate);

    try {
      // raise flag
      dispatch(flagPostingDiscountCodeReport(true));

      const reportsServerEndpoint = await getReportsServerEndpoint(
        dispatch,
        getState,
      );
      const query = {
        force,
      };
      // make request
      const url = reportsServerEndpoint + DISCOUNT_CODE_REPORT_ENDPOINT;
      const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify({
          fromDate,
          toDate,
        }),
      });
      await isErrorResponse(response, null, dispatch);

      const report = await response.json();
      dispatch({
        type: POST_DISCOUNT_CODE_REPORT,
        payload: report,
      });
      // redirect to report
      dispatch(push(`${DISCOUNT_CODE_REPORTS_PATH}/${report.id}`));
    } catch (err) {
      console.error(err);
      // lower flag
      toastr.error('Error', err);
    } finally {
      dispatch(flagPostingDiscountCodeReport(false));
    }
  };

const flagGettingDiscountCodeReport = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_DISCOUNT_CODE_REPORT,
  });

const clearErrorGettingDiscountCodeReport = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_GETTING_DISCOUNT_CODE_REPORT,
  });

const getDiscountCodeReport =
  async ({
    id,
    reportFormat = 'json',
    download = false,
    page = 0,
    size = DEFAULT_PAGE_SIZE,
    sort = DEFAULT_SORT,
  }) =>
  async (dispatch, getState) => {
    try {
      dispatch(flagGettingDiscountCodeReport(true));

      const reportsServerEndpoint = await getReportsServerEndpoint(
        dispatch,
        getState,
      );
      // make request
      const url = `${
        reportsServerEndpoint + DISCOUNT_CODE_REPORT_ENDPOINT
      }/${id}`;
      const query = {
        reportFormat,
        page,
        size,
        sort,
      };
      const response = await fetch(
        `${url}?${QueryString.stringify(query)}`,
        DEFAULT_GET_CONFIG,
      );

      await isErrorResponse(response, null, dispatch);
      // parse json or text depending on format
      const text = reportFormat === 'csv';
      const report = text ? await response.text() : await response.json();
      if (download) {
        dispatch({
          type: DOWNLOAD_DISCOUNT_CODE_REPORT,
          payload: report,
        });
      } else {
        dispatch({
          type: GET_DISCOUNT_CODE_REPORT,
          payload: report,
        });
      }
    } catch (err) {
      console.error(err);
      // lower flag
      toastr.error('Error', err);
    } finally {
      dispatch(flagGettingDiscountCodeReport(false));
    }
  };

const clearDiscountCodeReport = () => (dispatch) =>
  dispatch({
    type: CLEAR_DISCOUNT_CODE_REPORT,
  });

export {
  getDiscountCodeReports,
  clearErrorGettingDiscountCodeReports,
  clearDiscountCodeReports,
  clearErrorPostingDiscountCodeReport,
  postDiscountCodeReport,
  getDiscountCodeReport,
  clearDiscountCodeReport,
  clearErrorGettingDiscountCodeReport,
};
