import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import DatePicker from '../../../common/forms/input/DatePicker';
import { isRequired } from '../../../../utils/validators';
import FormFooter from '../../../common/forms/FormFooter';
import { tzNormalizeDate } from '../../../../utils/date';

export const ProcessDuplicateForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Nueva fecha" required>
        <Field
          min={tzNormalizeDate()}
          name="startDate"
          component={DatePicker}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormFooter />
  </Form>
);

ProcessDuplicateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'ProcessForm',
})(ProcessDuplicateForm);

export default connect()(formComponent);
