import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_FUEL_PROFILES,
  GET_FUEL_PROFILES,
  CLEAR_FUEL_PROFILES,
  FLAG_FUEL_PROFILE_ACTIVITY,
  POST_FUEL_PROFILE,
  GET_FUEL_PROFILE,
  CLEAR_FUEL_PROFILE,
  PUT_FUEL_PROFILE,
} from '../types/traffic';
import { FUEL_PROFILE_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { FUEL_PROFILE_PATH } from '../../config/paths';

const flagGettingFuelProfiles = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_FUEL_PROFILES,
    payload: flag,
  });

const getFuelProfiles = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingFuelProfiles(true));
    const query = tableFilters;
    const url = `${FUEL_PROFILE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const fuelProfiles = await response.json();
    dispatch({
      type: GET_FUEL_PROFILES,
      payload: fuelProfiles,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingFuelProfiles(false));
  }
};

const clearFuelProfiles = () => (dispatch) =>
  dispatch({
    type: CLEAR_FUEL_PROFILES,
  });

const flagFuelProfileActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_FUEL_PROFILE_ACTIVITY,
    payload: flag,
  });

const getFuelProfile =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelProfileActivity(true));
      const url = `${FUEL_PROFILE_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const fuelProfile = await response.json();
      dispatch({
        type: GET_FUEL_PROFILE,
        payload: fuelProfile,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagFuelProfileActivity(false));
    }
  };

const clearFuelProfile = () => (dispatch) =>
  dispatch({
    type: CLEAR_FUEL_PROFILE,
  });

const postFuelProfile =
  async ({ name, description, circuitId, refuellingStopList }) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelProfileActivity(true));
      const payload = {
        name,
        description,
        circuitId,
        refuellingStopList,
      };

      const url = FUEL_PROFILE_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const fuelProfile = await response.json();
      dispatch({
        type: POST_FUEL_PROFILE,
        payload: fuelProfile,
      });
      dispatch(push(FUEL_PROFILE_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagFuelProfileActivity(false));
    }
  };

const putFuelProfile =
  async (fuelProfileId, { name, description, circuitId, refuellingStopList }) =>
  async (dispatch) => {
    try {
      dispatch(flagFuelProfileActivity(true));
      const payload = {
        id: fuelProfileId,
        name,
        description,
        circuitId,
        refuellingStopList,
      };
      const url = `${FUEL_PROFILE_ENDPOINT}/${fuelProfileId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const fuelProfile = await response.json();
      dispatch({
        type: PUT_FUEL_PROFILE,
        payload: fuelProfile,
      });
      dispatch(push(FUEL_PROFILE_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagFuelProfileActivity(false));
    }
  };

export {
  flagGettingFuelProfiles,
  getFuelProfiles,
  clearFuelProfiles,
  getFuelProfile,
  clearFuelProfile,
  postFuelProfile,
  putFuelProfile,
};
