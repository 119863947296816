import Immutable from 'immutable';
import {
  COMPANY_PATH,
  CONTRACT_UNIT_PATH,
  HOME_PATH,
  COMPANY_TICKET_PATH,
  COMPANY_CREDIT_NOTE_PATH,
  COMPANY_USER_CONFIGURATION_PATH,
  PRIVATE_SERVICE_PATH,
  PAYMENT_PATH,
  CONTRACTOR_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      id: 'third-party-companies',
      title: 'Empresas Terceras',
      path: COMPANY_PATH,
      icon: 'building',
    },
    {
      id: 'company-ticket',
      title: 'Boletos',
      path: COMPANY_TICKET_PATH,
      icon: 'ticket',
    },
    {
      key: 'company-credit-note',
      title: 'Notas de Crédito',
      path: COMPANY_CREDIT_NOTE_PATH,
      icon: 'credit-card',
    },
    {
      key: 'company-user-configuration',
      title: 'Configuraciones de Usuario',
      path: COMPANY_USER_CONFIGURATION_PATH,
      icon: 'users',
    },
    {
      key: 'private-services',
      title: 'Servicios Privados',
      path: PRIVATE_SERVICE_PATH,
      icon: 'bus',
    },
    {
      key: 'payment',
      title: 'Pagos',
      path: PAYMENT_PATH,
      icon: 'money',
    },
    {
      key: 'contractor',
      title: 'Contratistas',
      path: CONTRACTOR_PATH,
      icon: 'vcard',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Contratos',
      tag: 'span',
      href: CONTRACT_UNIT_PATH,
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
