import { arrayOf, number, shape, string } from 'prop-types';

const paymentBasicInformationPropTypes = {
  createDate: number.isRequired,
  lastUpdate: number.isRequired,
  companyName: string.isRequired,
  manualVoucherId: number,
  operatorUserFullName: string.isRequired,
  parcelList: arrayOf(
    shape({
      id: number.isRequired,
      correlative: string.isRequired,
      salePrice: number.isRequired,
      createDate: number.isRequired,
    }),
  ),
  bankName: string.isRequired,
  accountNumber: string.isRequired,
  operationNumber: string.isRequired,
  paymentDate: number.isRequired,
  amount: number.isRequired,
  status: string.isRequired,
  manualVoucher: shape({
    currencyCode: string.isRequired,
    voucherTypeName: string.isRequired,
    documentSeries: string.isRequired,
    documentCode: number.isRequired,
    serviceType: string.isRequired,
    issueDate: number.isRequired,
    description: string,
    manualVoucherItems: arrayOf(
      shape({
        quantity: number.isRequired,
        description: string.isRequired,
        unitaryPrice: number.isRequired,
        totalPrice: number.isRequired,
      }),
    ),
  }),
};

export default paymentBasicInformationPropTypes;
