import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { LOCATION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import { clearLocation, getLocation, putLocation } from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import { TIME_ZONE } from '../../../../config/locale';
import LocationForm from './LocationForm';
import { addressGenerator } from '../../../../utils/app/json-generator-from-reducer';

export class EditLocation extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: locationId },
      },
      dispatchGetLocation,
    } = this.props;
    dispatchGetLocation({ locationId });
  }

  componentWillUnmount() {
    this.props.dispatchClearLocation();
  }

  onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    // TODO change timezone param when Flit is used in the future
    newFormValues.timezone = TIME_ZONE;
    newFormValues.addressId = formValues.addressId.value;
    newFormValues.address = {
      id: formValues.addressId.value,
      address: formValues.addressId.address,
      address2: formValues.addressId.address2,
      district: formValues.addressId.district,
      cityId: formValues.addressId.cityId,
      postalCode: formValues.addressId.postalCode,
      phone: formValues.addressId.phone,
    };

    const {
      match: {
        params: { id },
      },
      dispatchPutLocation,
    } = this.props;
    dispatchPutLocation(id, newFormValues);
  };

  generateInitialValues = (location) => {
    const locationJson = location.toJS();

    const addressId =
      locationJson.address && addressGenerator(locationJson.address);

    return {
      name: locationJson.name,
      description: locationJson.description,
      locationCorrelationId: locationJson.locationCorrelationId,
      isHomeBase: locationJson.isHomeBase,
      isTerminal: locationJson.isTerminal,
      isPointOfSale: locationJson.isPointOfSale,
      isStop: locationJson.isStop,
      isToll: locationJson.isToll,
      receiveCargoPoint: locationJson.receiveCargoPoint,
      pickupCargoPoint: locationJson.pickupCargoPoint,
      addressId,
      agencyCode: locationJson.agencyCode,
      latitude: locationJson.latitude,
      longitude: locationJson.longitude,
      ubigeo: locationJson.ubigeo,
    };
  };

  render() {
    const { breadcrumbs, location, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (location.isEmpty()) {
      content = <NoDataResource returnPage={LOCATION_PATH} />;
    } else {
      content = (
        <LocationForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(location)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Ubicación"
        subtitle="Editar ubicación"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { LocationUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ubicaciones',
      href: LOCATION_PATH,
    },
    {
      text: 'Ver',
      href: `${LOCATION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  location: LocationUnit.Location.getIn(['current', 'content']),
  loading: !LocationUnit.Location.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutLocation: putLocation,
  dispatchGetLocation: getLocation,
  dispatchClearLocation: clearLocation,
};

EditLocation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutLocation: PropTypes.func.isRequired,
  dispatchGetLocation: PropTypes.func.isRequired,
  dispatchClearLocation: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  location: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditLocation.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLocation);
