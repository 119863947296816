import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_GEOGRAPHIC_ZONE_COMBINATIONS,
  GET_GEOGRAPHIC_ZONE_COMBINATIONS,
  CLEAR_GEOGRAPHIC_ZONE_COMBINATIONS,
  GET_GEOGRAPHIC_ZONES_COMBINATION,
  CLEAR_GEOGRAPHIC_ZONES_COMBINATION,
  FLAG_GEOGRAPHIC_ZONES_COMBINATION_ACTIVITY,
} from '../types/cargo';
import { GEOGRAPHIC_ZONES_COMBINATION_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { GEOGRAPHIC_ZONE_COMBINATION_PATH } from '../../config/paths';

const flagGettingGeographicZoneCombinations = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_GEOGRAPHIC_ZONE_COMBINATIONS,
    payload: flag,
  });

const getGeographicZoneCombinations =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingGeographicZoneCombinations(true));
      const query = tableFilters;
      const url = `${GEOGRAPHIC_ZONES_COMBINATION_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const geographicZoneCombinations = await response.json();
      dispatch({
        type: GET_GEOGRAPHIC_ZONE_COMBINATIONS,
        payload: geographicZoneCombinations,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingGeographicZoneCombinations(false));
    }
  };

const clearGeographicZoneCombinations = () => (dispatch) =>
  dispatch({
    type: CLEAR_GEOGRAPHIC_ZONE_COMBINATIONS,
  });

const flagGeographicZonesCombinationActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GEOGRAPHIC_ZONES_COMBINATION_ACTIVITY,
    payload: flag,
  });

const getGeographicZonesCombination =
  async ({ geographicZonesCombinationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGeographicZonesCombinationActivity(true));
      const url = `${GEOGRAPHIC_ZONES_COMBINATION_ENDPOINT}/${geographicZonesCombinationId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const geographicZonesCombination = await response.json();
      dispatch({
        type: GET_GEOGRAPHIC_ZONES_COMBINATION,
        payload: geographicZonesCombination,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGeographicZonesCombinationActivity(false));
    }
  };

const clearGeographicZonesCombination = () => (dispatch) =>
  dispatch({
    type: CLEAR_GEOGRAPHIC_ZONES_COMBINATION,
  });

const postGeographicZonesCombination =
  async ({
    sourceGeographicZoneId,
    destinationGeographicZoneId,
    factor,
    description,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagGeographicZonesCombinationActivity(true));
      const payload = {
        sourceGeographicZoneId,
        destinationGeographicZoneId,
        factor,
        description,
      };
      const url = GEOGRAPHIC_ZONES_COMBINATION_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const geographicZonesCombination = await response.json();
      dispatch(
        push(
          `${GEOGRAPHIC_ZONE_COMBINATION_PATH}/${geographicZonesCombination.id}`,
        ),
      );
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGeographicZonesCombinationActivity(false));
    }
  };

const putGeographicZonesCombination =
  async (
    geographicZonesCombinationId,
    {
      sourceGeographicZoneId,
      destinationGeographicZoneId,
      factor,
      description,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagGeographicZonesCombinationActivity(true));
      const payload = {
        id: geographicZonesCombinationId,
        sourceGeographicZoneId,
        destinationGeographicZoneId,
        factor,
        description,
      };
      const url = `${GEOGRAPHIC_ZONES_COMBINATION_ENDPOINT}/${geographicZonesCombinationId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const geographicZonesCombination = await response.json();
      dispatch(
        push(
          `${GEOGRAPHIC_ZONE_COMBINATION_PATH}/${geographicZonesCombination.id}`,
        ),
      );
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGeographicZonesCombinationActivity(false));
    }
  };

const deleteGeographicZonesCombination =
  async ({ geographicZonesCombinationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGeographicZonesCombinationActivity(true));
      const url = `${GEOGRAPHIC_ZONES_COMBINATION_ENDPOINT}/${geographicZonesCombinationId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(GEOGRAPHIC_ZONE_COMBINATION_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGeographicZonesCombinationActivity(false));
    }
  };

export {
  flagGettingGeographicZoneCombinations,
  getGeographicZoneCombinations,
  clearGeographicZoneCombinations,
  getGeographicZonesCombination,
  clearGeographicZonesCombination,
  postGeographicZonesCombination,
  putGeographicZonesCombination,
  deleteGeographicZonesCombination,
};
