import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { string, number, PropTypes } from 'prop-types';
import { Button, FormGroup, Form } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import { isRequired, validateNumber } from '../../../../utils/validators';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';

const CreateCreditNoteParcelForm = ({
  parcel,
  handleProcess,
  onCancel,
  handleSubmit,
}) => (
  <Form onSubmit={handleSubmit(handleProcess)}>
    <ResourceProperty label="Serie:">{parcel.documentSeries}</ResourceProperty>
    <ResourceProperty label="Número de serie:">
      {parcel.documentCode}
    </ResourceProperty>
    <ResourceProperty label="Origen:">{parcel.sourceCityName}</ResourceProperty>
    <ResourceProperty label="Destino:">
      {parcel.destinationCityName}
    </ResourceProperty>
    <hr />
    <FormGroup row>
      <FormItem label="Monto de Crédito" required>
        <Field
          name="creditNoteAmount"
          component={TextInput}
          type="text"
          placeholder="Monto de Crédito"
          validate={[isRequired, validateNumber]}
        />
      </FormItem>
    </FormGroup>
    <FormFooter saveButtonColor="secondary" saveButtonIcon={null}>
      <Button color="primary" onClick={onCancel}>
        Cancelar
      </Button>
    </FormFooter>
  </Form>
);

const parcelPropTypes = {
  documentSeries: string,
  documentCode: number,
  sourceCityName: string,
  destinationCityName: string,
  paymentMethodId: number,
};

CreateCreditNoteParcelForm.propTypes = {
  parcel: PropTypes.shape(parcelPropTypes).isRequired,
  handleProcess: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'CreateCreditNoteParcelForm',
})(CreateCreditNoteParcelForm);

export default connect(null, null)(formComponent);
