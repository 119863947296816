import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Modal from '../../../../common/modal/Modal';
import AddCargoToItineraryByQrModalForms from './CargoOperationByQrModalForms';

const CargoOperationByQrButton = ({
  onClickAddItemToItinerary,
  cargoOperation,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);

  const body = (
    <AddCargoToItineraryByQrModalForms
      onClickAddItemToItinerary={onClickAddItemToItinerary}
      setShowModal={setShowModal}
      cargoOperation={cargoOperation}
    />
  );

  return (
    <>
      <Modal
        show={showModal}
        title={cargoOperation.label}
        size="lg"
        onClickClose={() => setShowModal(false)}
        onClickCancel={() => setShowModal(false)}
        body={body}
      />
      <Button color="success" onClick={openModal} disabled={disabled}>
        <i className="fa fa-qrcode" /> {cargoOperation.label} por QR
      </Button>
    </>
  );
};

CargoOperationByQrButton.propTypes = {
  onClickAddItemToItinerary: PropTypes.func.isRequired,
  cargoOperation: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CargoOperationByQrButton;
