import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CARGO_PATH } from '../../../../config/paths';
import { SALES_SESSION_OTHER_INCOMES_PARCELS_COLUMNS } from '../../../../config/columns';
import {
  clearOtherIncomesParcels,
  getSalesSessionOtherIncomesParcels,
} from '../../../../actions';
import Table from '../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';

const OtherIncomesParcelsTable = ({
  loading,
  salesSessionId,
  salesSessionParcels,
  dispatchClearOtherIncomesParcels,
  dispatchGetSalesSessionOtherIncomesParcels,
}) => {
  useLayoutEffect(() => {
    dispatchGetSalesSessionOtherIncomesParcels({ salesSessionId });

    return () => {
      dispatchClearOtherIncomesParcels();
    };
  }, []);

  const data = salesSessionParcels.toJS();
  const defaultPageSize =
    data.length !== 0 && data.length <= 10 ? data.length : DEFAULT_PAGE_SIZE;
  const showPagination = data.length > 10;

  return (
    <>
      <Table
        columns={SALES_SESSION_OTHER_INCOMES_PARCELS_COLUMNS}
        data={data}
        defaultPageSize={defaultPageSize}
        showPagination={showPagination}
        manual={false}
        loading={loading}
        modelPath={CARGO_PATH}
        filterable
        openPathInNewTab
        navigateToModelOnRowClick
        defaultFilterMethod={filterMethodCaseInsensitive}
      />
      <br />
    </>
  );
};

OtherIncomesParcelsTable.propTypes = {
  salesSessionId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetSalesSessionOtherIncomesParcels: PropTypes.func.isRequired,
  dispatchClearOtherIncomesParcels: PropTypes.func.isRequired,
  salesSessionParcels: PropTypes.instanceOf(Object),
};

OtherIncomesParcelsTable.defaultProps = {
  salesSessionParcels: [],
};

const mapStateToProps = ({ CargoUnit }) => ({
  salesSessionParcels: CargoUnit.Cargo.getIn(['salesSession', 'otherIncomes']),
  loading: CargoUnit.Cargo.getIn(['salesSession', 'loadingOtherIncomes']),
});

const mapDispatchToProps = {
  dispatchGetSalesSessionOtherIncomesParcels:
    getSalesSessionOtherIncomesParcels,
  dispatchClearOtherIncomesParcels: clearOtherIncomesParcels,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OtherIncomesParcelsTable);
