import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import Modal from '../../../common/modal/Modal';
import CargoPickupModel from '../cargo-pickup/CargoPickupModal';
import { putParcelsToPickupByItem } from '../../../../actions/cargo/CargoPickup';
import { getParcel } from '../../../../actions/cargo/Cargo';

export const DeliverParcelButton = ({
  parcel,
  dispatchPutParcelsToPickup,
  dispatchGetParcel,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  const getConsigneeData = () => {
    const { consigneeCustomer, secondaryConsigneeCustomer } = parcel;

    const consigneeCustomerData = {
      value: consigneeCustomer.id || '',
      label:
        `${consigneeCustomer.fullName} | ${consigneeCustomer.identificationType.name}: ${consigneeCustomer.idDocumentNumber}` ||
        '',
    };

    if (!secondaryConsigneeCustomer) {
      return [consigneeCustomerData];
    }

    const secondaryConsigneeCustomerData = {
      value: secondaryConsigneeCustomer.id || '',
      label:
        `${secondaryConsigneeCustomer.fullName} | ${secondaryConsigneeCustomer.identificationType.name}: ${secondaryConsigneeCustomer.idDocumentNumber}` ||
        '',
    };

    return [consigneeCustomerData, secondaryConsigneeCustomerData];
  };

  const onModalSubmit = async ({
    secretCode,
    paymentMethod,
    voucherCode,
    items,
    deliveredCustomer,
  }) => {
    await dispatchPutParcelsToPickup({
      parcelId: parcel.id,
      secretCode,
      paymentMethod,
      voucherCode,
      internal: parcel.internal,
      items,
      deliveredCustomerId: deliveredCustomer.value,
    });
    dispatchGetParcel({ parcelId: parcel.id });
    setShowModal(false);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const onShowModal = async () => {
    const newModalBody = (
      <CargoPickupModel
        parcel={parcel}
        onCancel={onCancel}
        handleProcess={onModalSubmit}
        options={getConsigneeData()}
      />
    );
    setModalBody(newModalBody);
    setShowModal(true);
  };

  return (
    <>
      <Button color="success" onClick={onShowModal}>
        <i className="fa fa-plus-circle" /> Entregar
      </Button>
      <Modal
        show={showModal}
        title="Entregar encomienda"
        onClickClose={() => setShowModal(false)}
        body={modalBody}
        size="lg"
      />
    </>
  );
};

DeliverParcelButton.propTypes = {
  parcel: PropTypes.instanceOf(Object).isRequired,
  dispatchPutParcelsToPickup: PropTypes.func.isRequired,
  dispatchGetParcel: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPutParcelsToPickup: putParcelsToPickupByItem,
  dispatchGetParcel: getParcel,
};

export default connect(null, mapDispatchToProps)(DeliverParcelButton);
