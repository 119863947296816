import React, { useEffect, useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import {
  clearItinerariesForBooking,
  clearSelectItinerary,
  getCurrentContractor,
  selectItinerarySearchResult,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import ItinerarySearch from '../../../modules/booking/search/ItinerarySearch';
import ItinerarySearchResults from '../../../modules/booking/itineraries/ItinerarySearchResults';

export const TripRequestForm = ({
  handleSubmit,
  onSubmit,
  loading,
  initialValues,
  dispatchClearSelectItinerary,
  dispatchClearItinerariesForBooking,
  dispatchSelectItinerarySearchResult,
  selectedItinerarySearchResults,
  contractor,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearSelectItinerary();
      dispatchClearItinerariesForBooking();
    },
    [],
  );

  useEffect(() => {
    if (initialValues.itinerary && !selectedItinerarySearchResults) {
      dispatchSelectItinerarySearchResult({
        index: 0,
        itinerarySearchResult: { itinerary: initialValues.itinerary },
      });
    }
  }, [initialValues]);

  const itinerarySection = !selectedItinerarySearchResults ? (
    <div className="border rounded p-3 mt-4">
      <h5>Itinerario seleccionado</h5>
      <span className="text-muted">
        <i>Debe seleccionar un itinerario</i>
      </span>
    </div>
  ) : (
    <div className="border rounded p-3 mt-4">
      <h5>Itinerario seleccionado</h5>
      <span>
        <i>
          {(selectedItinerarySearchResults.itinerary.route &&
            selectedItinerarySearchResults.itinerary.route.name) ||
            selectedItinerarySearchResults.itinerary.routeName ||
            ''}
        </i>
      </span>
    </div>
  );

  const onHandleSubmit = (formValues) => {
    if (!selectedItinerarySearchResults) {
      toastr.error('Error al guardar', 'Se debe elegir un itinerario');
    } else {
      onSubmit({
        ...formValues,
        itineraryId: selectedItinerarySearchResults.itinerary.id,
        contractorId: contractor.get('id'),
      });
    }
  };

  if (loading) return <Loader />;
  return (
    <>
      <Form onSubmit={(e) => e.preventDefault()}>
        <CustomerInputGroup
          label="Personal"
          name="customerList"
          labelRequired
          validate={[isRequired]}
          form="TripRequestForm"
          showDetails
          isMulti
        />
      </Form>
      <ItinerarySearch />
      <ItinerarySearchResults />
      {itinerarySection}
      <FormFooter saveButtonAction={handleSubmit(onHandleSubmit)} />
    </>
  );
};

TripRequestForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  selectedItinerarySearchResults: PropTypes.instanceOf(Object),
  dispatchClearSelectItinerary: PropTypes.func.isRequired,
  dispatchClearItinerariesForBooking: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  contractor: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchSelectItinerarySearchResult: PropTypes.func.isRequired,
};

TripRequestForm.defaultProps = {
  selectedItinerarySearchResults: null,
  initialValues: {},
};

const mapStateToProps = ({ ItineraryUnit, BookingUnit, ContractUnit }) => ({
  loading: !ItineraryUnit.TripRequest.getIn(['current', 'activity']).isEmpty(),
  selectedItinerarySearchResults: BookingUnit.Booking.getIn([
    'itineraries',
    'selectedItinerarySearchResults',
  ]).get(0),
  contractor: ContractUnit.Contractor.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchClearSelectItinerary: clearSelectItinerary,
  dispatchClearItinerariesForBooking: clearItinerariesForBooking,
  dispatchGetCurrentContractor: getCurrentContractor,
  dispatchSelectItinerarySearchResult: selectItinerarySearchResult,
};

const formComponent = reduxForm({
  form: 'TripRequestForm',
})(TripRequestForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
