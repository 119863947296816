import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_MAINTENANCE_FAIL_MODES,
  GET_MAINTENANCE_FAIL_MODES,
  CLEAR_MAINTENANCE_FAIL_MODES,
  FLAG_MAINTENANCE_FAIL_MODE_ACTIVITY,
  GET_MAINTENANCE_FAIL_MODE,
  CLEAR_MAINTENANCE_FAIL_MODE,
} from '../types/mechanical-maintenance';
import { MAINTENANCE_FAIL_MODE_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { MAINTENANCE_FAIL_MODE_PATH } from '../../config/paths';

const flagGettingMaintenanceFailModes = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MAINTENANCE_FAIL_MODES,
    payload: flag,
  });

const getMaintenanceFailModes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingMaintenanceFailModes(true));
    const query = tableFilters;
    const url = `${MAINTENANCE_FAIL_MODE_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_MAINTENANCE_FAIL_MODES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingMaintenanceFailModes(false));
  }
};

const clearMaintenanceFailModes = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_FAIL_MODES,
  });

const flagMaintenanceFailModeActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_MAINTENANCE_FAIL_MODE_ACTIVITY,
    payload: flag,
  });

const getMaintenanceFailMode =
  async ({ maintenanceFailModeId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceFailModeActivity(true));
      const url = `${MAINTENANCE_FAIL_MODE_ENDPOINT}/${maintenanceFailModeId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_FAIL_MODE,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceFailModeActivity(false));
    }
  };

const clearMaintenanceFailMode = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_FAIL_MODE,
  });

const postMaintenanceFailMode =
  async ({ description }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceFailModeActivity(true));
      const payload = { description };
      const url = MAINTENANCE_FAIL_MODE_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(MAINTENANCE_FAIL_MODE_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceFailModeActivity(false));
    }
  };

const deleteMaintenanceFailMode =
  async ({ maintenanceFailModeId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceFailModeActivity(true));
      const url = `${MAINTENANCE_FAIL_MODE_ENDPOINT}/${maintenanceFailModeId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(MAINTENANCE_FAIL_MODE_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceFailModeActivity(false));
    }
  };

const putMaintenanceFailMode =
  async (maintenanceFailModeId, { description }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceFailModeActivity(true));
      const payload = {
        id: maintenanceFailModeId,
        description,
      };
      const url = `${MAINTENANCE_FAIL_MODE_ENDPOINT}/${maintenanceFailModeId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(MAINTENANCE_FAIL_MODE_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceFailModeActivity(false));
    }
  };

export {
  flagGettingMaintenanceFailModes,
  getMaintenanceFailModes,
  clearMaintenanceFailModes,
  flagMaintenanceFailModeActivity,
  getMaintenanceFailMode,
  postMaintenanceFailMode,
  clearMaintenanceFailMode,
  deleteMaintenanceFailMode,
  putMaintenanceFailMode,
};
