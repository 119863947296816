import Immutable from 'immutable';
import {
  FLAG_GETTING_COMMON_PRODUCTS,
  GET_COMMON_PRODUCTS,
  CLEAR_COMMON_PRODUCTS,
  FLAG_COMMON_PRODUCT_ACTIVITY,
  CLEAR_COMMON_PRODUCT,
  GET_COMMON_PRODUCT,
  GET_COMMON_PRODUCT_PRICE
} from '../../../actions/types/cargo';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
    price: Immutable.List(), 
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_COMMON_PRODUCTS:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_COMMON_PRODUCTS:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case CLEAR_COMMON_PRODUCTS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_COMMON_PRODUCT_ACTIVITY:
      if (action.payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(action.payload),
        );
      }
      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_COMMON_PRODUCT:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));

    case GET_COMMON_PRODUCT_PRICE:
      return state.setIn(['current', 'price'], Immutable.List(action.payload));

    case CLEAR_COMMON_PRODUCT:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    default:
      return state;
  }
};
