import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { POST } from '../../../../config/permissions';
import { generateApprovePayment } from '../../../../config/endpoints';
import { postRejectContractPayment } from '../../../../actions';

const PaymentRejectButton = ({
  paymentId,
  dispatchPostRejectContractPayment,
}) => (
  <Button
    color="danger"
    onClick={() => dispatchPostRejectContractPayment({ paymentId })}
  >
    <i className="fa fa-remove" /> Rechazar
  </Button>
);

PaymentRejectButton.propTypes = {
  paymentId: PropTypes.number,
  dispatchPostRejectContractPayment: PropTypes.func.isRequired,
};

PaymentRejectButton.defaultProps = {
  paymentId: null,
};

const mapDispatchToProps = {
  dispatchPostRejectContractPayment: postRejectContractPayment,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(PaymentRejectButton);

export default withEndpointAuthorization({
  url: generateApprovePayment(),
  mapUrlParamsToProps: { paymentId: 'paymentId' },
  permissionType: POST,
})(connectedComponent);
