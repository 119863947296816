import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { PARCEL_SERVICE_TYPE_PATH } from '../../../../config/paths';
import {
  clearParcelServiceType,
  getParcelServiceType,
  putParcelServiceType,
} from '../../../../actions/cargo/ParcelServiceType';
import {
  breadcrumbPropType,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';

import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import ParcelServiceTypeForm from './ParcelServiceTypeForm';

const EditParcelServiceType = ({
  breadcrumbs,
  dispatchClearParcelServiceType,
  dispatchGetParcelServiceType,
  dispatchPutParcelServiceType,
  loading,
  match: {
    params: { id: parcelServiceTypeId },
  },
  parcelServiceType,
}) => {
  useLayoutEffect(() => {
    dispatchGetParcelServiceType({ parcelServiceTypeId });
    return () => dispatchClearParcelServiceType();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPutParcelServiceType(parcelServiceTypeId, formValues);

  const generateInitialValues = ({
    name,
    description,
    increasePercentage,
  }) => ({
    name,
    description,
    increasePercentage,
  });

  let content;

  if (loading) content = <Loader />;
  else if (parcelServiceType.isEmpty())
    content = <NoDataResource returnPage={PARCEL_SERVICE_TYPE_PATH} />;
  else
    content = (
      <ParcelServiceTypeForm
        initialValues={generateInitialValues(parcelServiceType.toJS())}
        onSubmit={onSubmit}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Tipo de Servicio"
      subtitle="Editar tipo de servicio"
      content={content}
    />
  );
};

EditParcelServiceType.propTypes = {
  breadcrumbs: breadcrumbPropType.isRequired,
  dispatchClearParcelServiceType: PropTypes.func.isRequired,
  dispatchGetParcelServiceType: PropTypes.func.isRequired,
  dispatchPutParcelServiceType: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: matchPropTypes.isRequired,
  parcelServiceType: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Tipos de Servicio',
      href: PARCEL_SERVICE_TYPE_PATH,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  parcelServiceType: CargoUnit.ParcelServiceType.getIn(['current', 'content']),
  loading: !CargoUnit.ParcelServiceType.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchClearParcelServiceType: clearParcelServiceType,
  dispatchGetParcelServiceType: getParcelServiceType,
  dispatchPutParcelServiceType: putParcelServiceType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditParcelServiceType);
