import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import ItinerarySearchResults from './itineraries/ItinerarySearchResults';
import ItinerarySearch from './search/ItinerarySearch';
import SeatSelection from './seats/SeatSelection';
import SelectedSeats from './seats/SelectedSeats';
import Loader from '../../common/Loader';

const RoundTrip = ({ postingReservation }) => {
  if (postingReservation) return <Loader />;
  return (
    <div>
      <ItinerarySearch />
      <Row>
        <Col lg={6} md={12}>
          <ItinerarySearchResults />
        </Col>
        <Col lg={4} md={6}>
          <SeatSelection />
        </Col>
        <Col lg={2} md={6}>
          <SelectedSeats />
        </Col>
      </Row>
    </div>
  );
};

RoundTrip.propTypes = {
  postingReservation: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ BookingUnit }) => ({
  postingReservation: BookingUnit.Booking.getIn([
    'passengers',
    'postingReservation',
  ]),
});

export default connect(mapStateToProps)(RoundTrip);
