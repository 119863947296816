import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_BUSINESSES,
  GET_BUSINESSES,
  CLEAR_BUSINESSES,
  CLEAR_BUSINESS,
  FLAG_BUSINESS_ACTIVITY,
  GET_BUSINESS,
} from '../types';
import { BUSINESS_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { BUSINESS_PATH } from '../../config/paths';

const flagGettingBusinesses = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_BUSINESSES,
    payload: flag,
  });

const flagBusinessActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_BUSINESS_ACTIVITY,
    payload: flag,
  });

const getBusinesses =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingBusinesses(true));
      const query = tableFilters;
      const url = `${BUSINESS_ENDPOINT}?${QueryString.stringify(query)}`;
      const promise = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({ type: GET_BUSINESSES, payload: response });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingBusinesses(false));
    }
  };

const clearBusinesses = () => (dispatch) =>
  dispatch({
    type: CLEAR_BUSINESSES,
  });

const clearBusiness = () => (dispatch) =>
  dispatch({
    type: CLEAR_BUSINESS,
  });

const getBusiness =
  async ({ businessId }) =>
  async (dispatch) => {
    try {
      dispatch(flagBusinessActivity(true));
      const url = `${BUSINESS_ENDPOINT}/${businessId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const business = await response.json();
      dispatch({
        type: GET_BUSINESS,
        payload: business,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBusinessActivity(false));
    }
  };

const createNewBusiness = async ({
  businessTaxId,
  name,
  email,
  primaryContact,
  phone,
  fax,
  addressSummary,
  addressId,
}) => {
  const payload = {
    businessTaxId,
    name,
    email,
    primaryContact,
    phone,
    fax,
    addressSummary,
    addressId,
  };

  const response = await fetch(BUSINESS_ENDPOINT, {
    ...DEFAULT_POST_CONFIG,
    body: JSON.stringify(payload),
  });

  await isErrorResponse(response);
  const business = await response.json();
  return business;
};

const postBusiness =
  async ({ values, flagGetBusiness = false, redirect = true }) =>
  async (dispatch) => {
    try {
      dispatch(flagBusinessActivity(true));
      const business = await createNewBusiness(values);

      if (flagGetBusiness)
        await dispatch(getBusiness({ businessId: business.id }));

      if (redirect) dispatch(push(BUSINESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBusinessActivity(false));
    }
  };

const putBusiness =
  async ({
    businessId,
    values: {
      businessTaxId,
      name,
      email,
      primaryContact,
      phone,
      fax,
      addressSummary,
      addressId,
    },
    flagGetBusiness = false,
    redirect = true,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagBusinessActivity(true));

      const payload = {
        businessTaxId,
        name,
        email,
        primaryContact,
        phone,
        fax,
        addressSummary,
        addressId,
        id: businessId,
      };

      const url = `${BUSINESS_ENDPOINT}/${businessId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);
      const business = await response.json();

      if (flagGetBusiness)
        await dispatch(getBusiness({ businessId: business.id }));

      if (redirect) dispatch(push(BUSINESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBusinessActivity(false));
    }
  };

const deleteBusiness =
  async ({ businessId }) =>
  async (dispatch) => {
    try {
      dispatch(flagBusinessActivity(true));
      const url = `${BUSINESS_ENDPOINT}/${businessId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(BUSINESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBusinessActivity(false));
    }
  };

export {
  flagGettingBusinesses,
  getBusinesses,
  clearBusinesses,
  createNewBusiness,
  clearBusiness,
  postBusiness,
  putBusiness,
  getBusiness,
  deleteBusiness,
};
