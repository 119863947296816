import React from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../utils/date';
import { FULL_DATE_TIME_FORMAT } from '../../../../config/locale';
import { numberFormatter } from '../../../../utils/number';

const ParcelServiceTypeBasicInformationResource = ({
  id,
  name,
  increasePercentage,
  user,
  createDate,
  lastUpdate,
}) => (
  <>
    <ResourceProperty label="ID:">{id}</ResourceProperty>
    <ResourceProperty label="Nombre:">{name}</ResourceProperty>
    <ResourceProperty label="Aumento porcentual:">
      {numberFormatter({ value: increasePercentage, style: 'percent' })}
    </ResourceProperty>
    <ResourceProperty label="Creado por:">{user.fullName}</ResourceProperty>
    <ResourceProperty label="Fecha de Creación:">
      {tzNormalizeDate({ date: createDate, format: FULL_DATE_TIME_FORMAT })}
    </ResourceProperty>
    <ResourceProperty label="Última Actualización:">
      {tzNormalizeDate({ date: lastUpdate, format: FULL_DATE_TIME_FORMAT })}
    </ResourceProperty>
  </>
);

ParcelServiceTypeBasicInformationResource.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  increasePercentage: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  createDate: PropTypes.number.isRequired,
  lastUpdate: PropTypes.number.isRequired,
};

export default ParcelServiceTypeBasicInformationResource;
