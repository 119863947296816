import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  EXPENSE_ENDPOINT,
  generateSalesSessionExpenseEnpoint,
  ALL_EXPENSE_ENDPOINT,
  TRIP_EXPENSE_ENDPOINT,
  TRIP_EXPENSE_ITINERARY_ENDPOINT,
  generateGetPreviewTripEndpoint,
  generateGetPreviewTripByCircuitEndpoint,
  TRIP_EXPENSE_CIRCUIT_ENDPOINT,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import {
  FLAG_GETTING_EXPENSES,
  GET_EXPENSES,
  CLEAR_EXPENSES,
  GET_EXPENSE,
  CLEAR_EXPENSE,
  POST_EXPENSE,
  PUT_EXPENSE,
  FLAG_EXPENSE_ACTIVITY,
} from '../types/sales/Expense';
import { EXPENSE_PATH } from '../../config/paths';
import { createNewBusiness } from '../user';
import { flagItineraryActivity } from '../itinerary';

const flagGettingExpenses = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_EXPENSES,
    payload: flag,
  });

const getSalesSessionExpense =
  async ({ salesSessionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingExpenses(true));
      const url = generateSalesSessionExpenseEnpoint(salesSessionId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const expense = await response.json();
      await dispatch({
        type: GET_EXPENSES,
        payload: { content: expense },
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingExpenses(false));
    }
  };

const flagExpenseActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_EXPENSE_ACTIVITY,
    payload: flag,
  });

const getExpenses = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingExpenses(true));
    const query = tableFilters;
    const url = `${EXPENSE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const expenses = await response.json();
    dispatch({ type: GET_EXPENSES, payload: expenses });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingExpenses(false));
  }
};

const getAllExpenses = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingExpenses(true));
    const query = tableFilters;
    const url = `${ALL_EXPENSE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const expenses = await response.json();
    dispatch({ type: GET_EXPENSES, payload: expenses });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingExpenses(false));
  }
};

const clearExpenses = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPENSES,
  });

const getExpense = async (expenseId) => async (dispatch) => {
  try {
    dispatch(flagExpenseActivity(true));
    const url = `${EXPENSE_ENDPOINT}/${expenseId}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const expense = await response.json();
    dispatch({ type: GET_EXPENSE, payload: expense });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagExpenseActivity(false));
  }
};

const clearExpense = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPENSE,
  });

const postExpense =
  async (
    {
      amount,
      authorizerUserId,
      businessId,
      description,
      documentCode,
      documentSeries,
      createDate,
      expenseDescription,
      expenseCode,
      expenseTypeLabel,
      expenseTypeId,
      lastUpdate,
      name,
      isSettled,
      payeeCustomerId,
      registeredBusId,
      voucherTime,
      voucherTypeId,
      costCenterId,
    },
    { businessTaxId, businessName, addressSummary },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseActivity(true));
      let newBusiness = null;
      if (businessTaxId) {
        // New business
        const payloadNewBusiness = {
          businessTaxId,
          businessName,
          addressSummary,
        };
        newBusiness = await createNewBusiness(payloadNewBusiness);
      }

      const payload = {
        amount,
        authorizerUserId,
        businessId,
        description,
        documentCode,
        documentSeries,
        expenseType: {
          createDate,
          description: expenseDescription,
          expenseCode,
          expenseTypeLabel,
          id: expenseTypeId,
          lastUpdate,
          name,
        },
        expenseTypeId,
        isSettled,
        payeeCustomerId,
        registeredBusId,
        voucherTime,
        voucherTypeId,
        costCenterId,
      };

      if (newBusiness) {
        payload.businessId = newBusiness.id;
      }

      const url = EXPENSE_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const expense = await response.json();
      dispatch({
        type: POST_EXPENSE,
        payload: expense,
      });
      dispatch(push(`${EXPENSE_PATH}/${expense.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExpenseActivity(false));
    }
  };

const putExpense =
  async (
    expenseId,
    {
      amount,
      authorizerUserId,
      businessId,
      description,
      documentCode,
      documentSeries,
      expenseTypeId,
      id,
      isSettled,
      payeeCustomerId,
      registeredBusId,
      voucherTime,
      voucherTypeId,
      costCenterId,
    },
    { businessTaxId, businessName, addressSummary },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseActivity(true));
      let newBusiness = null;
      if (businessTaxId) {
        // New business
        const payloadNewBusiness = {
          businessTaxId,
          businessName,
          addressSummary,
        };

        newBusiness = await createNewBusiness(payloadNewBusiness);
      }

      const payload = {
        amount,
        authorizerUserId,
        businessId,
        description,
        documentCode,
        documentSeries,
        expenseType: {
          id: expenseTypeId,
        },
        expenseTypeId,
        id,
        isSettled,
        payeeCustomerId,
        registeredBusId,
        voucherTime,
        voucherTypeId,
        costCenterId,
      };

      if (newBusiness) {
        payload.businessId = newBusiness.id;
      }
      const url = `${EXPENSE_ENDPOINT}/${expenseId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const expense = await response.json();
      dispatch({
        type: PUT_EXPENSE,
        payload: expense,
      });
      dispatch(push(`${EXPENSE_PATH}/${expense.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExpenseActivity(false));
    }
  };

const deleteExpense =
  async ({ expenseId }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseActivity(true));
      const url = `${EXPENSE_ENDPOINT}/${expenseId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(`${EXPENSE_PATH}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExpenseActivity(false));
    }
  };

const deleteTripExpense =
  async ({ expenseId }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseActivity(true));
      const url = `${TRIP_EXPENSE_ENDPOINT}/${expenseId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(`${EXPENSE_PATH}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExpenseActivity(false));
    }
  };

const postTripExpense =
  async (
    { itineraryId, driverPayeeId, secondDriverPayeeId, thirdDriverPayeeId },
    isTripExpenseByCircuit,
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const payload = {
        itineraryId,
        driverPayeeId,
        secondDriverPayeeId,
        thirdDriverPayeeId,
      };
      const url = isTripExpenseByCircuit
        ? TRIP_EXPENSE_CIRCUIT_ENDPOINT
        : TRIP_EXPENSE_ITINERARY_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const tripExpense = await response.json();
      return isTripExpenseByCircuit ? tripExpense : [tripExpense];
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const getPreviewTripExpense =
  async ({ itineraryId }, isTripExpenseByCircuit) =>
  async () => {
    try {
      const url = isTripExpenseByCircuit
        ? generateGetPreviewTripByCircuitEndpoint(itineraryId)
        : generateGetPreviewTripEndpoint(itineraryId);
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const tripExpense = await response.json();
      return isTripExpenseByCircuit ? tripExpense : [tripExpense];
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    }
  };

export {
  flagGettingExpenses,
  getExpenses,
  getAllExpenses,
  clearExpenses,
  getExpense,
  clearExpense,
  postExpense,
  putExpense,
  getSalesSessionExpense,
  deleteExpense,
  deleteTripExpense,
  postTripExpense,
  getPreviewTripExpense,
  flagExpenseActivity,
};
