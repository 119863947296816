import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  GET_CARGO_ITEM_TO_UNLOAD,
  GET_CARGO_ITEM_TO_ITINERARY,
  GET_CARGO_ITEM_BY_PARCEL_CORRELATION_ENDPOINT,
  GET_CARGO_ITEM_TO_UNLOAD_V2,
  CARGO_LOCATION_GROUP_ENDPOINT,
} from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_POST_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_CARGO_LOCATION_GROUP,
  CLEAR_MANIFEST_ITEMS,
  CLEAR_MANIFEST_ITEMS_BY_PARCEL,
  DELETE_MANIFEST_ITEMS,
  DISABLED_ACTIONS_AFTER_DEPARTURE,
  FLAG_GETTING_MANIFEST_ITEMS,
  FLAG_MANIFEST_ITEMS_ACTIVITY,
  GET_CARGO_LOCATION_GROUP,
  GET_MANIFEST_ITEMS,
  GET_MANIFEST_ITEMS_BY_PARCEL,
  UPDATE_MANIFEST_ITEMS,
  UPDATE_MANIFEST_ITEMS_BY_PARCEL,
} from '../types/cargo';

const updateManifestItems = (payload) => (dispatch) =>
  dispatch({
    type: UPDATE_MANIFEST_ITEMS,
    payload,
  });

const deleteManifestItemFromItinerary = (payload) => ({
  type: DELETE_MANIFEST_ITEMS,
  payload,
});

const clearManifestItems = () => (dispatch) =>
  dispatch({
    type: CLEAR_MANIFEST_ITEMS,
  });

const flagManifestItems = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MANIFEST_ITEMS,
    payload: flag,
  });

const flagManifestItemsActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_MANIFEST_ITEMS_ACTIVITY,
    payload: flag,
  });

const getManifestCargoItemsToItinerary = (filters) => async (dispatch) => {
  try {
    dispatch(flagManifestItems(true));
    const query = filters;
    const url = `${GET_CARGO_ITEM_TO_ITINERARY}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const items = await response.json();
    dispatch({
      type: GET_MANIFEST_ITEMS,
      payload: items,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagManifestItems(false));
  }
};

const getManifestItemToUnload = (filters) => async (dispatch) => {
  try {
    dispatch(flagManifestItems(true));
    const query = filters;
    const url = `${GET_CARGO_ITEM_TO_UNLOAD}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const items = await response.json();
    dispatch({
      type: GET_MANIFEST_ITEMS,
      payload: items,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagManifestItems(false));
  }
};

const getManifestItemToUnloadV2 = (filters) => async (dispatch) => {
  try {
    dispatch(flagManifestItems(true));
    const query = filters;
    const url = `${GET_CARGO_ITEM_TO_UNLOAD_V2}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const items = await response.json();
    dispatch({
      type: GET_MANIFEST_ITEMS,
      payload: items,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagManifestItems(false));
  }
};

const getManifestItemsByParcelCorrelation =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      const query = tableFilters;
      dispatch(flagManifestItemsActivity(true));
      const url = `${GET_CARGO_ITEM_BY_PARCEL_CORRELATION_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;

      const response = await fetch(url, DEFAULT_GET_CONFIG);

      await isErrorResponse(response, null, dispatch);
      const payload = await response.json();
      dispatch({
        type: GET_MANIFEST_ITEMS_BY_PARCEL,
        payload,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagManifestItemsActivity(false));
    }
  };

const clearManifestItemsByParcelCorrelation = () => (dispatch) =>
  dispatch({
    type: CLEAR_MANIFEST_ITEMS_BY_PARCEL,
  });

const updateManifestItemsByParcelCorrelation = (payload) => ({
  type: UPDATE_MANIFEST_ITEMS_BY_PARCEL,
  payload,
});

const disabledActionsAfterDeparture = (flag) => (dispatch) =>
  dispatch({
    type: DISABLED_ACTIONS_AFTER_DEPARTURE,
    payload: flag,
  });

const postCargoLocationGroup = (locationIds) => async (dispatch) => {
  try {
    dispatch(flagManifestItems(true));

    const url = CARGO_LOCATION_GROUP_ENDPOINT;
    const promise = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(locationIds),
    });
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();

    dispatch({
      type: GET_CARGO_LOCATION_GROUP,
      payload: response,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagManifestItems(false));
  }
  return null;
};

const clearCargoLocationGroup = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_LOCATION_GROUP,
  });

export {
  clearManifestItems,
  getManifestCargoItemsToItinerary,
  updateManifestItems,
  getManifestItemToUnload,
  deleteManifestItemFromItinerary,
  getManifestItemsByParcelCorrelation,
  clearManifestItemsByParcelCorrelation,
  updateManifestItemsByParcelCorrelation,
  disabledActionsAfterDeparture,
  getManifestItemToUnloadV2,
  postCargoLocationGroup,
  clearCargoLocationGroup,
};
