import React from 'react';
import { ButtonToolbar } from 'reactstrap';
import TableToolbar from '../../../common/TableToolbar';
import FindParcelByQrButton from './FindParcelByQrButton';
import { NEW_CARGO_PATH } from '../../../../config/paths';

const ParcelsToolbar = () => (
  <ButtonToolbar className="pull-right">
    <div>
      <TableToolbar
        buttonPath={NEW_CARGO_PATH}
        buttonText="Crear"
        buttonClassName="btn btn-success"
      />
      <FindParcelByQrButton />
    </div>
  </ButtonToolbar>
);

export default ParcelsToolbar;
