import { toastr } from 'react-redux-toastr';
import { LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION,
  FLAG_GETTING_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION,
  GET_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION,
} from '../types/itinerary/LocationForItinerarySearchOmission';

const flagGettingLocationsForItinerarySearchOmission = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION,
    payload: flag,
  });

const getLocationsForItinerarySearchOmission = () => async (dispatch) => {
  try {
    dispatch(flagGettingLocationsForItinerarySearchOmission(true));

    const url = LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION_ENDPOINT;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const locationsForItinerarySearchOmission = await response.json();
    dispatch({
      type: GET_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION,
      payload: locationsForItinerarySearchOmission,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingLocationsForItinerarySearchOmission(false));
  }
};

const clearLocationsForItinerarySearchOmission = () => (dispatch) =>
  dispatch({
    type: CLEAR_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION,
  });

export {
  flagGettingLocationsForItinerarySearchOmission,
  getLocationsForItinerarySearchOmission,
  clearLocationsForItinerarySearchOmission,
};
