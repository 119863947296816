import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { PROCESS_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import ItineraryStatusSearchForm from '../itinerary/itinerary-schedule/ItineraryStatusSearchForm';
import EditItineraryStatusForm from '../itinerary/itinerary-schedule/EditItineraryStatusForm';
import Content from '../../../layout/Content';
import {
  clearItineraries,
  getItinerariesByProcess,
  putUpdateActiveStatusOfItineraries,
} from '../../../../actions';

export const ProcessEditItineraryStatus = ({
  breadcrumbs,
  match: {
    params: { id: processId },
  },
  itinerariesByProcess,
  loadingItinerariesByProcess,
  loadingItinerary,
  dispatchPutUpdateActiveStatusOfItineraries,
  dispatchGetItinerariesByProcess,
  dispatchClearItineraries,
}) => {
  const [searchBy, setSearchBy] = useState({});
  const [itineraries, setItineraries] = useState([]);

  useLayoutEffect(() => {
    dispatchGetItinerariesByProcess({ processId });

    return () => {
      dispatchClearItineraries();
    };
  }, []);

  useEffect(() => {
    const content = itinerariesByProcess.get('content');

    if (!(content.length === 0) && itineraries.length === 0) {
      setItineraries(
        content.map(
          ({
            id,
            active,
            sourceLocationName,
            destinationLocationName,
            routeName,
            departuretime,
            serviceType,
            companyBusId,
            type,
            tripId,
            ticketsSold,
          }) => ({
            id,
            active,
            routeName,
            trip: !!tripId,
            departuretime: tzNormalizeDate({
              date: departuretime,
              format: DATE_TIME_FORMAT,
            }),
            sourceLocationName,
            destinationLocationName,
            serviceType,
            companyBusId,
            type,
            ticketsSold,
          }),
        ),
      );
    }
  }, [itinerariesByProcess, itineraries]);

  const onSubmitSearch = (formValues) => {
    setSearchBy(formValues || {});
  };

  const onSubmitItineraryStatus = (formValues) => {
    const itineraryList = formValues.items.map((element) => ({
      itineraryId: element.id,
      active: element.checked,
    }));

    dispatchPutUpdateActiveStatusOfItineraries({ itineraryList }, PROCESS_PATH);
  };

  const generateFilteredItineraries = () => {
    let itinerariesFiltered = [...itineraries];

    if (searchBy.trip) {
      itinerariesFiltered = itineraries.filter(
        (itinerary) => !!itinerary.tripId === searchBy.trip.value,
      );
    }

    return itinerariesFiltered;
  };

  let content = null;

  if (loadingItinerariesByProcess || loadingItinerary) {
    content = <Loader />;
  } else {
    content = (
      <>
        <ItineraryStatusSearchForm onSubmit={onSubmitSearch} />
        <EditItineraryStatusForm
          onSubmit={onSubmitItineraryStatus}
          itineraries={generateFilteredItineraries()}
        />
      </>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Estado de Itinerarios"
      subtitle="Debe seleccionar los itinerarios de la lista que desea activar"
      content={content}
    />
  );
};

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Procesos',
      href: PROCESS_PATH,
    },
    {
      text: 'Ver',
      href: `${PROCESS_PATH}/${id}`,
    },
    {
      text: 'Actualizar estados',
      href: '',
    },
  ],
  itinerariesByProcess: ItineraryUnit.Itinerary.getIn(['all', 'content']),
  loadingItinerariesByProcess: ItineraryUnit.Itinerary.getIn([
    'all',
    'loading',
  ]),
  loadingItinerary: !ItineraryUnit.Itinerary.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutUpdateActiveStatusOfItineraries:
    putUpdateActiveStatusOfItineraries,
  dispatchGetItinerariesByProcess: getItinerariesByProcess,
  dispatchClearItineraries: clearItineraries,
};

ProcessEditItineraryStatus.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutUpdateActiveStatusOfItineraries: PropTypes.func.isRequired,
  dispatchGetItinerariesByProcess: PropTypes.func.isRequired,
  dispatchClearItineraries: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  itinerariesByProcess: PropTypes.instanceOf(Immutable.Map),
  loadingItinerariesByProcess: PropTypes.bool,
  loadingItinerary: PropTypes.bool,
};

ProcessEditItineraryStatus.defaultProps = {
  itinerariesByProcess: [],
  loadingItinerariesByProcess: false,
  loadingItinerary: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProcessEditItineraryStatus);
