import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  generateItinerarySalesControlDetailEndpoint,
  ITINERARY_SALES_CONTROL_ENDPOINT,
  LIVE_ITINERARY_SALES_CONTROL_ENDPOINT,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import {
  FLAG_GETTING_ITINERARY_SALES_CONTROL,
  GET_ITINERARY_SALES_CONTROL,
  CLEAR_ITINERARY_SALES_CONTROL,
  CLEAR_ITINERARY_SALES_CONTROL_DETAIL,
  FLAG_GETTING_ITINERARY_SALES_CONTROL_DETAIL,
  GET_ITINERARY_SALES_CONTROL_DETAIL,
  GET_LIVE_ITINERARY_SALES_CONTROL,
  CLEAR_LIVE_ITINERARY_SALES_CONTROL,
  FLAG_GETTING_LIVE_ITINERARY_SALES_CONTROL,
  UPDATE_LIVE_ITINERARY_SALES_CONTROL_PARAMS,
  CLEAR_LIVE_ITINERARY_SALES_CONTROL_PARAMS,
} from '../types';

const flagGettingItinerarySalesControl = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ITINERARY_SALES_CONTROL,
    payload: flag,
  });

const flagGettingLiveItinerarySalesControl = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_LIVE_ITINERARY_SALES_CONTROL,
    payload: flag,
  });

const getItinerarySalesControl =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingItinerarySalesControl(true));
      const query = tableFilters;
      const url = `${ITINERARY_SALES_CONTROL_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const salesControl = await response.json();

      // TODO backend should implement sort
      const contentSorted = salesControl.content
        .sort((a, b) => {
          if (a.sourceLocationName > b.sourceLocationName) return 1;
          if (a.sourceLocationName < b.sourceLocationName) return -1;
          return 0;
        })
        .sort((a, b) => b.active - a.active);
      const salesControlSorted = { ...salesControl, content: contentSorted };

      dispatch({
        type: GET_ITINERARY_SALES_CONTROL,
        payload: salesControlSorted,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingItinerarySalesControl(false));
    }
  };

const getLiveItinerarySalesControl =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingLiveItinerarySalesControl(true));
      const query = tableFilters;
      const url = `${LIVE_ITINERARY_SALES_CONTROL_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const salesControl = await response.json();
      dispatch({
        type: GET_LIVE_ITINERARY_SALES_CONTROL,
        payload: salesControl,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingLiveItinerarySalesControl(false));
    }
  };

const clearLiveItinerarySalesControl = () => (dispatch) =>
  dispatch({
    type: CLEAR_LIVE_ITINERARY_SALES_CONTROL,
  });

const clearItinerarySalesControl = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITINERARY_SALES_CONTROL,
  });

const flagGettingItinerarySalesControlDetail = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ITINERARY_SALES_CONTROL_DETAIL,
    payload: flag,
  });

const getItinerarySalesControlDetail =
  async (itineraryId) => async (dispatch) => {
    try {
      dispatch(flagGettingItinerarySalesControlDetail(true));
      const url = generateItinerarySalesControlDetailEndpoint(itineraryId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const detail = await response.json();
      dispatch({
        type: GET_ITINERARY_SALES_CONTROL_DETAIL,
        payload: detail,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingItinerarySalesControlDetail(false));
    }
  };

const clearItinerarySalesControlDetail = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITINERARY_SALES_CONTROL_DETAIL,
  });

const updateItinerarySalesControlParams = (params) => ({
  type: UPDATE_LIVE_ITINERARY_SALES_CONTROL_PARAMS,
  payload: params,
});

const clearItinerarySalesControlParams = () => (dispatch) =>
  dispatch({
    type: CLEAR_LIVE_ITINERARY_SALES_CONTROL_PARAMS,
  });

export {
  flagGettingItinerarySalesControl,
  getItinerarySalesControl,
  clearItinerarySalesControl,
  flagGettingItinerarySalesControlDetail,
  getLiveItinerarySalesControl,
  getItinerarySalesControlDetail,
  clearItinerarySalesControlDetail,
  clearLiveItinerarySalesControl,
  updateItinerarySalesControlParams,
  clearItinerarySalesControlParams,
};
