import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { CONTRACTOR_PATH } from '../../../../config/paths';
import ContractorDeleteButton from './ContractorDeleteButton';

const ContractorToolbar = ({ contractorId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${CONTRACTOR_PATH}/${contractorId}/edit`} />{' '}
      <ContractorDeleteButton contractorId={contractorId} />
    </div>
  </ButtonToolbar>
);

ContractorToolbar.propTypes = {
  contractorId: PropTypes.number.isRequired,
};

export default ContractorToolbar;
