import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../../Badge';

const CargoItemStatusCell = ({ value }) => {
  switch (value) {
    case 'RECEIVED':
      return <Badge color="info" text="REGISTRADO" />;
    case 'IN_TRANSIT':
      return <Badge color="warning" text="DESPACHADO" />;
    case 'READY_FOR_PICKUP':
      return <Badge color="primary" text="RECEPCIONADO EN DESTINO" />;
    case 'REFUNDED':
      return <Badge color="info" text="DEVUELTO AL CLIENTE" />;
    case 'DELIVERED':
      return <Badge color="success" text="ENTREGADO" />;
    case 'UNCLAIMED':
      return <Badge color="danger" text="SIN RECLAMAR" />;
    case 'DEPURATED':
      return <Badge color="warning" text="DEPURADO" />;
    case 'LOST':
      return <Badge color="danger" text="NO LLEGO" />;
    case 'UNCLAIMED_IN_TRANSIT':
      return <Badge color="warning" text="SIN RECLAMAR - EN TRANSITO" />;
    case 'COMMERCIAL_SCALE':
      return <Badge color="warning" text="EN ESCALA COMERCIAL" />;
    default:
      return <Badge color="danger" text="INCAUTADO" />;
  }
};

CargoItemStatusCell.propTypes = {
  value: PropTypes.string,
};

CargoItemStatusCell.defaultProps = {
  value: '',
};

export default CargoItemStatusCell;
