import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import {
  FLAG_MANUAL_VOUCHER_ACTIVITY,
  GET_MANUAL_VOUCHER,
  CLEAR_MANUAL_VOUCHER,
  FLAG_GETTING_MANUAL_VOUCHERS,
  CLEAR_MANUAL_VOUCHERS,
  GET_MANUAL_VOUCHERS,
} from '../types/accounting';
import { MANUAL_VOUCHER_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { MANUAL_VOUCHER_PATH } from '../../config/paths';

const flagGettingManualVouchers = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MANUAL_VOUCHERS,
    payload: flag,
  });

const clearManualVouchers = () => (dispatch) =>
  dispatch({
    type: CLEAR_MANUAL_VOUCHERS,
  });

const flagManualVoucherActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_MANUAL_VOUCHER_ACTIVITY,
    payload: flag,
  });

const clearManualVoucher = () => (dispatch) =>
  dispatch({
    type: CLEAR_MANUAL_VOUCHER,
  });

const getManualVouchers =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingManualVouchers(true));

      const url = `${MANUAL_VOUCHER_ENDPOINT}?${QueryString.stringify(
        tableFilters,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();

      dispatch({ type: GET_MANUAL_VOUCHERS, payload: response });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingManualVouchers(false));
    }
  };

const obtainManualVoucher = async ({ manualVoucherId }) => {
  const url = `${MANUAL_VOUCHER_ENDPOINT}/${manualVoucherId}`;
  const promise = await fetch(url, DEFAULT_GET_CONFIG);
  await isErrorResponse(promise);
  const response = await promise.json();
  return response;
};

const getManualVoucher =
  async ({ manualVoucherId }) =>
  async (dispatch) => {
    try {
      dispatch(flagManualVoucherActivity(true));

      const response = await obtainManualVoucher({ manualVoucherId });

      if (response.previousManualVoucherId) {
        const previousManualVoucher = await obtainManualVoucher({
          manualVoucherId: response.previousManualVoucherId,
        });

        response.previousManualVoucher = previousManualVoucher;
      }

      dispatch({
        type: GET_MANUAL_VOUCHER,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagManualVoucherActivity(false));
    }
  };

const postManualVoucher =
  async ({
    voucherTypeId,
    customerId,
    businessId,
    issueDate,
    paymentType,
    affectedByTaxes,
    currencyCode,
    items,
    description,
    detraction, // percent value
    detractionAmount,
    serviceType,
    installments,
    dueDate,
    totalAmount,
    totalAmountPayable,
    taxes,
    totalAmountWithoutTaxes,
    previousManualVoucherId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagManualVoucherActivity(true));

      const payload = {
        voucherTypeId,
        customerId,
        businessId,
        issueDate,
        paymentType,
        affectedByTaxes,
        currencyCode,
        items,
        description,
        detraction,
        detractionAmount,
        serviceType,
        installments,
        dueDate,
        totalAmount,
        totalAmountPayable,
        taxes,
        totalAmountWithoutTaxes,
        previousManualVoucherId,
      };
      const url = MANUAL_VOUCHER_ENDPOINT;

      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();

      dispatch(push(`${MANUAL_VOUCHER_PATH}/${response.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagManualVoucherActivity(false));
    }
  };

const putManualVoucher =
  async (
    manualVoucherId,
    {
      voucherTypeId,
      customerId,
      businessId,
      issueDate,
      paymentType,
      affectedByTaxes,
      currencyCode,
      items,
      description,
      detraction, // percent value
      detractionAmount,
      serviceType,
      installments,
      dueDate,
      totalAmount,
      totalAmountPayable,
      taxes,
      totalAmountWithoutTaxes,
      documentSeries,
      documentCode,
      previousManualVoucherId,
      userId,
      createDate,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagManualVoucherActivity(true));

      const payload = {
        voucherTypeId,
        customerId,
        businessId,
        issueDate,
        paymentType,
        affectedByTaxes,
        currencyCode,
        items,
        description,
        detraction, // percent value
        detractionAmount,
        serviceType,
        installments,
        dueDate,
        totalAmount,
        totalAmountPayable,
        taxes,
        totalAmountWithoutTaxes,
        documentSeries,
        documentCode,
        previousManualVoucherId,
        userId,
        createDate,
        id: manualVoucherId,
      };
      const url = `${MANUAL_VOUCHER_ENDPOINT}/${manualVoucherId}`;

      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();

      dispatch(push(`${MANUAL_VOUCHER_PATH}/${response.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagManualVoucherActivity(false));
    }
  };

export {
  flagGettingManualVouchers,
  clearManualVouchers,
  getManualVouchers,
  flagManualVoucherActivity,
  getManualVoucher,
  clearManualVoucher,
  postManualVoucher,
  putManualVoucher,
};
