import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_CABIN_CREWS,
  GET_CABIN_CREWS,
  CLEAR_CABIN_CREWS,
  FLAG_CABIN_CREW_ACTIVITY,
  GET_CABIN_CREW,
  CLEAR_CABIN_CREW,
} from '../types/traffic';
import {
  CABIN_CREW_ENDPOINT,
  generateFreeCabinCrewsEndpoint,
} from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingCabinCrews = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CABIN_CREWS,
    payload: flag,
  });

const getCabinCrews = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCabinCrews(true));
    const query = tableFilters;
    const url = `${CABIN_CREW_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const cabinCrews = await response.json();
    dispatch({
      type: GET_CABIN_CREWS,
      payload: cabinCrews,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCabinCrews(false));
  }
};

const clearCabinCrews = () => (dispatch) =>
  dispatch({
    type: CLEAR_CABIN_CREWS,
  });

const flagCabinCrewActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CABIN_CREW_ACTIVITY,
    payload: flag,
  });

const getCabinCrew =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagCabinCrewActivity(true));
      const url = `${CABIN_CREW_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const cabinCrew = await response.json();
      dispatch({
        type: GET_CABIN_CREW,
        payload: cabinCrew,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCabinCrewActivity(false));
    }
  };

const clearCabinCrew = () => (dispatch) =>
  dispatch({
    type: CLEAR_CABIN_CREW,
  });

const getFreeCabinCrews =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCabinCrews(true));
      const url = generateFreeCabinCrewsEndpoint(itineraryId);
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const cabinCrews = await response.json();
      dispatch({
        type: GET_CABIN_CREWS,
        payload: cabinCrews,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingCabinCrews(false));
    }
  };

export {
  flagGettingCabinCrews,
  getCabinCrews,
  clearCabinCrews,
  getCabinCrew,
  clearCabinCrew,
  getFreeCabinCrews,
};
