import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteParcelServiceType } from '../../../../actions/cargo/ParcelServiceType';

export const ParcelServiceTypeDeleteButton = ({
  dispatchDeleteParcelServiceType,
  parcelServiceTypeId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () =>
    dispatchDeleteParcelServiceType({ parcelServiceTypeId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

ParcelServiceTypeDeleteButton.propTypes = {
  dispatchDeleteParcelServiceType: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  parcelServiceTypeId: PropTypes.number.isRequired,
};

ParcelServiceTypeDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteParcelServiceType: deleteParcelServiceType,
};

export default connect(null, mapDispatchToProps)(ParcelServiceTypeDeleteButton);
