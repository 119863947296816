import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import FormItem from '../../../common/forms/FormItem';
import {
  COMPANY_BUS_SEAT_COUNT,
  COMPANY_STAFF_TYPE,
} from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';
import { isRequired } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import ShiftSelect from '../../../common/forms/select/ShiftSelect';
import FormFooter from '../../../common/forms/FormFooter';
import DatePicker from '../../../common/forms/input/DatePicker';
import Loader from '../../../common/Loader';
import DynamicForm from '../../../common/forms/DynamicForm';
import { PROCESS_ROUTES_FORM_COLUMNS } from '../../../../config/dynamicFormFields';
import { timeFormat, tzNormalizeDate } from '../../../../utils/date';
import {
  DATE_TIME_FORMAT,
  INT_DATE_FORMAT,
  INT_DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../../../../config/locale';

export const ProcessForm = ({
  loading,
  handleSubmit,
  onSubmit,
  initialValues: { itineraryCode },
}) => {
  const generateTimetableData = (
    routeSegmentList,
    departureTime,
    startDate,
  ) => {
    const timetableData = [];

    const formattedDepartureTime = departureTime
      ? tzNormalizeDate({ date: departureTime, format: TIME_FORMAT })
      : null;
    const formattedStartDate = startDate
      ? tzNormalizeDate({ date: startDate, format: INT_DATE_FORMAT })
      : null;

    let lastArrivalTimeWithoutFormat = null;
    let lastDurationAdjusment = 0;
    let lastLayover = 0;

    if (routeSegmentList) {
      routeSegmentList.forEach(
        (
          {
            segment: {
              id: segmentId,
              sourceLocation,
              destinationLocation,
              duration,
            },
          },
          index,
          array,
        ) => {
          const timetable = {
            segmentId,
            sourceLocationName: sourceLocation.name,
            destinationLocationName: destinationLocation.name,
            duration: timeFormat(duration),
            startOffset: '-',
            departureTime: '-',
            arrivalTime: '-',
          };

          if (startDate && departureTime) {
            if (index === 0) {
              const date = formattedStartDate;

              const time = formattedDepartureTime;

              timetable.startOffset = 0;

              timetable.departureTimeWithoutFormat = `${date} ${time}`;

              timetable.departureTime = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
              });

              timetable.departureTimeFormatted = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
                format: DATE_TIME_FORMAT,
              });
            } else {
              const { segment } = array[index - 1];

              const { startOffset: lastStartOffset } = timetableData[index - 1];

              timetable.startOffset =
                segment.duration +
                lastDurationAdjusment +
                lastLayover +
                lastStartOffset;

              timetable.departureTimeWithoutFormat =
                lastArrivalTimeWithoutFormat;

              timetable.departureTime = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
                addTime: { amount: lastLayover, unit: 'minutes' },
              });

              timetable.departureTimeFormatted = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
                addTime: { amount: lastLayover, unit: 'minutes' },
                format: DATE_TIME_FORMAT,
              });
            }

            lastDurationAdjusment = 0;

            timetable.segmentAdjustment = lastDurationAdjusment;

            timetable.arrivalTimeWithoutFormat = tzNormalizeDate({
              date: timetable.departureTimeWithoutFormat,
              addTime: {
                amount: duration + timetable.segmentAdjustment + lastLayover,
                unit: 'minutes',
              },
              format: INT_DATE_TIME_FORMAT,
            });

            timetable.arrivalTime = tzNormalizeDate({
              date: timetable.arrivalTimeWithoutFormat,
            });

            lastArrivalTimeWithoutFormat = timetable.arrivalTimeWithoutFormat;

            timetable.arrivalTimeFormatted = tzNormalizeDate({
              date: timetable.departureTimeWithoutFormat,
              addTime: {
                amount: duration + timetable.segmentAdjustment + lastLayover,
                unit: 'minutes',
              },
              format: DATE_TIME_FORMAT,
            });

            lastLayover = 0;

            timetable.layover = lastLayover;
          }

          timetableData.push(timetable);
        },
      );
    }
    return timetableData;
  };

  const validateRoutesValues = (routes) => {
    if (!routes || !routes.length) {
      toastr.error('Error', 'Ingrese al menos una ruta');
      return false;
    }
    return true;
  };

  const onHandleSubmit = (formValues) => {
    const { shift, startDate, routes, staffType, name } = formValues;
    if (!validateRoutesValues(routes)) return;

    const formattedShift = shift
      .map((currentShift) => currentShift.value)
      .join(',');

    const companyItineraryRequestList = [];

    routes.forEach(
      ({ routeId: currentRoute, passengerCount, departureTime }) => {
        const startDateformatted = formValues.startDate
          ? tzNormalizeDate({
              date: formValues.startDate,
              format: INT_DATE_FORMAT,
            })
          : tzNormalizeDate({ format: INT_DATE_FORMAT });

        const formattedDepartureTime = departureTime
          ? tzNormalizeDate({
              date: departureTime,
              format: TIME_FORMAT,
            })
          : tzNormalizeDate({ format: TIME_FORMAT });

        const startDateValue = tzNormalizeDate({
          date: `${startDateformatted} ${formattedDepartureTime}`,
        });

        const endDateformatted = startDate
          ? tzNormalizeDate({ date: startDate, format: INT_DATE_FORMAT })
          : tzNormalizeDate({ format: INT_DATE_FORMAT });

        const endDateValue = tzNormalizeDate({
          date: `${endDateformatted} ${formattedDepartureTime}`,
          addTime: { amount: 30, unit: 'seconds' },
        });

        const itineraryScheduleSegmentList = generateTimetableData(
          currentRoute.routeSegmentList,
          departureTime,
          startDate,
        );
        const quantity = Math.ceil(passengerCount / COMPANY_BUS_SEAT_COUNT);
        const companyItineraryRequest = {
          itineraryScheduleSegmentList,
          endDate: endDateValue,
          startDate: startDateValue,
          name,
          routeId: currentRoute.value,
          shift: formattedShift,
          staffType: staffType.value,
          dailyRepeat: {},
          quantity,
          repeatEvery: '1',
        };
        companyItineraryRequestList.push(companyItineraryRequest);
      },
    );

    onSubmit({ companyItineraryRequestList });
  };

  const startDateField = (
    <FormGroup row>
      <FormItem label="Fecha" required>
        <Field
          name="startDate"
          component={DatePicker}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
  );

  const routeField = (
    <DynamicForm
      name="routes"
      columns={PROCESS_ROUTES_FORM_COLUMNS}
      labelWithoutElements="No hay rutas seleccionadas"
    />
  );
  const itineraryFields = (
    <>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            type="text"
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Régimen" required>
          <Field
            name="shift"
            component={ShiftSelect}
            disabled={!!itineraryCode}
            placeholder="Régimen"
            isMulti
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo" required>
          <Field
            name="staffType"
            value={COMPANY_STAFF_TYPE.HOLDERS.value}
            component={Select}
            options={enumToSelectOptions(COMPANY_STAFF_TYPE)}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      {startDateField}
      {routeField}
    </>
  );

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <h3>Itinerario</h3>
      {itineraryFields}
      <FormFooter />
    </Form>
  );
};

ProcessForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    departureTime: PropTypes.instanceOf(Date),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    routeSegmentList: PropTypes.arrayOf({}),
    layoverList: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    durationAdjustmentList: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    isMaciva: PropTypes.bool,
    repeatType: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      unit: PropTypes.string,
    }),
    itineraryCode: PropTypes.string,
  }),
};

ProcessForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: !ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'ProcessForm',
})(ProcessForm);

export default connect(mapStateToProps)(formComponent);
