import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_COMPANY_CREDIT_NOTES,
  GET_COMPANY_CREDIT_NOTES,
  CLEAR_COMPANY_CREDIT_NOTES,
  FLAG_COMPANY_CREDIT_NOTE_ACTIVITY,
  GET_COMPANY_CREDIT_NOTE,
  CLEAR_COMPANY_CREDIT_NOTE,
} from '../types';
import { COMPANY_CREDIT_NOTE_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';

const flagGettingCompanyCreditNote = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_COMPANY_CREDIT_NOTES,
    payload: flag,
  });

const getCompanyCreditNotes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCompanyCreditNote(true));

    const query = tableFilters;

    const url = `${COMPANY_CREDIT_NOTE_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;

    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response, null, dispatch);

    const creditNotes = await response.json();

    dispatch({
      type: GET_COMPANY_CREDIT_NOTES,
      payload: creditNotes,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCompanyCreditNote(false));
  }
};

const clearCompanyCreditNotes = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_CREDIT_NOTES,
  });

const flagCompanyCreditNoteActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_COMPANY_CREDIT_NOTE_ACTIVITY,
    payload: flag,
  });

const getCompanyCreditNote =
  async ({ companyCreditNoteId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyCreditNoteActivity(true));

      const url = `${COMPANY_CREDIT_NOTE_ENDPOINT}/${companyCreditNoteId}`;

      const response = await fetch(url, DEFAULT_GET_CONFIG);

      await isErrorResponse(response, null, dispatch);

      const creditNote = await response.json();

      dispatch({
        type: GET_COMPANY_CREDIT_NOTE,
        payload: creditNote,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCompanyCreditNoteActivity(false));
    }
  };

const clearCompanyCreditNote = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_CREDIT_NOTE,
  });

export {
  getCompanyCreditNotes,
  clearCompanyCreditNotes,
  getCompanyCreditNote,
  clearCompanyCreditNote,
};
