import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_DISTRICTS,
  GET_DISTRICTS,
  CLEAR_DISTRICTS,
  FLAG_DISTRICT_ACTIVITY,
  GET_DISTRICT,
  CLEAR_DISTRICT,
} from '../types/location';
import { DISTRICT_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { DISTRICT_PATH } from '../../config/paths';

const flagGettingDistricts = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DISTRICTS,
    payload: flag,
  });

const getDistricts = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDistricts(true));
    const query = tableFilters;
    const url = `${DISTRICT_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const districts = await response.json();
    dispatch({
      type: GET_DISTRICTS,
      payload: districts,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingDistricts(false));
  }
};

const clearDistricts = () => (dispatch) =>
  dispatch({
    type: CLEAR_DISTRICTS,
  });

const flagDistrictActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DISTRICT_ACTIVITY,
    payload: flag,
  });

const getDistrict =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagDistrictActivity(true));
      const url = `${DISTRICT_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const district = await response.json();
      dispatch({
        type: GET_DISTRICT,
        payload: district,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDistrictActivity(false));
    }
  };

const clearDistrict = () => (dispatch) =>
  dispatch({
    type: CLEAR_DISTRICT,
  });

const postDistrict =
  async ({ cityId, districts }) =>
  async (dispatch) => {
    try {
      dispatch(flagDistrictActivity(true));
      const payload = {
        cityId,
        districts,
      };
      const url = DISTRICT_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(DISTRICT_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDistrictActivity(false));
    }
  };

const putDistrict =
  async (districtId, { name, cityId, ubigeo }) =>
  async (dispatch) => {
    try {
      dispatch(flagDistrictActivity(true));
      const payload = {
        id: districtId,
        name,
        cityId,
        ubigeo,
      };
      const url = `${DISTRICT_ENDPOINT}/${districtId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const district = await response.json();
      dispatch(push(`${DISTRICT_PATH}/${district.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDistrictActivity(false));
    }
  };

const deleteDistrict =
  async ({ districtId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDistrictActivity(true));
      const url = `${DISTRICT_ENDPOINT}/${districtId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(DISTRICT_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDistrictActivity(false));
    }
  };

export {
  flagGettingDistricts,
  getDistricts,
  clearDistricts,
  getDistrict,
  clearDistrict,
  postDistrict,
  putDistrict,
  deleteDistrict,
};
