import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { string, number, PropTypes } from 'prop-types';
import { Button, FormGroup, Form } from 'reactstrap';
import {
  isRequired,
  validateIntegerNumber,
  validateMaxLength,
} from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import PaymentMethodInputGroup from '../../../common/forms/PaymentMethodInputGroup';
import {
  PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT,
  PARCEL_STATUS,
  PAYMENT_METHOD_PAGO_EN_DESTINO,
} from '../../../../config/constants';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import Select from '../../../common/forms/select/Select';

const validateMaxLength10 = validateMaxLength(10);

const CargoPickupModel = ({
  handleSubmit,
  parcel,
  handleProcess,
  onCancel,
  dispatchChange,
  options,
}) => {
  const onShowVoucherCode = () => {
    dispatchChange('CargoPickupModelForm', 'voucherCode', '');
  };

  const paymenthMethodInput =
    parcel.transactionCode === PAYMENT_METHOD_PAGO_EN_DESTINO &&
    parcel.parcelPaymentStatusType ===
      PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT ? (
      <PaymentMethodInputGroup onShowVoucherCode={onShowVoucherCode} />
    ) : null;

  const data = parcel.cargoItemList.filter(
    (item) => item.status !== PARCEL_STATUS.DELIVERED.value,
  );

  const generateBaggageItemColumns = [
    {
      Header: 'Descripción',
      accessor: 'description',
      className: 'text-center',
    },
    {
      Header: 'Categoria',
      accessor: 'cargoCategoryName',
      className: 'text-center',
    },
    {
      Header: 'Peso (kg)',
      accessor: 'weight',
      className: 'text-center',
    },
    {
      Header: 'Correlativo',
      accessor: 'correlation',
      className: 'text-center',
    },
  ];

  return (
    <Form onSubmit={handleSubmit(handleProcess)}>
      <ResourceProperty label="Serie:">
        {parcel.documentSeries}
      </ResourceProperty>
      <ResourceProperty label="Número de serie:">
        {parcel.documentCode}
      </ResourceProperty>
      <ResourceProperty label="Origen:">
        {parcel.sourceCityName}
      </ResourceProperty>
      <ResourceProperty label="Destino:">
        {parcel.destinationCityName}
      </ResourceProperty>
      <hr />

      <SelectionableTable
        columns={generateBaggageItemColumns}
        data={data}
        keyField="id"
        required
        filterable
        form="CargoPickupModelForm"
        returnOnlySelectedItems
      />

      {paymenthMethodInput}
      <FormGroup row className="mt-4">
        <FormItem label="Clave" required>
          <Field
            name="secretCode"
            component={TextInput}
            type="text"
            placeholder="Clave secreta"
            validate={[isRequired, validateIntegerNumber, validateMaxLength10]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row className="mt-4">
        <FormItem label="Se entrega a" required>
          <Field
            name="deliveredCustomer"
            component={Select}
            type="text"
            placeholder="Seleccione al Consignado a Entregar"
            validate={[isRequired]}
            options={options}
          />
        </FormItem>
      </FormGroup>
      <FormFooter
        saveButtonColor="secondary"
        saveButtonIcon="fa fa-share"
        saveButtonText="Entregar"
      >
        <Button color="primary" onClick={onCancel}>
          Cancelar
        </Button>
      </FormFooter>
    </Form>
  );
};

const parcelPropTypes = {
  documentSeries: string,
  documentCode: number,
  sourceCityName: string,
  destinationCityName: string,
  paymentMethodId: number,
};

CargoPickupModel.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  parcel: PropTypes.shape(parcelPropTypes).isRequired,
  handleProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'CargoPickupModelForm',
})(CargoPickupModel);

export default connect(null, mapDispatchToProps)(formComponent);
