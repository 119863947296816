import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_CARGO_MINIMAL_PRICES,
  GET_CARGO_MINIMAL_PRICES,
  CLEAR_CARGO_MINIMAL_PRICES,
  FLAG_CARGO_MINIMAL_PRICE_ACTIVITY,
  GET_CARGO_MINIMAL_PRICE,
  CLEAR_CARGO_MINIMAL_PRICE,
} from '../types/cargo';
import { CARGO_MINIMAL_PRICE_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { CARGO_MINIMAL_PRICE_PATH } from '../../config/paths';

const flagGettingCargoMinimalPrices = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CARGO_MINIMAL_PRICES,
    payload: flag,
  });

const flagCargoMinimalPriceActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CARGO_MINIMAL_PRICE_ACTIVITY,
    payload: flag,
  });

const getCargoMinimalPrices = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCargoMinimalPrices(true));
    const query = tableFilters;
    const url = `${CARGO_MINIMAL_PRICE_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const prices = await response.json();
    dispatch({
      type: GET_CARGO_MINIMAL_PRICES,
      payload: prices,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCargoMinimalPrices(false));
  }
};

const getCargoMinimalPrice =
  async ({ minimalPriceId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoMinimalPriceActivity(true));
      const url = `${CARGO_MINIMAL_PRICE_ENDPOINT}/${minimalPriceId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const distance = await response.json();
      dispatch({
        type: GET_CARGO_MINIMAL_PRICE,
        payload: distance,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoMinimalPriceActivity(false));
    }
  };

const clearCargoMinimalPrice = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_MINIMAL_PRICE,
  });

const clearCargoMinimalPrices = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_MINIMAL_PRICES,
  });

const postCargoMinimalPrice =
  async ({ minimalPrice }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoMinimalPriceActivity(true));
      const payload = {
        minimalPrice,
      };
      const url = CARGO_MINIMAL_PRICE_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(CARGO_MINIMAL_PRICE_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoMinimalPriceActivity(false));
    }
  };

const putCargoMinimalPrice =
  async (minimalPriceId, { minimalPrice }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoMinimalPriceActivity(true));
      const payload = {
        id: minimalPriceId,
        minimalPrice,
      };
      const url = `${CARGO_MINIMAL_PRICE_ENDPOINT}/${minimalPriceId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(CARGO_MINIMAL_PRICE_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoMinimalPriceActivity(false));
    }
  };

export {
  postCargoMinimalPrice,
  getCargoMinimalPrice,
  clearCargoMinimalPrice,
  putCargoMinimalPrice,
  getCargoMinimalPrices,
  clearCargoMinimalPrices,
};
