import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_COMPANY_USER_CONFIGURATIONS,
  GET_COMPANY_USER_CONFIGURATIONS,
  CLEAR_COMPANY_USER_CONFIGURATIONS,
  FLAG_COMPANY_USER_CONFIGURATION_ACTIVITY,
  GET_COMPANY_USER_CONFIGURATION,
  CLEAR_COMPANY_USER_CONFIGURATION,
} from '../types';
import { COMPANY_USER_CONFIGURATION_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { COMPANY_USER_CONFIGURATION_PATH } from '../../config/paths';

const flagGettingCompanyUserConfigurations = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_COMPANY_USER_CONFIGURATIONS,
    payload: flag,
  });

const getCompanyUserConfigurations =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingCompanyUserConfigurations(true));

      const query = tableFilters;

      const url = `${COMPANY_USER_CONFIGURATION_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);

      const configurations = await response.json();

      dispatch({
        type: GET_COMPANY_USER_CONFIGURATIONS,
        payload: configurations,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingCompanyUserConfigurations(false));
    }
  };

const clearCompanyUserConfigurations = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_USER_CONFIGURATIONS,
  });

const flagCompanyUserConfigurationActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_COMPANY_USER_CONFIGURATION_ACTIVITY,
    payload: flag,
  });

const postCompanyUserConfiguration =
  async ({ userId, companyId, parentUserId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyUserConfigurationActivity(true));

      const payload = { userId, companyId, parentUserId };

      const url = COMPANY_USER_CONFIGURATION_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(COMPANY_USER_CONFIGURATION_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCompanyUserConfigurationActivity(false));
    }
  };

const getCompanyUserConfiguration =
  async ({ companyUserConfigurationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyUserConfigurationActivity(true));

      const url = `${COMPANY_USER_CONFIGURATION_ENDPOINT}/${companyUserConfigurationId}`;

      const response = await fetch(url, DEFAULT_GET_CONFIG);

      await isErrorResponse(response, null, dispatch);

      const configuration = await response.json();

      dispatch({
        type: GET_COMPANY_USER_CONFIGURATION,
        payload: configuration,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCompanyUserConfigurationActivity(false));
    }
  };

const clearCompanyUserConfiguration = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_USER_CONFIGURATION,
  });

const putCompanyUserConfiguration =
  async (companyUserConfigurationId, { userId, companyId, parentUserId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyUserConfigurationActivity(true));
      const payload = {
        userId,
        companyId,
        parentUserId,
        id: companyUserConfigurationId,
      };

      const url = `${COMPANY_USER_CONFIGURATION_ENDPOINT}/${companyUserConfigurationId}`;

      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      const configuration = await response.json();

      dispatch(push(`${COMPANY_USER_CONFIGURATION_PATH}/${configuration.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCompanyUserConfigurationActivity(false));
    }
  };

const deleteCompanyUserConfiguration =
  async ({ companyUserConfigurationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyUserConfigurationActivity(true));

      const url = `${COMPANY_USER_CONFIGURATION_ENDPOINT}/${companyUserConfigurationId}`;

      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(COMPANY_USER_CONFIGURATION_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCompanyUserConfigurationActivity(false));
    }
  };

export {
  getCompanyUserConfigurations,
  clearCompanyUserConfigurations,
  postCompanyUserConfiguration,
  getCompanyUserConfiguration,
  clearCompanyUserConfiguration,
  putCompanyUserConfiguration,
  deleteCompanyUserConfiguration,
};
