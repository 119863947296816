import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { breadcrumbPropType } from '../../../common/resource/proptypes/CommonPropTypes';
import { PARCEL_SERVICE_TYPE_PATH } from '../../../../config/paths';
import { postParcelServiceType } from '../../../../actions/cargo/ParcelServiceType';
import Content from '../../../layout/Content';
import ParcelServiceTypeForm from './ParcelServiceTypeForm';

export const NewParcelServiceType = ({
  breadcrumbs,
  dispatchPostParcelServiceType,
}) => {
  const onSubmit = (formValues) => dispatchPostParcelServiceType(formValues);

  const content = <ParcelServiceTypeForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Tipo de Servicio"
      subtitle="Crear nuevo tipo de servicio"
      content={content}
    />
  );
};

NewParcelServiceType.propTypes = {
  breadcrumbs: breadcrumbPropType.isRequired,
  dispatchPostParcelServiceType: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Tipos de Servicio',
      href: PARCEL_SERVICE_TYPE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostParcelServiceType: postParcelServiceType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewParcelServiceType);
