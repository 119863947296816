import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { USER_LOCALE_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import {
  FLAG_GETTING_USER_LOCALES,
  GET_USER_LOCALES,
  CLEAR_USER_LOCALES,
} from '../types/user/UserLocale';

const flagGettingUserLocales = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_USER_LOCALES,
    payload: flag,
  });

const getUserLocales =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingUserLocales(true));
      const query = tableFilters;
      const url = `${USER_LOCALE_ENDPOINT}?${QueryString.stringify(query)}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const locales = await response.json();
      dispatch({ type: GET_USER_LOCALES, payload: locales });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingUserLocales(false));
    }
  };

const clearUserLocales = () => (dispatch) =>
  dispatch({
    type: CLEAR_USER_LOCALES,
  });

export { flagGettingUserLocales, getUserLocales, clearUserLocales };
