import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { AGENCY_GROUP_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { AGENCY_GROUP_PATH } from '../../config/paths';
import {
  CLEAR_AGENCY_GROUP,
  CLEAR_AGENCY_GROUPS,
  FLAG_AGENCY_GROUP_ACTIVITY,
  FLAG_GETTING_AGENCY_GROUPS,
  GET_AGENCY_GROUP,
  GET_AGENCY_GROUPS,
} from '../types';

const flagGettingAgencyGroups = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_AGENCY_GROUPS,
    payload: flag,
  });

const getAgencyGroups = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingAgencyGroups(true));

    const query = tableFilters;

    const url = `${AGENCY_GROUP_ENDPOINT}?${QueryString.stringify(query)}`;

    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response, null, dispatch);

    const agencyGroups = await response.json();

    dispatch({
      type: GET_AGENCY_GROUPS,
      payload: agencyGroups,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingAgencyGroups(false));
  }
};

const clearAgencyGroups = () => (dispatch) =>
  dispatch({
    type: CLEAR_AGENCY_GROUPS,
  });

const clearAgencyGroup = () => (dispatch) =>
  dispatch({
    type: CLEAR_AGENCY_GROUP,
  });

const flagAgencyGroupActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_AGENCY_GROUP_ACTIVITY,
    payload: flag,
  });

const postAgencyGroup =
  async ({ name, description, agencies }) =>
  async (dispatch) => {
    try {
      dispatch(flagAgencyGroupActivity(true));

      const payload = {
        name,
        description,
        agencies,
      };

      const url = AGENCY_GROUP_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(AGENCY_GROUP_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagAgencyGroupActivity(false));
    }
  };

const getAgencyGroup =
  async ({ agencyGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagAgencyGroupActivity(true));

      const url = `${AGENCY_GROUP_ENDPOINT}/${agencyGroupId}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);

      const agencyGroup = await response.json();

      dispatch({
        type: GET_AGENCY_GROUP,
        payload: agencyGroup,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagAgencyGroupActivity(false));
    }
  };

const putAgencyGroup =
  async (agencyGroupId, { name, description, agencies }) =>
  async (dispatch) => {
    try {
      dispatch(flagAgencyGroupActivity(true));

      const payload = {
        name,
        description,
        agencies,
        id: agencyGroupId,
      };

      const url = `${AGENCY_GROUP_ENDPOINT}/${agencyGroupId}`;

      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      const agencyGroup = await response.json();

      dispatch(push(`${AGENCY_GROUP_PATH}/${agencyGroup.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagAgencyGroupActivity(false));
    }
  };

const deleteAgencyGroup =
  async ({ agencyGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagAgencyGroupActivity(true));

      const url = `${AGENCY_GROUP_ENDPOINT}/${agencyGroupId}`;

      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(AGENCY_GROUP_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagAgencyGroupActivity(false));
    }
  };

export {
  getAgencyGroups,
  clearAgencyGroups,
  postAgencyGroup,
  clearAgencyGroup,
  getAgencyGroup,
  putAgencyGroup,
  deleteAgencyGroup,
};
