import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import TripRequestForm from './TripRequestForm';
import { getCurrentContractor, postTripRequest } from '../../../../actions';
import { TRIP_REQUEST_PATH } from '../../../../config/paths';
import Loader from '../../../common/Loader';

const NewTripRequest = ({
  breadcrumbs,
  dispatchPostTripRequest,
  dispatchGetCurrentContractor,
  gettingContractor,
}) => {
  useLayoutEffect(() => {
    dispatchGetCurrentContractor();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPostTripRequest({
      ...formValues,
      customerList: formValues.customerList.map((customer) => ({
        id: customer.id,
      })),
    });

  if (gettingContractor) return <Loader />;

  const content = <TripRequestForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Solicitud de Viaje"
      subtitle="Crear nueva solicitud de viaje"
      content={content}
    />
  );
};

NewTripRequest.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostTripRequest: PropTypes.func.isRequired,
  dispatchGetCurrentContractor: PropTypes.func.isRequired,
  gettingContractor: PropTypes.bool,
};

NewTripRequest.defaultProps = {
  gettingContractor: false,
};

const mapDispatchToProps = {
  dispatchPostTripRequest: postTripRequest,
  dispatchGetCurrentContractor: getCurrentContractor,
};

const mapStateToProps = ({ ItineraryUnit, ContractUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Solicitudes de Viajes',
      href: TRIP_REQUEST_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
  gettingContractor: !ContractUnit.Contractor.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTripRequest);
