import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import AddressInputGroup from '../../../common/forms/AddressInputGroup';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';

const LocationForm = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            type="text"
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            type="text"
            placeholder="Descripción"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="ID de Correlación Civa">
          <Field
            name="locationCorrelationId"
            component={TextInput}
            type="text"
            placeholder="ID de Correlación Civa"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Ubigeo">
          <Field
            name="ubigeo"
            component={TextInput}
            type="text"
            placeholder="Ubigeo"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Latitud">
          <Field
            name="latitude"
            component={TextInput}
            type="text"
            placeholder="Latitud"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Longitud">
          <Field
            name="longitude"
            component={TextInput}
            type="text"
            placeholder="Longitud"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Codigo de Establecimiento">
          <Field
            name="agencyCode"
            component={TextInput}
            type="text"
            placeholder="Codigo de Establecimiento"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Atributos">
          <Label>
            <Field name="isHomeBase" component="input" type="checkbox" /> Base
          </Label>
          <br />
          <Label>
            <Field name="isTerminal" component="input" type="checkbox" />{' '}
            Terrapuerto
          </Label>
          <br />
          <Label>
            <Field name="isPointOfSale" component="input" type="checkbox" />{' '}
            Punto de Venta
          </Label>
          <br />
          <Label>
            <Field name="isStop" component="input" type="checkbox" /> Parada
            Autorizada
          </Label>
          <br />
          <Label>
            <Field name="isToll" component="input" type="checkbox" /> Peaje
          </Label>
          <br />
          <Label>
            <Field name="receiveCargoPoint" component="input" type="checkbox" />{' '}
            Punto de Recepción de Carga
          </Label>
          <br />
          <Label>
            <Field name="pickupCargoPoint" component="input" type="checkbox" />{' '}
            Punto de Recojo de Carga
          </Label>
        </FormItem>
      </FormGroup>
      <h3>Dirección</h3>
      <AddressInputGroup
        label="Dirección"
        name="addressId"
        form="CustomerForm"
        required
        validate={[isRequired]}
      />
      <FormFooter />
    </Form>
  );
};

LocationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ LocationUnit }) => ({
  loading: !LocationUnit.Location.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'LocationForm',
})(LocationForm);

export default connect(mapStateToProps)(formComponent);
