import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { generateManifestCargoDetailsColumns } from '../../../../../config/columns';
import Loader from '../../../../common/Loader';
import Table from '../../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { DEFAULT_QUERY_GET_ALL } from '../../../../../config/queries';
import {
  clearManifestItineraryDetail,
  getManifestItemDetail,
} from '../../../../../actions/cargo/ItineraryManifest';
import { putRemoveItemFromManifest } from '../../../../../actions';
import { tzNormalizeDate } from '../../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../../config/locale';

const ManifestCargoDetail = ({
  manifestId,
  dispatchGetManifestItemDetail,
  dispatchPutRemoveItemFromManifest,
  loading,
  sourceLocation,
  itineraryId,
  onItemRemoved,
  itineraryCounter,
}) => {
  const [details, setDetails] = useState({});
  const [disabledRemoved, setDisabledRemoved] = useState(false);

  const disabledRemovedAfterDeparture = () => {
    const { itineraryScheduleSegmentList, departureTime } =
      itineraryCounter.toJS();

    const currentTime = tzNormalizeDate({
      date: new Date(),
      format: TIMESTAMP_FORMAT,
    });

    return itineraryScheduleSegmentList.some((segmentData) => {
      const { segment, startOffset } = segmentData;

      const finalDepartureTime = tzNormalizeDate({
        date: new Date(departureTime),
        addTime: { amount: startOffset + 480, unit: 'minutes' },
        format: TIMESTAMP_FORMAT,
      });

      const isBeforeCurrentTime = finalDepartureTime < currentTime;

      const isMatchingLocation =
        segment.sourceLocation.id === sourceLocation.id;

      return isBeforeCurrentTime && isMatchingLocation;
    });
  };

  useEffect(() => {
    const query = [`manifestCargoItemId:${manifestId}`];

    const getManifestItemDetailFunction = async () => {
      const newDetails = await dispatchGetManifestItemDetail({
        query,
        size: DEFAULT_QUERY_GET_ALL.size,
      });

      setDetails(newDetails);

      setDisabledRemoved(disabledRemovedAfterDeparture);
    };

    getManifestItemDetailFunction();
  }, []);

  const onClickRemoveItemFromManifest = async (row) => {
    const removed = await dispatchPutRemoveItemFromManifest({
      cargoItemId: row.item.id,
      itineraryId,
      locationId: sourceLocation.id,
      internalCargoItem: row.item.internal,
      manifestCargoItemId: row.manifestCargoItemId,
    });

    onItemRemoved(removed.empty);

    const query = [`manifestCargoItemId:${manifestId}`];

    const newDetails = await dispatchGetManifestItemDetail({
      query,
      size: DEFAULT_QUERY_GET_ALL.size,
    });

    setDetails(newDetails);
  };

  const data = details.content || [];

  let table =
    data.length > 0 ? (
      <Table
        manual={false}
        columns={generateManifestCargoDetailsColumns(
          onClickRemoveItemFromManifest,
          disabledRemoved,
        )}
        data={data}
        defaultPageSize={20}
        filterable
        defaultFilterMethod={filterMethodCaseInsensitive}
        loading={loading}
      />
    ) : null;

  if (loading && !data.length) table = <Loader />;
  return (
    <>
      <h4>Detalle del Manifiesto</h4>
      {table}
    </>
  );
};

ManifestCargoDetail.propTypes = {
  manifestId: PropTypes.number.isRequired,
  dispatchGetManifestItemDetail: PropTypes.func.isRequired,
  dispatchPutRemoveItemFromManifest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sourceLocation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  itineraryId: PropTypes.number.isRequired,
  onItemRemoved: PropTypes.func.isRequired,
  itineraryCounter: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.CargoUnit.ItineraryManifest.getIn(['detail', 'loading']),
  itineraryCounter:
    state.ItineraryUnit.Itinerary.getIn(['current', 'content']) || undefined,
});

const mapDispatchToProps = {
  dispatchGetManifestItemDetail: getManifestItemDetail,
  dispatchClearManifestItineraryDetail: clearManifestItineraryDetail,
  dispatchPutRemoveItemFromManifest: putRemoveItemFromManifest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManifestCargoDetail);
