import { toastr } from 'react-redux-toastr';
import {
  FLAG_REQUIRES_TWO_STEP_AUTHORIZATION,
  SET_TWO_STEP_AUTHORIZATION_URL,
  FLAG_SENDING_TWO_STEP_AUTHORIZATION_REQUEST,
  SET_TWO_STEP_AUTHORIZATION_CALLBACK,
  SET_TWO_STEP_AUTHORIZATION_METHOD,
} from '../types';
import { DEFAULT_POST_CONFIG, getDefaultConfig } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { BASE_URL } from '../../config/endpoints';

const flagRequiresTwoStepAuthentication = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_REQUIRES_TWO_STEP_AUTHORIZATION,
    payload: flag,
  });

const setTwoStepAuthenticationUrl = (url) => (dispatch) =>
  dispatch({
    type: SET_TWO_STEP_AUTHORIZATION_URL,
    payload: url,
  });

const setTwoStepAuthenticationCallback = (callback) => (dispatch) =>
  dispatch({
    type: SET_TWO_STEP_AUTHORIZATION_CALLBACK,
    payload: callback,
  });

const setTwoStepAuthenticationMethod = (callback) => (dispatch) =>
  dispatch({
    type: SET_TWO_STEP_AUTHORIZATION_METHOD,
    payload: callback,
  });

const flagSendingTwoStepAuthenticationRequest = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_SENDING_TWO_STEP_AUTHORIZATION_REQUEST,
    payload: flag,
  });

const sendTwoStepAuthenticationRequest =
  async ({ formValues, url, callback, method }) =>
  async (dispatch) => {
    try {
      dispatch(flagSendingTwoStepAuthenticationRequest(true));
      const payload = formValues;
      // send two step authorization request
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const twoStepAuthorizationResponse = await response.json();

      // send original request
      const originalPayload = {
        ...getDefaultConfig(method),
        body: twoStepAuthorizationResponse.payload,
      };
      const originalResponse = await fetch(
        `${BASE_URL}${twoStepAuthorizationResponse.endpoint}?operationId=${twoStepAuthorizationResponse.id}`,
        originalPayload,
      );

      await isErrorResponse(originalResponse);
      const originalResponseJson = await originalResponse.json();

      if (callback) {
        dispatch(callback(originalResponseJson));
      }
      dispatch(flagRequiresTwoStepAuthentication(false));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagSendingTwoStepAuthenticationRequest(false));
    }
  };

export {
  flagRequiresTwoStepAuthentication,
  setTwoStepAuthenticationUrl,
  setTwoStepAuthenticationCallback,
  sendTwoStepAuthenticationRequest,
  setTwoStepAuthenticationMethod,
};
