import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_EVENTS,
  GET_EVENTS,
  CLEAR_EVENTS,
  GET_EVENT,
  FLAG_EVENT_ACTIVITY,
  CLEAR_EVENT,
} from '../types';
import { EVENT_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingEvents = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_EVENTS,
    payload: flag,
  });

const getEvents = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingEvents(true));

    const query = tableFilters;

    const url = `${EVENT_ENDPOINT}?${QueryString.stringify(query)}`;

    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await isErrorResponse(response, null, dispatch);

    const events = await response.json();

    dispatch({
      type: GET_EVENTS,
      payload: events,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingEvents(false));
  }
};

const clearEvents = () => (dispatch) =>
  dispatch({
    type: CLEAR_EVENTS,
  });

const flagEventActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_EVENT_ACTIVITY,
    payload: flag,
  });

const getEvent =
  async ({ eventId }) =>
  async (dispatch) => {
    try {
      dispatch(flagEventActivity(true));
      const url = `${EVENT_ENDPOINT}/${eventId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const user = await response.json();
      dispatch({
        type: GET_EVENT,
        payload: user,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagEventActivity(false));
    }
  };

const clearEvent = () => (dispatch) =>
  dispatch({
    type: CLEAR_EVENT,
  });

export { flagGettingEvents, getEvents, clearEvents, getEvent, clearEvent };
