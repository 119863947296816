import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { change, Field, reduxForm } from 'redux-form';
import Alert from '../../../common/informative/Alert';
import Select from '../../../common/forms/select/Select';
import Loader from '../../../common/Loader';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import DynamicForm from '../../../common/forms/DynamicForm';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import { isRequired } from '../../../../utils/validators';
import { clearAgencies, getUserAgencies } from '../../../../actions';
import { generateInternalTransferItemColumns } from '../../../../config/dynamicFormFields';

const validateFields = (values) => {
  const errors = {};

  if (!values.internalCargoItems || !values.internalCargoItems.length) {
    errors.internalCargoItemsError = 'Debe ingresar un item';
  }

  return errors;
};

export const InternalParcelForm = ({
  handleSubmit,
  user,
  loading,
  agencyOptions,
  loadingAgencies,
  dispatchChange,
  dispatchClearAgencies,
  dispatchGetUserAgencies,
}) => {
  const [destinationLocation, setDestinationLocation] = useState(null);
  const [locationToShow, setLocationToShow] = useState([]);
  const [showError, setShowError] = useState(false);
  const [locationFetched, setLocationFetched] = useState(false);

  useLayoutEffect(
    () => () => {
      dispatchClearAgencies();
    },
    [],
  );

  useEffect(() => {
    dispatchGetUserAgencies({ userId: user.id });
  }, [user]);

  useEffect(() => {
    if (agencyOptions.length > 0) {
      const locationsToShow = [];
      agencyOptions.forEach((agency) => {
        if (
          !locationsToShow.some(
            (location) => location.value === agency.locationId,
          )
        ) {
          locationsToShow.push({
            value: agency.locationId,
            label: agency.locationName,
            city: {
              id: agency.cityId,
            },
          });
        }
      });

      dispatchChange(
        'InternalParcelForm',
        'sourceLocation',
        locationsToShow[0],
      );

      setLocationToShow(locationsToShow);
    }
  }, [agencyOptions]);

  const onClickRemove = (index, fields) => {
    fields.remove(index);
  };

  useEffect(() => {
    if (loadingAgencies) {
      setLocationFetched(true);
    }

    if (
      !loadingAgencies &&
      agencyOptions.length === 0 &&
      !showError &&
      locationFetched
    ) {
      toastr.error('Error', 'No se encontraron agencias asociadas al usuario');
      setShowError(true);
    }
  }, [agencyOptions, loadingAgencies, showError, locationFetched]);

  const handleLocationChange = (location, name) => {
    if (name === 'destinationLocation') {
      setDestinationLocation(location.value != null ? location.value : null);
    }
  };

  const renderDynamicForm = () => {
    if (destinationLocation === null) {
      return <Alert message="Seleccione Origen y Destino" />;
    }
    return (
      <DynamicForm
        name="internalCargoItems"
        columns={generateInternalTransferItemColumns(null, null, null)}
        onClickRemove={onClickRemove}
      />
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Origen" required>
            <Field
              name="sourceLocation"
              component={Select}
              isLoading={loadingAgencies}
              options={locationToShow}
              isDisabled={locationToShow.length <= 1}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Destino" required>
            <Field
              name="destinationLocation"
              component={LocationSelect}
              onChange={(option) =>
                handleLocationChange(option, 'destinationLocation')
              }
              isDisabled={locationToShow.length === 0}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <CustomerInputGroup
          label="Remitente"
          labelRequired
          name="senderCustomer"
          form="InternalParcelForm"
          validate={[isRequired]}
          showDetails
        />
        <CustomerInputGroup
          label="Consignado"
          labelRequired
          name="consigneeCustomer"
          form="InternalParcelForm"
          validate={[isRequired]}
          showDetails
        />
        <FormGroup row>
          <FormItem label="Nota">
            <Field
              name="note"
              component={TextInput}
              type="textarea"
              placeholder="Nota"
            />
          </FormItem>
        </FormGroup>
        <h5>Detalle de Carga</h5>
        <Field
          name="internalCargoItemsError"
          component={TextInput}
          type="hidden"
        />
        {renderDynamicForm()}
        <FormFooter />
      </Form>
    </>
  );
};

InternalParcelForm.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loadingAgencies: PropTypes.bool,
  agencyOptions: PropTypes.instanceOf(Array).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  dispatchClearAgencies: PropTypes.func.isRequired,
  dispatchGetUserAgencies: PropTypes.func.isRequired,
};

InternalParcelForm.defaultProps = {
  loading: false,
  loadingAgencies: false,
};

const mapStateToProps = (state) => ({
  user: state.authentication.get('user'),
  loading: !state.CargoUnit.Cargo.getIn(['current', 'activity']).isEmpty(),
  agencyOptions: state.HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]),
  loadingAgencies: state.HumanResourcesUnit.Agency.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetUserAgencies: getUserAgencies,
  dispatchClearAgencies: clearAgencies,
};

const formComponent = reduxForm({
  form: 'InternalParcelForm',
  validate: validateFields,
})(InternalParcelForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
