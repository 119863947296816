import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import { SEGMENT_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import {
  FLAG_GETTING_SEGMENTS,
  GET_SEGMENTS,
  CLEAR_SEGMENTS,
  FLAG_SEGMENT_ACTIVITY,
  CLEAR_SEGMENT,
  GET_SEGMENT,
} from '../types';
import { SEGMENT_PATH } from '../../config/paths';

const flagGettingSegments = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_SEGMENTS,
    payload: flag,
  });

const clearSegments = () => (dispatch) =>
  dispatch({
    type: CLEAR_SEGMENTS,
  });

const flagSegmentActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_SEGMENT_ACTIVITY,
    payload: flag,
  });

const clearSegment = () => (dispatch) =>
  dispatch({
    type: CLEAR_SEGMENT,
  });

const obtainSegments = async (tableFilters) => {
  const url = `${SEGMENT_ENDPOINT}?${QueryString.stringify(tableFilters)}`;
  const promise = await fetch(url, { ...DEFAULT_GET_CONFIG });
  await isErrorResponse(promise);
  const response = await promise.json();
  return response;
};

const getSegments =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingSegments(true));
      const segments = await obtainSegments(tableFilters);
      dispatch({ type: GET_SEGMENTS, payload: segments });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingSegments(false));
    }
  };

const postSegment =
  async ({
    sourceLocationId,
    destinationLocationId,
    distance,
    duration,
    isOneWay,
    tollSegmentList,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagSegmentActivity(true));

      const goingPayload = {
        sourceLocationId,
        destinationLocationId,
        distance,
        duration,
        isOneWay,
        tollSegmentList,
        lackingData: false,
      };
      const url = SEGMENT_ENDPOINT;

      const goingPromise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(goingPayload),
      });
      await isErrorResponse(goingPromise);
      const goingResponse = await goingPromise.json();
      let returnResponse = true;

      if (!isOneWay) {
        const returnPayload = { ...goingPayload };
        returnPayload.sourceLocationId = goingPayload.destinationLocationId;
        returnPayload.destinationLocationId = goingPayload.sourceLocationId;

        const returnPromise = await fetch(url, {
          ...DEFAULT_POST_CONFIG,
          body: JSON.stringify(returnPayload),
        });
        await isErrorResponse(returnPromise);
        returnResponse = await returnPromise.json();
      }

      return goingResponse && returnResponse;
    } catch ({ message }) {
      toastr.error('Error', message);
      return false;
    } finally {
      dispatch(flagSegmentActivity(false));
    }
  };

const getSegment =
  async ({ segmentId }) =>
  async (dispatch) => {
    try {
      dispatch(flagSegmentActivity(true));
      const url = `${SEGMENT_ENDPOINT}/${segmentId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_SEGMENT,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagSegmentActivity(false));
    }
  };

const putSegment =
  async (
    segmentId,
    {
      sourceLocationId,
      destinationLocationId,
      distance,
      duration,
      isOneWay,
      tollSegmentList,
      lackingData,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagSegmentActivity(true));

      const goingPayload = {
        sourceLocationId,
        destinationLocationId,
        distance,
        duration,
        isOneWay,
        tollSegmentList,
        lackingData,
        id: segmentId,
      };
      const goingUrl = `${SEGMENT_ENDPOINT}/${segmentId}`;
      const goingPromise = await fetch(goingUrl, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(goingPayload),
      });
      await isErrorResponse(goingPromise);
      const goingResponse = await goingPromise.json();
      let returnResponse = true;

      if (!isOneWay) {
        const query = [
          `sourceLocation.id:${destinationLocationId}`,
          `destinationLocation.id:${sourceLocationId}`,
        ];
        const { content: returnSegment } = await obtainSegments({ query });

        if (returnSegment.length > 0) {
          const returnPayload = {
            sourceLocationId: returnSegment[0].sourceLocationId,
            destinationLocationId: returnSegment[0].destinationLocationId,
            distance,
            duration,
            isOneWay: returnSegment[0].isOneWay,
            tollSegmentList: returnSegment[0].tollSegmentList.map(
              (segment) => ({
                id: segment.id,
                segmentId: segment.segmentId,
                tollId: segment.tollId,
              }),
            ),
            lackingData: returnSegment[0].lackingData,
            id: returnSegment[0].id,
          };

          const returnUrl = `${SEGMENT_ENDPOINT}/${returnSegment[0].id}`;
          const returnPromise = await fetch(returnUrl, {
            ...DEFAULT_PUT_CONFIG,
            body: JSON.stringify(returnPayload),
          });
          await isErrorResponse(returnPromise);
          returnResponse = await returnPromise.json();
        }
      }

      return goingResponse && returnResponse;
    } catch ({ message }) {
      toastr.error('Error', message);
      return false;
    } finally {
      dispatch(flagSegmentActivity(false));
    }
  };

const deleteSegment =
  async ({ segmentId }) =>
  async (dispatch) => {
    try {
      dispatch(flagSegmentActivity(true));
      const url = `${SEGMENT_ENDPOINT}/${segmentId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(SEGMENT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagSegmentActivity(false));
    }
  };

export {
  flagGettingSegments,
  getSegments,
  clearSegments,
  clearSegment,
  getSegment,
  postSegment,
  putSegment,
  deleteSegment,
};
