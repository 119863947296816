import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form, Button, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import FormItem from '../../../common/forms/FormItem';
import Select from '../../../common/forms/select/Select';
import {
  getCustomerEmails,
  clearCustomerEmails,
  getCustomerDeliveryAddresses,
  clearCustomerDeliveryAddresses,
  getPaymentMethods,
  getVoucherTypes,
  postCustomerEmail,
  postCustomerDeliveryAddress,
  postPostpaidBookingPretransaction,
} from '../../../../actions/';
import { isRequired, validateEmail } from '../../../../utils/validators';
import CreatableSelect from '../../../common/forms/select/CreatableSelect';
import {
  TRANSACTION_TYPE_PAGO_EFECTIVO,
  TRANSACTION_TYPE_DELIVERY_CREDIT_CARD,
  TRANSACTION_TYPE_DELIVERY_CASH,
  EMAIL_PATTERN,
} from '../../../../config/constants';
import VoucherTypeInputGroup from '../../../common/forms/VoucherTypeInputGroup';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import PagoEfectivoFields from './PagoEfectivoFields';
import TextInput from '../../../common/forms/input/TextInput';
import PaymentMethodSelect from '../../../common/forms/select/PaymentMethodSelect';

const formatCreateLabel = (inputValue) => <span>Registrar {inputValue}</span>;

const isValidEmail = (optionValue) => {
  const email = optionValue.label;
  return validateEmail(email);
};

const selector = formValueSelector('ReceiverForm');

class ReceiverForm extends Component {
  constructor(props) {
    super(props);

    const { dispatchGetPaymentMethods, dispatchGetVoucherTypes } = this.props;

    dispatchGetPaymentMethods();
    dispatchGetVoucherTypes();
  }

  state = {
    showVoucherCode: false,
    showDeliveryAddress: false,
  };

  handleChangeEmail = (option) =>
    this.props.change('userEmail', option ? option.label : null);

  handleCustomerChange = ({
    value: customerId,
    firstName,
    lastName,
    email,
    idDocumentNumber,
    mobilePhone,
  }) => {
    const {
      dispatchGetCustomerEmails,
      dispatchGetCustomerDeliveryAddresses,
      dispatchClearCustomerEmails,
      dispatchClearCustomerDeliveryAddresses,
      change,
    } = this.props;

    dispatchClearCustomerEmails();
    dispatchClearCustomerDeliveryAddresses();

    change('userName', null);
    change('userLastName', null);
    change('userEmail', null);
    change('userDocumentNumber', null);
    change('userPhone', null);

    if (customerId) {
      dispatchGetCustomerEmails({ customerId });
      dispatchGetCustomerDeliveryAddresses({ customerId });
      change('userName', firstName);
      change('userLastName', lastName);
      change('userEmail', email);
      change('userDocumentNumber', idDocumentNumber);
      change('userPhone', mobilePhone);
    }
  };

  isValidNewEmail = (inputValue, selectValue, selectOptions) => {
    const { selectedCustomer } = this.props;

    const existsInOptions = selectOptions.find(
      (option) => option.label === inputValue,
    );

    const isInputEmpty = inputValue.trim().length === 0;

    const validEmail = EMAIL_PATTERN.test(inputValue);

    return !isInputEmpty && !existsInOptions && validEmail && selectedCustomer;
  };

  handleNoEmails = () => {
    const { selectedCustomer } = this.props;

    if (!selectedCustomer)
      return <p className="text-danger">Debe seleccionar un destinatario</p>;

    return <p>Ingrese un email válido para ser registrado</p>;
  };

  handleCreateEmail = async (inputValue) => {
    const { selectedCustomer, change, dispatchGetCustomerEmails } = this.props;

    const newCustomerEmail = await postCustomerEmail({
      customerId: selectedCustomer.value,
      email: inputValue,
    });

    dispatchGetCustomerEmails({
      customerId: selectedCustomer.value,
    });

    change('email', {
      value: newCustomerEmail.id,
      label: newCustomerEmail.email,
    });
    change('userEmail', newCustomerEmail.email);
  };

  handlePaymentMethodChange = (paymentMethod) => {
    this.setState({
      showVoucherCode:
        paymentMethod.value &&
        paymentMethod.transactionCode === TRANSACTION_TYPE_PAGO_EFECTIVO,
    });

    this.setState({
      showDeliveryAddress:
        paymentMethod.value &&
        [
          TRANSACTION_TYPE_DELIVERY_CASH,
          TRANSACTION_TYPE_DELIVERY_CREDIT_CARD,
        ].includes(paymentMethod.transactionCode),
    });

    const { showVoucherCode, showDeliveryAddress } = this.state;
    const { change } = this.props;

    if (showVoucherCode) change('voucherCode', '');

    if (showDeliveryAddress) change('deliveryAddress', null);
  };

  isValidNewDeliveryAddress = (inputValue, selectValue, selectOptions) => {
    const { selectedCustomer } = this.props;

    const existsInOptions = selectOptions.find(
      (option) => option.label === inputValue,
    );

    const isInputEmpty = inputValue.trim().length === 0;

    return !isInputEmpty && !existsInOptions && selectedCustomer;
  };

  handleNoDeliveryAddress = () => {
    const { selectedCustomer } = this.props;

    if (!selectedCustomer)
      return <p className="text-danger">Debe seleccionar un destinatario</p>;

    return (
      <p>No hay direcciones registradas, ingrese una para ser registrada</p>
    );
  };

  handleCreateDeliveryAddress = async (inputValue) => {
    const { selectedCustomer, change, dispatchGetCustomerDeliveryAddresses } =
      this.props;

    const newCustomerDeliveryAddress = await postCustomerDeliveryAddress({
      customerId: selectedCustomer.value,
      deliveryAddress: inputValue,
    });

    dispatchGetCustomerDeliveryAddresses({
      customerId: selectedCustomer.value,
    });

    change('deliveryAddress', {
      value: newCustomerDeliveryAddress.id,
      label: newCustomerDeliveryAddress.deliveryAddress,
    });
  };

  handleReceiverSubmit = (formValues) => {
    const { customerOrderId, dispatchPostPostpaidBookingPretransaction } =
      this.props;

    const postpaidPretransaction = {
      customerOrderId,
      customerEmailAddressId: formValues.email.value,
      customerDeliveryAddressId: formValues.deliveryAddress
        ? formValues.deliveryAddress.value
        : null,
      paymentMethodId: formValues.paymentMethod.value,
      voucherTypeId: formValues.voucherType.value,
      businessId: formValues.business ? formValues.business.value : null,
      voucherCode: formValues.voucherCode || null,
      businessTaxId: formValues.businessTaxId,
      businessName: formValues.businessName,
      addressSummary: formValues.addressSummary,
    };

    dispatchPostPostpaidBookingPretransaction(postpaidPretransaction);
  };

  render() {
    const {
      handleSubmit,
      emails,
      isEmailLoading,
      deliveryAddresses,
      isDeliveryAddressLoading,
      isPaymentMethodLoading,
      submitting,
      change,
    } = this.props;

    const { showVoucherCode, showDeliveryAddress } = this.state;

    const voucherCodeField = showVoucherCode && (
      <>
        <hr />
        <FormGroup row>
          <FormItem label="Código">
            <Field
              name="voucherCode"
              component={TextInput}
              validate={[isRequired]}
              disabled
            />
          </FormItem>
        </FormGroup>
      </>
    );

    const voucherCode = (
      <>
        <PagoEfectivoFields
          selector={selector}
          show={showVoucherCode}
          form="ReceiverForm"
        />
        {voucherCodeField}
      </>
    );
    const deliveryAddress = showDeliveryAddress ? (
      <Fragment>
        <h2>Delivery</h2>
        <FormGroup row>
          <FormItem label="Dirección">
            <Field
              name="deliveryAddress"
              component={CreatableSelect}
              options={deliveryAddresses}
              isLoading={isDeliveryAddressLoading}
              placeholder="Calle de ejemplo 123 - Distrito ejemplo"
              isValidNewOption={this.isValidNewDeliveryAddress}
              noOptionsMessage={this.handleNoDeliveryAddress}
              onCreateOption={this.handleCreateDeliveryAddress}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      </Fragment>
    ) : null;
    return (
      <Form onSubmit={handleSubmit(this.handleReceiverSubmit)}>
        <CustomerInputGroup
          label="Destinatario"
          labelRequired
          name="customer"
          form="ReceiverForm"
          mobilePhoneRequired
          onChange={this.handleCustomerChange}
          showDetails
          autoFocus
        />
        <FormGroup row>
          <FormItem label="Correo">
            <Field
              invalid
              name="email"
              component={CreatableSelect}
              options={emails}
              isLoading={isEmailLoading}
              formatCreateLabel={formatCreateLabel}
              validate={[isRequired, isValidEmail]}
              onCreateOption={this.handleCreateEmail}
              isValidNewOption={this.isValidNewEmail}
              noOptionsMessage={this.handleNoEmails}
              onChange={this.handleChangeEmail}
            />
          </FormItem>
        </FormGroup>
        <VoucherTypeInputGroup
          voucherTypeFieldName="voucherType"
          businessFieldName="business"
          businessTaxIdFieldName="businessTaxId"
          businessNameFieldName="businessName"
          addressSummaryFieldName="addressSummary"
          change={change}
        />
        <FormGroup row>
          <FormItem label="Método de Pago">
            <Field
              name="paymentMethod"
              component={PaymentMethodSelect}
              onChange={this.handlePaymentMethodChange}
              validate={[isRequired]}
              isLoading={isPaymentMethodLoading}
              transactionCodesNotIncluded={[]}
            />
          </FormItem>
        </FormGroup>
        {voucherCode}
        {deliveryAddress}
        <div className="FormButtonGroup">
          <Button type="submit" disabled={submitting} color="primary" size="lg">
            Reservar <i className="fa fa-arrow-circle-right" />
          </Button>
        </div>
      </Form>
    );
  }
}

ReceiverForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  emails: Select.propTypes.options.isRequired,
  isEmailLoading: PropTypes.bool.isRequired,
  deliveryAddresses: Select.propTypes.options.isRequired,
  isDeliveryAddressLoading: PropTypes.bool.isRequired,
  dispatchGetCustomerEmails: PropTypes.func.isRequired,
  dispatchClearCustomerEmails: PropTypes.func.isRequired,
  dispatchGetCustomerDeliveryAddresses: PropTypes.func.isRequired,
  dispatchClearCustomerDeliveryAddresses: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  isPaymentMethodLoading: PropTypes.bool.isRequired,
  dispatchGetPaymentMethods: PropTypes.func.isRequired,
  customerOrderId: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatchPostPostpaidBookingPretransaction: PropTypes.func.isRequired,
  dispatchGetVoucherTypes: PropTypes.func.isRequired,
};

ReceiverForm.defaultProps = {
  selectedCustomer: null,
};

const mapStateToProps = (state) => ({
  emails: state.UserUnit.Customer.getIn(['emails', 'content', 'content']).map(
    (customerEmail) => ({
      value: customerEmail.id,
      label: customerEmail.email,
    }),
  ),
  isEmailLoading: state.UserUnit.Customer.getIn(['emails', 'loading']),
  deliveryAddresses: state.UserUnit.Customer.getIn([
    'deliveryAddresses',
    'content',
    'content',
  ]).map((deliveryAddress) => ({
    value: deliveryAddress.id,
    label: deliveryAddress.deliveryAddress,
  })),
  isDeliveryAddressLoading: state.UserUnit.Customer.getIn([
    'deliveryAddresses',
    'loading',
  ]),
  selectedCustomer: selector(state, 'customer'),
  isPaymentMethodLoading: state.AccountingUnit.PaymentMethod.getIn([
    'all',
    'loading',
  ]),
  voucherTypes: state.AccountingUnit.VoucherType.getIn([
    'all',
    'content',
    'content',
  ]).reduce((options, voucherType) => {
    if (voucherType.displayInPaymentsPage) {
      options.push({
        value: voucherType.id,
        label: voucherType.name,
      });
    }
    return options;
  }, []),
  isVoucherTypeLoading: state.AccountingUnit.VoucherType.getIn([
    'all',
    'loading',
  ]),
  customerOrderId: state.PostpaidBookingUnit.PostpaidBooking.getIn([
    'receiver',
    'customerOrder',
    'id',
  ]),
  businesses: state.UserUnit.Business.getIn(['all', 'content', 'content']).map(
    (business) => ({
      value: business.id,
      label: `${business.businessTaxId} - ${business.name}`,
    }),
  ),
  isBusinessLoading: state.UserUnit.Business.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCustomerEmails: getCustomerEmails,
  dispatchClearCustomerEmails: clearCustomerEmails,
  dispatchGetCustomerDeliveryAddresses: getCustomerDeliveryAddresses,
  dispatchClearCustomerDeliveryAddresses: clearCustomerDeliveryAddresses,
  dispatchGetPaymentMethods: getPaymentMethods,
  dispatchGetVoucherTypes: getVoucherTypes,
  dispatchPostPostpaidBookingPretransaction: postPostpaidBookingPretransaction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'ReceiverForm',
  })(ReceiverForm),
);
