import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { PAYMENT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { clearContractPayment, getContractPayment } from '../../../../actions';
import PaymentBasicInformationResource from './resource/PaymentBasicInformationResource';
import PaymentToolbar from './PaymentToolbar';
import { PAYMENT_STATUS } from '../../../../config/constants';

export const Payment = ({
  breadcrumbs,
  dispatchGetContractPayment,
  dispatchClearContractPayment,
  match: {
    params: { id: paymentId },
  },
  loading,
  payment,
}) => {
  useLayoutEffect(() => {
    dispatchGetContractPayment({ paymentId });

    return () => dispatchClearContractPayment();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (payment.isEmpty())
    content = <NoDataResource returnPage={PAYMENT_PATH} />;
  else {
    const paymentJson = payment.toJS();

    content = <PaymentBasicInformationResource {...paymentJson} />;

    toolbar =
      paymentJson.status === PAYMENT_STATUS.PENDING.value ? (
        <PaymentToolbar paymentId={paymentJson.id} />
      ) : null;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Pago"
      content={content}
    />
  );
};

Payment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearContractPayment: PropTypes.func.isRequired,
  dispatchGetContractPayment: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  payment: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Payment.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearContractPayment: clearContractPayment,
  dispatchGetContractPayment: getContractPayment,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Pagos',
      href: PAYMENT_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  payment: ContractUnit.Payment.getIn(['current', 'content']),
  loading: !ContractUnit.Payment.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
