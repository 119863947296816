import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { USER_SECURITY_PROFILE_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import {
  FLAG_GETTING_USER_SECURITY_PROFILES,
  GET_USER_SECURITY_PROFILES,
  CLEAR_USER_SECURITY_PROFILES,
} from '../types/user';

const flagGettingUserSecurityProfiles = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_USER_SECURITY_PROFILES,
    payload: flag,
  });

const getUserSecurityProfiles =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingUserSecurityProfiles(true));
      const query = tableFilters;
      const url = `${USER_SECURITY_PROFILE_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const profiles = await response.json();
      dispatch({ type: GET_USER_SECURITY_PROFILES, payload: profiles });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingUserSecurityProfiles(false));
    }
  };

const clearUserSecurityProfiles = () => (dispatch) =>
  dispatch({
    type: CLEAR_USER_SECURITY_PROFILES,
  });

export {
  flagGettingUserSecurityProfiles,
  getUserSecurityProfiles,
  clearUserSecurityProfiles,
};
