import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_CIRCUITS,
  GET_CIRCUITS,
  CLEAR_CIRCUITS,
  FLAG_CIRCUIT_ACTIVITY,
  GET_CIRCUIT,
  CLEAR_CIRCUIT,
} from '../types/route';
import { CIRCUIT_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingCircuits = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CIRCUITS,
    payload: flag,
  });

const flagCircuitActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CIRCUIT_ACTIVITY,
    payload: flag,
  });

const getCircuits = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCircuits(true));
    const query = tableFilters;
    const url = `${CIRCUIT_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const circuits = await response.json();
    dispatch({
      type: GET_CIRCUITS,
      payload: circuits,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingCircuits(false));
  }
};

const getCircuit =
  async ({ circuitId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCircuitActivity(true));
      const url = `${CIRCUIT_ENDPOINT}/${circuitId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const circuitGroup = await response.json();
      dispatch({
        type: GET_CIRCUIT,
        payload: circuitGroup,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCircuitActivity(false));
    }
  };

const clearCircuits = () => (dispatch) =>
  dispatch({
    type: CLEAR_CIRCUITS,
  });

const clearCircuit = () => (dispatch) =>
  dispatch({
    type: CLEAR_CIRCUIT,
  });

export {
  flagGettingCircuits,
  getCircuits,
  clearCircuits,
  getCircuit,
  clearCircuit,
};
