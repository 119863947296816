import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Field } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { GET } from '../../../../config/permissions';
import { LEGAL_ABANDONMENT_ENPOINT } from '../../../../config/endpoints';

const ListLegalAbandonmentCheckbox = () => (
  <FormGroup row>
    <FormItem>
      <Label>
        <Field
          name="listLegalAbandonment"
          id="listLegalAbandonment"
          component="input"
          type="checkbox"
        />{' '}
        Listar Encomiendas de Abandono Legal
      </Label>
    </FormItem>
  </FormGroup>
);

ListLegalAbandonmentCheckbox.propTypes = {};

const authorizedComponent = withEndpointAuthorization({
  url: LEGAL_ABANDONMENT_ENPOINT,
  permissionType: GET,
})(ListLegalAbandonmentCheckbox);

export default authorizedComponent;
