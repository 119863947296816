import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { postParcelVoid } from '../../../../actions';
import { PARCEL_STATUS } from '../../../../config/constants';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';

const VoidButton = ({ dispatchPostParcelVoid, parcelId, status }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleParcelVoid = () => {
    dispatchPostParcelVoid({ parcelId });
  };

  if (status !== PARCEL_STATUS.RECEIVED.value) {
    return null;
  }

  return (
    <>
      <Button color="danger" onClick={openConfirmationModal}>
        Anulación
      </Button>
      <ConfirmationModal
        show={showConfirmationModal}
        onClickConfirm={handleParcelVoid}
        onClickCancel={closeConfirmationModal}
      />
    </>
  );
};

VoidButton.propTypes = {
  dispatchPostParcelVoid: PropTypes.func.isRequired,
  parcelId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  dispatchPostParcelVoid: postParcelVoid,
};

export default connect(null, mapDispatchToProps)(VoidButton);
