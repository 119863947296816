import { toastr } from 'react-redux-toastr';
import { BASE_URL } from '../config/endpoints';
import {
  setTwoStepAuthenticationUrl,
  flagRequiresTwoStepAuthentication,
  setTwoStepAuthenticationCallback,
  setTwoStepAuthenticationMethod,
  deauthenticateUser,
} from '../actions';
import ApiError from './errors/ApiError';
import { DATA_NOT_REGISTERED_ERROR_MESSAGE } from '../config/messages';

/**
 * returns error if there is an error
 * returns false if there is no error
 * @param response
 * @returns {Promise<boolean>}
 */
const handleResponseError = async (response) => {
  const LOWEST_HTTP_ERROR_CODE = 400;
  const HIGHEST_HTTP_ERROR_CODE = 600;

  if (
    response.status >= LOWEST_HTTP_ERROR_CODE &&
    response.status < HIGHEST_HTTP_ERROR_CODE
  ) {
    // assumes response has a message property
    const { message } = await response.json();

    return message;
  }
  return false;
};

/**
 * throws error if there response code is between 400 and 600
 * error contains the returned message
 * @param response
 */
const isErrorResponse = async (
  response,
  callback,
  dispatch,
  method = 'post',
) => {
  const LOWEST_HTTP_ERROR_CODE = 400;
  const HIGHEST_HTTP_ERROR_CODE = 600;

  // header that indicates if it's a two step
  // authorization response
  const isTwoStepAuthorization = Boolean(
    response.headers.get('Two-Step-Authorization'),
  );

  // if status is 428 and isTwoStepAuthorization true
  // dispatch two step authorization actions
  if (response.status === 428 && isTwoStepAuthorization) {
    if (isTwoStepAuthorization) {
      const url = `${BASE_URL}${response.headers.get('Location')}`;
      dispatch(setTwoStepAuthenticationUrl(url));
      dispatch(flagRequiresTwoStepAuthentication(true));
      dispatch(setTwoStepAuthenticationCallback(callback));
      dispatch(setTwoStepAuthenticationMethod(method));
    }
  } else if (
    response.status >= LOWEST_HTTP_ERROR_CODE &&
    response.status < HIGHEST_HTTP_ERROR_CODE
  ) {
    // assumes response has a message property
    let error;
    try {
      error = await response.json();
    } catch (e) {
      if (response.status === 401) {
        setTimeout(() => dispatch(deauthenticateUser()), 1000);
        throw new Error('Se ha cerrado su sesión');
      }
    }
    const apiError = new ApiError(error.errorCode, error.message);
    throw apiError;
  } else if (callback) dispatch(callback(await response.json()));
};

const hadResponseSuccessWithErrorCode = (response) => {
  const allElementsAreSuccess = response.every(
    (row) => row.status === 'fulfilled',
  );
  const allElementsAreErrors =
    allElementsAreSuccess &&
    response.every(({ value }) => value.errorCode || value.errorCode === null);
  return allElementsAreErrors;
};

/**
 * validate if a element of promises array was rejected
 * @param response
 */
const hadErrorInPromiseAllSettled = async (response) => {
  const allElementsAreErrors = response.every(
    (row) => row.status === 'rejected',
  );
  if (response.length > 0 && allElementsAreErrors) {
    const INTERNAL_SERVER_ERROR_CODE = 500;
    const apiError = new ApiError(
      INTERNAL_SERVER_ERROR_CODE,
      DATA_NOT_REGISTERED_ERROR_MESSAGE,
    );
    throw apiError;
  } else {
    const someElementsAreErrors = response.some(
      (row) => row.status === 'rejected',
    );
    if (response.length > 0 && someElementsAreErrors)
      toastr.warning(
        'Advertencia',
        'Algunos registros no pudieron ser procesados, por favor verifique los datos',
      );
  }
};

export {
  handleResponseError,
  isErrorResponse,
  hadErrorInPromiseAllSettled,
  hadResponseSuccessWithErrorCode,
};
