import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { DEFAULT_GET_CONFIG, DEFAULT_PUT_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  ADD_SHAPE_DETAIL_FROM_PARCEL,
  CLEAR_ALL_DETAILS_FROM_PARCELS_TO_PICKUP,
  CLEAR_DETAIL_FROM_PARCEL,
  CLEAR_PARCELS_TO_PICKUP,
  FLAG_GETTING_DETAIL_FROM_PARCEL,
  FLAG_GETTING_PARCELS_TO_PICKUP,
  GET_DETAIL_FROM_PARCEL,
  GET_PARCELS_TO_PICKUP,
  DELETE_PARCELS_TO_PICKUP,
} from '../types';
import {
  GET_CARGO_ITEM_BY_PARCEL_ENDPOINT,
  PARCELS_TO_PICKUP_ENDPOINT,
  generatePutInternalParcelPickup,
  generatePutParcelPickup,
  generatePutParcelPickupByItem,
} from '../../config/endpoints';

const clearParcelsToPickup = () => (dispatch) =>
  dispatch({
    type: CLEAR_PARCELS_TO_PICKUP,
  });

const flagGettingParcels = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PARCELS_TO_PICKUP,
    payload: flag,
  });

const getParcelsToPickup = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingParcels(true));
    const query = tableFilters;
    const url = `${PARCELS_TO_PICKUP_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const parcels = await response.json();
    dispatch({
      type: GET_PARCELS_TO_PICKUP,
      payload: parcels,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingParcels(false));
  }
};

const putParcelsToPickup =
  ({ parcelId, internal, secretCode, paymentMethod, voucherCode, items }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingParcels(true));
      const url = internal
        ? generatePutInternalParcelPickup(parcelId)
        : generatePutParcelPickup(parcelId);
      const payload = paymentMethod
        ? {
            secretCode,
            paymentMethodId: paymentMethod.value,
            voucherCode,
          }
        : {
            secretCode,
            items,
          };
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const deliveredParcel = await response.json();
      dispatch({
        type: DELETE_PARCELS_TO_PICKUP,
        payload: deliveredParcel,
      });
      toastr.success('Entregado', 'Carga entregada correctamente');
      return true;
    } catch (error) {
      toastr.error('Error', error.message);
      return false;
    } finally {
      dispatch(flagGettingParcels(false));
    }
  };

const putParcelsToPickupByItem =
  ({ parcelId, secretCode, items, deliveredCustomerId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingParcels(true));
      const url = generatePutParcelPickupByItem(parcelId);
      const payload = { secretCode, items, deliveredCustomerId };
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const deliveredParcel = await response.json();
      dispatch({
        type: DELETE_PARCELS_TO_PICKUP,
        payload: deliveredParcel,
      });
      toastr.success('Entregado', 'Carga entregada correctamente');
      return true;
    } catch (error) {
      toastr.error('Error', error.message);
      return false;
    } finally {
      dispatch(flagGettingParcels(false));
    }
  };

const clearDetailFromParcel =
  ({ parcelId, internal }) =>
  (dispatch) =>
    dispatch({
      type: CLEAR_DETAIL_FROM_PARCEL,
      payload: { parcelId, internal },
    });

const clearAllDetailsFromParcelsToPickup = () => (dispatch) =>
  dispatch({
    type: CLEAR_ALL_DETAILS_FROM_PARCELS_TO_PICKUP,
  });

const flagGettingDetailFromParcel = (payload) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DETAIL_FROM_PARCEL,
    payload,
  });

const addShapeDetailFromParcel = (payload) => (dispatch) =>
  dispatch({
    type: ADD_SHAPE_DETAIL_FROM_PARCEL,
    payload,
  });

const getDetailFromParcel =
  async ({ parcelId, ...tableFilters }) =>
  async (dispatch) => {
    try {
      const query = tableFilters;
      const { internal } = query;
      dispatch(flagGettingDetailFromParcel({ parcelId, internal, flag: true }));
      const url = `${GET_CARGO_ITEM_BY_PARCEL_ENDPOINT}/${parcelId}?${QueryString.stringify(
        query,
      )}`;

      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const cargoItems = await response.json();
      const detailPayload = {
        content: cargoItems,
        parcelId,
        internal,
      };
      dispatch({
        type: GET_DETAIL_FROM_PARCEL,
        payload: detailPayload,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      const { internal } = tableFilters;
      dispatch(
        flagGettingDetailFromParcel({ parcelId, internal, flag: false }),
      );
    }
  };

export {
  clearParcelsToPickup,
  getParcelsToPickup,
  putParcelsToPickup,
  clearDetailFromParcel,
  clearAllDetailsFromParcelsToPickup,
  getDetailFromParcel,
  addShapeDetailFromParcel,
  putParcelsToPickupByItem,
};
