import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  NEW_TRIP_REQUEST_PATH,
  TRIP_REQUEST_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { TRIP_REQUEST_COLUMNS } from '../../../../config/columns';
import { clearTripRequests, getTripRequests } from '../../../../actions';

export const TripRequests = ({
  breadcrumbs,
  tripRequests,
  loading,
  dispatchGetTripRequests,
  dispatchClearTripRequests,
}) => {
  useLayoutEffect(() => () => dispatchClearTripRequests(), []);

  const data = tripRequests.get('content') || [];
  const pages = tripRequests.get('totalPages') || null;
  const defaultPageSize = tripRequests.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Solicitudes de Viajes"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_TRIP_REQUEST_PATH}
      tableStructure={{
        columns: TRIP_REQUEST_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetTripRequests,
        modelPath: TRIP_REQUEST_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetTripRequests: getTripRequests,
  dispatchClearTripRequests: clearTripRequests,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Solicitudes de Viajes',
      href: TRIP_REQUEST_PATH,
    },
  ],
  tripRequests: ItineraryUnit.TripRequest.getIn(['all', 'content']),
  loading: ItineraryUnit.TripRequest.getIn(['all', 'loading']),
});

TripRequests.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  tripRequests: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetTripRequests: PropTypes.func.isRequired,
  dispatchClearTripRequests: PropTypes.func.isRequired,
};

TripRequests.defaultProps = {
  tripRequests: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(TripRequests);
